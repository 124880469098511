import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Translations } from '@graphics-flow/shared/assets';
import { ApiError, Art, GenericRemovalDialogData, NotificationType, Tag } from '@graphics-flow/types';
import { ArtService, TagQuery, TagService, UserQuery } from '@graphics-flow/util';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { ControlsOf } from '@ngneat/reactive-forms/lib/types';
import { ControlState } from '@ngneat/reactive-forms/lib/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { cloneDeep as _cloneDeep, sortBy as _sortBy } from 'lodash-es';
import { Observable } from 'rxjs/internal/Observable';
import { filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { MergeTagsDialogComponent } from '../merge-tags-dialog/merge-tags-dialog.component';
import { GenericDataRemovalDailogComponent, NotificationService } from 'shared/ui';

interface NewTag {
  tag: string
}

@UntilDestroy()
@Component({
  selector: 'tag-manager-dialog',
  templateUrl: 'tag-manager-dialog.component.html',
  styleUrls: ['tag-manager-dialog.component.scss']
})
export class TagManagerDialogComponent implements OnInit {
  @ViewChild('newTagInputSection') newTagInputSection: ElementRef;
  @ViewChild('inputNewTagElement') inputNewTagElement: ElementRef;

  showAddNewTag: boolean;
  tags: Tag[];
  newTagFormGroup: FormGroup<ControlsOf<NewTag>>;
  isNewTagFormGroupValid$: Observable<boolean>;
  mergeTagsDialogRef: MatDialogRef<MergeTagsDialogComponent>;
  loading: boolean;
  tagManagerDialogRef: MatDialogRef<TagManagerDialogComponent>;
  tagsCountMessageMap: { [k: string]: string } = {
    '=1': this.translations.common.tag,
    'other': this.translations.common.tags
  };

  constructor(
    private artService: ArtService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<TagManagerDialogComponent>,
    private notificationService: NotificationService,
    private tagService: TagService,
    public tagQuery: TagQuery,
    public translations: Translations,
    public readonly userQuery: UserQuery,
    @Inject(MAT_DIALOG_DATA) public data: Art
  ) {
  }

  ngOnInit() {
    this.getTags();
    this.newTagFormGroup = new FormGroup<ControlsOf<NewTag>>({
      tag: new FormControl('', {
        validators: [
          this.tagAlreadyExists(),
          Validators.required
        ]
      })
    });

    this.isNewTagFormGroupValid$ = this.newTagFormGroup.status$.pipe(
      map((status: ControlState) => status === 'VALID')
    );
  }

  tagAlreadyExists(): ValidatorFn {
    return (control: FormControl<string>): (ValidationErrors | null) => {
      const isExist = this.tags?.some((t: Tag) => t.canonicalName === control.value?.toLowerCase()?.trim());
      return isExist ? { alreadyExists: true } : null;
    };
  }

  close() {
    this.dialogRef.close();
  }

  showNewTagInput() {
    this.showAddNewTag = true;
    setTimeout(() => {
      this.newTagInputSection.nativeElement.scrollIntoView({ behavior: 'smooth'});
      this.inputNewTagElement?.nativeElement.focus();
    }, 0);
  }

  addNewTag() {
    const newTag = this.newTagFormGroup.controls.tag.value;
    if (!newTag) {
      return;
    }

    if (this.newTagFormGroup.invalid) {
      return;
    }

    this.loading = true;

    this.tagService.addNewTag(newTag)
      .subscribe(() => {
        this.getTags();
        this.loading = false;
        this.resetNewTagField();
      }, (err: ApiError) => {
        this.loading = false;
        this.notificationService.showNotification(NotificationType.ERROR, 'Error', err.message);
      });
  }

  resetNewTagField() {
    this.showAddNewTag = false;
    this.newTagFormGroup.reset();
  }

  cancelExistingTagEdit(position: number) {
    this.tags[position].isEdit = false;
    this.tags[position].displayName = this.tags[position].backupValue;
  }

  editTag(position: number) {
    this.resetTags();
    this.tags[position].isEdit = true;
  }

  saveExistingTag(position: number) {
    if (!this.tags[position].displayName) {
      return;
    }
    const tagToBeEdited = this.tags[position];
    let existingTagPosition: number;

    this.tags.forEach((t: Tag, i: number) => {
      if (i !== position && this.tags[i].canonicalName === tagToBeEdited.displayName.toLowerCase()) {
        existingTagPosition = i;
      }
    });
    if (existingTagPosition >= 0) {
      this.mergeTag(position);
    } else {
      this.renameTag(position);
    }
  }

  renameTag(pos: number) {
    this.loading = true;
    this.tagService.editTag(this.tags[pos].backupValue, this.tags[pos].displayName).pipe(
      filter((tag: Tag) => {
        this.getTags();
        if (tag.count > 0) {
          return true;
        }
        this.loading = false;
        return false;
      }),
      switchMap(() => this.artService.getArtById(this.data.artId))
    ).subscribe(() => {
      this.loading = false;
    }, (err: ApiError) => {
      this.loading = false;
      this.notificationService.showNotification(NotificationType.ERROR, 'Error', err.message);
    });
  }

  mergeTag(sourcePos: number) {
    const tagToBeMareged = this.tags[sourcePos];
    const dialogConfig: MatDialogConfig = {
      autoFocus: false,
      data: this.tags[sourcePos].displayName,
      disableClose: false,
      hasBackdrop: false,
      panelClass: 'mobile-screen-modal',
      restoreFocus: false,
    };
    this.dialog.open(MergeTagsDialogComponent, dialogConfig).afterClosed().pipe(
      filter((isMerge: boolean) => isMerge),
      switchMap(() => {
          this.loading = true;
          return this.tagService.mergeTags(tagToBeMareged)
        }
      ),
    ).subscribe((mergedTag: Tag) => {
      if (tagToBeMareged.count > 0 || mergedTag.count > 0) {
        this.getArtById();
      } else {
        this.loading = false;
      }
      this.getTags();
    }, (err: ApiError) => {
      this.loading = false;
      this.notificationService.showNotification(NotificationType.ERROR, 'Error', err.message);
    });
  }

  deleteTag(position) {
    this.tagManagerDialogRef = this.dialog.openDialogs.find((dialog: MatDialogRef<any>) => dialog.id === 'TAG_MANAGER_DIALOG');
    if (this.tagManagerDialogRef) {
      this.tagManagerDialogRef.addPanelClass('hide-dialog');
    }
    this.dialog.open(GenericDataRemovalDailogComponent, {
      autoFocus: false,
      data: <GenericRemovalDialogData>{
        header: this.translations.tag.delete_tag,
        body: this.translations.tag.delete_tag_warning,
        removalData: this.tags[position].displayName,
        note: this.translations.tag.delete_tag_warning_note,
        cancelText: this.translations.common.cancel,
        continueText: this.translations.tag.delete_tag
      },
      disableClose: false,
      hasBackdrop: false,
      panelClass: 'mobile-screen-modal',
    }).afterClosed().pipe(
      tap(() => {
        if (this.tagManagerDialogRef) {
          this.tagManagerDialogRef.removePanelClass('hide-dialog');
        }
      }),
      filter((isDelete: boolean) => isDelete),
      switchMap(() => {
        this.loading = true;
        return this.tagService.deleteTag(this.tags[position].displayName)
      })
    ).subscribe(() => {
      if (this.tags[position].count > 0) {
        this.getArtById();
      } else {
        this.loading = false;
      }
      this.getTags();
    }, (err: ApiError) => {
      this.loading = false;
      this.notificationService.showNotification(NotificationType.ERROR, 'Error', err.message);
    });
  }

  getArtById() {
    this.artService.getArtById(this.data.artId).pipe(
      finalize(() => {
        this.loading = false;
      })
    ).subscribe();
  }

  getTags() {
    this.tagQuery.tags$.pipe(
      take(1),
      tap((tags: Tag[]) => {
        this.tags = _cloneDeep(tags);
        this.sort();
      })
    ).subscribe();
  }

  sort() {
    this.tags = _sortBy(this.tags, (tag: Tag) => tag.canonicalName);
  }

  resetTags(): void {
    this.tags.forEach((tag: Tag) => {
      tag.isEdit = false;
      tag.displayName = tag.backupValue;
    });
  }

}
