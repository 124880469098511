import { Injectable } from '@angular/core';
import { ID } from '@graphics-flow/types';
import { createState, Store, withProps } from '@ngneat/elf';

export interface FolderMoveDialogState {
   selectedFolderId: ID;
   navigationLevel: ID;
}

export const initialState: FolderMoveDialogState = {
    selectedFolderId: null,
    navigationLevel: null
};

const { state, config } = createState(
  withProps<FolderMoveDialogState>(initialState)
)

@Injectable({ providedIn: 'root' })
export class FolderMoveDialogStore extends Store {

  constructor() {
    super({ name: 'folder-move-dialog', state, config });
  }
}
