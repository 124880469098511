import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule } from '../color-picker/color-picker.module';
import { ColorPickerDropdownComponent } from './color-picker-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    ColorPickerModule,
    TranslateModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
  ],
  declarations: [
    ColorPickerDropdownComponent,
  ],
  exports: [
    ColorPickerDropdownComponent
  ]
})
export class ColorPickerDropdownModule {
}
