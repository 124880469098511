import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Translations } from '@graphics-flow/shared/assets';

@Component({
  selector: 'graphics-flow-collection-filter-dialog',
  templateUrl: './collection-filter-dialog.component.html',
  styleUrls: ['./collection-filter-dialog.component.scss']
})
export class CollectionFilterDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {canShowClipArtMobileViewFilter: boolean},
    public matDialogRef: MatDialogRef<CollectionFilterDialogComponent>,
    public translations: Translations
  ) {
  }

}
