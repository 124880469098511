<ng-container *ngFor="let folder of children$ | async">
  <div [class.selected]="(folderMoveDialogQuery.selectedFolder$ | async)?.folderId === folder.folderId"
       class="folder-item flex-container align-middle m-x-3 p-l-2 p-r-1">

    <div class="folder-name flex-1 flex-container align-middle text-truncate"
         (click)="setSelectedFolder(folder?.folderId)">
      <mat-icon class="m-r-2" svgIcon="folder-icon"></mat-icon>
      <span class="t-14-600-p flex-1 text-truncate">{{folder.name}}</span>
    </div>

    <div *ngIf="folder | isDescendantAvailable: this.sourceFolderIds"
         class="flex-container align-middle align-center">
      <mat-spinner *ngIf="showFolderLoaderById === folder?.folderId; else showArrow"
        [diameter]="20"
        [strokeWidth]="2"
        color="primary"
        class="folder-loading-state">
      </mat-spinner>
      <ng-template #showArrow>
        <button [class.cursor-none]="!!showFolderLoaderById" mat-icon-button (click)="toggleExpanded(folder?.folderId)">
          <mat-icon class="hint" fontIcon="keyboard_arrow_right"></mat-icon>
        </button>
      </ng-template>
    </div>

  </div>
</ng-container>
