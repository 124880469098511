<div id="finalApprovalActionsDialog">
  <div class="grid-y">
    <div class="flex-container flex-dir-column align-center-middle">
      <img *ngIf="approvalDialogData?.organization?.imageAssetGuid" [src]="approvalDialogData.organization | organizationImage: ImagePreviewType.THUMBNAIL" class="logo" [alt]="organization?.name">
      <p *ngIf="approvalDialogData?.approvalName" class="t-28-600-p m-y-4" [title]="approvalDialogData?.approvalName">{{approvalDialogData?.approvalName | truncate: 60}}</p>
    </div>
    <h3 [class.t-20-500-p]="windowsService.tiny$ | async">{{translations.approval.review_term_n_condition | translate}}</h3>
    <form autocomplete="off">
      <div mat-dialog-content class="terms m-y-1" [innerHTML]="(organizationQuery.organization$ | async)?.termsAndConditions || defaultTermsAndConditions">
      </div>
    </form>
    <mat-checkbox #terms class="agree-to-terms m-y-4 display-block">{{translations.common.i_agree_to_terms | translate}}</mat-checkbox>
  </div>
  <mat-dialog-actions class="approval-action-wrap"
    [class.align-right]="!(windowsService.tiny$ | async)">
    <button id="cancelBtn"
      mat-dialog-close
      mat-raised-button
      color="secondary"
      class="large"
      [class.m-y-1]="windowsService.tiny$ | async">
      {{this.translations.common.cancel | translate}}
    </button>
    <button id="submitBtn"
      mat-raised-button
      color="secondary"
      [disabled]="!terms.checked"
      [class.success]="terms.checked"
      class="large"
      (click)="approve()">
      {{this.translations.approval.approve_artwork | translate}}
    </button>
  </mat-dialog-actions>
</div>
