import { Injectable } from '@angular/core';
import { createState, Store, withProps } from '@ngneat/elf';
import { ArtDetailSections, ID } from '@graphics-flow/types';

export interface MyArtDetailState {
  isDrawerOpen: boolean;
  activeSection?: string;
  artId: ID;
}

const initialState: MyArtDetailState = {
  isDrawerOpen: true,
  artId: null
};

const { state, config } = createState(
  withProps<MyArtDetailState>(initialState)
);

@Injectable({ providedIn: 'root' })
export class MyArtDetailStore extends Store {

  constructor() {
    super({ name: 'my-art-detail', state, config })
  }

  updateState(myArtDetailState: MyArtDetailState) {
    this.update((state) => ({
      ...state,
      ...myArtDetailState
    }));
  }

  reset() {
    this.updateState(initialState);
  }

  setActiveSection(activeSection: ArtDetailSections): void {
    this.update((state) => ({
        ...state,
        activeSection: activeSection,
        isDrawerOpen: true
      })
    );
  }

}
