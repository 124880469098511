import { Pipe, PipeTransform } from '@angular/core';
import { StringHelpers } from 'shared/util';

@Pipe({
  name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {

  transform(value: string, onlyFirst: boolean = false): string {
    return StringHelpers.shortName(value, onlyFirst);
  }

}
