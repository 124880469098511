import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { CollaboratorQuery } from '@graphics-flow/util';
import { Collaborator, ID } from '@graphics-flow/types';

@Pipe({
  name: 'collaborator'
})
export class CollaboratorPipe implements PipeTransform {
  constructor(private readonly collaboratorQuery: CollaboratorQuery) {

  }

  transform(collaboratorId: ID): Observable<Collaborator> {
    return this.collaboratorQuery.selectCollabratorEntity(collaboratorId);
  }

}
