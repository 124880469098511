import { Pipe, PipeTransform } from '@angular/core';
import { Folder, ID } from '@graphics-flow/types';
import { FolderQuery } from '@graphics-flow/util';

@Pipe({
  name: 'isDescendantAvailable'
})
export class IsDescendantAvailablePipe implements PipeTransform {

  constructor(private readonly folderQuery: FolderQuery) {}

  // Used to check, whether folder has children.
  transform(folder: Folder, selectedFolderIds: ID[]): boolean {
    const children = this.folderQuery.getFolderChildren(folder?.folderId);
    if (children?.length === 1 && selectedFolderIds?.length) {
      // If folder has only one child & that to got selected to move, So we should consider it as folder without child
      // so we should return false here...
      return (folder?.hasChildFolder || !!children.length) && !selectedFolderIds.includes(children[0].folderId);
    }
    return folder?.hasChildFolder || !!children.length;
  }

}
