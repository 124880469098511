import { Pipe, PipeTransform } from '@angular/core';
import { EnvService } from '@graphics-flow/api';
import { ID, ImagePreviewType } from '@graphics-flow/types';

@Pipe({
  name: 'imagePreview'
})
export class ImagePreviewPipe implements PipeTransform {

  constructor(private envService: EnvService) {
  }

  transform(
    orgId: ID,
    assetId: string,
    previewType: ImagePreviewType,
    isWaterMark: boolean = false,
    isStockArt: boolean = false,
    extName?: string,
    watermarkId?: string,
    isFont: boolean = false,
    blockCache: boolean = false,
    timeStamp?: string
  ): string {

    let url = `${this.envService.apiEndpoint}/preview/${orgId}/${assetId}/${previewType}/${isWaterMark}/${isStockArt}/${isFont}`;
    if (isWaterMark && watermarkId) {
      url += `?waterMarkId=${watermarkId}`
    }
    if (extName) {
      url += url.includes('?waterMarkId') ? `&sourceExtensionName=${extName}` : `?sourceExtensionName=${extName}`;
    }
    if (blockCache) {
      url += (url.includes('?waterMarkId') || url.includes('?sourceExtensionName')) ? `&blockCache=${blockCache}` : `?blockCache=${blockCache}`;
    }
    if (isWaterMark && timeStamp) {
      url += `&timeStamp=${timeStamp}`;
    }
    return url;
  }

}
