import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, throwError } from 'rxjs';
import { ApiError, User } from '@graphics-flow/types';
import { GraphicsFlowService, SegmentService, UserQuery, UserService } from '@graphics-flow/util';
import { WelcomeToGFDialogComponent } from './components/welcome-to-gf-dialog/welcome-to-gf-dialog.component';

@Component({
  selector: 'gf-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

  isUserWelcomed$ = new Subject();

  @HostListener('window:beforeunload', ['$event'])
  isBulkActionInProgress($event): string {
    if (this.graphicsFlowService.isBulkActionInProgress$.getValue()) {
      const warning = 'bulkActionInProgress';
      $event.preventDefault();
      $event.returnValue = warning;
      return warning;
    }
    return '';
  }

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private userQuery: UserQuery,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private readonly segmentService: SegmentService,
    private readonly graphicsFlowService: GraphicsFlowService
  ) {

    this.router.events.pipe(
      takeUntil(this.isUserWelcomed$),
      filter((e) => e instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while(child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['showWelcomeGFModal']) {
            return child.snapshot.data['showWelcomeGFModal'];
          } else {
            return false;
          }
        }
      })
    ).subscribe((showFileUploadPanel: boolean) => {
      const user: User = _cloneDeep(this.userQuery.getUser());

      if (!showFileUploadPanel || !user) {
        return;
      }

      if (user?.isFirstLogin) {
        this.matDialog.open(WelcomeToGFDialogComponent, {
          autoFocus: false,
          backdropClass: ['welcome-modal-backdrop'],
          closeOnNavigation: false,
          disableClose: true,
          hasBackdrop: true,
          panelClass: 'welcome-modal-panelclass'
        }).afterClosed().pipe(
          filter((isWelcomed: boolean) => isWelcomed),
          switchMap(() => {
            user.isFirstLogin = false;
            // update in store before api call to prevent showing modal again in different route.
            this.userService.changeUserFirstLoginStatus(user.userId, false);
            return userService.updateUser(user);
          }),
          catchError((error: ApiError) => {
            // If the api throws error then roll back to previous status.
            this.userService.changeUserFirstLoginStatus(user.userId, true);
            return throwError(() => error);
          })
        ).subscribe();
      } else {
        this.isUserWelcomed$.next(null);
      }
    });
  }

  ngOnInit(): void {
    this.segmentService.track('graphics-flow-main');
  }

  ngOnDestroy(): void {
    this.isUserWelcomed$?.unsubscribe();
  }
}
