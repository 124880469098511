import { Injectable } from '@angular/core';
import { createState, Store, withProps } from '@ngneat/elf';

import { ID, StockArtDetailSections } from '@graphics-flow/types';

export interface StockArtDetailState {
  activeStockArtId?: ID;
  ui: {
    detail: {
      isDrawerOpen: boolean;
      activeSection: string;
    };
  };
}

export const initialStockArtDetailState = {
  ui: {
    detail: {
      isDrawerOpen: true,
      activeSection: StockArtDetailSections.INFO
    }
  }
};

const { state, config } = createState(
  withProps<StockArtDetailState>(initialStockArtDetailState)
);

@Injectable({ providedIn: 'root' })
export class StockArtDetailStore extends Store {

  constructor() {
    super({ name: 'stock-art-detail', state, config });
  }

  toggleDrawerStatus() {
    this.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        detail: {
          ...state.ui.detail,
          isDrawerOpen: !state.ui.detail.isDrawerOpen
        }
      }
    }));
  }

  setActiveSection(section: StockArtDetailSections) {
    this.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        detail: {
          activeSection: section,
          isDrawerOpen: true
        }
      }
    }));
  }
}
