import { Pipe, PipeTransform } from '@angular/core';
import { BasePrice, Plan, SelectedPlanInfo, SubscriptionItem } from '@graphics-flow/types';
import { BillingPlansQuery, UpdateSubscriptionPlanService } from '@graphics-flow/util';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { cloneDeep as _cloneDeep } from 'lodash-es';

@Pipe({
  name: 'selectedBundlePlan'
})
export class SelectedBundlePlanPipe implements PipeTransform {
  constructor(private billingPlansQuery: BillingPlansQuery,
    private readonly updateSubscriptionPlanService: UpdateSubscriptionPlanService) {
  }

  transform(selectedPlan: SelectedPlanInfo): Observable<SelectedPlanInfo> {
    return combineLatest([
      this.billingPlansQuery.currentPlan$,
      this.billingPlansQuery.currentStorageSubscription$,
      this.billingPlansQuery.currentStockArtSubscription$,
      this.billingPlansQuery.currentUserSubscription$,
      this.billingPlansQuery.subscriptionTotalUsers$,
      this.billingPlansQuery.getBundlePlans(),
      this.billingPlansQuery.currentSmartDesignerAddOnSubscription$,
      this.billingPlansQuery.currentSmartDesignerAdditionalDevicesAddOnSubscription$,
    ])
    .pipe(
      map(([ currentPlan, currentStorageSubscription, currentStockArtSubscription,
        currentUserSubscription, subscriptionTotalUsers, bundlePlans,
        currentSmartDesignerAddOn, currentSmartDesignerAdditionalDevicesAddOn ]:
        [ BasePrice, SubscriptionItem, SubscriptionItem, SubscriptionItem,
        number, Plan[], SubscriptionItem, SubscriptionItem ]) => {
        const selectNewPlan: SelectedPlanInfo = _cloneDeep(selectedPlan);

        // Current Plan
        if (!selectNewPlan.selectedPlan) {
          selectNewPlan.selectedPlan = _cloneDeep(currentPlan);
        }

        // Addon Storage
        if (!selectNewPlan.storagePlan) {
          selectNewPlan.storagePlan = _cloneDeep(currentStorageSubscription?.price);
        }

        // Addon Stock Art
        if (!selectNewPlan.stockArtPlan) {
          selectNewPlan.stockArtPlan = _cloneDeep(currentStockArtSubscription?.price);
        }

        // Smart Designer Addon
        if (!selectNewPlan.smartDesignerAddOnPlan) {
          selectNewPlan.smartDesignerAddOnPlan = _cloneDeep(currentSmartDesignerAddOn?.price);
        }

        // Smart Designer Additional Devices Addon
        if (!selectNewPlan.smartDesignerAdditionalDevicesAddOnPlan) {
          selectNewPlan.smartDesignerAdditionalDevicesAddOnPlan = _cloneDeep(currentSmartDesignerAdditionalDevicesAddOn?.price);
        }

        // Team members
        let addonUserCount = 0;
        if (this.updateSubscriptionPlanService.isReactivationInProgress.getValue()) {
          selectNewPlan.userPlan = _cloneDeep(this.billingPlansQuery.getAdditionalUserInfoWithCount((selectNewPlan?.selectedPlan?.users || 0)));
        } else {
          selectNewPlan.userPlan = _cloneDeep(currentUserSubscription?.price);
          if (currentUserSubscription?.price && selectNewPlan.selectedPlan?.users) {
            addonUserCount = selectNewPlan.selectedPlan.users < subscriptionTotalUsers ? subscriptionTotalUsers - selectNewPlan.selectedPlan.users : 0;
            selectNewPlan.userPlan.quantity = addonUserCount;
          }
        }

        // Current Plan info
        const selectedCurrentPlan: Plan = bundlePlans.find((plan) => plan.product?.productMetadata?.plan_name === selectNewPlan.selectedPlan?.productMetadata?.plan_name);
        selectNewPlan.planName = 'plans.bundle_plans.plan_price_' + selectedCurrentPlan.plan;

        // Calculating remaining add on amount without bundle plan
        let totalAmount = 0;
        for (const selectedPlanKey in selectNewPlan) {
          if (selectNewPlan[selectedPlanKey] && selectNewPlan[selectedPlanKey]?.amount) {
            totalAmount += (selectNewPlan[selectedPlanKey]?.amount || 0) * selectNewPlan[selectedPlanKey]?.quantity;
          }
        }

        // Calculating new plan total amount
        selectNewPlan.newPlanTotalAmount = totalAmount;
        return selectNewPlan;
      })
    );
  }
}
