import { Component, Input, OnInit } from '@angular/core';
import { StockArtType } from '@graphics-flow/types';
import { Translations } from '@graphics-flow/shared/assets';
import { FormControl } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { WindowService } from 'shared/util';
import { BillingPlansQuery, StockArtListQuery, StockArtListService } from '@graphics-flow/util';

@UntilDestroy()
@Component({
  selector: 'gf-stock-art-filters',
  templateUrl: './stock-art-filters.component.html',
  styleUrls: ['./stock-art-filters.component.scss']
})
export class StockArtFiltersComponent implements OnInit {

  @Input() alignmentClass = '';
  @Input() showDynamicSearchPlaceholder: boolean;
  @Input() showCollectionFilter: boolean;
  @Input() showDownloadFilter = true;
  @Input() isCustomStockArtFilter: boolean;
  searchControl: FormControl<string> = new FormControl<string>('');
  StockArtType: typeof StockArtType = StockArtType;

  constructor(
    public readonly billingPlansQuery: BillingPlansQuery,
    public readonly stockArtListQuery: StockArtListQuery,
    public readonly stockArtListService: StockArtListService,
    public readonly translations: Translations,
    public windowService: WindowService
  ) { }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      untilDestroyed(this),
      filter((value: string) => value !== this.stockArtListQuery.getSearchText()),
      debounceTime(500),
    ).subscribe((value: string) => {
      this.stockArtListService.setActiveFilters({
        index: 0,
        searchText: value
      }, this.isCustomStockArtFilter);
    });

    this.stockArtListQuery.activeSearchText$.pipe(
      untilDestroyed(this),
      distinctUntilChanged(),
    ).subscribe((searchText: string) => {
      this.setSearch(searchText);
    })
  }

  setSearch(searchText: string): void {
    this.searchControl.setValue(searchText);
  }

}
