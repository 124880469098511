import { Pipe, PipeTransform } from '@angular/core';
import { ArtApproval } from '@graphics-flow/types';
import { ApprovalHelper } from '@graphics-flow/util';

@Pipe({
  name: 'isApprovalArchived'
})
export class IsApprovalArchivedPipe implements PipeTransform {

  transform(value: ArtApproval): boolean {
    return ApprovalHelper.isArchived(value);
  }

}
