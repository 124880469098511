import { Injectable } from '@angular/core';
import { setProps } from '@ngneat/elf';
import { stateHistory } from '@ngneat/elf-state-history';
import { StateHistory } from '@ngneat/elf-state-history/src/lib/state-history';
import { isEqual as _isEqual } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { StockArtHttpService } from '@graphics-flow/api';
import { ApiResponse, AssetSettings, ID, StockArtCategory, StockArtFilterType, StockArtHeaderPackage, StockArtOrder, StockArtPackage, StockArtRequest, StockArtType } from '@graphics-flow/types';
import { StockArtHelper } from '../../helpers/stock-art.helper';
import { StockArtCategoriesQuery } from '../stock-art-categories/stock-art-categories.query';
import { StockArtCategoriesService } from '../stock-art-categories/stock-art-categories.service';
import { StockArtService } from '../stock-art/stock-art.service';
import { StockArtListQuery } from './stock-art-list-query.service';
import { stockArtListBatchSize, StockArtListState, StockArtListStore } from './stock-art-list-store.service';
import { GlobalHelpers } from '../../helpers/global.helpers';

@Injectable({ providedIn: 'root' })
export class StockArtListService {
  destroyed$ = new Subject();
  _isScrolledTop: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isScrolledTop$: Observable<boolean>;
  stockArtListScrollPosition = 0;
  stockArtListStoreInfo: StockArtListState = null;
  stockArtSubscription$: Subscription;
  categoryFilterHistory:  StateHistory<StockArtListStore, StockArtListState>;

  constructor(
    private readonly stockArtListStore: StockArtListStore,
    private readonly stockArtListQuery: StockArtListQuery,
    private readonly stockArtCategoriesService: StockArtCategoriesService,
    private readonly stockArtHttpService: StockArtHttpService,
    private readonly stockArtService: StockArtService,
    private readonly stockArtCategoriesQuery: StockArtCategoriesQuery
  ) {
    this.isScrolledTop$ = this._isScrolledTop.asObservable();
  }

  initialize() {
    this.stockArtSubscription$ = this.stockArtListQuery.activeFilters$.pipe(
      debounceTime(200), // Filters get changed a lot, we don't necessarily want to make a call instantly every time
      distinctUntilChanged((oldFilter, newFilter) => {
        // Hack to remove "__proto__" from the objects
        // TODO: find a cleaner way to handle this.
        oldFilter = JSON.parse(JSON.stringify(oldFilter));
        newFilter = JSON.parse(JSON.stringify(newFilter));
        return _isEqual(oldFilter, newFilter);
      }),
      filter(activeFilters => {
        if (activeFilters.isArtPortal && activeFilters.hasArtPortalCategoryFilter) {
          return !!activeFilters.artPortalCategories?.length || !!activeFilters.artPortalSubCategories?.length;
        }
        return !activeFilters.isCategoryModalOpened
      }),
      switchMap((activeFilters: StockArtRequest) => {
        return this.stockArtService.getStockArtsPackage(StockArtHelper.buildStockArtRequest(activeFilters));
      }),
      takeUntil(this.destroyed$)
    ).subscribe((response: ApiResponse<StockArtPackage>) => {
      this.stockArtListStore.setPagination(response.pagination);
      if (response.pagination.index === 0) {
        this.stockArtListStore.setStockArts(response.data.stockArt);
      } else {
        this.stockArtListStore.appendStockArts(response.data.stockArt);
      }
    });

    this.stockArtListQuery.selectedCategories$.pipe(
      takeUntil(this.destroyed$),
      tap((cats: StockArtCategory[]) => {
        this.stockArtListStore.update(state => ({
          ...state,
          activeFilters: {
            ...state.activeFilters,
            filterHeaders: {
              ...state.activeFilters.filterHeaders,
              categories: cats?.length ? cats : undefined
            }
          }
        }));
      })
    ).subscribe();

    this.stockArtListQuery.selectedSubCategories$.pipe(
      takeUntil(this.destroyed$),
      tap((subCats: StockArtCategory[]) => {
        this.stockArtListStore.update(state => ({
          ...state,
          activeFilters: {
            ...state.activeFilters,
            filterHeaders: {
              ...state.activeFilters.filterHeaders,
              subCategories: subCats?.length ? subCats : undefined
            }
          }
        }));
      })
    ).subscribe();

    this.loadStockArtHeaders();
  }

  loadStockArtHeaders(): void {
    this.stockArtHttpService.getStockArtHeaders(StockArtType.DesignIdea).subscribe(
      (pkg: StockArtHeaderPackage) => {
        this.stockArtCategoriesService.addMany(pkg.categories);
        this.stockArtCategoriesService.addMany(pkg.subCategories);
        this.stockArtListStore.setFilterOptions(StockArtType.DesignIdea, pkg);
      });

    this.stockArtHttpService.getStockArtHeaders(StockArtType.ClipArt).subscribe(
      (pkg: StockArtHeaderPackage) => {
        this.stockArtCategoriesService.addMany(pkg.categories);
        this.stockArtCategoriesService.addMany(pkg.subCategories);
        this.stockArtListStore.setFilterOptions(StockArtType.ClipArt, pkg);
      });
  }

  setActiveFolderId(id: ID): void {
    return this.stockArtListStore.setActiveFilters({
      filterHeaders: {
        headerId: id
      }
    });
  }

  changeSortOrder(orderBy: StockArtOrder, orderDesc: boolean): void {
    this.stockArtListStore.update((state) => ({
      ...state,
      activeFilters: {
        ...state.activeFilters,
        index: 0,
        limit: 36,
        orderBy: orderBy,
        orderDesc: orderDesc
      },
      loading: true,
      filteredStockArts: []
    }));
  }

  changeFilterType(filterType: StockArtFilterType): void {
    this.stockArtListStore.update((state) => ({
      ...state,
      activeFilters: {
        ...state.activeFilters,
        index: 0,
        limit: 36,
        stockArtFilterType: filterType
      },
      loading: true,
      filteredStockArts: []
    }));
  }

  loadStockArtCategories() {
    this.stockArtCategoriesService.load();
  }

  setActiveFilters(activeFilters: StockArtRequest, csaClipArt: boolean = false, infiniteScroll: boolean = false) {
    // For ClipArt List View in CSA Section, we need to use csaClipArt (boolean) to overcome the bypassIndexValidation in store!
    this.stockArtListStore.setActiveFilters(activeFilters, !csaClipArt && this.stockArtListStoreInfo?.activeFilters?.index === 0, infiniteScroll);
  }

  setType(type: StockArtType) {
    this.resetFilters(type);
  }

  resetFilters(type?: StockArtType) {
    this.stockArtListStore.resetActiveFilters(type);
    this.stockArtListStore.resetCategorySelection();
  }

  removeActiveFilterHeader(key: string, value: ID) {
    this.stockArtListStore.removeActiveFilterHeader(key, value);
  }

  selectCategories(categories: StockArtCategory[]) {
    const catIds: ID[] = categories.map((cat) => cat.folderId);
    this.stockArtListStore.selectCategories(catIds);
  }

  deselectCategories(categories: StockArtCategory[]) {
    const catIds: ID[] = categories.map((cat) => cat.folderId);
    this.stockArtListStore.deselectCategories(catIds);
  }

  selectSubCategories(categories: StockArtCategory[]) {
    const catIds: ID[] = categories.map((cat) => cat.folderId);
    this.stockArtListStore.selectSubCategories(catIds);
  }

  deselectSubCategories(categories: StockArtCategory[], updateParents: boolean = true) {
    const catIds: ID[] = categories.map((cat) => cat.folderId);
    this.stockArtListStore.deselectSubCategories(catIds);

    if (updateParents) {
      // Deselect the parent categories of all of these as well
      const parentIds: ID[] = categories.map((cat) => cat.parentId);
      this.stockArtListStore.deselectCategories(parentIds);
    }
  }

  getBatch(offset: number) {
    const activeListStoreindex: number = this.stockArtListStoreInfo?.activeFilters?.index;
    this.setActiveFilters({
      index: (offset > 0) ? offset : (activeListStoreindex ?? offset),
      limit: stockArtListBatchSize
    }, false, offset !== 0);
  }

  selectStockArt(stockArtId: ID, selected: boolean) {
    this.stockArtListStore.update((state) => ({
      ...state,
      selectedStockArtIds: selected
        ? GlobalHelpers.arrayAdd(state.selectedStockArtIds, stockArtId)
        : GlobalHelpers.arrayRemove(state.selectedStockArtIds, stockArtId)
      })
    );
  }

  clearStockArtSelection() {
    this.stockArtListStore.setActiveFilters({
      index: 0,
      searchText: ''
    });
    this.clearSelectedStockArts();
  }

  clearSelectedStockArts() {
    this.stockArtListStore.update(setProps({
      selectedStockArtIds: []
    }));
  }

  clearSubscriptions() {
    this.destroyed$.next(null);
  }

  // Used to create the categoryFilter history.
  createCategoryFilterHistory(): void {
    // maxAge is set to 1000, because user can update the filter options as many times, also it prevents the loss of history.
    this.categoryFilterHistory = stateHistory(this.stockArtListStore, { maxAge: 1000 });
  }

  // Used to revert the recent updates on store.
  resetFilterHistory(): void {
    this.categoryFilterHistory.jumpToPast(0);
  }

  // Destory's the category filter history & also clear the updates.
  destroyCategoryFilterHistory(): void {
    this.categoryFilterHistory.destroy({ clearHistory: true });
  }

  setScrollYPosition(yPosition: number): void {
    this.stockArtListScrollPosition = yPosition ?? 0;
  }

  setStockArtListStoreInfo(updateStoreInfo: boolean = true): void {
    this.stockArtListStoreInfo = (updateStoreInfo && this.stockArtListStore.getValue()) || null;
  }

  updateActiveStoreInfo(): void {
    if (!this.stockArtListStoreInfo) {
      return;
    }
    this.stockArtListStore.updateState(this.stockArtListStoreInfo);
  }

  setArtPortalFilters(assetSetting: AssetSettings): void {
    const hasArtPortalCategoryFilter = !!assetSetting.categoryIds?.length || !!assetSetting.subCategoryIds?.length;
    this.stockArtListStore.setActiveFilters({
      defaultCategory: assetSetting.defaultCategory,
      isArtPortal: true,
      hasArtPortalCategoryFilter: hasArtPortalCategoryFilter
    });
    this.stockArtListStore.update((state) => ({
      ...state,
      activeFilters: {
        ...state.activeFilters,
        filterHeaders: {
          ...state.activeFilters.filterHeaders,
          hiddenKeywords: assetSetting.hiddenKeywords || []
        }
      }
    }));
    if (assetSetting.categoryIds?.length) {
      this.setArtPortalCategories(assetSetting, hasArtPortalCategoryFilter);
    }
    if (assetSetting.subCategoryIds?.length) {
      this.setArtPortalSubCategories(assetSetting);
    }
  }

  setArtPortalCategories(assetSetting: AssetSettings, hasArtPortalCategoryFilter: boolean): void {
    combineLatest([
      this.stockArtListQuery.activeStockArtTypeCategories$,
      this.stockArtCategoriesQuery.selectManyCategoryEntities(assetSetting.categoryIds)
    ]).pipe(
      filter(([categories, artPortalCategories]: [StockArtCategory[], StockArtCategory[]]) => !!categories.length && !!artPortalCategories?.length),
      take(1),
      tap(([categories, artPortalCategories]: [StockArtCategory[], StockArtCategory[]]) => {
        const isAllCategoriesSelected = categories?.length === artPortalCategories?.length;
        this.stockArtListStore.setActiveFilters({
          // If user select all the categories we will send empty array for categories to avoid API load.
          artPortalCategories: isAllCategoriesSelected ? [] : artPortalCategories,
          hasArtPortalCategoryFilter: isAllCategoriesSelected ? false : hasArtPortalCategoryFilter
        });
      })
    ).subscribe();
  }

  setArtPortalSubCategories(assetSetting: AssetSettings): void {
    this.stockArtCategoriesQuery.selectManyCategoryEntities(assetSetting.subCategoryIds).pipe(
      filter((subCategories: StockArtCategory[]) => !!subCategories.length),
      take(1),
      tap((artPortalSubCategories: StockArtCategory[]) => {
        this.stockArtListStore.setActiveFilters({
          artPortalSubCategories
        });
      })
    ).subscribe();
  }

  scrolledToBottom(offset: number) {
    const pagination = this.stockArtListQuery.getStockArtsPagination();
    if (pagination?.totalResults !== offset) {
      this.getBatch(offset);
    }
  }
}
