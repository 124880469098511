import { Injectable } from '@angular/core';
import { createState, Store } from '@ngneat/elf';
import { withEntities, withActiveId } from '@ngneat/elf-entities';
import { Folder } from '@graphics-flow/types';

const { state, config } = createState(
  withEntities<Folder, 'folderId'>({ idKey: 'folderId', initialValue: [] }),
  withActiveId()
);

@Injectable({
  providedIn: 'root'
})
export class FolderStore extends Store {
  constructor() {
    super({ name: 'folders', state, config })
  }
}
