import { ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

import { LoginResult, User } from '@graphics-flow/types';
import { ApiService } from '@graphics-flow/api';
import { UserService } from '@graphics-flow/util';

@UntilDestroy({checkProperties: true})
@Injectable({
  providedIn: 'root'
})
export class InviteTeammateResolveService  {

  email: string;
  tempPass: string;

  constructor(
    private readonly apiService: ApiService,
    private router: Router,
    private userService: UserService,
  ) {
  }

  resolve(activatedRoute: ActivatedRouteSnapshot): Observable<LoginResult> {
    const param: Params = activatedRoute.queryParams;
    const email: string = decodeURIComponent(param['email']);
    const tempPass: string = decodeURIComponent(param['tempPass']);
    if (!email || !tempPass) {
      this.router.navigate(['/signin'], { replaceUrl: true });
    } else {
      return this.userService.authenticate(email, tempPass).pipe(
        filter((user: LoginResult) => !!user),
        tap((loginResult: LoginResult) => {
          const user: User = loginResult.loggedInUser;
          this.apiService.setToken(user.token);
          this.apiService.setOrgUri(loginResult.organizations[0]?.uri);
        }),
        map((loginResult: LoginResult) => loginResult),
        catchError(() => of(null))
      );
    }
  }

}
