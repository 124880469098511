import { cloneDeep as _cloneDeep, omit as _omit, isEmpty } from 'lodash-es';

import {
  Art,
  AssetSettings,
  CatalogSettings,
  ID,
  StockArt,
  StockArtCategory,
  StockArtFilterType,
  StockArtListQueryParams,
  StockArtOrder,
  StockArtRequest,
  StockArtType,
  emptyAssetSettings
} from '@graphics-flow/types';

export class StockArtHelper {
  public static getStockArtArtIds(stockArt: StockArt[]): ID[] {
    return stockArt.flatMap((art: StockArt) => {
      return art.artIdList;
    });
  }

  public static convertStockArtRequestToQueryParams(req: StockArtRequest, defaults?: StockArtRequest): StockArtListQueryParams {
    // TODO: This seems like it could be cleaned up a lot   ~JJH 2020-12-08
    const params: StockArtListQueryParams = {};
    if (req.filterHeaders && req.filterHeaders !== defaults?.filterHeaders) {
      const headers = Object.assign({}, req.filterHeaders);
      delete headers.stockArtType;
      for (const key of Object.keys(headers)) {
        if (isEmpty(headers[key])) {
          delete headers[key];
        }
      }
      if (headers && Object.keys(headers)?.length) {
        params.filters = JSON.stringify(headers);
      }
    }
    if (req.stockArtFilterType !== StockArtFilterType.All) {
      params.stockArtFilterType = req.stockArtFilterType;
    }
    if (req.searchText) {
      params.searchText = req.searchText;
    }
    return params;
  }

  public static convertQueryParamsToStockArtRequest(queryParams: StockArtListQueryParams): StockArtRequest {
    const filtersFromParams: StockArtRequest = {};

    // Read in filter query parameters from the URL for "deep-linking" to specific searches
    if (queryParams['orderBy'] || queryParams['orderDesc']) {
      filtersFromParams.orderBy = +queryParams['orderBy'] || StockArtOrder.Name;
      filtersFromParams.orderDesc = queryParams['orderDesc'] ? JSON.parse(queryParams['orderDesc']) : true;
    }

    if (queryParams['filters']) {
      filtersFromParams.filterHeaders = JSON.parse(queryParams['filters']);
    }

    if (queryParams['searchText']) {
      filtersFromParams.searchText = queryParams['searchText'];
    }

    if (queryParams['stockArtFilterType']) {
      filtersFromParams.stockArtFilterType = queryParams['stockArtFilterType'];
    }

    return filtersFromParams;
  }

  public static stockArtTypeToRoute(type: StockArtType): string {
    switch (type) {
      case StockArtType.DesignIdea:
        return 'design-ideas';
      case StockArtType.ClipArt:
        return 'clip-art';
      case StockArtType.Font:
        return 'fonts';
      default:
        return '';
    }
  }

  public static getCDRExtensionFilename(art: Art): string {
    return `${art.assetId}${art.artExtensions[1].name}`;
  }

  public static filterSubCategoriesByParentId(parentId: ID, subCategories: StockArtCategory[]): StockArtCategory[] {
    return subCategories.filter((c: StockArtCategory) => c.parentId === parentId);
  }

  public static removeSubCategoriesByParentId(parentId: ID, subCategories: StockArtCategory[]): StockArtCategory[] {
    return subCategories.filter((c: StockArtCategory) => c.parentId !== parentId);
  }

  public static getAssetSettingsByStockArtType(artType: StockArtType, catalogSettings: CatalogSettings): AssetSettings {
    switch (artType) {
      case StockArtType.DesignIdea:
        return catalogSettings.designIdeasSettings;
      case StockArtType.ClipArt:
        return catalogSettings.clipArtSettings;
      default:
        return emptyAssetSettings;
    }
  }

  public static stockArtRequestHasAppliedFilters(filters: StockArtRequest): boolean {
    return !!filters.filterHeaders.categories?.length
     || !!filters.filterHeaders.subCategories?.length
     || !!filters.filterHeaders.styles?.length
     || !!filters.filterHeaders.artLibraries?.length
     || !!filters.searchText?.length;
  }

  public static buildStockArtRequest(activeFilters: StockArtRequest): StockArtRequest {
    let filters = _cloneDeep(activeFilters);
    if (filters.hasArtPortalCategoryFilter) {
      if (filters.filterHeaders?.categories?.length) {
        // In art-portal, If we have any category selected, we need to check whether the category
        // is already available in "filters.artPortalCategories".
        filters.filterHeaders.categories = filters.filterHeaders.categories?.filter((c: StockArtCategory) => {
          // If yes, we will select that category and proceed further
          if (filters.artPortalCategories.find((cat) => cat.folderId === c.folderId)) {
            return true;
          }
          // we need to find the sub-categories based on the category ("cat.parentId === c.folderId")
          // so, we need to send the sub-categories instead of categories in this scenario.
          const artPortalSubCategories = filters.artPortalSubCategories.filter((cat) => cat.parentId === c.folderId);
          filters.filterHeaders.subCategories = filters.filterHeaders.subCategories ?? [];
          filters.filterHeaders.subCategories = [...filters.filterHeaders.subCategories, ...artPortalSubCategories];
          return false;
        });
      }

      const hasAppliedCategoryFilter = filters.filterHeaders.categories?.length || filters.filterHeaders.subCategories?.length;
      filters.defaultCategory = !StockArtHelper.stockArtRequestHasAppliedFilters(filters) ? filters.defaultCategory : null;
      filters.filterHeaders.categories = hasAppliedCategoryFilter ? filters.filterHeaders.categories : filters.artPortalCategories;
      filters.filterHeaders.subCategories = hasAppliedCategoryFilter ? filters.filterHeaders.subCategories : filters.artPortalSubCategories;
    }
    filters = _omit(filters, ['artPortalCategories', 'artPortalSubCategories', 'hasArtPortalCategoryFilter']);
    return filters;
  }
}
