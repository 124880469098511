import { Injectable } from '@angular/core';
import { getActiveEntity, getActiveId, getAllEntities,
  getAllEntitiesApply, getEntity, selectActiveEntity,
  selectAllEntities, selectAllEntitiesApply,
  selectEntitiesCountByPredicate, selectEntity,
  selectMany } from '@ngneat/elf-entities';
import { first as _first, isNil as _isNil, orderBy as _orderBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Folder, ID, Order, SortBy } from '@graphics-flow/types';
import { FolderHelper } from '../../helpers/folder.helpers';
import { GlobalHelpers } from '../../helpers/global.helpers';
import { FolderStore } from './folder.state';

@Injectable({
  providedIn: 'root'
})
export class FolderQuery {
  folders$: Observable<Folder[]> = this.folderStore.pipe(selectAllEntities());
  active$: Observable<Folder> = this.folderStore.pipe(selectActiveEntity()) as Observable<Folder>;

  activeAncestry$: Observable<Folder[]> = this.active$.pipe(
    map((activeFolder: Folder) => {
      return activeFolder ? this.prependParent([activeFolder]) : [];
    })
  );

  rootFolders$: Observable<Folder[]> = this.selectFolderChildren();

  constructor(protected folderStore: FolderStore) {
  }

  // Query Methods.
  // Select Methods - Return Value as Observable.
  selectFolderEntity(id: ID): Observable<Folder> {
    return this.folderStore.pipe(selectEntity(id));
  }

  selectAllFolderEntitiesByApply(predicate): Observable<Folder[]> {
    return this.folderStore.pipe(selectAllEntitiesApply(predicate));
  }

  selectManyFolders(folderIds: ID[]): Observable<Folder[]> {
    return this.folderStore.pipe(selectMany(folderIds));
  }

  selectFolderCountByPredicate(predicate):  Observable<number> {
    return this.folderStore.pipe(selectEntitiesCountByPredicate(predicate));
  }

  // Get Methods - Return Raw Value.
  getActiveFolderEntityId(): ID {
    return this.folderStore.query((getActiveId));
  }

  getActiveFolderEntity(): Folder {
    return this.folderStore.query(getActiveEntity());
  }

  getFolderEntity(id: ID): Folder {
    return this.folderStore.query(getEntity(id));
  }

  getAllFolderEntities(): Folder[] {
    return this.folderStore.query(getAllEntities());
  }

  getAllFolderEntitiesByApply(predicate): Folder[] {
    return this.folderStore.query(getAllEntitiesApply(predicate));
  }

  // Custom Methods Used OverAll Application.
  getFolderById(id: ID): Folder {
    return this.getFolderEntity(id);
  }

  getFolderChildren(parentId?: ID): Folder[] {
    return this.getAllFolderEntitiesByApply({
      filterEntity: (folder: Folder) => FolderHelper.getFolderFromParent(folder, parentId)
    });
  }

  selectFolderChildren(parentId?: ID,
    sortKey: SortBy<Folder> = 'name',
    sortOrder: Order = Order.ASC
  ): Observable<Folder[]> {
    return this.selectAllFolderEntitiesByApply({
      filterEntity: (folder: Folder) => {
        return (_isNil(parentId) && _isNil(folder.parentId)) || folder.parentId === parentId;
      }
    }).pipe(
      map(folders => _orderBy(folders, GlobalHelpers.getProperSortByKey<Folder>(<string>sortKey), sortOrder))
    );
  }

  // For breadcrumbs, generally
  selectFolderHierarchy(folderId: ID): Observable<Folder[]> {
    return this.selectFolderEntity(folderId).pipe(
      map((folder: Folder) => {
        return folder ? this.prependParent([folder]) : [];
      })
    );
  }

  private prependParent(folders: Folder[]): Folder[] {
    const first: Folder = _first(folders);
    if (first && first.parentId) {
      folders.unshift(this.getFolderEntity(first.parentId));
      this.prependParent(folders);
    }
    return folders;
  }

  getParentId(folderId: ID): ID {
    return folderId ? this.getFolderEntity(folderId).parentId : folderId;
  }
}
