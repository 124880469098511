import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericWarningDialogData } from '@graphics-flow/types';

@Component({
  templateUrl: './generic-warning-dialog.component.html',
  styleUrls: ['generic-warning-dialog.component.scss']
})
export class GenericWarningDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<GenericWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericWarningDialogData
  ) {
  }
}
