import { Component, Input } from '@angular/core';

@Component({
  selector: 'gf-alert-panel',
  templateUrl: './alert-panel.component.html',
  styleUrls: ['./alert-panel.component.scss']
})
export class AlertPanelComponent {

  @Input() message: string;

}
