import { Art, Folder, ID } from '@graphics-flow/types';

export class FolderHelper {
  static getArtFromParent(art: Art, parentFolderId?: ID) {
    // if folderId is undefined, then it assumes to get arts from root folder
    return parentFolderId ? art?.folderId === parentFolderId : !art?.folderId && !art?.artApprovalId;
  }

  static getFolderFromParent(folder: Folder, parentFolderId?: ID) {
    // if folderId is undefined, then it assumes to get folders from root folder
    return parentFolderId ? folder?.parentId === parentFolderId : !folder.parentId && !parentFolderId;
  }
}
