import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { TeamQuery } from '@graphics-flow/util';
import { ID, User } from '@graphics-flow/types';

@Pipe({
  name: 'user'
})
export class UserPipe implements PipeTransform {
  constructor(private readonly teamQuery: TeamQuery) {

  }

  transform(userId: ID): Observable<User> {
    return this.teamQuery.selectUserEntity(userId);
  }

}
