import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { Translations } from '@graphics-flow/shared/assets';
import { StockArtDetailSections } from '@graphics-flow/types';
import { BillingPlansQuery } from '../../data/billing-plans/billing-plans.query';
import { UserQuery } from '../../data/user/user.query';

@Component({
  selector: 'gf-storage-limit-exceed-dialog',
  templateUrl: './storage-limit-exceed-dialog.component.html',
  styleUrls: ['./storage-limit-exceed-dialog.component.scss']
})
export class StorageLimitExceedDialogComponent implements OnInit {
  stockArtDetailSections: typeof StockArtDetailSections = StockArtDetailSections;
  activeSection = '';

  constructor(
    private dialogRef: MatDialogRef<StorageLimitExceedDialogComponent>,
    public translations: Translations,
    public userQuery: UserQuery,
    public billingPlansQuery: BillingPlansQuery,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.activeSection = this.activatedRoute.snapshot.queryParams?.activeSection || '';
  }

  navigateUpdateSubscription(): void {
    if (this.activeSection === StockArtDetailSections.CUSTOMIZE) {
      window.open('/plans-and-billing/update-subscription', '_blank');
      return;
    }
    this.router.navigate(['/plans-and-billing/update-subscription']);
  }

}
