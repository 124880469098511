import { Injectable } from '@angular/core';
import { getEntity, selectAllEntities, selectAllEntitiesApply, selectEntity } from '@ngneat/elf-entities';
import { TranslateService } from '@ngx-translate/core';
import { orderBy as _orderBy, toLower as _toLower } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Translations } from '@graphics-flow/shared/assets';
import { ArtApproval, ArtApprovalStatus, ID, Order, SortBy } from '@graphics-flow/types';
import { ApprovalHelper } from '../../helpers/approval.helper';
import { ApprovalStore } from './approval.state';

@Injectable({
  providedIn: 'root'
})
export class ApprovalQuery {
  openApprovals$: Observable<ArtApproval[]> = this.selectAllApprovalEntitiesApply({
    filterEntity: ApprovalHelper.isOpen
  });

  approvals$: Observable<ArtApproval[]> = this.selectAllApprovalEntities();

  constructor(protected store: ApprovalStore,
    private translateService: TranslateService,
    private translations: Translations) {
  }

  selectApprovalEntity(id: ID): Observable<ArtApproval> {
    return this.store.pipe(selectEntity(id));
  }

  selectAllApprovalEntities(): Observable<ArtApproval[]> {
    return this.store.pipe(selectAllEntities());
  }

  selectAllApprovalEntitiesApply(value): Observable<ArtApproval[]> {
    return this.store.pipe(selectAllEntitiesApply(value));
  }

  getApprovalByStatus(status: ArtApprovalStatus): Observable<ArtApproval[]> {
    const STATUS_TYPE = ArtApprovalStatus;
    return this.selectAllApprovalEntitiesApply({
      filterBy: ((approval: ArtApproval) => {
        if (status === STATUS_TYPE.OPEN) {
          return ApprovalHelper.isOpen(approval);
        } else if (status === STATUS_TYPE.ARCHIVED) {
          return ApprovalHelper.isArchived(approval);
        }
      })
    });
  }

  sortApprovalsList(sortKey: SortBy<ArtApproval> = 'createdDate',
    sortOrder: Order = Order.ASC,
    status?: ArtApprovalStatus): Observable<ArtApproval[]> {
    return this.selectAllApprovalEntitiesApply({
      filterBy: ((approval: ArtApproval) => {
        const STATUS_TYPE = ArtApprovalStatus;
        if (status === STATUS_TYPE.OPEN) {
          return ApprovalHelper.isOpen(approval);
        } else if (status === STATUS_TYPE.ARCHIVED) {
          return ApprovalHelper.isArchived(approval);
        }
      })
    }).pipe(
      map((artApprovals: ArtApproval[]) => {
        if (sortKey === 'name') {
          return _orderBy(artApprovals, (artApproval: ArtApproval) => {
            return artApproval.name?.length ? _toLower(artApproval.name) : _toLower(this.translateService.instant(this.translations.approval.untitled_art_approval));
          }, sortOrder);
        }
        return _orderBy(artApprovals, sortKey, sortOrder);
      })
    );
  }

  getApprovalById(approvalId: ID): ArtApproval {
    return this.store.query(getEntity(approvalId));
  }
}
