import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse, CreateUserModel, LoginResult, User, IRoles, InviteTeam, ID } from '@graphics-flow/types';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UserHttpService {

  constructor(private readonly httpService: HttpService) {}

  authenticate(email: string, password: string): Observable<LoginResult> {
    const formData: FormData = new FormData();
    formData.append('Email', email);
    formData.append('password', password);
    return this.httpService.post(this.getUrlWithoutOrgUri('Login'), formData).pipe(
      map((response: ApiResponse<LoginResult>) => response.data)
    );
  }

  changePassword(email: string, currentPassword: string, newPassword: string): Observable<User> {
    const params = new FormData();
    params.append('email', email);
    params.append('currentPassword', currentPassword);
    params.append('newPassword', newPassword);
    return this.httpService.post(this.getUrlWithoutOrgUri('ResetPasswordFromCurrentPassword'), params).pipe(
      map((response: ApiResponse<User>) => response.data)
    );
  }

  createUser(createUserModel: CreateUserModel): Observable<User> {
    return this.httpService.post(this.getUrlWithoutOrgUri('CreateUser'), createUserModel).pipe(
      map((response: ApiResponse<User>) => response.data)
    );
  }

  get(userId: ID, includeDeleted: boolean = false): Observable<User> {
    return this.httpService.get(this.getUrl(`GetUser?userId=${userId}&includeDeleted=${includeDeleted}`)).pipe(
      map((response: ApiResponse<User>) => response.data)
    );
  }

  getUsers(includeDeleted: boolean = false): Observable<User[]> {
    return this.httpService.get(this.getUrl(`GetUsers?includeDeleted=${includeDeleted}`)).pipe(
      map((response: ApiResponse<User[]>) => response.data)
    );
  }

  deleteUser(userId: ID) {
    const params = new FormData();
    params.append('userId', userId.toString());
    return this.httpService.post(this.getUrl(`DeleteUser`), params).pipe(
      map((response: ApiResponse<User>) => response.data)
    );
  }

  updateUser(user: User): Observable<User> {
    const userData: any = Object.assign({}, user);
    delete userData.userSettings;
    return this.httpService.post(this.getUrlWithoutOrgUri('UpdateUser'), userData).pipe(
      map((response: ApiResponse<User>) => response.data)
    );
  }

  forgotPassword(email: string): Observable<any> {
    const formData = new FormData();
    formData.set('email', email);
    return this.httpService.post(this.getUrlWithoutOrgUri('ForgotPassword'), formData).pipe(
      map((response) => response.data)
    );
  }

  resetPassword(email: string, resetPasswordToken: string, newPassword: string): Observable<User> {
    const formData = new FormData();
    formData.set('email', email);
    formData.set('resetPasswordToken', resetPasswordToken);
    formData.set('newPassword', newPassword);
    return this.httpService.post(this.getUrlWithoutOrgUri('ResetPasswordFromResetPasswordToken'), formData).pipe(
      map((response) => <User>response.data)
    );
  }

  inviteTeamMemeber(inviteTeams: InviteTeam[]): Observable<User> {
    if (inviteTeams.length) {
      const inviteUsers = { inviteUsers: inviteTeams };
      return this.httpService.post(this.getUrl(`InviteUser`), inviteUsers).pipe(
        map((response) => response.data)
      );
    }
  }

  changeUserRoleInOrganization(userId: ID, role: IRoles): Observable<User> {
    const formData = new FormData();
    formData.set('userId', userId?.toString());
    formData.set('role', role.toString());
    return this.httpService.post(this.getUrl('ChangeUserRoleInOrganization'), formData).pipe(
      map(response => response?.data)
    );
  }

  transferOwnership(userId: ID): Observable<User> {
    const formData = new FormData();
    formData.set('userId', userId.toString());
    return this.httpService.post(this.getUrl('TransferOwnership'), formData).pipe(
      map(response => response?.data)
    );
  }

  userExists(email: string): Observable<boolean> {
    const formData = new FormData();
    formData.set('email', email);
    return this.httpService.post(this.getUrlWithoutOrgUri('UserExists'), formData).pipe(
      map((response: ApiResponse<boolean>) => response.data)
    );
  }

  private getUrl(api: string): string {
    return this.httpService.getUrl(`users/${api}`, true);
  }

  private getUrlWithoutOrgUri(api: string): string {
    return this.httpService.getUrl(`users/${api}`, false);
  }
}
