import { Component } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';

@Component({
    selector: 'stock-art-search-no-result',
    templateUrl: './stock-art-search-no-result.component.html',
    styleUrls: ['./stock-art-search-no-result.component.scss']
})
export class StockArtSearchNoResultComponent {
    constructor(public translations: Translations) {}
}
