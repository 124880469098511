import { BasePlanPrice, BundlePlanType, Plan } from '@graphics-flow/types';

export const PRO_PLAN_BASE_DOWNLOAD = 200;

export const BundlePlans: Plan[] = [
  {
    plan: BundlePlanType.STANDARD,
    name: 'plans.bundle_plans.standard',
    productName: BasePlanPrice.STANDARD,
    uriName: 'Starter'
  },
  {
    plan: BundlePlanType.PRO,
    name: 'plans.bundle_plans.pro',
    productName: BasePlanPrice.PRO,
    uriName: 'Pro'
  },
  {
    plan: BundlePlanType.ELITE,
    name: 'plans.bundle_plans.elite',
    productName: BasePlanPrice.ELITE,
    uriName: 'Elite'
  }
];

export const ProPlans: BundlePlanType[] = [BundlePlanType.PRO, BundlePlanType.ELITE];
