import { FolderFile, FolderHierarchy } from '@graphics-flow/types';

export class FolderFilesHelper {
  static readonly DEFAULT_FILES_TO_IGNORE = [
    '.DS_Store', // OSX indexing file
    'Thumbs.db' // Windows indexing file
  ];

  static async getFilesAsync(item: DataTransferItem) {
    const files: File[] = [];
    if (item.kind === 'file') {
      if (typeof item.webkitGetAsEntry === 'function') {
        const entry = item.webkitGetAsEntry();
        const entryContent = await FolderFilesHelper.readEntryContentAsync(entry);
        files.push(...entryContent);
      } else {
        const file = item.getAsFile();
        if (file) {
          files.push(file);
        }
      }
    }

    return files;
  }

  // TODO:- fix this warning message
  // Returns a promise with all the files of the directory hierarchy
  static readEntryContentAsync(entry: FileSystemEntry) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise<File[]>((resolve, reject) => {
      let reading = 0;
      const contents: File[] = [];

      const readEntry = (entry: FileSystemEntry) => {
        if (FolderFilesHelper.isFile(entry)) {
          reading++;
          const fullPath = entry.fullPath;
          entry.file((file) => {
            reading--;
            file['fullPath'] = fullPath;
            contents.push(file);

            if (reading === 0) {
              resolve(contents);
            }
          });
        } else if (FolderFilesHelper.isDirectory(entry)) {
          readReaderContent(entry.createReader());
        }
      };

      const readReaderContent = (reader: FileSystemDirectoryReader) => {
        reading++;

        reader.readEntries((entries) => {
          reading--;
          for (const entry of entries) {
            readEntry(entry);
          }

          if (reading === 0) {
            resolve(contents);
          }
        });
      };

      readEntry(entry);
    });
  }

  // for TypeScript typing (type guard function)
  // https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
  static isDirectory(entry: FileSystemEntry): entry is FileSystemDirectoryEntry {
    return entry.isDirectory;
  }

  static isFile(entry: FileSystemEntry): entry is FileSystemFileEntry {
    return entry.isFile;
  }

  static buildFolderHierarchy(files: FolderFile[]): FolderHierarchy {
    const hierarchy: FolderHierarchy = {};

    //Before processing folder stucture filtering system index file
    const filteredFiles = files.filter((file: FolderFile) => !FolderFilesHelper.isSystemIndexFile(file));
    filteredFiles.map((file) => {
      const fileName = file?.fullPath || file.webkitRelativePath;
      const paths = fileName.split('/').slice(0, -1);
      let parentFolder = null;
      // builds the hierarchy of folders.
      paths.map((path) => {
        if (!parentFolder) {
          if (!hierarchy[path]) {
            hierarchy[path] = {
              name: path,
              children: {},
              files: []
            };
          }
          parentFolder = hierarchy[path];
        } else {
          if (!parentFolder.children[path]) {
            parentFolder.children[path] = {
              name: path,
              children: {},
              files: []
            };
          }
          parentFolder = parentFolder.children[path];
        }
      });
      parentFolder.files.push(file);
    });

    return hierarchy;
  }

  static isSystemIndexFile(file: FolderFile) {
    return FolderFilesHelper.DEFAULT_FILES_TO_IGNORE.indexOf(file.name) >= 0;
  }
}
