import { Pipe, PipeTransform } from '@angular/core';
import { StockArtType } from '@graphics-flow/types';
import { Translations } from '@graphics-flow/shared/assets';

@Pipe({
  name: 'stockArtType'
})
export class StockArtTypePipe implements PipeTransform {
  constructor(
    private readonly translations: Translations,
  ) {
  }

  transform(value: StockArtType): string {
    switch (value) {
      case StockArtType.DesignIdea:
        return this.translations.navigation.design_ideas;
      case StockArtType.ClipArt:
        return this.translations.navigation.clip_art;
      case StockArtType.Font:
        return this.translations.navigation.fonts;
      default:
        return '';
    }
  }

}
