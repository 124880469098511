<div class="orientation-not-supported-dialog grid-y">
    <mat-dialog-content class="m-t-3 m-b-1">
      <div class="orientation-not-supported">
        <mat-icon svgIcon="rotate-device"></mat-icon>
        <p id="modalLabel">{{translations.common.please_rotate_device | translate}}</p>
      </div>
      <mat-divider class="m-y-3"></mat-divider>
      <div id="warningMsg" class="flex-container flex-dir-column align-center-middle">
        <div class="t-16-400-p">{{translations.common.landscape_mode_is_not_supported | translate}}</div>
        <div class="t-16-400-p">{{translations.common.please_rotate_your_device | translate}}</div>
      </div>
    </mat-dialog-content>
  </div>
  