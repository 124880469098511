import { Component, Input } from '@angular/core';
import {
  ArtApprovalComment,
  ApprovalItemAction,
  ArtApproval,
  ArtApprovalItem,
  ArtApprovalItemStatus,
  Collaborator,
  CollaboratorLinkPackage,
  Organization,
  ID
} from '@graphics-flow/types';
import { Translations } from '@graphics-flow/shared/assets';
import {
  ApprovalDetailQuery, ApprovalHelper,
  ApprovalService,
  ArtApprovalItemForm,
  CollaboratorService,
  FormHelper
} from '@graphics-flow/util';
import { filter, tap, take, map, catchError, finalize, switchMap } from 'rxjs/operators';
import { ControlsOf, FormGroup } from '@ngneat/reactive-forms';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { FinalApprovalDialogComponent } from '../final-approval-dialog/final-approval-dialog.component';
import { ApprovalActionsService } from '../../services/approval-actions.service';

@Component({
  selector: 'gf-art-approval-item-cta',
  templateUrl: './approval-item-cta.component.html',
  styleUrls: ['./approval-item-cta.component.scss']
})
export class ApprovalItemCtaComponent {
  ArtApprovalItemStatus = ArtApprovalItemStatus;

  formGroup$: BehaviorSubject<FormGroup<ControlsOf<ArtApprovalItemForm>>> = new BehaviorSubject<FormGroup<ControlsOf<ArtApprovalItemForm>>>(null);
  artApprovalItemFormDetail: FormGroup<ControlsOf<ArtApprovalItemForm>>;
  collaborator: Collaborator;
  canApprove = false;
  isCollaborator = true;
  private approvalId: ID;
  approvalItem: ArtApprovalItem;
  private needSign = false;
  actionItem: ApprovalItemAction;
  approval: ArtApproval;

  isArchived$: Observable<boolean> = this.approvalDetailQuery.isArchived$;
  approval$ = this.approvalDetailQuery.approvalItem$.pipe(
    filter(approval => !!approval),
    tap(((approvalItem: ArtApprovalItem) => {
      this.approvalItem = approvalItem;
    })),
    switchMap(() => combineLatest([
      this.approvalDetailQuery.approval$,
      this.approvalDetailQuery.collaboratorLink$,
      this.approvalDetailQuery.canApprove$
    ])),
    map(([approval, cl, canApprove]: [ArtApproval, CollaboratorLinkPackage, boolean]) => {
      this.collaborator = cl?.collaborator;
      this.initForm(this.approvalItem);
      this.needSign = ApprovalHelper.needToBeSigned(approval);
      this.canApprove = canApprove;
      this.approvalId = approval.artApprovalId;
      this.isCollaborator = !!cl?.collaborator;
      this.approval = approval;
      return approval;
    })
  );

  approvalItemStatusInProgress = false;

  viewOnly$: Observable<boolean> = combineLatest([this.approvalDetailQuery.isArchived$, this.approvalDetailQuery.collaboratorLink$])
    .pipe(
      map(([isArchived, collboratorLink]: [boolean, CollaboratorLinkPackage]) => isArchived || !!collboratorLink)
    );
  @Input() organization: Organization;

  constructor(
    public readonly translations: Translations,
    private readonly approvalService: ApprovalService,
    private readonly approvalActionsService: ApprovalActionsService,
    private readonly collaboratorService: CollaboratorService,
    public readonly approvalDetailQuery: ApprovalDetailQuery
  ) {
    this.actionItem = {
      showConfirmation: false,
      action: null,
      actionPerformed: false,
      comment: null
    }
  }

  private initForm(approvalItem: ArtApprovalItem) {
    this.artApprovalItemFormDetail = FormHelper.getArtApprovalItemDetailForm(approvalItem);
    this.artApprovalItemFormDetail.valueChanges.pipe(
      filter(() => !this.collaborator && !this.artApprovalItemFormDetail.pristine)
    ).subscribe((data: ArtApprovalItemForm) => {
      if (this.artApprovalItemFormDetail.valid) {
        const artApprovalItem: ArtApprovalItem = Object.assign({}, approvalItem, data);
        this.approvalService.updateArtApprovalItem(this.approvalId, artApprovalItem, false, data.comment).subscribe();
      }
    });
    this.formGroup$.next(this.artApprovalItemFormDetail);
  }

  updateLineItem(status: ArtApprovalItemStatus) {
    // If the status already in Approved and Rejected state then not allow it back to OPEN
    if (status === ArtApprovalItemStatus.OPEN || !ApprovalHelper.userCanApproveItem(this.collaborator)) {
      return;
    }

    //Check if approved
    if (ApprovalHelper.canRevealFinalApproval(this.approval, this.approvalItem.artApprovalItemId, status)) {
      const dialog: MatDialogRef<FinalApprovalDialogComponent> = this.approvalActionsService.openFinalApprovalDialog(this.organization, this.approval.name);
      // Should I move this inside the service?
      dialog.afterClosed().pipe(
        filter(data => !!data),
        tap(() => this.updateLineItemStatus(status))

      ).subscribe();
    }
    else {
      this.updateLineItemStatus(status);
    }
  }

  updateLineItemStatus(status: ArtApprovalItemStatus) {
    let updateApproval$;
    const artApprovalItem: ArtApprovalItem = Object.assign({}, this.approvalItem, {status: status});
    let actionComment: ArtApprovalComment = null;

    if (this.actionItem.comment) {
      actionComment = {
        actionCommentStatus: this.actionItem.action,
        comment: this.actionItem.comment,
      };
    }
    this.approvalItemStatusInProgress = true;
    if (this.collaborator) {
      updateApproval$ = this.collaboratorService.updateArtApprovalItem(this.approvalId, this.collaborator.collaboratorId, artApprovalItem, actionComment?.comment)
    } else {
      updateApproval$ = this.approvalService.updateArtApprovalItem(this.approvalId, artApprovalItem, false, actionComment?.comment);
    }
    updateApproval$.pipe(
      take(1),
      finalize(() => {
        this.approvalItemStatusInProgress = false;
      }),
      catchError((err) => {
        this.approvalItemStatusInProgress = false;
        return throwError(err);
      })
    ).subscribe();
  }

  showActionConfirmation(confirmation: ArtApprovalItemStatus) {
    if (this.approvalItem.status === confirmation) {
      return;
    }
    this.actionItem.showConfirmation = true;
    this.actionItem.action = confirmation;
    this.actionItem.comment = null;
    this.approvalActionsService.openApprovalItemConfirmationDialog(this.actionItem)
      .pipe(
        filter(confirmation => !!confirmation),
        tap(() => this.updateLineItem(this.actionItem.action))
      ).subscribe();
  }
}
