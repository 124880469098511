import { Component, Input } from '@angular/core';
import { ID, StockArtCategory, StockArtFilterType } from '@graphics-flow/types';
import { WindowService } from 'shared/util';
import { Translations } from '@graphics-flow/shared/assets';
import { StockArtListQuery, StockArtListService } from '@graphics-flow/util';
@Component({
  selector: 'gf-stock-art-chips',
  templateUrl: './stock-art-chips.component.html',
  styleUrls: ['./stock-art-chips.component.scss']
})
export class StockArtChipsComponent {
  @Input() showDownloadFilterChips = false;

  stockArtFilterType: typeof StockArtFilterType = StockArtFilterType;
  constructor(
    public readonly stockArtListQuery: StockArtListQuery,
    public readonly stockArtListService: StockArtListService,
    public readonly translations: Translations,
    public readonly windowService: WindowService
    ) { }

  removeFilter(key: string, value: ID) {
    this.stockArtListService.removeActiveFilterHeader(key, value);
  }

  removeCategoryFilter(category: StockArtCategory) {
    this.stockArtListService.deselectCategories([category]);
  }

  removeSubCategoryFilter(subCategory: StockArtCategory) {
    this.stockArtListService.deselectSubCategories([subCategory]);
  }

  removeStockArtFilterType(): void {
    this.stockArtListService.changeFilterType(this.stockArtFilterType.All);
  }
}
