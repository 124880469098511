import { Injectable } from '@angular/core';
import { getActiveEntity, getActiveId, selectActiveEntity, selectActiveId, selectAllEntities } from '@ngneat/elf-entities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ID, Organization, Plan } from '@graphics-flow/types';
import { OrganizationStore } from './organization.state';
import { BundlePlans, ProPlans } from '../../constants/Plans.constants';

@Injectable({
  providedIn: 'root'
})
export class OrganizationQuery {
  organizations$: Observable<Organization[]> = this.selectAllEntities();
  organization$: Observable<Organization> = this.selectActive();
  public activeOrganizationUri$: Observable<ID> = this.selectActive().pipe(
    map(({ organizationId }) => organizationId)
  );
  selectedBundle$: Observable<Plan> = this.organization$.pipe(
    map((organization) => {
      // used to set the default plan for old organizations, if billingPlan not available.
      return BundlePlans.find((plan) => plan.plan === organization?.billingPlan) || BundlePlans[0];
    })
  );
  isProPlan$: Observable<boolean> = this.organization$.pipe(
    map((organization: Organization) => ProPlans.includes(organization?.billingPlan))
  );

  constructor(protected store: OrganizationStore) {}

  selectAllEntities(): Observable<Organization[]> {
    return this.store.pipe(selectAllEntities());
  }

  selectActive():  Observable<Organization> {
    return this.store.pipe(selectActiveEntity());
  }

  selectActiveId(): Observable<ID> {
    return this.store.pipe(selectActiveId());
  }

  getActive(): Organization {
    return this.store.query(getActiveEntity());
  }

  getActiveId(): ID {
    return this.store.query(getActiveId);
  }

  getOrgUri(): string {
    return this.getActive()?.uri;
  }

  getReactivationStatus(): boolean {
    return this.getActive().reactivationSuccess;
  }
}
