import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService, EnvService } from '@graphics-flow/api';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private readonly apiService: ApiService,
    private readonly envService: EnvService,) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token: string = this.apiService.getToken();
    if (request.url.startsWith(this.envService.apiEndpoint)) {
      if (token) {
        request = request.clone({
          setHeaders: {
            //'Content-Type': 'application/json; charset=utf-8',
            //'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
      }
      /*
      else {
        if (!request.url.endsWith('Login') && !request.url.endsWith('GetCollaboratorLinkPackage')) { // TODO: Don't be shitty, strongly typed routes, perhaps?
          console.log('ERROR! Trying to make an API call but we don\'t have a token yet.');
          this.router.navigateByUrl('/');
        }
      }

       */
    }

    return next.handle(request);
  }
}
