import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';
import { CustomizeStockArtDetailSection } from '@graphics-flow/types';
import { WindowService } from 'shared/util';

@Component({
  selector: 'gf-sidenav-footer-action',
  templateUrl: './sidenav-footer-action.component.html',
  styleUrls: ['./sidenav-footer-action.component.scss']
})
export class SidenavFooterComponent {
  customizeStockArtDetailSection: typeof CustomizeStockArtDetailSection = CustomizeStockArtDetailSection;

  @Input() type: CustomizeStockArtDetailSection;
  @Input() disableBtn: boolean;
  @Output() cancelSelection: EventEmitter<void> = new EventEmitter<void>();
  @Output() applySelection: EventEmitter<void> = new EventEmitter<void>();

  constructor(public translations: Translations,
    public readonly windowService: WindowService) {}

}
