import { Observable } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';
import { Tag } from '@graphics-flow/types';
import { TagQuery } from '@graphics-flow/util';

@Pipe({
  name: 'getTagByCanonicalName'
})
export class GetTagByCanonicalNamePipe implements PipeTransform {

  constructor(
    private readonly tagQuery: TagQuery
  ) { }

  transform(tag: string): Observable<Tag> {
    return this.tagQuery.getTagByCanonicalName$(tag);
  }

}
