<div class="stock-art-filters" [ngClass]="alignmentClass">
  <gf-stock-art-type-filter *ngIf="showDownloadFilter && !(windowService?.smallDown$ | async)"></gf-stock-art-type-filter>
  <ng-container
    *ngIf="((stockArtListQuery.activeStockArtType$ | async) !== StockArtType.Font) && !(windowService?.smallDown$ | async)">
    <gf-category-filter *ngIf="!isCustomStockArtFilter"></gf-category-filter>

    <ng-container *ngIf="(stockArtListQuery.activeStockArtType$ | async) !== StockArtType.ClipArt">
      <!-- Styles on clip art aren't what we want right now -->
      <gf-stock-art-list-filter filterProperty="styles"
                                [label]="translations.stock.style | translate"></gf-stock-art-list-filter>
    </ng-container>
    <ng-container *ngIf="!isCustomStockArtFilter && ((billingPlansQuery.isGraphicsBuilderPartOfAddOn$ | async) || showCollectionFilter)">
      <gf-stock-art-list-filter filterProperty="artLibraries"
                                [label]="translations.stock.collection | translate"></gf-stock-art-list-filter>
    </ng-container>
  </ng-container>

  <mat-form-field
    class="filter-form-field"
    floatLabel="never"
    [class.search-input]="showDynamicSearchPlaceholder || isCustomStockArtFilter">
    <input matInput
      id="filterStockArt"
      name="filterStockArt"
      [placeholder]="( showDynamicSearchPlaceholder
      ? 'public_stock_art.search_' + (stockArtListQuery.activeStockArtType$ | async)
      : translations.common.filter_results ) | translate"
      [formControl]="searchControl">
      <mat-icon class="hint" matSuffix class="hint" fontIcon="search"></mat-icon>
  </mat-form-field>
</div>
