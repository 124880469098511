import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileExtension'
})

export class FileExtensionPipe implements PipeTransform {
  transform(fileName: string) {
    return fileName.substr(fileName.lastIndexOf('.') + 1);
  }
}
