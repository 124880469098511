import { Pipe, PipeTransform } from '@angular/core';
import { ArtApproval } from '@graphics-flow/types';
import { ApprovalHelper } from '@graphics-flow/util';

@Pipe({
  name: 'isApprovalApproved'
})
export class IsApprovalApprovedPipe implements PipeTransform {

  transform(value: ArtApproval): boolean {
    return value.isApproved || ApprovalHelper.isApproved(value);
  }

}
