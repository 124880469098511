import { Pipe, PipeTransform } from '@angular/core';
import { Art, ArtExtension, ID, ImagePreviewType } from '@graphics-flow/types';
import { ImagePreviewPipe } from './image-preview.pipe';

@Pipe({
  name: 'fontPreviewUrl'
})
export class FontPreviewUrlPipe implements PipeTransform {
  constructor(
    private readonly imagePreviewPipe: ImagePreviewPipe
  ) {
  }
  transform(art: Art, displayType: 'ab' | 'full' = 'full', organizationId?: ID): string {
    if (!art) return '';
    const orgId: ID = organizationId || art.assignedOrganizationId;
    const assetId: ID = art.assetId;
    const extension: ArtExtension = art.artExtensions[displayType === 'ab' ? 1 : 0];

    if (!extension) {
      return '';
    }

    return this.imagePreviewPipe.transform(orgId, assetId.toString(), ImagePreviewType.LARGE, false, true, extension.name, null, true);
  }
}
