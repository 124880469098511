import { Pipe, PipeTransform } from '@angular/core';
import { Art } from '@graphics-flow/types';
import { ArtHelper } from '@graphics-flow/util';

@Pipe({
  name: 'isCSASupportedFile'
})
export class IsCSASupportedArtPipe implements PipeTransform {

  transform(art: Art): boolean {
    return ArtHelper.isCSASupportedFile(art);
  }

}
