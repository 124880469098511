import { Component, Input } from '@angular/core';
import { Collaborator } from '@graphics-flow/types';


@Component({
  selector: 'gf-collaborator-avatar',
  templateUrl: './collaborator-avatar.component.html',
  styleUrls: ['./collaborator-avatar.component.scss']
})
export class CollaboratorAvatarComponent {

  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() collaborator: Collaborator;
}
