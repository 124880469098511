import { CustomizeStockArtShape } from '@graphics-flow/types';

export interface UpdatedArtShapeWithShapeInfo {
  shapeIds: number[];
  artShape: CustomizeStockArtShape;
}
export class CustomizeStockArtHelper {
  static updateSelectedColorOverArtShape(artShape: CustomizeStockArtShape, currentColor: string, selectedColor: string): UpdatedArtShapeWithShapeInfo {
    const updatedCurveShapeInfo: UpdatedArtShapeWithShapeInfo = {
      artShape: null,
      shapeIds: [artShape.shapeId]
    };
    if (artShape.curveShapeColors?.length) {
      artShape.curveShapeColors?.map((curveShapeColor) => {
        if (curveShapeColor.hexValue?.toUpperCase() === currentColor?.toUpperCase()) {
          curveShapeColor.hexValue = selectedColor?.toUpperCase();
          updatedCurveShapeInfo.shapeIds.push(curveShapeColor.colorId);
        }
        return curveShapeColor;
      });
    }
    updatedCurveShapeInfo.artShape = artShape;
    return updatedCurveShapeInfo;
  }
}
