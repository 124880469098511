import { Injectable } from '@angular/core';
import { getAllEntities, getEntity, selectAllEntities, selectEntity } from '@ngneat/elf-entities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Collaborator, ID } from '@graphics-flow/types';
import { CollaboratorStore } from './collaborator.state';

@Injectable({
  providedIn: 'root'
})
export class CollaboratorQuery {
  all$: Observable<Collaborator[]> = this.store.pipe(selectAllEntities());

  constructor(protected store: CollaboratorStore) {}

  getArtApprovalCollaborator(idApproval: ID): Observable<Collaborator[]> {
    return this.all$.pipe(
      map((collaborators: Collaborator[]) => {
        return collaborators.filter((collaborator: Collaborator) => collaborator.artApprovalIds.includes(idApproval));
      })
    );
  }

  getCollaboratorByEmail(email: string): Collaborator {
    return this.store.query(getAllEntities()).find((collaborator: Collaborator) => collaborator.email === email);
  }

  getCollabratorEntity(id: ID): Collaborator {
    return this.store.query(getEntity(id));
  }

  selectCollabratorEntity(id: ID): Observable<Collaborator> {
    return this.store.pipe(selectEntity(id));
  }
}
