<div class="page-not-found-container">
  <div class="page-not-found-body flex-container align-middle dark-secondary-text">
    <div id="errorPageContent" class="flex-1">
      <h1 id="errHeader" class="status-heading m-b-0">{{ translations.common.error_header | translate }}</h1>
      <p id="errMsg" class="status-message m-b-0">{{ translations.common.error_message | translate }}</p>
      <p id="errCode" class="status-code m-b-0">{{ translations.common.error_code | translate }}</p>
    </div>
    <div class="flex-1 m-l-5">
      <div class="page-not-found-img">
        <img id="pageNotFoundImg" [src]="'/assets/images/mill.svg' | addDomain" [alt]="translations.common.page_not_found | translate"/>
        <p id="imgCredits" class="credits-text m-b-0">{{ translations.common.designed_by | translate }}<a id="freepikLink" href="http://www.freepik.com" target="_blank">{{ translations.common.freepik | translate }}</a></p>
      </div>
    </div>
  </div>
  <gf-footer [blankFooter]="true" [organizationName]="organizationName"></gf-footer>
</div>
