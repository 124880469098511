import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  ActiveSectionQueryParamGuard,
  AdminOnlyRouteGuard,
  PaymentSetupGuard,
  ProPlansOnlyRouteGuard,
  ReactivateGuard,
  SignedInGuard,
  TeamSettingsRouteGuard,
  TeamSetupGuard
} from '@graphics-flow/util';
import { InviteTeammateResolveService } from './components/invite-teammate/invite-teamate-reslove.service';
import { MainModule } from './main.module';
import { DeletedFilesResolverService } from './services/deleted-files-resolver.service';
import { UpdateSubscriptionPlanResolverService } from './services/update-subscription-plan-resolver.service';

export const mainRoutes: Routes = [
  /** LAZY LOADED ROUTES - ACCOUNT SETUP, TEAM SETUP & PAYMENT SETUP */
  {
    path: 'signin',
    loadComponent: () => import('./components/signin/signin.component').then(c =>  c.SigninComponent)
  },
  {
    path: 'account-setup',
    loadComponent: () => import('./components/signup/signup.component').then((c) => c.SignupComponent)
  },
  {
    path: 'forgot-password',
    loadComponent: () => import('./components/forgot-password/forgot-password.component').then(c => c.ForgotPasswordComponent)
  },
  {
    path: 'account-reactivation',
    canActivate: [ReactivateGuard],
    loadComponent: () =>  import('./components/reactivation/reactivation.component').then(c => c.ReactivationComponent)
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./components/reset-password/reset-password.component').then(c => c.ResetPasswordComponent)
  },
  {
    path: 'invited-user',
    resolve: {
      loginResult: InviteTeammateResolveService
    },
    loadComponent: () => import('./components/invite-teammate/invite-teammate.component').then(c => c.InviteTeammateComponent)
  },
  {
    path: 'team-setup',
    loadChildren: () => import('../../../feature-main/src/lib/components/team-setup/team-setup.module').then(m => m.TeamSetupModule),
    canActivate: [SignedInGuard, TeamSetupGuard],
  },
  {
    path: 'payment-setup',
    loadChildren: () => import('../../../feature-main/src/lib/components/payment-setup/payment-setup.module').then(m => m.PaymentSetupModule),
    canActivate: [SignedInGuard],
  },
  {
    path: 'invite',
    loadChildren: () => import ('@graphics-flow/invite').then(m => m.GraphicsFlowFeatureInviteModule)
  },
  {
    path: 'plans-and-billing/update-subscription',
    loadChildren: () => import('@graphics-flow/feature-org-settings').then(m => m.UpdateSubscriptionPlanModule),
    canActivate: [AdminOnlyRouteGuard],
    resolve: {
      canProceed: UpdateSubscriptionPlanResolverService
    }
  },
  /** LAZY LOADED ROUTES - ALL DETAIL VIEWS */
  {
    path: 'my/detail/:id',
    loadChildren: () => import ('@graphics-flow/feature-my-art').then(m => m.MyArtDetailModule),
    canActivate: [SignedInGuard, PaymentSetupGuard, ActiveSectionQueryParamGuard],
    data: {
      isMyArtDetailView: true
    }
  },
  {
    path: 'approval/item/:artApprovalId/:artApprovalItemId',
    loadChildren: () => import ('@graphics-flow/feature-approvals').then(m => m.ApprovalItemDetailModule),
    canActivate: [SignedInGuard, PaymentSetupGuard]
  },
  {
    path: 'approval/item/:artApprovalId/:artApprovalItemId/:collaboratorLinkId/:orgUri',
    loadChildren: () => import ('@graphics-flow/feature-approvals').then(m => m.ApprovalItemDetailModule)
  },
  {
    path: 'approval/item/:artApprovalId/:artApprovalItemId/:collaboratorLinkId/:collaboratorId/:orgUri',
    loadChildren: () => import ('@graphics-flow/feature-approvals').then(m => m.ApprovalItemDetailModule)
  },
  {
    path: 'approval/:artApprovalId',
    loadChildren: () => import ('@graphics-flow/feature-approvals').then(m => m.ApprovalDetailModule),
    canActivate: [SignedInGuard, PaymentSetupGuard]
  },
  {
    path: 'approval/:artApprovalId/:collaboratorLinkId/:orgUri',
    loadChildren: () => import ('@graphics-flow/feature-approvals').then(m => m.ApprovalDetailModule)
  },
  {
    path: 'approval/:artApprovalId/:collaboratorLinkId/:collaboratorId/:orgUri',
    loadChildren: () => import ('@graphics-flow/feature-approvals').then(m => m.ApprovalDetailModule)
  },
  {
    path: 'stock/:stockArtType/detail/:id',
    loadChildren: () => import ('@graphics-flow/stock-art').then(m => m.StockArtDetailModule),
    canActivate: [SignedInGuard, PaymentSetupGuard, ActiveSectionQueryParamGuard]
  },
  /** LAZY LOADED ROUTES - ALL LIST VIEWS */
  {
    path: '',
    canActivate: [SignedInGuard, PaymentSetupGuard],
    canActivateChild: [SignedInGuard, PaymentSetupGuard],
    loadComponent: () => import('./components/container/container.component').then(c => c.ContainerComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'stock'
      },
      {
        path: 'my',
        loadChildren: () => import('@graphics-flow/feature-my-art').then(m => m.MyArtModule),
        data: {
          showFileUploadPanel: true,
          showWelcomeGFModal: true,
          page: 'myArt',
          isDeletedFiles: false
        }
      },
      {
        path: 'deleted-files',
        loadChildren: () => import('@graphics-flow/feature-my-art').then(m => m.MyArtModule),
        data: {
          showFileUploadPanel: true,
          showWelcomeGFModal: true,
          page: 'deletedFiles',
          isDeletedFiles: true
        },
        resolve: {
          webJob: DeletedFilesResolverService
        }
      },
      {
        path: 'stock',
        loadChildren: () => import('@graphics-flow/feature-stock-art').then(m => m.FeatureStockArtModule),
        data: {
          page: 'stockArt'
        }
      },
      {
        path: 'approvals',
        loadChildren: () => import('@graphics-flow/feature-approvals').then(m => m.ApprovalsListModule),
        data: {
          showFileUploadPanel: true,
          showWelcomeGFModal: true,
          page: 'artApprovals'
        }
      },
      {
        path: 'design-requests',
        loadChildren: () => import('@graphics-flow/feature-design-requests').then(m => m.DesignRequestListModule),
        canActivate: [ProPlansOnlyRouteGuard],
        data: {
          page: 'designRequests'
        }
      },
      {
        path: 'my-team',
        loadChildren: () => import('@graphics-flow/feature-my-team').then(m => m.MyTeamModule),
        data: {
          showFileUploadPanel: true,
          showWelcomeGFModal: true,
          page: 'myTeam'
        },
      },
      {
        path: 'profile',
        loadChildren: () => import('@graphics-flow/feature-profile').then(m => m.ProfileModule),
        data: {
          showFileUploadPanel: true,
          showWelcomeGFModal: true,
          page: 'mySettings'
        }
      },
      {
        path: 'team-settings',
        loadChildren: () => import('@graphics-flow/feature-org-settings').then(m => m.OrgSettingsModule),
        canActivate: [TeamSettingsRouteGuard],
        data: {
          page: 'teamSettings'
        }
      }
    ]
  },
  /** LAZY LOADED ROUTE - ART PORTAL VIEW */
  {
    path: 'art-portal/:companyName/:orgUri',
    loadChildren: () => import('@graphics-flow/stock-art-catalog').then(m => m.StockArtCatalogModule),
  },
  /** LAZY LOADED ROUTES - ERRORS & WILDCARD ROUTES */
  {
    path: 'approval-link-expired',
    loadComponent: () => import('./components/page-not-found/page-not-found.component').then(c => c.PageNotFoundComponent)
  },
  {
    path: 'page-not-found',
    loadComponent: () => import('./components/page-not-found/page-not-found.component').then(c => c.PageNotFoundComponent)
  },
  {
    path: 'service-not-available',
    loadComponent: () => import('./components/service-not-available/service-not-available.component').then(c => c.ServiceNotAvailableComponent)
  },
  {
    path: '**',
    redirectTo: 'signin'
  }
];

export const mainRoutesModule: ModuleWithProviders<MainModule> = RouterModule.forRoot(mainRoutes, {
    enableTracing: false
});
