<div  class="no-results width-100 height-100 overflow-auto">
    <div class="empty-cards flex-container flex-dir-row">
      <div class="card"></div>
      <div class="card m-m-r-0"></div>
      <div class="card show-for-large"></div>
      <div class="card show-for-medium"></div>
    </div>
    <div class="content">
      <div class="flex-container align-center-middle">
        <mat-icon class="mat-36 m-r-1" fontIcon="image_search"></mat-icon>
        <p class="m-b-0 t-20-600-p">{{translations.common.no_results_found | translate}}</p>
      </div>
      <p class="m-t-3 t-16-400-s">{{translations.public_catalog_view.empty_result_message | translate}}</p>
    </div>
  </div>
