import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { setProps } from '@ngneat/elf';

import { ID, StockArtDetailSections, StockArtType } from '@graphics-flow/types';
import { StockArtDetailStore } from './stock-art-detail.store';

@Injectable({
  providedIn: 'root'
})
export class StockArtDetailService {
  type: StockArtType;

  constructor(
    private readonly stockArtDetailStore: StockArtDetailStore,
    private readonly router: Router
  ) { }

  show(stockArtId: ID, activeSection: StockArtDetailSections = StockArtDetailSections.INFO, type?: StockArtType) {
    this.updateActiveStockArtInfo(stockArtId, activeSection, type);
    this.router.navigate(['stock', type, 'detail', stockArtId], {
      queryParams: {
        activeSection
      }
    });
  }

  updateActiveStockArtInfo(stockArtId: ID, activeSection: StockArtDetailSections = StockArtDetailSections.INFO, type?: StockArtType) {
    this.type = type;
    this.setActiveStockArtId(stockArtId);
    this.setActiveSection(activeSection);
  }

  setActiveStockArtId(id: ID): void {
    this.stockArtDetailStore.update(setProps({
      activeStockArtId: id
    }));
  }

  setActiveSection(section: StockArtDetailSections): void {
    this.stockArtDetailStore.setActiveSection(section);
  }

  toggleDrawerStatus(): void {
    this.stockArtDetailStore.toggleDrawerStatus();
  }
}
