<div class="art-item"
  [ngClass]="{
    'downloaded': art.isDownloaded,
    'active': artMenuTrigger?.menuOpen,
    'small': small,
    'cursor-none': art?.inProgress
  }">
  <mat-icon
    *ngIf="customizeStockArt && (customizeStockArtService.selectedFontData$ | async)?.stockArtId === stockArt.stockArtId"
    class="done-icon m-t-1">
    check_circle
  </mat-icon>
  <div class="art-image-container cursor-pointer" [ngClass]="{'downloading-container': art?.inProgress}">

    <div class="art-image position-relative">
      <mat-icon *ngIf="art.isHidden && organizationQuery.isProPlan$ | async" matSuffix id="visibilityOffIcon" class="cursor-pointer visibility-icon hint position-absolute"
      [matTooltipPosition]="'above'" [matTooltip]="translations.public_catalog_view.asset_hidden_in_art_portal | translate">
        visibility_off
      </mat-icon>
      <img
        [src]="art.assignedOrganizationId | imagePreview : art.assetId : imagePreviewType.MEDIUM : false : true : (art | artExtension : 'png')"
        srcset="{{art.assignedOrganizationId | imagePreview : art.assetId : imagePreviewType.THUMBNAIL : false : true : (art | artExtension : 'png')}} 1024w,
                {{art.assignedOrganizationId | imagePreview : art.assetId : imagePreviewType.MEDIUM : false : true : (art | artExtension : 'png')}} 1920w"
        #artImg
        fetchpriority="auto"
        loading="auto"
        brokenImage
        [alt]="art?.name | fileName"/>
    </div>
    <div *ngIf="!customizeStockArt && !hideStockArtItemInfoFooter" class="art-image-details grid-x">
      <span class="art-image-details-info overflow-hidden">
        <div *ngIf="isGraphicsBuilder" class="graphics-builder-icon m-r-1">
          <mat-icon fontIcon="star"></mat-icon>
        </div>
        <div class="text-truncate">
          <span class="art-name text-truncate">{{art?.name | fileName}}</span>
          <span *ngIf="art.isDownloaded" class="downloaded-info">
            <p class="m-b-0 text-truncate">
              {{translations.common.downloaded | translate}}
            </p>
            <button mat-icon-button
              #tooltip="matTooltip"
              (click)="tooltip.toggle()"
              matTooltipClass="mat-tooltip-below"
              [matTooltipPosition]="'below'"
              [matTooltip]="translations.common.stock_art_already_downloaded_message| translate">
              <mat-icon fontIcon="info_outline"></mat-icon>
            </button>
          </span>
        </div>
      </span>
      <button id="favoriteBtn"
        class="art-actions-btn m-r-1"
        mat-icon-button
        (click)="artService.favoriteArt(art, art.stockArtType, $event)">
        <mat-icon [fontIcon]="art?.isFavorite ? 'favorite' : 'favorite_border'"></mat-icon>
      </button>
      <button [mat-menu-trigger-for]="folderMenu"
        #artMenuTrigger="matMenuTrigger"
        class="art-actions-btn"
        mat-icon-button
        (click)="stopAndPrevent($event)">
        <mat-icon fontIcon="more_vert"></mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="art?.inProgress" class="flex-container align-middle downloading">
    <loading-indicator [diameter]="28" color="primary" class="loader m-r-1"></loading-indicator>
    <div class="t-15-600-s">{{ translations.common.downloading | translate}}</div>
  </div>
</div>

<mat-menu #folderMenu="matMenu" yPosition="below" xPosition="before">
  <gf-stock-art-menu-actions
    [art]="art"
    [showCustomizeStockArtOption]="showCustomizeStockArt"
    (openStockArtDetails)="openStockArtDetails.emit()">
  </gf-stock-art-menu-actions>
</mat-menu>
