import { Pipe, PipeTransform } from '@angular/core';
import { Art, Folder, MyArtFileType, TableFile } from '@graphics-flow/types';

@Pipe({
  name: 'isInProgress'
})

export class IsInProgressPipe implements PipeTransform {

  transform(gridViewList: [Folder[], Art[]] | TableFile[], type?: string): boolean {
    const folders: Folder[] = <Folder[]>gridViewList[0];
    const arts: Art[] = <Art[]>gridViewList[1];

    switch (type) {
      case (MyArtFileType.Folder):
        return folders.every((folder) => folder.inProgress);
      case (MyArtFileType.Art):
        return arts.every((art) => art.inProgress);
      case MyArtFileType.ListView:
        const tableFiles: TableFile[] = <TableFile[]>gridViewList;
        return tableFiles.every((tableFile) => tableFile.inProgress);
      default:
        return folders.every((folder) => folder.inProgress) && arts.every((art) => art.inProgress);
    }
  }
}
