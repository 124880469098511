import { Injectable } from '@angular/core';
import { createState, Store } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { TimelineEvent } from '@graphics-flow/types';


const {state, config} = createState(
  withEntities<TimelineEvent, 'eventId'>({ idKey: 'eventId', initialValue: [] })
)

@Injectable({ providedIn: 'root' })
export class TimelineStore extends Store {

  constructor() {
    super({ name: 'timeline', state, config });
  }
}
