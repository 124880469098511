import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { each as _each, findIndex as _findIndex, flatMap as _flatMap, isEmpty as _isEmpty } from 'lodash-es';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { debounceTime, filter, finalize, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';

import { Translations } from '@graphics-flow/shared/assets';
import { ApiError, Art, FileQueueStatus, Folder, FolderHierarchy, FolderStructure, ID, NotificationType } from '@graphics-flow/types';
import { NotificationService } from 'shared/ui';

import { storageLimitExceedErrorCode } from '../constants/errors.constants';
import { ArtService } from '../data/art/art.service';
import { FolderService } from '../data/folder/folder.service';
import { MyArtSearchService } from '../data/my-art-search/my-art-search.service';
import { FolderFilesHelper } from '../helpers/folder-files.helper';
import { GlobalHelpers } from '../helpers/global.helpers';
import { GraphicsFlowService } from './graphics-flow.service';

export class FileQueueObject {
  public artId?: ID;
  public file: File;
  public status: FileQueueStatus = FileQueueStatus.Pending;
  public request: Subscription = null;
  public folderId: ID;
  public errorMsg;
  public showFolder?: boolean;

  constructor(file: any) {
    this.file = file;
  }

  // actions
  public upload = () => { /* set in service */ };
  public cancel = () => { /* set in service */ };

  // statuses
  public isPending = () => this.status === FileQueueStatus.Pending;
  public isSuccess = () => this.status === FileQueueStatus.Success;
  public isError = () => this.status === FileQueueStatus.Error;
  public inProgress = () => this.status === FileQueueStatus.Progress;
  public isUploadable = () => this.status === FileQueueStatus.Pending;
}

@Injectable({
  providedIn: 'root'
})
export class MyArtUploaderService {
  private _showPanel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _uploadPreparing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _files: FileQueueObject[] = [];
  private _queue: BehaviorSubject<FileQueueObject[]>;
  private _folders: FolderStructure[] = [];
  private createFolderSubscription: Subscription;

  isShowPanel$: Observable<boolean> = this._showPanel.asObservable();
  isUploadPreparing$: Observable<boolean> = this._uploadPreparing.asObservable();

  failedFilesCount$: Observable<number>;
  isQueueProcessed$: Observable<boolean>;
  nonFailFilesCount$: Observable<number>;
  successFilesCount$: Observable<number>;
  pendingAndInprogressFileCount$: Observable<number>;
  isPendingFilesThere$: Observable<boolean>;

  constructor(
    private artService: ArtService,
    private translations: Translations,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private graphicsFlowService: GraphicsFlowService,
    private folderService: FolderService,
    private notificationService: NotificationService,
    private myArtSearchService: MyArtSearchService
  ) {
    this._queue = new BehaviorSubject(this._files);
    this.failedFilesCount$ = this._queue.asObservable().pipe(
      map((queue) => {
        return queue.filter((f) => f.isError()).length;
      })
    );

    this.pendingAndInprogressFileCount$ = this._queue.asObservable().pipe(
      map((queue: FileQueueObject[]) => {
        return queue.filter((f) => (f.inProgress() || f.isPending())).length;
      })
    );

    this.isQueueProcessed$ = combineLatest([this.pendingAndInprogressFileCount$, this.isUploadPreparing$]).pipe(
      map(([count, isPreparing]: [number, boolean]) => {
        return count === 0 && !isPreparing;
      })
    );

    this.nonFailFilesCount$ = this._queue.asObservable().pipe(
      map((queue: FileQueueObject[]) => {
        return queue.filter((f) => !f.isError()).length;
      })
    );

    this.isPendingFilesThere$ = this._queue.asObservable().pipe(
      map((queue: FileQueueObject[]) => {
        return queue.filter((f) => f.isPending()).length > 0;
      })
    );

    this.successFilesCount$ = this._queue.asObservable().pipe(
      map((queue: FileQueueObject[]) => {
        return queue.filter((f) => f.isSuccess()).length;
      })
    );

    // Used to trigger the checkIsQueueProcessed() on every instance of queue updates,
    // because, If any files and folders uploaded in bulk or single! then we need to reload the entire list view
    // to its intial state with index 0, So that it we can maintain the scroll instance properly.
    this.queue.pipe(
      switchMap(() => this.checkIsQueueProcessed())
    ).subscribe();
  }

  public get queue() {
    return this._queue.asObservable();
  }

  public firstPendingFilePosition() {
    return _findIndex(this._files, { status: FileQueueStatus.Pending });
  }

  public totalCount(): number {
    return this._files.length;
  }

  public inProgressCount(): number {
    return this._files.filter((f) => f.status === FileQueueStatus.Progress).length;
  }

  public remainingFilesCount(): number {
    return this._files.filter((f) => f.isPending() || f.inProgress())?.length;
  }

  public onCompleteItem(queueObj: FileQueueObject): any {
    return queueObj;
  }

  public addToQueue(data: File[], folderId?: ID, showFolder?: boolean) {
    // show file upload panel if queue is empty
    if (!this._files?.length) {
      this.displayPanel();
    }

    // clear queue once all files completed and if we try to upload files again
    if (this._files.length > 0 && this.remainingFilesCount() === 0) {
      this._files = [];
      this._queue.next(this._files);
    }

    // add file to the queue
    const files: FileQueueObject[] = [];
    _each(data, (file: any) => {
      const queueObj = new FileQueueObject(file);

      // set parent folderid
      queueObj.folderId = folderId;

      queueObj.status = FileQueueStatus.Pending;
      queueObj.showFolder = showFolder;

      // set the individual object events
      queueObj.upload = () => this._upload(queueObj);
      queueObj.cancel = () => this._cancel(queueObj);

      // check file size
      if (!GlobalHelpers.isFileLessThan50MB(queueObj.file)) {
        queueObj.status = FileQueueStatus.Error;
        queueObj.errorMsg = this.translateService.instant(this.translations.fileupload.file_size_exceeded);
      }

      // check file format
      const fileType = queueObj.file.name.substr(queueObj.file.name.lastIndexOf('.'))?.toLowerCase();
      if (!GlobalHelpers.SUPPORTED_MY_ART_FILE_EXTENSIONS.includes(fileType)) {
        queueObj.status = FileQueueStatus.Error;
        queueObj.errorMsg = this.translateService.instant(this.translations.fileupload.file_format_not_supported);
      }

      files.push(queueObj);
    });

    this._files.push(...files);
    this._queue.next(this._files);
  }

  public clearQueue() {
    // clear the queue
    this.hidePanel();
    this._files = [];
    this._queue.next(this._files);
  }

  public paralledUploadUpToFive(start: number, limit: number) {
    // upload all except already successfull or in progress
    for (let i = start; i < limit; i++) {
      if (this._files[i]?.isUploadable()) {
        this._upload(this._files[i]);
      }
    }
  }

  public cancelAll() {
    this.createFolderSubscription?.unsubscribe();
    this._folders = [];
    this._files.forEach((f) => {
      if (f.isPending()) {
        f.cancel();
        f.errorMsg = this.translateService.instant(this.translations.fileupload.upload_stopped);
      }
    });
    if (!this._files?.length) {
      this.hidePanel();
      this.hideUploadInProgress();
    }
  }

  public displayPanel() {
    this._showPanel.next(true);
  }

  public hidePanel() {
    this._showPanel.next(false);
    this.ngZone.run(() => {/**/});
  }

  public closePanelIfDisplaying() {
    this.isShowPanel$.pipe(
      take(1)
    ).subscribe((panelIsDisplaying: boolean) => {
      if (panelIsDisplaying) {
        this.hidePanel();
      }
    });
  }

  private _upload(queueObj: FileQueueObject) {
    queueObj.status = FileQueueStatus.Progress;

    queueObj.request = this.artService.uploadArt([queueObj.file], queueObj.folderId)
      .pipe(
        finalize(() => {
          this.pickNextFileInTheQueue();
        })
      )
      .subscribe((res: Art[]) => {
        queueObj.artId = res[0].artId;
        // TODO: GF-2380 - To stop unwanted rendering in DOM! Right now, we are hold this update.
        // this.myArtSearchService.addOrUpdateGivenArtsAndFolders(res, []);
        this._uploadComplete(queueObj);
      }, (err: ApiError) => {
        queueObj.errorMsg = err.message;
        if (err.status === 413) {
          queueObj.errorMsg = this.translateService.instant(this.translations.fileupload.file_size_exceeded);
        }
        // Used to show the storage limit exceed modal, while uploading
        if (storageLimitExceedErrorCode === err.errorCode) {
          // If any file upload in progress canceling all.
          this.cancelAll();
          this.graphicsFlowService.openStorageLimitExceedModal();
        }

        if (err.status === 0 && !navigator.onLine) {
          queueObj.errorMsg = this.translateService.instant(this.translations.common.no_internet_connection);
        }
        this._uploadFailed(queueObj);
      });

    return queueObj;
  }

  private _cancel(queueObj: FileQueueObject) {
    // update the FileQueueObject as cancelled
    if (queueObj.request) {
      queueObj.request.unsubscribe();
    }
    queueObj.status = FileQueueStatus.Error;
    // default error msg.. this will be replaced by other errorMgs when we stop all uploads or  api error.
    queueObj.errorMsg = this.translateService.instant(this.translations.fileupload.file_item_canceled);
    this._queue.next(this._files);
    this.pickNextFileInTheQueue();
  }

  private _uploadComplete(queueObj: FileQueueObject) {
    // update the FileQueueObject as completed
    queueObj.status = FileQueueStatus.Success;
    this._queue.next(this._files);
    this.onCompleteItem(queueObj);
  }

  private _uploadFailed(queueObj: FileQueueObject) {
    // update the FileQueueObject status as Error.
    queueObj.status = FileQueueStatus.Error;
    this._queue.next(this._files);
  }

  pickNextFileInTheQueue() {
    const pendingFilesCount = this._files.filter((f) => f.isPending()).length;
    const firstPendingFilePos = _findIndex(this._files, { status: FileQueueStatus.Pending });
    const inProgressFileCount = this._files.filter((f) => f.inProgress()).length;
    if (pendingFilesCount === 0 || inProgressFileCount >= 5) {
      this.ngZone.run(() => {/**/});
      return;
    }
    this._upload(this._files[firstPendingFilePos]);
    this.ngZone.run(() => {/**/});
  }

  processFolders(folder: FolderStructure) {
    // Only children folder have folder name
    if (folder.name?.length) {
      const newFolder = <Folder>{
        parentId: folder?.parentFolderId || null,
        name: folder.name
      };

      this.createFolderSubscription = this.folderService.createFolder(newFolder)
      .subscribe((createdFolder: Folder) => {
        // TODO: GF-2380 - To stop unwanted rendering in DOM! Right now, we are hold this update.
        // this.myArtSearchService.addOrUpdateGivenArtsAndFolders([], [createdFolder]);
        this.addFolderFilesToQueue(folder, createdFolder.folderId);
      }, (err: ApiError) => {
        if (!this._files?.length && err.status === 0 && !navigator.onLine) {
          this.resetInProgress(this.translations.common.no_internet_connection);
        }
      });
    } else { // Root folder will not have folder name
      this.addFolderFilesToQueue(folder, folder.parentFolderId);
    }
  }

  addFolderFilesToQueue(folder: FolderStructure, parentId: ID) {
    const showFolderName = !!folder.name?.length;
    if (folder.files?.length) {
      this.addToQueue(folder.files, parentId, showFolderName);
    }
    const childernFolders: string[] = Object.keys(folder?.children);
    if (childernFolders?.length) {
      childernFolders.forEach((childrenFolder: string) => {
        this.addFolders(folder.children[childrenFolder], parentId);
      });
    }
    this.processNextFolders();
    this.hideUploadInProgress();
  }

  processNextFolders() {
    this._folders?.shift();
    if (this._folders?.length) {
      this.processFolders(this._folders[0]);
    }
  }

  addFolders(folder: FolderStructure, parentFolderId: ID) {
    const isQueueEmpty = !this._folders?.length;
    folder.parentFolderId = parentFolderId;
    this._folders.push(folder);

    if (isQueueEmpty) {
      this.processFolders(this._folders[0]);
    }
  }

  processFolderHierarchy(folders: FolderHierarchy, parentFolderId: ID) {
    // Reset for empty folders
    if (_isEmpty(folders)) {
      this.resetInProgress(this.translations.common.no_files_uploaded);
      return;
    }
    this.showUploadInProgress();
    // Adding delay to fix processing upload loading screen
    setTimeout(() => {
      Object.keys(folders).forEach((folderName: string) => {
        this.addFolders(folders[folderName], parentFolderId);
      });
    }, 100);
  }

  showUploadInProgress(): void {
    if (!this._files?.length) {
      this._uploadPreparing.next(true);
      this.displayPanel();
    }
  }

  hideUploadInProgress(): void {
    if (this._files?.length) {
      this._uploadPreparing.next(false);
      this.ngZone.run(() => {/**/});
    }
  }

  async processDragAndDrop(dataTransfer: DataTransfer, parentFolderId: ID): Promise<void> {
    this.showUploadInProgress();
    const filePromise = [];
    for (let i = 0; i < dataTransfer.items.length; i++) {
      filePromise.push(FolderFilesHelper.getFilesAsync(dataTransfer.items[i]));
    }
    Promise.all(filePromise).then((files) => {
      const folderFiles = _flatMap(files);
      const hierarchy: FolderHierarchy = FolderFilesHelper.buildFolderHierarchy(folderFiles);
      this.processFolderHierarchy(hierarchy, parentFolderId);
    });
  }

  resetInProgress(message: string): void {
    this.hidePanel();
    this._uploadPreparing.next(false);
    this.ngZone.run(() => {
      this.notificationService.showNotification(NotificationType.ERROR, this.translateService.instant(message), '');
      this._folders = [];
    });
  }

  checkIsQueueProcessed(): Observable<any> {
    // Used to trigger the refreshArtSection(), When all the files in queue processed!
    return this.isQueueProcessed$.pipe(
      debounceTime(500),
      filter(() => !!this._files.length),
      takeWhile((isQueueProcessed) => !isQueueProcessed, true),
      tap((isQueueProcessed) => {
        if (isQueueProcessed) {
          this.myArtSearchService.refreshArtSection();
        }
      })
    );
  }
}
