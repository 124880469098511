import { Injectable } from '@angular/core';
import { Collaborator } from '@graphics-flow/types';
import { createState, Store } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';


const {state, config} = createState(
  withEntities<Collaborator, 'collaboratorId'>({idKey: 'collaboratorId', initialValue: []})
)

@Injectable({
  providedIn: 'root'
})
export class CollaboratorStore extends Store {
  constructor() {
    super({ name: 'collaborator', state, config });
  }
}
