import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImagePreviewType, User } from '@graphics-flow/types';
import { Observable } from 'rxjs';
import { MyArtUploaderService, UserQuery } from '@graphics-flow/util';
import { GraphicsFlowService } from '@graphics-flow/util';
import { Translations } from '@graphics-flow/shared/assets';

@Component({
  selector: 'gf-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss']
})
export class UserDropdownComponent {
  @Input() isDarkHeader = false;
  @Input() isPlanUpdated = false;
  @Output() resetIsPlanUpdated: EventEmitter<void> = new EventEmitter();
  user$: Observable<User> = this.userQuery.user$;
  imagePreviewType: typeof ImagePreviewType = ImagePreviewType;
  profilePreviewType: typeof ImagePreviewType = ImagePreviewType;

  constructor(
    private readonly graphicsFlowService: GraphicsFlowService,
    public readonly userQuery: UserQuery,
    public translations: Translations,
    private translate: TranslateService,
    private myArtUploaderService: MyArtUploaderService) {
  }

  signOut(): void {
    if (this.myArtUploaderService.remainingFilesCount() || this.isPlanUpdated) {
      this.showBrowserConfirmation();
      return;
    }
    this.deauthenticate();
  }

  showBrowserConfirmation(): void {
    if (window.confirm(this.translate.instant(this.isPlanUpdated ? 'common.unsaved_changes_desc' : 'common.bulk_upload_in_progress_message'))) {
      this.deauthenticate();
    }
  }

  deauthenticate(): void {
    if (this.isPlanUpdated) {
      this.resetIsPlanUpdated.emit();
    }
    this.graphicsFlowService.deauthenticate();
    this.myArtUploaderService.cancelAll();
    this.myArtUploaderService.clearQueue();
  }

}
