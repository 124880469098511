import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ControlsOf, FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep as _cloneDeep, sortBy as _sortBy, toLower as _toLower } from 'lodash-es';
import { filter, map, switchMap } from 'rxjs/operators';

import { Translations } from '@graphics-flow/shared/assets';
import { Art, ArtDescriptionForm, Color, DecorationMethod, EMPTY, Organization, StockArtType } from '@graphics-flow/types';
import { ArtService, OrganizationQuery } from '@graphics-flow/util';
import { ManageDecorationMethodsComponent } from '../manage-decoration-methods-dialog/manage-decoration-methods-modal.component';

@UntilDestroy()
@Component({
  selector: 'gf-art-description',
  templateUrl: './art-description.component.html',
  styleUrls: ['./art-description.component.scss']
})
export class ArtDescriptionComponent implements OnInit, OnChanges {
  readonly DEFAULT_MEASUREMENT = 'in';
  readonly NONE = 'None';
  artDescriptionForm: FormGroup<ControlsOf<ArtDescriptionForm>>;
  artDetailsPanel = false;
  decorationMethods: DecorationMethod[] = [];
  stockArtType: typeof StockArtType = StockArtType;

  @Input() art: Art;
  @Input() organization: Organization;
  @Input() viewOnly: boolean;

  constructor(
    private artService: ArtService,
    private dialog: MatDialog,
    public readonly organizationQuery: OrganizationQuery,
    public translations: Translations
  ) {
  }

  ngOnInit(): void {
    if (this.art) {
      let decorationMethodId: string = this.NONE;
      if (this.art?.details?.decorationMethodId) {
        const dm = this.organization.decorationMethods?.find(d => d.decorationMethodId === this.art.details.decorationMethodId);
        decorationMethodId = (!!dm && !dm.deleted) ? dm.decorationMethodId : this.NONE;
      }
      const emptyPlaceholder: EMPTY | null = this.viewOnly ? <EMPTY>'-' : null;
      this.artDescriptionForm = new FormGroup<ControlsOf<ArtDescriptionForm>>({
        colors: new FormArray([]),
        decorationMethodId: new FormControl({ value: decorationMethodId, disabled: this.viewOnly }),
        height: new FormControl(this.art?.details?.height || emptyPlaceholder, {
          updateOn: 'blur'
        }),
        width: new FormControl(this.art?.details?.width || emptyPlaceholder, {
          updateOn: 'blur'
        }),
        unit: new FormControl({
          value : this.art?.details?.heightMeasurement || this.art?.details?.widthMeasurement || this.DEFAULT_MEASUREMENT,
          disabled: this.viewOnly
        })
      });

      if (this.art?.details?.colors?.length > 0) {
        this.art.details.colors.forEach(color => {
          this.addColor(color?.color, color?.name || emptyPlaceholder);
        });
      }

      this.artDescriptionForm.valueChanges.pipe(
        untilDestroyed(this),
        filter(() => !this.artDescriptionForm.pristine),
        map((formData: ArtDescriptionForm) => {
          const artData: Art = _cloneDeep(this.art);

          if (!artData?.details) {
            artData['details'] = {};
          }

          artData.details.width = formData.width ? Number(formData.width) : null;
          artData.details.height = formData.height ? Number(formData.height) : null;

          if (formData.unit) {
            artData.details.widthMeasurement = formData.unit;
            artData.details.heightMeasurement = formData.unit;
          }

          artData.details.decorationMethodId = formData.decorationMethodId !== this.NONE ? formData.decorationMethodId as string : null;

          const colors: Color[] = [];
          formData?.colors?.forEach((c: Color) => {
            if (c?.color) {
              colors.push({ ...c });
            }
          });
          artData.details.colors = colors;

          return artData;
        }),
        switchMap((artData: Art) => {
          return this.artService.updateArt(artData);
        })
      ).subscribe((art) => {
        this.art = art;
      });

    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.organization?.currentValue) {
      this.decorationMethods = [];

      if (!changes.organization.currentValue?.decorationMethods?.length) {
        return;
      }
      const dms = changes.organization.currentValue.decorationMethods.filter((dm: DecorationMethod) => !dm.deleted);
      this.decorationMethods = _sortBy(dms, (dm: DecorationMethod) => _toLower(dm.name));

      // update decorationMethod if all dm are deleted or current art dm is deleted
      const decorationMethod = this.decorationMethods.find(dm => dm?.decorationMethodId === this.art?.details?.decorationMethodId);

      // if both dm and art decorationMethodId are not present consider decoration method not set so far
      if (!decorationMethod && !this.art?.details?.decorationMethodId) {
        return;
      }

      // Note:- If the current art dm is deleted and the art is holding the deleted decoration method Id
      // (happens when we delete the dm from manage dm dialog which is mapped to this art)
      // In this case we need to update art
      if (!decorationMethod && !!this.artDescriptionForm) {
        this.artDescriptionForm.patchValue({ decorationMethodId: this.NONE });
      }
    }
  }

  openDecorationMethodModal() {
    this.dialog.open(ManageDecorationMethodsComponent, {
      closeOnNavigation: true,
      data: _cloneDeep(this.organization),
      autoFocus: false,
      panelClass: 'mobile-screen-modal'
    });
  }

  addColor(code: string = '', name: string = '') {
    this.colorCodesForm.push(
      new FormGroup({
        color: new FormControl(code, { 'updateOn': 'blur' }),
        name: new FormControl(name, { 'updateOn': 'blur' })
      })
    );
  }

  removeColor(position: number) {
    this.colorCodesForm.removeAt(position);
  }

  editColor(color: string, position) {
    this.colorCodesForm.controls[position].patchValue({
      ...this.colorCodesForm.controls[position].value,
      color: color
    });
  }

  get colorCodesForm() {
    return this.artDescriptionForm.get('colors') as FormArray<Color>;
  }

}
