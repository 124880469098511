/*tslint:disable:no-bitwise*/
import { toLower as _toLower, includes as _includes, size as _size, every as _every } from 'lodash-es';

export class ColorHelpers {
  // Return black or white depending on the given color.
  // Works for hex color input only.
  static getContrastColor($color): string {
    if ($color) {
      return this.getContrastColorValue($color) < 0.5 ? 'black' : 'white';
    }
    return 'black';
  }

  static getContrastColorValue($color): number {
    if ($color) {
      let c = $color.substring(1); // strip #
      // cases like '#BBB'
      if (c.length === 3) {
        c =
          c.charAt(0) +
          c.charAt(0) +
          c.charAt(1) +
          c.charAt(1) +
          c.charAt(2) +
          c.charAt(2);
      }
      const rgb = parseInt(c, 16); // convert rrggbb(hex) to decimal
      const r = (rgb >> 16) & 0xff; // extract red
      const g = (rgb >> 8) & 0xff; // extract green
      const b = (rgb >> 0) & 0xff; // extract blue
      // Bright colors return black. Dark colors return white.
      return 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    }
    return 0;
  }

  static getTextContrastColors(color) {
    const c = this.getContrastColor(color);
    if (c === 'white') {
      return {
        primary: 'rgba(255, 255, 255, 1)',
        secondary: 'rgba(255, 255, 255, .7)',
      };
    } else {
      return {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, .54)',
      };
    }
  }

  // taken from https://css-tricks.com/snippets/javascript/lighten-darken-color/
  static lightenDarkenColor(col, amt) {
    let usePound = false;

    if (col[0] === '#') {
      col = col.slice(1);
      usePound = true;
    }

    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;
    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    let b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }

    let g = (num & 0x0000ff) + amt;
    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  }

  static colorsAllWhite(colors: string[]): boolean {
    if (!colors || !_size(colors)) {
      return false;
    }

    return _every(colors, (color: string) => _includes(['ffffff', '#ffffff', 'none', '#none'], _toLower(color)));
  }
  // Used to convert the hex to hexa(Opacity).
  static convertHexToHexA(hex: string, opacity: number): string {
    if (!hex) {
      return null;
    }
    return hex + Math.round(opacity * 255).toString(16);
  }

  static shadeHexColor(color: string, percent: number): string {
    // used to increase / decrease the brightness for the given color in %.
    // lighter -> +  ====> shadeHexColor('#000000', 0.1) => adds 10% brightness
    // Darker  -> -  ====> shadeHexColor('#000000', -0.1) => adds 10% darkness
    if (!color) {
      return;
    }
    const f = parseInt(color.slice(1), 16),
      t = percent < 0 ? 0 : 255,
      p = percent < 0 ? percent * -1 : percent,
      R = f >> 16,
      G = (f >> 8) & 0x00ff,
      B = f & 0x0000ff;
    return (
      '#' +
      (
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
        .toString(16)
        .slice(1)
    );
  }
}
