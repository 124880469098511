import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, map } from 'rxjs';

import { IRoles, ReactivationStatus, StripeSubscriptionStatus, User } from '@graphics-flow/types';

import { defaultHomePage } from '../constants/default-route.constants';
import { OrganizationQuery } from '../data/organization/organization.query';
import { UserQuery } from '../data/user/user.query';
import { UserHelper } from '../helpers/user.helper';

@Injectable({
  providedIn: 'root'
})
export class ReactivateGuard  {
  constructor(
    private userQuery: UserQuery,
    private router: Router,
    private organizationQuery: OrganizationQuery)
  {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const isReactivationSuccess = (route.queryParams.status === ReactivationStatus.SUCCESS) && this.organizationQuery.getReactivationStatus();
    return this.userQuery.user$.pipe(
      map((user: User) => {
        const userRole = UserHelper.getUserRole(user);
        if ((user.subscriptionStatus === StripeSubscriptionStatus.CANCELLED
          || (user.subscriptionStatus === StripeSubscriptionStatus.ACTIVE && isReactivationSuccess))
          && !([IRoles.User, IRoles.Support].includes(userRole))) {
          return true;
        }
        this.router.navigateByUrl(defaultHomePage);
        return false;
      })
    );
  }
}
