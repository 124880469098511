<div class="flex-container flex-child-auto">
  <div [className]="'avatar rounded cursor-pointer '+size"
       [matTooltip]="collaborator.email"
       #tooltip="matTooltip"
       matTooltipClass="mat-tooltip-below"
       (click)="tooltip.toggle()"
       [ngClass]="(collaborator.name || collaborator.email)| shortName : true | iconBackground">
    {{(collaborator.name || collaborator.email)  | shortName : true }}
  </div>
</div>
