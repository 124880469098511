import { Router } from '@angular/router';
import { Injectable, NgZone, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { filter, switchMap, take, tap, finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import {
  ApiError,
  Art,
  ArtApproval,
  ArtApprovalPackage,
  CreateArtApprovalForm,
  GenericRemovalDialogData,
  GenericWarningDialogData,
  ID,
  NotificationData,
  NotificationType,
} from '@graphics-flow/types';
import { ApprovalService, ArtService, GlobalHelpers, MyArtBulkSelectionService, TagService, MyArtSearchService, GraphicsFlowService, BillingPlansService, MyArtSearchStore, storageLimitExceedErrorCode } from '@graphics-flow/util';
import { GenericWarningDialogComponent, LoadingService, NotificationComponent, NotificationService, GenericDataRemovalDailogComponent } from 'shared/ui';
import { Translations } from '@graphics-flow/shared/assets';

import { AddArtToApprovalDialogComponent, AddArtToApprovalType } from '../components/add-art-to-approval-dialog/add-art-to-approval-dialog.component';
import { RenameArtDialogComponent } from '../components/rename-art-dialog/rename-art-dialog.component';
import { EditTagsDialogComponent } from '../components/edit-tags-dialog/edit-tags-dialog.component';
import { CreateArtApprovalDialogComponent } from '../components/create-art-approval-dialog/create-art-approval-dialog.component';


@Injectable({
  providedIn: 'root'
})
export class ArtActionsService {

  public approvalId: ID;
  snackBarRef: MatSnackBarRef<NotificationComponent>;
  snackBarConfig: MatSnackBarConfig = {
    horizontalPosition: 'start',
    verticalPosition: 'bottom',
  };
  uploadArtSubscription$;

  constructor(
    private readonly dialog: MatDialog,
    private readonly myArtBulkSelectionService: MyArtBulkSelectionService,
    private readonly ngZone: NgZone,
    private readonly notificationService: NotificationService,
    private readonly approvalService: ApprovalService,
    private readonly artService: ArtService,
    private readonly tagService: TagService,
    private readonly router: Router,
    private readonly translations: Translations,
    private readonly translationService: TranslateService,
    private readonly loadingService: LoadingService,
    private readonly myArtSearchService: MyArtSearchService,
    private graphicsFlowService: GraphicsFlowService,
    private billingPlansService: BillingPlansService,
    private myArtSearchStore: MyArtSearchStore
  ) {}

  uploadArt(files?: File[], folderId?: ID) {

    if (!files?.length) {
      return;
    }

    // File size should not be greater than 50 MB
    if (!GlobalHelpers.isFileLessThan50MB(files[0])) {
      this.notificationService.showFileNotification(NotificationType.ERROR, this.translationService.instant(this.translations.fileupload.file_size_exceeded), files[0].name);
      return;
    }

    // Check file format
    const fileType =files[0]?.name.substr(files[0].name.lastIndexOf('.'))?.toLowerCase();
    if (!GlobalHelpers.SUPPORTED_MY_ART_FILE_EXTENSIONS.includes(fileType)) {
      this.notificationService.showFileNotification(NotificationType.ERROR, this.translationService.instant(this.translations.fileupload.file_format_not_supported), files[0].name);
      return;
    }

    this.snackBarConfig.data = files[0];

    this.snackBarRef = this.notificationService.showFileNotification(NotificationType.INPROGRESS,
      this.translationService.instant(this.translations.art.uploading),
      files[0].name);

    this.artService.uploadArt([files[0]], folderId).pipe(
      take(1)
    ).subscribe((arts) => {
      this.myArtSearchService.addOrUpdateGivenArtsAndFolders(arts, []);
      this.snackBarRef.instance.updateNotification(<NotificationData>{
        type: NotificationType.SUCCESS,
        title: this.translationService.instant(this.translations.art.upload_complete)
      });
      this.closeNotification();
    }, (err: ApiError) => {
      let errorMessage: string = err.message;
      if (err.status === 413) {
        errorMessage = this.translationService.instant(this.translations.fileupload.file_size_exceeded);
      }
      if (storageLimitExceedErrorCode === err.errorCode) {
        this.graphicsFlowService.openStorageLimitExceedModal();
      }
      this.snackBarRef.instance.updateNotification(<NotificationData>{
        type: NotificationType.ERROR,
        title: this.translationService.instant(this.translations.art.upload_failed),
        description: errorMessage
      });
      this.closeNotification();
    });
  }

  closeNotification() {
    this.ngZone.run(() => {
      setTimeout(() => {
        this.snackBarRef.dismissWithAction();
      }, 3000);
    });
  }

  // TODO:- implementation
  renameArt(art: Art) {
    this.dialog.open(RenameArtDialogComponent, {
      data: art.name,
      panelClass: 'mobile-screen-modal',
      closeOnNavigation: true,
      autoFocus: false
    }).afterClosed().pipe(
      filter((renamedArt: string) => !!renamedArt && renamedArt !== art.name),
      switchMap((name: string) => {
        const artClone: Art = Object.assign({}, art);
        artClone.name = name;
        return this.artService.updateArt(artClone);
      })
    ).subscribe();
  }

  // Note:- navigateToParent will be true when try to delete art from it's detail page.
  deleteArt(art: Art, navigateToParent: boolean = false) {
    const deleteArtLoaderId = 'DELETE_ART_LOADER';
    this.dialog.open(GenericDataRemovalDailogComponent, {
      data: <GenericRemovalDialogData>{
        header: this.translations.common.delete_file,
        body: this.translationService.instant(this.translations.common.my_art_delete, {x: 'file'}),
        removalData: art.name,
        note: this.myArtSearchService.getDeleteModalNote(),
        continueText: this.translations.common.delete_file,
        cancelText: this.translations.common.cancel
      },
      autoFocus: false,
      closeOnNavigation: true,
      panelClass: 'mobile-screen-modal-medium-y'
    }).afterClosed().pipe(
      filter((canDelete) => !!canDelete),
      tap(() => {
        this.myArtBulkSelectionService.removeArt(art.artId);
        this.loadingService.showLoader(deleteArtLoaderId);
        this.graphicsFlowService.isBulkActionInProgress$.next(true);
      }),
      switchMap(() => this.artService.deleteArt(art)),
    ).subscribe(() => {
      if (!navigateToParent) {
        this.myArtSearchStore.reduceTotalResultsCount(1);
      }
      this.myArtSearchService.removeSelectedArtsAndFolders([art.artId], []);
      this.loadingService.hideLoader(deleteArtLoaderId);
      this.graphicsFlowService.isBulkActionInProgress$.next(false);
      this.notificationService.showNotification(NotificationType.SUCCESS, '', this.translationService.instant(this.translations.common.art_deleted_successfully));
      if (navigateToParent) {
        const path = art?.folderId ? `/my/${art?.folderId}` : '/my';
        this.router.navigate([path], {replaceUrl: true});
      }
    }, (err: ApiError) => {
      this.loadingService.hideLoader(deleteArtLoaderId);
      this.graphicsFlowService.isBulkActionInProgress$.next(false);
      this.notificationService.showNotification(NotificationType.ERROR, err.statusText, err.message);
    });
  }

  deleteArtPermanently(artId: ID) {
    const deleteArtLoaderId = 'DELETE ART FOLDER';
    this.dialog.open(GenericWarningDialogComponent, {
      data: <GenericWarningDialogData> {
        icon: 'info_outline',
        header: this.translations.permanently_delete.file,
        body: this.translations.permanently_delete.confirmation_msg_file,
        cancelText: this.translations.common.cancel,
        continueText: this.translations.permanently_delete.delete,
      },
      autoFocus: false,
      panelClass: 'mobile-screen-modal'
    }).afterClosed().pipe(
      filter(confirmed => confirmed),
      tap(() => {
        this.loadingService.showLoader(deleteArtLoaderId);
      }),
      switchMap(() => this.artService.permanentlyDeleteArt(artId)),
      switchMap(() => this.billingPlansService.getOrganizationResourceUsage())
    ).subscribe(() => {
      this.loadingService.hideLoader(deleteArtLoaderId);
      this.notificationService.showNotification(NotificationType.SUCCESS, this.translationService.instant(this.translations.common.art_deleted_successfully), '');
    }, () => {
      this.loadingService.hideLoader(deleteArtLoaderId);
    });
  }

  addArtToApproval(artIds: ID[], templateRef?: TemplateRef<ID[]>, showCreateNewArtApproval: boolean = true) {
    this.dialog.open(AddArtToApprovalDialogComponent, <MatDialogConfig>{
      data: showCreateNewArtApproval,
      autoFocus: false,
      panelClass: 'mobile-screen-modal-medium-y'
    }).afterClosed().pipe(
      filter(approval => {
        if (approval === AddArtToApprovalType.NEW) {
          this.createArtApprovalAndAddArt(artIds, templateRef);
          return false;
        }
        return !!approval;
      }),
      tap(() => {
        this.notificationService.showNotification(NotificationType.INPROGRESS, this.translationService.instant(
        this.translations.approval.adding_to_art_approval), '', null, null);
        this.graphicsFlowService.isBulkActionInProgress$.next(true);
      }),
      switchMap(approval =>
        this.approvalService.addArtToApproval(artIds, approval.artApprovalId)
      ),
      tap((artApprovalPackage: ArtApprovalPackage) => {
        this.approvalId = artApprovalPackage.artApproval.artApprovalId;
        this.notificationService.showNotification(NotificationType.SUCCESS,
          this.translationService.instant(this.translations.common.file_added_successfully),
          artApprovalPackage.artApproval.name,
          templateRef);
      }),
      finalize(() => {
        this.graphicsFlowService.isBulkActionInProgress$.next(false);
      })
    ).subscribe();
  }

  addStockArtToApproval(artId: ID, templateRef?: TemplateRef<ID[]>) {
    const addStockArtLoaderId = 'ADD_STOCK_ART_LOADER';
    this.dialog.open(AddArtToApprovalDialogComponent, <MatDialogConfig> {
      data: true,
      autoFocus: false,
      panelClass: 'mobile-screen-modal-medium-y'
    }).afterClosed().pipe(
      filter(approval => {
        if (approval === AddArtToApprovalType.NEW) {
          this.createArtApprovalAndAddStockArt([artId], templateRef);
          return false;
        }
        return !!approval;
      }),
      tap(() => this.loadingService.showLoader(addStockArtLoaderId)),
      switchMap(approval =>
        this.approvalService.addStockArtToApproval([artId], approval.artApprovalId)
      ),
      finalize(() => this.loadingService.hideLoader(addStockArtLoaderId))
    )
    .subscribe((val: ArtApprovalPackage) => {
      this.approvalId = val.artApproval.artApprovalId;
      this.myArtBulkSelectionService.reset();
      this.notificationService.showNotification(NotificationType.SUCCESS,
        this.translationService.instant(this.translations.common.file_added_successfully),
        val.artApproval.name,
        templateRef);
    }, (err: ApiError) => {
      this.notificationService.showNotification(NotificationType.ERROR, err.statusText, err.message);
    });
  }

  createArtApprovalAndAddArt(artIds: ID[], templateRef?: TemplateRef<ID[]>) {
    this.dialog.open(CreateArtApprovalDialogComponent, <MatDialogConfig> {
      data: {},
      autoFocus: false,
      panelClass: 'mobile-screen-modal-medium-y'
    }).afterClosed().pipe(
      filter(approvalForm => !!approvalForm),
      tap(() => {
        this.notificationService.showNotification(NotificationType.INPROGRESS, this.translationService.instant(
        this.translations.approval.adding_to_art_approval), '', null, null)
        this.graphicsFlowService.isBulkActionInProgress$.next(true);
      }),
      switchMap((approvalForm: CreateArtApprovalForm) => this.approvalService.createArtApprovalAndAddArt(artIds, approvalForm)),
      tap((artApproval: Partial<ArtApproval>) => {
        this.approvalId = artApproval.artApprovalId;
        this.notificationService.showNotification(NotificationType.SUCCESS,
          this.translationService.instant(this.translations.common.file_added_successfully),
          artApproval.artApprovalName,
          templateRef);
      }),
      finalize(() => {
        this.graphicsFlowService.isBulkActionInProgress$.next(false);
      })
    ).subscribe();
  }

  createArtApprovalAndAddStockArt(artIds: ID[], templateRef?: TemplateRef<ID[]>) {
    const addArtLoaderId = 'create_art_approval';
    this.dialog.open(CreateArtApprovalDialogComponent, <MatDialogConfig> {
      data: {},
      autoFocus: false,
      panelClass: 'mobile-screen-modal-medium-y'
    }).afterClosed().pipe(
      filter(approvalForm => !!approvalForm),
      tap(() => this.loadingService.showLoader(addArtLoaderId)),
      switchMap((approvalForm: CreateArtApprovalForm) => this.approvalService.createArtApprovalAndAddStockArt(artIds, approvalForm)),
      finalize(() => this.loadingService.hideLoader(addArtLoaderId))
    )
    .subscribe((val: ArtApprovalPackage) => {
      this.approvalId = val.artApproval.artApprovalId;
      this.notificationService.showNotification(NotificationType.SUCCESS,
        this.translationService.instant(this.translations.common.file_added_successfully),
        val.artApproval.name,
        templateRef);
    }, (err: ApiError) => {
      this.notificationService.showNotification(NotificationType.ERROR, err.statusText, err.message);
    });
  }

  editTags(artIds: ID[], tags: string[]): void {
    this.dialog.open(EditTagsDialogComponent, <MatDialogConfig> {
      id: 'EDIT_TAGS_DIALOG',
      data: {
        artIds,
        tags
      },
      autoFocus: false,
      panelClass: 'mobile-screen-modal',
      closeOnNavigation: true,
      disableClose: true,
    }).afterClosed()
      .pipe(
        switchMap(() => this.tagService.getAllTags(true)))
      .subscribe();
  }

}
