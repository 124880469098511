import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';
import { BundlePlanType, Plan } from '@graphics-flow/types';
import { BillingPlansQuery } from '@graphics-flow/util';
import { TranslateService } from '@ngx-translate/core';
import { UpdateSubscriptionPlanService } from '@graphics-flow/util';
import { take, tap } from 'rxjs';
import { WindowService } from 'shared/util';

interface PlanDescription {
  name: string;
  price: number;
}

interface PlanTable {
  plan: PlanDescription[];
  teamMembers: string[];
  storage: number[];
  stockArtDownloads: string[];
  privateBranding: boolean[];
  proGraphicsCollection: boolean[];
  publicCatalog: boolean[];
  customizeStockArt: boolean[];
}

@Component({
  selector: 'gf-plans-details',
  templateUrl: './plans-details.component.html',
  styleUrls: ['./plans-details.component.scss'],
})
export class PlansDetailsComponent implements OnInit {
  existingPlan: Plan;
  expandPlan = false;
  isLoading = true;
  headers: string[];
  plans: Plan[];
  planTable: PlanTable = {
    plan: [],
    teamMembers: [],
    storage: [],
    stockArtDownloads: [],
    privateBranding: [],
    proGraphicsCollection: [],
    publicCatalog: [],
    customizeStockArt: [],
  };
  @Input() selectedBundlePlanType: BundlePlanType;
  @Input() isAccountReactivation = false;
  @Output() newPlanSelection: EventEmitter<Plan> = new EventEmitter<Plan>();

  selectedPlan: Plan;
  // Plan Table Section, used to maintain the borders for the plan sections.
  allowedBorders: number[];

  constructor(
    private billingPlansQuery: BillingPlansQuery,
    private translateService: TranslateService,
    public translationService: TranslateService,
    public translations: Translations,
    public windowService: WindowService,
    public updateSubscriptionPlanService: UpdateSubscriptionPlanService
  ) {
  }

  ngOnInit(): void {
    this.billingPlansQuery
      .getBundlePlans()
      .pipe(
        take(1),
        tap((bundlePlans: Plan[]) => {
          this.selectedPlan = bundlePlans.find((bundlePlan) => bundlePlan.plan === this.selectedBundlePlanType);
          this.existingPlan = this.selectedPlan;
          this.plans = bundlePlans;
          const selectedPlanIndex = bundlePlans.findIndex((bundlePlan) => bundlePlan.plan === this.selectedBundlePlanType);
          this.allowedBorders = [selectedPlanIndex, selectedPlanIndex - 1];
          this.headers = [];
          this.headers.push(this.translationService.instant(this.translations.plans.plan_comparison));
          this.plans.forEach((plan: Plan) => {
            this.headers.push(this.translateService.instant(plan.name));
            this.planTable.plan.push({
              name: plan.name,
              price: plan.price,
            });
            this.planTable.teamMembers.push(plan.product.productMetadata.users);
            this.planTable.storage.push(plan.product.storageBytes);
            this.planTable.stockArtDownloads.push(plan.product.productMetadata?.downloads);
            this.planTable.proGraphicsCollection.push(!!plan.product.productMetadata.graphics_builder);
            this.planTable.privateBranding.push(!!plan.product.productMetadata.private_branding);
            this.planTable.publicCatalog.push(!!plan.product.productMetadata.public_catalog);
            this.planTable.customizeStockArt.push(!!plan.product.productMetadata.customize_stockart);
          });
          this.isLoading = false;
        })
      )
      .subscribe();
  }
}
