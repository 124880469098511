import { Injectable } from '@angular/core';
import { createState, Store } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';

import { StockArt } from '@graphics-flow/types';

const { state, config } = createState(
  withEntities<StockArt, 'stockArtId'>({ idKey: 'stockArtId', initialValue: [] })
);

@Injectable({ providedIn: 'root' })
export class StockArtStore extends Store {

  constructor() {
    super({ name: 'stock-art', state, config });
  }

}
