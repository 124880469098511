import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { User } from '@graphics-flow/types';
import { UserQuery } from '../data/user/user.query';
import { defaultHomePage } from '../constants/default-route.constants';

@Injectable({
  providedIn: 'root'
})
export class TeamSetupGuard {
  constructor(
    private userQuery: UserQuery,
    private readonly router: Router
  ) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkTeamSetup();
  }

  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkTeamSetup();
  }

  // note:- no need to fix this warning
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private checkTeamSetup(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([this.userQuery.user$, this.userQuery.isOwner$]).pipe(
      map(([user, isOwner]: [User, boolean]) => {
        const setupNotComplete: boolean = isOwner && user.isFirstLogin;

        if (setupNotComplete) {
          return true;
        }

        this.router.navigate([defaultHomePage]);
        return false;
      })
    );
  }
}
