import { Component, Input } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';

@Component({
  selector: 'gf-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
  @Input() organizationName: string;
  constructor(public translations: Translations) { }

}
