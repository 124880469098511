import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FolderFile, FolderHierarchy } from '@graphics-flow/types';
import { FolderFilesHelper } from '@graphics-flow/util';

@Component({
  selector: 'gf-folder-input',
  templateUrl: './folder-input.component.html',
  styleUrls: ['./folder-input.component.scss']
})
export class FolderInputComponent {
  @ViewChild('folderInput', {static: false})private folderInput: ElementRef;
  @Output() folders: EventEmitter<FolderHierarchy> = new EventEmitter<FolderHierarchy>();

  open() {
    this.folderInput?.nativeElement.click();
  }

  uploadFiles([...files]: FolderFile[]): void {
    const hierarchy: FolderHierarchy = FolderFilesHelper.buildFolderHierarchy(files);
    this.folders.emit(hierarchy);
    this.reset();
  }

  reset() {
    if (this.folderInput) {
      this.folderInput.nativeElement.value = '';
    }
  }

}
