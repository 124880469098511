<div class="t-12-400-s align-middle flex-container" [class.m-y-1]="!art?.isDownloaded">
  {{(art?.customizedStockArtId ? translations.common.customized_stock_art : translations.art.stock_art) | translate}}
  <ng-container *ngIf="art?.isDownloaded && !art?.customizedStockArtId">
    <span class="downloaded">{{translations.common.downloaded | translate}}</span>
    <span #tooltip="matTooltip"
      matTooltipClass="mat-tooltip-below"
      [matTooltipPosition]="'below'"
      [matTooltip]="translations.common.stock_art_already_downloaded_message | translate"
      (click)="tooltip.toggle()">
      <button mat-icon-button
        id="downloadedIcon"
        class="downloaded-icon">
        <mat-icon fontIcon="info_outline"></mat-icon>
      </button>
    </span>
  </ng-container>
</div>
