import { Component } from '@angular/core';
import { NavigationService } from '@graphics-flow/util';
import { Translations } from '@graphics-flow/shared/assets';
import { WindowService } from 'shared/util';

@Component({
  selector: 'gf-header',
  templateUrl: './gf-header.component.html',
  styleUrls: ['./gf-header.component.scss']
})
export class GfHeaderComponent {

  constructor(
    public readonly navigationService: NavigationService,
    public readonly translations: Translations,
    public readonly windowService: WindowService,
  ) {
  }

  toggleSidenav() {
    this.navigationService.toggleSidenav();
  }
}
