// Scroller Constants
export const MAX_SCROLL_LIMIT = 9999;


// My Art Constants
export const MY_ART_BASE_LIMIT = 36;


// Art Approval Constants
export const ART_APPROVAL_BASE_LIMIT = 20;
