import { Injectable } from '@angular/core';
import { DesignRequestHttpService } from '@graphics-flow/api';
import { cloneDeep as _cloneDeep, isEmpty as _isEmpty, isEqual as _isEqual } from 'lodash-es';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ApiResponse, Art, ArtApproval, ArtApprovalStatus, CreateArtApprovalForm, CustomField, CustomerInfo, DesignRequest, DesignRequestItem, DesignRequestQueryParam, DesignRequestStatus, ID, IUser } from '@graphics-flow/types';
import { FileHelpers } from 'shared/util';
import { ApprovalService } from '../approval/approval.service';
import { ArtService } from '../art/art.service';
import { DesignRequestListQuery } from './design-request-list.query';
import { DesignRequestListStore, designRequestBatchSize } from './design-request-list.state';

@Injectable({
  providedIn: 'root'
})
export class DesignRequestListService {
  destroyed$ = new Subject();
  downloadSubscription: Subscription;

  constructor(
    private readonly designRequestListQuery: DesignRequestListQuery,
    private readonly designRequestListStore: DesignRequestListStore,
    private readonly designRequestHttpService: DesignRequestHttpService,
    private readonly artService: ArtService,
    private readonly approvalService: ApprovalService
  ) {
  }

  initialize() {
    this.designRequestListQuery.activeFilters$.pipe(
      debounceTime(500), // Filters get changed a lot, we don't necessarily want to make a call instantly every time
      distinctUntilChanged((oldFilter, newFilter) =>  {
        return _isEqual(oldFilter, newFilter);
      }),
      switchMap((activeFilters: DesignRequestQueryParam) => {
        return this.designRequestHttpService.getDesignRequestSummary(activeFilters);
      }),
      takeUntil(this.destroyed$)
    ).subscribe((response: ApiResponse<DesignRequest[]>) => {
      this.designRequestListStore.setPagination(response.pagination);
      if (response.pagination.index === 0) {
        this.designRequestListStore.setDesignRequests(response.data);
      } else {
        this.designRequestListStore.appendDesignRequests(response.data);
      }
    });
  }

  clearSubscriptions() {
    this.destroyed$.next(null);
    this.designRequestListStore.reset();
    this.clearActiveDesignRequest();
    this.cancelDownloadArts();
  }

  clearActiveDesignRequest(): void {
    this.designRequestListStore.updateDesignRequestDetails(null);
  }

  getBatch(offset: number) {
    this.setActiveFilters({
      index: offset,
      limit: designRequestBatchSize
    }, offset !== 0);
  }

  setActiveFilters(activeFilters: DesignRequestQueryParam, inProgress: boolean = false) {
    this.designRequestListStore.setActiveFilters(activeFilters, inProgress);
  }

  setStatus(status: DesignRequestStatus) {
    this.designRequestListStore.update((state) => {
      if (status !== state.activeFilters?.status) {
        state.loading = true;
        state.filteredDesignRequests = [];
        state.activeFilters = {
          ...state.activeFilters,
          status: status,
          index: 0
        };
      }

      return {...state};
    });
  }

  setAssignee(id: ID, status: string) {
    this.designRequestListStore.update((state) => {
      if (id !== state.activeFilters?.assigneeUserId || status !== state.activeFilters?.assigneeStatus) {
        state.loading = true;
        state.filteredDesignRequests = [];
        state.activeFilters = {
          ...state.activeFilters,
          assigneeStatus: status,
          assigneeUserId: id,
          index: 0
        };
      }

      return {...state};
    });
  }

  setSearchText(search: string) {
    this.designRequestListStore.update((state) => ({
      ...state,
      loading: true,
      filteredDesignRequests: [],
      activeFilters: {
        ...state.activeFilters,
        searchText: search,
        index: 0
      }
    }));
  }

  updateDefaultDesignRequest(designRequest: DesignRequest): DesignRequest {
    const updatedDesignRequest = _cloneDeep(designRequest);
    // If the assignee set to unassigned, after save we wont get assigneeUserId key from API.
    // so to maintain a valid design request info, this will be helpful.
    if (!updatedDesignRequest.assigneeUserId) {
      updatedDesignRequest.assigneeUserId = null;
    }

    if (!updatedDesignRequest?.internalNotes?.text) {
      updatedDesignRequest.internalNotes.text = '';
    }

    if (!updatedDesignRequest?.customerNotes) {
      updatedDesignRequest.customerNotes = '';
    }
    return updatedDesignRequest;
  }

  getDesignRequestPackage(id: ID): Observable<DesignRequest> {
    return this.designRequestHttpService.getDesignRequest(id).pipe(
      tap((designRequest: DesignRequest) => {
        this.designRequestListStore.updateDesignRequestDetails(this.updateDefaultDesignRequest(designRequest));
        this.saveArtsFromDesignRequest(designRequest);
        this.approvalService.addApprovals(designRequest.artApprovals);
      })
    );
  }

  updateDesignRequest(designRequest: DesignRequest): Observable<DesignRequest> {
    this.cancelDownloadArts();
    return this.designRequestHttpService.updateDesignRequest(designRequest).pipe(
      tap((updatedDesignRequest: DesignRequest) => {
        this.designRequestListStore.updateDesignRequestDetails(this.updateDefaultDesignRequest(updatedDesignRequest));
        this.designRequestListStore.updateDesignRequestInList(this.updateDefaultDesignRequest(updatedDesignRequest));
      })
    );
  }

  setDesignRequestAssignee(designRequest: DesignRequest, assigneeUserId: ID): Observable<DesignRequest> {
    const updatedDesignRequest: DesignRequest = Object.assign({}, designRequest, { assigneeUserId });
    return this.updateDesignRequest(updatedDesignRequest);
  }

  setDesignRequestInternalNotes(designRequest: DesignRequest, notes: string): Observable<DesignRequest> {
    this.cancelDownloadArts();
    return this.designRequestHttpService.saveDesignRequestNotes(designRequest.designRequestId, notes).pipe(
      tap((updatedDesignRequest: DesignRequest) => {
        updatedDesignRequest.hasInternalNotes = !_isEmpty(updatedDesignRequest?.internalNotes?.text);
        this.designRequestListStore.updateDesignRequestDetails(this.updateDefaultDesignRequest(updatedDesignRequest));
        this.designRequestListStore.updateDesignRequestInList(this.updateDefaultDesignRequest(updatedDesignRequest));
      })
    );
  }

  downloadDesignRequestArts(id: ID, fileName: string, artIds: ID[]): void {
    this.designRequestListStore.setDownloadingStatus(true);
    this.downloadSubscription = this.designRequestHttpService.downloadDesignRequestArt(id, fileName).pipe(
      finalize(() => this.designRequestListStore.setDownloadingStatus(false))
    ).subscribe((result) => {
      this.artService.updateArtDownloadedStatus(artIds, true);
      return FileHelpers.downloadFile(result.data, result.filename);
    });
  }

  cancelDownloadArts(): void {
    this.downloadSubscription?.unsubscribe();
    this.designRequestListStore.setDownloadingStatus(false);
  }

  deleteDesignRequest(designRequest: DesignRequest): Observable<DesignRequest> {
    this.cancelDownloadArts();
    return this.designRequestHttpService.deleteDesignRequest(designRequest.designRequestId).pipe(
      tap((updatedDesignRequest: DesignRequest) => {
        this.designRequestListStore.updateDesignRequestDetails(null);
        this.designRequestListStore.removeDesignRequestFromList(updatedDesignRequest);
      })
    );
  }

  addDesignRequestToArtApproval(designRequest: DesignRequest, artApprovalId: ID): Observable<DesignRequest> {
    this.cancelDownloadArts();
    return this.designRequestHttpService.addDesignRequestToArtApproval(designRequest.designRequestId, artApprovalId).pipe(
      tap((updatedDesignRequest: DesignRequest) => {
        updatedDesignRequest.hasArtApprovals = true;
        this.designRequestListStore.updateDesignRequestDetails(this.updateDefaultDesignRequest(updatedDesignRequest));
        this.designRequestListStore.updateDesignRequestInList(this.updateDefaultDesignRequest(updatedDesignRequest));
      })
    )
  }

  updateDesignRequestStatus(designRequest: DesignRequest, status: ArtApprovalStatus): Observable<DesignRequest> {
    this.cancelDownloadArts();
    return this.designRequestHttpService.updateDesignRequestStatus(designRequest.designRequestId, status).pipe(
      tap((updatedDesignRequest: DesignRequest) => {
        this.designRequestListStore.updateDesignRequestDetails(this.updateDefaultDesignRequest(updatedDesignRequest));
        this.designRequestListStore.updateDesignRequestInList(this.updateDefaultDesignRequest(updatedDesignRequest));
      })
    );
  }

  setCustomerInfo(designRequest: DesignRequest, updatedCustomerInfo: CustomerInfo): Observable<DesignRequest> {
    const updatedDesignRequest: DesignRequest = Object.assign({}, designRequest, updatedCustomerInfo);
    return this.updateDesignRequest(updatedDesignRequest);
  }

  setCustomerRequestNotes(designRequest: DesignRequest, customerNotes: string): Observable<DesignRequest> {
    const updatedDesignRequest: DesignRequest = Object.assign({}, designRequest, { customerNotes });
    return this.updateDesignRequest(updatedDesignRequest);
  }

  setCustomerAddtionalInfo(designRequest: DesignRequest, customFields: CustomField[]): Observable<DesignRequest> {
    const updatedDesignRequest: DesignRequest = Object.assign({}, designRequest, { customFields });
    return this.updateDesignRequest(updatedDesignRequest);
  }

  createArtApproval(designRequest: DesignRequest, artApprovalForm: CreateArtApprovalForm): Observable<ArtApproval> {
    this.cancelDownloadArts();
    return this.designRequestHttpService.createArtApprovalFromDesignRequest(artApprovalForm).pipe(
      tap((artApproval: ArtApproval) => {
        const updatedDesignRequest = _cloneDeep(designRequest);
        updatedDesignRequest.artApprovalIds.push(artApproval.artApprovalId);
        updatedDesignRequest.hasArtApprovals = true;
        if (artApprovalForm.archive) {
          updatedDesignRequest.status = DesignRequestStatus.ARCHIVED;
        }
        this.designRequestListStore.updateDesignRequestDetails(this.updateDefaultDesignRequest(updatedDesignRequest));
        this.designRequestListStore.updateDesignRequestInList(this.updateDefaultDesignRequest(updatedDesignRequest));
        this.approvalService.addApproval(artApproval);
      })
    );
  }

  saveArtsFromDesignRequest(designRequest: DesignRequest): void {
    const availableArts: Art[] = designRequest.items.map((item: DesignRequestItem) => item.art);
    this.artService.addArtToStore(availableArts);
  }

  getDesignRequestAssignedUsers(): Observable<IUser[]> {
    return this.designRequestHttpService.getDesignRequestAssignedUsers().pipe(
      tap((assignedUsers: IUser[]) => {
        this.designRequestListStore.update((state) => ({
          ...state,
          assignedUsers: assignedUsers
        }));
      })
    );
  }
}
