import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ArtApproval, DialogData } from '@graphics-flow/types';
import { Translations } from '@graphics-flow/shared/assets';

export interface DeleteApprovalDialogData extends DialogData {
  approval: ArtApproval;
}

@Component({
  selector: 'gf-delete-approval-dialog',
  templateUrl: './delete-approval-dialog.component.html',
  styleUrls: ['./delete-approval-dialog.component.scss']
})
export class DeleteApprovalDialogComponent {
  readonly maxApprovalNameLength = 70;

  constructor(
    public dialogRef: MatDialogRef<DeleteApprovalDialogComponent>,
    public translations: Translations,
    @Inject(MAT_DIALOG_DATA) public data: DeleteApprovalDialogData
  ) {}

  deleteArtApproval() {
    this.dialogRef.close(true);
  }

}
