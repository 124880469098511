<div class="p-x-3 flex-container floating-action-container"
  [ngClass]="(windowService.smallDown$ | async) ? 'sidenav-footer-mobile' : 'sidenav-footer p-y-2'">
  <button id="cancelBtn"
    mat-raised-button
    color="secondary"
    class="action-btn medium flex-1"
    [disabled]="disableBtn"
    (click)="cancelSelection.emit()">
    {{translations.common.cancel | translate}}
  </button>
  <button id="applyFontBtn"
    class="action-btn t-15-600-p medium m-l-1  flex-1"
    mat-raised-button
    color="primary"
    type="submit"
    [disabled]="disableBtn"
    (click)="applySelection.emit()">
    {{translations.common.apply | translate}}
  </button>
</div>
