export const textShapeConstant = {
  outlineSize: {
    min: 0,
    difference: 1
  },
  size: {
    min: 1,
    max: 400,
    difference: 10
  },
  move: {
    difference: 5
  },
  rotate: {
    min: 0,
    max: 360,
    difference: 10
  },
  clipArtSize: {
    min: 1,
    max: 200,
    difference: 10
  }
};
