import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';
import { ArtActionsService } from '../../services/art-actions.service';

@Component({
  selector: 'gf-add-to-approval-msg',
  templateUrl: './add-to-approval-msg.component.html',
  styleUrls: ['./add-to-approval-msg.component.scss']
})
export class AddToApprovalMsgComponent {
  @ViewChild('artAddedToApproval', { static: true }) public artApprovalMsgRef: TemplateRef<any>;
  constructor(
    public artActionsService: ArtActionsService,
    public translations: Translations
  ) { }
}
