<div class="folder-breadcrumbs flex-container align-middle" id="folder-breadcrumbs">
  <ng-container *ngIf="folders$ | async as folders">
    <button *ngIf="showHomeIcon"
              id="breadcrumbRoot"
              mat-button
              (click)="folderClick.emit(null)">
        <mat-icon fontIcon="home"></mat-icon>
    </button>
    <p *ngIf="!showHomeIcon" class="t-18-400-p m-b-0 cursor-pointer outline-none" [class.detele-files]="isDeletedFiles"
      id="breadcrumbRoot" (click)="folderClick.emit(null)">{{(isDeletedFiles ? translations.art.deleted_files : translations.art.my_art) | translate}}</p>
    <ng-container *ngFor="let folder of folders">
      <gf-folder-drop *ngIf="(windowService.largeUp$ | async) && !folder?.trashed; else folderName;" [parentFolderId]="folder.folderId">
        <ng-container [ngTemplateOutlet]="folderName"></ng-container>
      </gf-folder-drop>

      <ng-template #folderName>
        <div class="flex-container align-middle">
          <mat-icon class="hint" fontIcon="chevron_right"></mat-icon>
          <p class="folder-name t-18-400-p dark-primary-text m-b-0 cursor-pointer outline-none" (click)="folderClick.emit(folder.folderId)">{{folder.name}}</p>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
