import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { first } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { Art, ID, StockArt, StockArtDetailSections } from '@graphics-flow/types';
import { ArtQuery } from '../art/art.query';
import { CustomizeStockArtQuery } from '../custom-stock-art/customize-stock-art.query';
import { StockArtQuery } from '../stock-art/stock-art.query';
import { StockArtService } from '../stock-art/stock-art.service';
import { StockArtDetailState, StockArtDetailStore } from './stock-art-detail.store';

@Injectable({ providedIn: 'root' })
export class StockArtDetailQuery {
  activeStockArt$: Observable<StockArt> = this.selectStateProps<ID>((state) => state.activeStockArtId).pipe(
    switchMap((activeStockArtId: ID) => {
      return this.stockArtQuery.selectStockArtEntity(activeStockArtId);
    })
  );
  activeStockArtId$: Observable<ID> = this.selectStateProps<ID>((state) => state.activeStockArtId);
  activeArt$: Observable<Art> = this.activeStockArt$.pipe(
    filter((stockArt: StockArt) => !!stockArt),
    switchMap((stockArt: StockArt) => {
      return this.artQuery.selectArt(first(stockArt.artIdList));
    })
  );
  detailIsDrawerOpen$: Observable<boolean> = this.selectStateProps<boolean>((state) => state.ui.detail.isDrawerOpen);
  detailActiveSection$: Observable<string> = this.selectStateProps<string>((state) => state.ui.detail.activeSection);
  isGraphicsBuilderArt$ = this.activeStockArt$.pipe(
    map((stockArt: StockArt) => !!stockArt.stockArtRecord?.artLibrary)
  );

  // In stock art details page we won't show customize stock art only in Art Approval.
  activeCustomizedArts$: Observable<Art[]> = this.artQuery.customizedArts$.pipe(
    map((customizedArts) => customizedArts.filter(customizedArt => customizedArt?.customizedStockArtId === this.getValue()?.activeStockArtId && !customizedArt?.artApprovalId))
  );

  isCSAEditMode$: Observable<boolean> = combineLatest([this.detailActiveSection$, this.customizeStockArtQuery.isEditMode$]).pipe(
    map(([detailActiveSection, inEditMode]: [string, boolean]) => detailActiveSection === StockArtDetailSections.CUSTOMIZE && inEditMode)
  );

  constructor(
    protected readonly store: StockArtDetailStore,
    protected readonly stockArtQuery: StockArtQuery,
    protected readonly stockArtService: StockArtService,
    protected readonly artQuery: ArtQuery,
    protected readonly customizeStockArtQuery: CustomizeStockArtQuery
  ) {
  }

  selectStateProps<T>(predicate): Observable<T> {
    return this.store.pipe(select(predicate));
  }

  getValue(): StockArtDetailState {
    return this.store.getValue();
  }
}
