import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { upsertEntities } from '@ngneat/elf-entities';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

import { StockArtHttpService } from '@graphics-flow/api';
import { Translations } from '@graphics-flow/shared/assets';
import { ApiError, ApiResponse, Art, ID, NotificationType, StockArt, StockArtPackage, StockArtRequest, StockArtType, UploadArt } from '@graphics-flow/types';
import { NotificationComponent, NotificationService } from 'shared/ui';
import { FileHelpers } from 'shared/util';

import { ArtHelper } from '../../helpers/art.helper';
import { ArtQuery } from '../art/art.query';
import { ArtService } from '../art/art.service';
import { BillingPlansService } from '../billing-plans/billing-plans.service';
import { StockArtListQuery } from '../stock-art-list/stock-art-list-query.service';
import { StockArtQuery } from './stock-art.query';
import { StockArtStore } from './stock-art.store';

@Injectable({ providedIn: 'root' })
export class StockArtService {
  snackBarRef: MatSnackBarRef<NotificationComponent>;
  downloadAllFontSubscription$: Subscription;
  downloadStockArtFontSubscription$: Subscription;

  constructor(
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    private stockArtStore: StockArtStore,
    private stockArtHttpService: StockArtHttpService,
    private readonly artService: ArtService,
    private translateService: TranslateService,
    public readonly translations: Translations,
    private readonly billingPlansService: BillingPlansService,
    private stockArtQuery: StockArtQuery,
    private stockArtListQuery: StockArtListQuery,
    private artQuery: ArtQuery) {
  }

  upsertStockArtEntities(stockArts: StockArt | StockArt[]): void {
    this.stockArtStore.update(upsertEntities(stockArts));
  }

  getStockArtsPackage(request: StockArtRequest): Observable<ApiResponse<StockArtPackage>> {
    return this.stockArtHttpService.getStockArts(request).pipe(
      tap((response: ApiResponse<StockArtPackage>) => {
        this.upsertStockArtEntities(response.data.stockArt);
        this.artService.addArtToStore(response.data.art);
      })
    );
  }

  getStockArtsOnly(request: StockArtRequest): Observable<StockArt[]> {
    return this.getStockArtsPackage(request).pipe(
      map((response: ApiResponse<StockArtPackage>) => {
        return response.data.stockArt;
      })
    );
  }

  add(stockArt: StockArt | StockArt[]) {
    this.upsertStockArtEntities(stockArt);
  }

  downloadStockArt(art: Art, extension: string, useParentId: boolean, isStockArt: boolean = false, showNotification: boolean = true): Observable<{ data; filename: string }> {
    if (isStockArt) {
      this.artService.updateArtInProgress([art.artId], true);
    }
    if (showNotification) {
      this.notificationService.showNotification(NotificationType.INPROGRESS, this.translateService.instant(this.translations.common.downloading), '');
    }
    return this.stockArtHttpService.downloadStockArt(art, extension, useParentId, isStockArt).pipe(
      catchError((err: ApiError) => {
        this.artService.updateArtInProgress([art.artId], false);
        return throwError(err);
      }),
      tap((result) => {
        if (!art.isDownloaded) {
          this.billingPlansService.incrementStockArtDownloads();
        }
        if (art.customizedStockArtId) {
          const originalArt: Art = _cloneDeep(this.artQuery.getArtById(this.stockArtQuery.getStockArtEntity(art.customizedStockArtId)?.artIdList[0]));
          if (originalArt) {
            originalArt.isDownloaded = true;
            this.artService.updateArtInStore(originalArt);
          }
        }
        if (showNotification) {
          this.notificationService.showNotification(NotificationType.SUCCESS, this.translateService.instant(this.translations.common.downloaded), '');
        }
        this.billingPlansService.updateResourceUsageInBackground();
        const downloadedArt: Art = _cloneDeep(art);
        downloadedArt.isDownloaded = true;
        this.artService.updateArtInStore(downloadedArt);
        this.artService.updateArtInProgress([art.artId], false);
        return FileHelpers.downloadFile(result.data, result.filename);
      }));
  }

  downloadFont(art: Art, useParentId?: boolean) {
    this.stockArtHttpService.downloadStockArt(art, ArtHelper.getFontFileExtension(art), useParentId, true).subscribe(
      (result) => {
        this.billingPlansService.updateResourceUsageInBackground();
        return FileHelpers.downloadFile(result.data, art.name);
      }
    );
  }

  downloadAllFonts() {
    if (this.downloadAllFontSubscription$) {
      // already a download in progress.. so let it finish.
      return;
    }

    this.snackBarRef = this.notificationService.showFileNotification(NotificationType.INPROGRESS, 'Downloading', 'GraphicsFlow Fonts');
    const name = `GraphicsFlowFonts_${this.datePipe.transform(new Date(), 'MM-dd-yy')}.zip`;
    this.downloadAllFontSubscription$ = this.stockArtHttpService.downloadAllFonts(name).pipe(
      finalize(() => {
        this.snackBarRef.dismiss();
      })
    ).subscribe((result) => {
      return FileHelpers.downloadFile(result.data, result.filename);
    });

    this.snackBarRef.afterDismissed().subscribe(() => {
      this.downloadAllFontSubscription$?.unsubscribe();
      this.downloadAllFontSubscription$ = null;
    });
  }

  downloadStockArtFont(stockArtId: ID, fileName: string) {
    if (this.downloadStockArtFontSubscription$) {
      return;
    }

    this.snackBarRef = this.notificationService.showFileNotification(NotificationType.INPROGRESS, 'Downloading', fileName);
    this.downloadStockArtFontSubscription$ = this.stockArtHttpService.downloadStockArtFonts(stockArtId, fileName)
    .pipe(
      finalize(() => {
        this.snackBarRef.dismiss();
      })
    )
    .subscribe((result) => {
      return FileHelpers.downloadFile(result.data, result.filename + '.zip');
    });

    this.snackBarRef.afterDismissed().subscribe(() => {
      this.downloadStockArtFontSubscription$?.unsubscribe();
      this.downloadStockArtFontSubscription$ = null;
    });
  }

  getStockArtDetail(stockArtId: ID): Observable<StockArtPackage> {
    return this.stockArtHttpService.getStockArtDetail(stockArtId).pipe(
      tap((response: ApiResponse<StockArtPackage>) => {
        this.upsertStockArtEntities(response.data.stockArt);
        this.artService.addArtToStore(response.data.art);
      }),
      map((response: ApiResponse<StockArtPackage>) => {
        return response.data;
      })
    );
  }

  downloadUploadedArt(art: UploadArt, assignedOrganizationId: ID): Observable<{ data: Blob; filename: string }> {
    this.notificationService.showNotification(NotificationType.INPROGRESS, this.translateService.instant(this.translations.common.downloading), '');

    return this.stockArtHttpService.downloadDesignRequestUploadedArt(art, assignedOrganizationId).pipe(
      catchError((err: ApiError) => {
        this.artService.updateArtInProgress([art.artId], false);
        return throwError(() => err);
      }),
      tap((result) => {
        this.notificationService.showNotification(NotificationType.SUCCESS, this.translateService.instant(this.translations.common.downloaded), '');
        return FileHelpers.downloadFile(result.data, result.filename);
      }));
  }

  hideStockArt(art: Art, isHide: boolean): void {
    const stockArtTypeNum: number = this.stockArtListQuery.getActiveType() === StockArtType.DesignIdea ? 0 : 1;
    this.stockArtHttpService.hideStockArt(art.artId, stockArtTypeNum, isHide).pipe(
      tap(() => {
        const updateHiddenArt: Art = _cloneDeep(art);
        updateHiddenArt.isHidden = isHide;
        this.artService.updateArtInStore(updateHiddenArt);
        this.notificationService.showNotification(NotificationType.SUCCESS, isHide ?
          this.translateService.instant(this.translations.public_stock_art.asset_now_hidden_in_art_portal) :
          this.translateService.instant(this.translations.public_stock_art.asset_now_showing_in_art_portal), '');
      })
    ).subscribe();
  }

  favoriteStockArt(art: Art, stockArtType: StockArtType) {
    this.artService.favoriteArt(art, stockArtType);
  }
}
