import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Translations } from '@graphics-flow/shared/assets';
import { FilesRoutes, Organization, StockArtType } from '@graphics-flow/types';
import { GlobalHelpers, MyArtUploaderService, OrganizationQuery, UserQuery, StockArtListService, StockArtHelper } from '@graphics-flow/util';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WindowService } from 'shared/util';

@Component({
  selector: 'gf-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @ViewChild('feedbackTooltip') feedbackTooltip: MatTooltip;
  @ViewChild('linkCopiedToolTip') linkCopiedToolTip: MatTooltip;
  @Input() showCloseButton = false;
  @Input() isDrawerOpened = false;
  @Output() closeDrawer: EventEmitter<void> = new EventEmitter();
  publicArtLibUrl: string;
  fileRoutes: typeof FilesRoutes = FilesRoutes;
  StockArtType: typeof StockArtType = StockArtType;

  readonly feedbackEmail: string = 'feedback@graphicsflow.com';
  currentYear: number = new Date().getFullYear();
  queueClosedWhileUploadInProgress$: Observable<boolean> = combineLatest([this.myArtUploaderService.isShowPanel$, this.myArtUploaderService.isQueueProcessed$]).pipe(
    map(([isPanelOpen, isQueueCompleted]: [boolean, boolean]) => {
    return (!isPanelOpen && !isQueueCompleted);
  }));

  fileUploadFailMessage: { [k: string]: string } = {
    '=1': this.translations.fileupload.single_item_failed,
    'other': this.translations.fileupload.n_items_failed,
  };

  constructor(
    public readonly translations: Translations,
    public readonly userQuery: UserQuery,
    public readonly windowService: WindowService,
    private readonly translateService: TranslateService,
    public readonly myArtUploaderService: MyArtUploaderService,
    public readonly organizationQuery: OrganizationQuery,
    public readonly stockArtListService: StockArtListService,
    public router: Router
  ) {
  }

  copyToClipboard(): void {
    GlobalHelpers.copyToClipboard(this.feedbackEmail);
    // used to handle multiple tooltip on single element.
    this.feedbackTooltip.show();
    this.feedbackTooltip.message = this.translateService.instant(this.translations.feedback.copied);
    setTimeout(() => {
      this.feedbackTooltip.hide();
      this.feedbackTooltip.message = this.translateService.instant(this.translations.feedback.copy_email);
    }, 1000);
  }

  copyPublicArtLink() {
    const org: Organization = this.organizationQuery.getActive();
    GlobalHelpers.copyToClipboard(GlobalHelpers.buildPublicCatalogURL(org));
    this.linkCopiedToolTip.show();
    setTimeout(() => {
      this.linkCopiedToolTip.hide();
    }, 3000)
  }

  openCatalog() {
    const org: Organization = this.organizationQuery.getActive();
    window.open(GlobalHelpers.buildPublicCatalogURL(org), '_blank').focus();
  }

  goToStockArt(type: StockArtType): void {
    const routeUrlSegment: string[] = this.router.url.split('?');

    // When we try to navigate b/w stockArt routes, while clicking on the same routes in side-nav-bar
    // we need to reset the exsisting filters, this reset should only happen when we are in
    // one of the stockArt routes & has queryParams to it.
    if (routeUrlSegment[0].includes(StockArtHelper.stockArtTypeToRoute(type)) && routeUrlSegment[1]) {
      this.stockArtListService.resetFilters(type);
    }

    // We need to explicitly the close the drawer on medium down screens, because we are trying to click on same active route.
    if (this.windowService['windowSizeSubject'].getValue().mediumDown) {
      this.closeDrawer.emit();
    }
  }
}
