export class FileHelpers {
  public static downloadFile(data, filename) {
    const fileUrl: string = window.URL.createObjectURL(data);
    const anchor: HTMLAnchorElement = document.createElement('a');
    document.body.appendChild(anchor);
    anchor.setAttribute('style', 'display: none');
    anchor.href = fileUrl;
    anchor.download = filename;
    anchor.click();
    window.URL.revokeObjectURL(fileUrl);
    anchor.remove();
  }
}
