import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { FormControl } from '@ngneat/reactive-forms';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ID, StockArtCategory, StockArtType } from '@graphics-flow/types';
import { map, switchMap, tap } from 'rxjs/operators';
import { GlobalHelpers, StockArtCategoriesQuery, StockArtCategoriesService, StockArtListQuery, StockArtListService, StockArtQuery } from '@graphics-flow/util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { uniq, without } from 'lodash-es';
import { WindowService } from 'shared/util';

@UntilDestroy()
@Component({
  selector: 'gf-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.scss']
})
export class CategoryFilterComponent implements OnInit {
  isIOS: boolean = GlobalHelpers.isIOS;
  @ViewChild(CdkConnectedOverlay) overlay: CdkConnectedOverlay;
  searchControl: FormControl<string> = new FormControl<string>('');
  artPortalSubCategoriesIds: ID[];
  isOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchString$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  searching$: Observable<boolean> = this.searchString$.pipe(map(string => !!string?.length));
  selectedTopLevelCategoryId$: BehaviorSubject<ID> = new BehaviorSubject<ID>(null);
  selectedTopLevelCategory$: Observable<StockArtCategory> = this.selectedTopLevelCategoryId$.pipe(
    switchMap(id => this.stockArtCategoriesQuery.selectCategoryEntity(id))
  );
  topLevelCategories$: Observable<StockArtCategory[]> = combineLatest([
    this.searchString$,
    this.stockArtListQuery.activeStockArtType$,
    this.stockArtListQuery.artPortalCategoriesIds$,
  ]).pipe(
    switchMap(([search, artType, categoriesIds]: [string, StockArtType, ID[]]) => {
      return this.stockArtCategoriesQuery.searchCategories(search, artType, categoriesIds);
    })
  )
  secondLevelCategories$: Observable<StockArtCategory[]> = combineLatest([
    this.selectedTopLevelCategoryId$,
    this.searchString$,
    this.stockArtListQuery.artPortalSubCategoriesIds$,
  ]).pipe(
    switchMap(([parentId, searchString, subCategoriesIds]: [ID, string, ID[]]) => {
      this.artPortalSubCategoriesIds = subCategoriesIds;
      return this.stockArtCategoriesQuery.searchSubCategories(parentId, searchString, subCategoriesIds);
    })
  );

  // Top level categories which are selected, or have selected subcategories
  categoriesWithSelection$: Observable<ID[]> = combineLatest([
    this.stockArtListQuery.selectedCategories$,
    this.stockArtListQuery.selectedSubCategories$
  ]).pipe(
    map(([cats, subCats]: [StockArtCategory[], StockArtCategory[]]) => {
      return uniq([
        ...cats.map(cat => cat.folderId),
        ...subCats.map(cat => cat.parentId)
      ]);
    })
  );
  categoriesWithSelection: Set<ID>;

  // Whether or not the currently selected top-level category checkbox should appear as indeterminate
  indeterminate$: Observable<boolean> = combineLatest([
    this.selectedTopLevelCategory$,
    this.stockArtListQuery.selectedSubCategories$
  ]).pipe(
    map(([parentCat, selectedSubCats]: [StockArtCategory, StockArtCategory[]]) => {
      const isCatSelected: boolean = this.selectedCategoryIds.includes(parentCat?.folderId);
      const hasSelectedSubCats = !!selectedSubCats.find(cat => cat.parentId === parentCat?.folderId);
      return !isCatSelected && hasSelectedSubCats;
    })
  )
  selectedCategoryIds: ID[];
  selectedSubCategoryIds: ID[];
  @Input() canShowClipArtMobileViewFilter = false;

  constructor(
    public readonly stockArtQuery: StockArtQuery,
    public readonly stockArtCategoriesQuery: StockArtCategoriesQuery,
    public readonly stockArtCategoriesService: StockArtCategoriesService,
    public readonly stockArtListQuery: StockArtListQuery,
    public readonly stockArtListService: StockArtListService,
    public readonly translations: Translations,
    public readonly windowService: WindowService
  ) {
    this.stockArtListQuery.selectedCategories$.pipe(
      untilDestroyed(this)
    ).subscribe((selectedCategories: StockArtCategory[]) => {
      this.selectedCategoryIds = selectedCategories?.map(cat => cat.folderId) || [];
    });

    this.stockArtListQuery.selectedSubCategories$.pipe(
      untilDestroyed(this)
    ).subscribe((selectedSubCategories: StockArtCategory[]) => {
      this.selectedSubCategoryIds = selectedSubCategories?.map(cat => cat.folderId) || [];
    });

    this.categoriesWithSelection$.pipe(untilDestroyed(this)).subscribe((ids: ID[]) => {
      this.categoriesWithSelection = new Set(ids);
    });
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      untilDestroyed(this),
      tap((searchString: string) => {
        // Clear the selection if they start searching
        // But not if they clicked the "View all Sub-categories" button to clear out the searchString
        if (searchString) {
          this.selectedTopLevelCategoryId$.next(null);
        }
        this.searchString$.next(searchString);
      })
    ).subscribe()
  }

  open() {
    this.isOpen$.next(true);
  }

  // On Clicking backdrop detach overlay
  close() {
    this.overlay.overlayRef.detach();
  }

  toggle() {
    if (this.isOpen$.getValue()) {
      this.close();
    } else {
      this.open();
    }
  }

  // Resetting overlay on clicking backdrop or pressing esc key
  detach() {
    this.isOpen$.next(false);
    this.reset();
  }

  reset() {
    this.selectedTopLevelCategoryId$.next(null);
    this.searchString$.next('');
    this.searchControl.setValue('');
  }

  updateSelectedCategories(parentCategory: StockArtCategory, subCategories: StockArtCategory[], selected: boolean) {
    if (selected) {
      this.stockArtListService.selectCategories([parentCategory]);
    } else {
      this.stockArtListService.deselectCategories([parentCategory]);
    }

    // When we make a change to the parent category's selectedness, deselect all sub categories
    this.stockArtListService.deselectSubCategories(subCategories, false);
  }

  updateSelectedSubCategories(parentCategory: StockArtCategory, subCategoryToToggle: StockArtCategory,
    selected: boolean) {
    // In the unique case where the top level category is selected (and thus, all subcategories appear selected - even
    // though they aren't technically in the selected list), we want to deselect the top level category, and select
    // all subCategories of it except for this one
    const allSubCategories = this.stockArtCategoriesQuery.getSubCategoryByParentId(parentCategory.folderId, this.artPortalSubCategoriesIds);
    if (this.selectedCategoryIds.includes(parentCategory.folderId)) {
      this.stockArtListService.deselectCategories([parentCategory]);
      this.stockArtListService.selectSubCategories(without(allSubCategories, subCategoryToToggle));
      return;
    }

    // Common Path
    if (selected) {
      this.stockArtListService.selectSubCategories([subCategoryToToggle]);
    } else {
      this.stockArtListService.deselectSubCategories([subCategoryToToggle]);
    }
    // In the unique case where all the subCategories is selected (selected through subCategories checkbox), we want to select the top level category
    // and deselect all subCategories.
    const filteredCategories = this.selectedSubCategoryIds.filter((subCategory) => allSubCategories.find((cat) => cat.folderId === subCategory));
    if ((filteredCategories.length === allSubCategories.length) && selected) {
      this.stockArtListService.deselectSubCategories(allSubCategories);
      this.stockArtListService.selectCategories([parentCategory]);
    }
  }
}
