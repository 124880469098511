import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountSubscriptionHelper } from './../helpers/account-subscription.helper';

import { IRoles, User } from '@graphics-flow/types';
import { UserQuery } from '../data/user/user.query';
import { UserHelper } from '../helpers/user.helper';

@Injectable({
  providedIn: 'root'
})
export class PaymentSetupGuard {

  constructor(
    private readonly userQuery: UserQuery,
    private readonly router: Router
  ) { }

  canActivate(state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkPaymentSetup();
  }

  canActivateChild(state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkPaymentSetup();
  }

  // note:- no need to fix this warning
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private checkPaymentSetup(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userQuery.user$.pipe(
      map((user: User) => {
        const role: IRoles = UserHelper.getUserRole(user);

        if (AccountSubscriptionHelper.isSubscriptionActive(user.subscriptionStatus)) {
          return true;
        }

        if ([IRoles.User, IRoles.Support].includes(role) || AccountSubscriptionHelper.isSubscriptionNeedPaymentSetup(user.subscriptionStatus)) {
          this.router.navigateByUrl('/payment-setup');
          return false;
        }

        this.router.navigateByUrl('/account-reactivation');
        return false;
      })
    );
  }
}
