import { Pipe, PipeTransform } from '@angular/core';
import { ArtHelper } from '@graphics-flow/util';

@Pipe({
  name: 'getBase64Preview'
})
export class GetBase64PreviewsPipe implements PipeTransform {

  transform(url: string): string {
    return ArtHelper.buildCustomizeStockArtPngPreview(url);
  }

}
