import { Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IAssociatedOrganization, IRoles, Organization, User } from '@graphics-flow/types';

import { OrganizationQuery } from './../data/organization/organization.query';
import { UserQuery } from './../data/user/user.query';
import { defaultHomePage } from '../constants/default-route.constants';

@Injectable({
  providedIn: 'root'
})
export class AdminOnlyRouteGuard {

  constructor(
    private origanizationQuery: OrganizationQuery,
    private readonly userQuery: UserQuery,
    private readonly router: Router
  ) {
  }

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.isAllowed();
  }
  canActivateChild(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.isAllowed();
  }

  isAllowed() {
    const organization: Organization = this.origanizationQuery.getActive();
    const user: User = this.userQuery.getActiveUserEntity();
    const userAssociatedOrg: IAssociatedOrganization = user.associatedOrganizations.find((associateOrganization: IAssociatedOrganization) => associateOrganization.organizationId === organization.organizationId);
    if (userAssociatedOrg?.role === IRoles.Admin || userAssociatedOrg?.role === IRoles.Owner) {
      return true;
    } else {
      return this.router.navigate([defaultHomePage]);
    }
  }

}
