<div id="helpResourcesDialog" class="help-resources-dialog">
  <mat-dialog-content>
    <p class="title m-b-1">{{ translations.common.training_videos | translate }}</p>
    <div class="flex-container" [class.flex-dir-column]="(windowService.smallDown$ | async)">
      <div class="description t-16-400-s flex-child-auto" [class.m-r-4]="(windowService.mediumUp$ | async)">
        {{ translations.common.training_videos_desc | translate }}
      </div>
      <a id="allTrainingVideoBtn" mat-raised-button class="flex-basis-auto medium" color="primary"
        href="https://www.graphicsflow.com/training/" target="_blank">
        {{ translations.common.all_training_videos | translate }}
      </a>
    </div>
    <mat-divider class="m-y-4"></mat-divider>
    <p class="title m-b-1">{{ translations.common.product_updates | translate }}</p>
    <div class="flex-container" [class.flex-dir-column]="(windowService.smallDown$ | async)">
      <div class="description t-16-400-s flex-child-auto" [class.m-r-4]="(windowService.mediumUp$ | async)">
        {{ translations.common.product_updates_desc | translate }}
      </div>
      <a id="productUpdatesBtn" mat-raised-button class="flex-basis-auto medium" color="primary"
        href="https://www.graphicsflow.com/category/product-updates/" target="_blank">
        {{ translations.common.product_updates | translate }}
      </a>
    </div>
    <mat-divider class="m-y-4"></mat-divider>
    <p class="title m-b-1">{{ translations.common.get_help | translate }}</p>
    <div class="flex-container" [class.flex-dir-column]="(windowService.smallDown$ | async)">
      <div class="description t-16-400-s flex-child-auto" [class.m-r-4]="(windowService.mediumUp$ | async)">
        {{ translations.common.get_help_desc | translate }}
      </div>
      <a id="submitRequestBtn" mat-raised-button class="flex-basis-auto medium" color="primary"
        href="https://graphicsflow.zendesk.com/hc/en-us/requests/new" target="_blank">
        {{ translations.common.submit_a_request | translate }}
      </a>
    </div>
    <mat-divider class="m-y-4"></mat-divider>
    <mat-dialog-actions>
      <a id="feedbackBtn" mat-raised-button class="notification-btn medium elevate m-l-1"
        href="https://www.graphicsflow.com/graphicsflow-feedback/" target="_blank">
        <ng-container>
          <mat-icon class="material-icons-outlined m-r-1">mail</mat-icon>
          <span>{{ translations.feedback.give_feedback | translate }}</span>
        </ng-container>
      </a>
      <button id="cancelBtn" color="secondary" mat-dialog-close mat-raised-button class="medium">
        {{ translations.common.cancel | translate }}
      </button>
    </mat-dialog-actions>
  </mat-dialog-content>
</div>
