<div id="artRenameDialog">
  <div mat-dialog-title>
    <div class="flex-child-auto">{{translations.art.rename_art | translate}}</div>
    <mat-icon class="mat-icon-close cursor-pointer" (click)="close()" fontIcon="close"></mat-icon>
  </div>
  <mat-dialog-content>
    <form #artForm="ngForm" name="artForm" autocomplete="off">
      <div class="flex-container">
        <mat-form-field class="normal-fill flex-child-auto" appearance="fill" hideRequiredMarker>
          <mat-label>{{translations.art.art_name | translate}}</mat-label>
          <input id="artInput" #artInput="ngModel" matInput name="artInput" [(ngModel)]="name" maxlength="1024" required trim
            (keyup.enter)="submit()">
          <mat-error *ngIf="artInput.invalid">{{translations.art.art_name_required | translate}}</mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="align-right">
    <button id="cancelBtn"
      mat-raised-button
      mat-dialog-close
      color="secondary"
      class="large">
     {{translations.common.cancel | translate}}
    </button>

    <button id="submitBtn"
      mat-raised-button
      color="primary"
      class="large"
      [disabled]="artForm.invalid"
      (click)="submit()">
      {{translations.common.rename | translate}}
    </button>
  </mat-dialog-actions>
</div>
