import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { compact as _compact } from 'lodash-es';

import { ApiResponse, BulkActionPostData } from '@graphics-flow/types';
import { ApiService } from '../api.service';
import { EnvService } from '../env.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvService,
    private apiService: ApiService
  ) {
  }

  delete(url: string): Observable<ApiResponse<any>> {
    return this.http.delete(url).pipe(
      map((response: ApiResponse<any>) => {
        return response;
      })
    );
  }

  get(url: string): Observable<ApiResponse<any>> {
    return this.http.get(url).pipe(
      map((response: ApiResponse<any>) => {
        return response;
      })
    );
  }

  post(url: string, body?, options?): Observable<ApiResponse<any>> {
    return this.http.post(url, body, options).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  put(url: string, body, options?): Observable<ApiResponse<any>> {
    return this.http.put(url, body, options).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public getUrl(api: string, withOrgUri: boolean = true, orgUri: string = null): string {
    return _compact([ // filter out falsey values (eg. if we don't have organizationUri yet)
      this.envService.apiEndpoint,
      withOrgUri ? this.apiService.getOrgUri() || orgUri : null,
      api
    ]).join('/');
  }

  public downloadFile(url: string, filename: string): Observable<{ data, filename: string }> {
    return this.http.get(url, { responseType: 'arraybuffer' }).pipe(
      filter(data => !!data),
      map(data => {
        return {
          data: new Blob([data]),
          filename
        }
      })
    );
  }

  public downloadFileViaPost(url: string, formData: BulkActionPostData, filename: string): Observable<{ data: Blob, filename: string }> {
    return this.http.post(url, formData, { responseType: 'arraybuffer' }).pipe(
      filter(data => !!data),
      map(data => ({
        data: new Blob([data]),
        filename
      }))
    );
  }

  getImage(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob'});
  }
}
