import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Translations } from '@graphics-flow/shared/assets';
import { Art, Folder, ID, MyArtActiveQueryParams, Order, TableFile } from '@graphics-flow/types';
import { ArtHelper, FolderQuery, FolderService, MyArtSearchQuery, MyArtSearchService } from '@graphics-flow/util';
import { FormControl } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';
import { WindowService } from 'shared/util';
import { ArtListComponent } from '../art-list/art-list.component';

export interface SelectArtDialogData {
  parentFolderId: ID;
  selectMultiple: boolean;
  checkFileFormat?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'gf-select-art-dialog',
  templateUrl: './select-art-dialog.component.html',
  styleUrls: ['./select-art-dialog.component.scss']
})
export class SelectArtDialogComponent implements OnDestroy {
  @ViewChild('artList', { static: false }) artList: ArtListComponent;
  searchControl: FormControl<string> = new FormControl<string>('');
  Order = Order;
  myArtLength = 0;
  selectMultiple = true;
  checkFileFormat = false;

  // Subject for the currently selected folderId
  currentFolderId$: BehaviorSubject<ID> = new BehaviorSubject<ID>(null);

  selectedArtIds$: BehaviorSubject<ID[]> = new BehaviorSubject<ID[]>([]);

  // The folders (starting with My Art) to display as breadcrumbs
  breadcrumbFolders$: Observable<Folder[]> = this.currentFolderId$.pipe(
    untilDestroyed(this),
    switchMap((folderId: ID) => {
      return this.folderQuery.selectFolderHierarchy(folderId);
    })
  );

  foldersAndArts$: Observable<[Folder[], Art[]]> = this.myArtSearchQuery.activeArtsAndFolders$;

  dataSource$: Observable<TableFile[]> = this.myArtSearchQuery.activeArtsAndFolders$.pipe(
    untilDestroyed(this),
    map(([folders, arts]) => {
      this.myArtLength = arts.length;
      return ArtHelper.getTableFiles(folders, arts);
    })
  );

  // Current folder details
  currentFolder$: Observable<Folder> = this.currentFolderId$.pipe(
    map((folderId: ID) => {
      return this.folderQuery.getFolderById(folderId);
    })
  );

  uploadFileMessageMap: { [k: string]: string } = {
    '=1': this.translations.art.add_file,
    'other': this.translations.art.add_files,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SelectArtDialogData,
    private readonly folderQuery: FolderQuery,
    private readonly dialogRef: MatDialogRef<SelectArtDialogComponent>,
    public translations: Translations,
    public readonly windowService: WindowService,
    public readonly myArtSearchService: MyArtSearchService,
    public readonly myArtSearchQuery: MyArtSearchQuery,
    private readonly folderService: FolderService
  ) {
    this.selectFolder(data.parentFolderId);
    this.selectMultiple = data.selectMultiple;
    this.checkFileFormat = data.checkFileFormat;
    this.myArtSearchService.setDefaultMyArtQueryParams();
    this.myArtSearchService.initialize();
    this.searchControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(500),
      untilDestroyed(this),
      tap((value) => {
        if (!value) {
          return this.myArtSearchService.updateFolderId(this.folderQuery.getActiveFolderEntityId());
        }
        this.myArtSearchService.searchMyArt(value);
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.myArtSearchService.destroyed$.next(null);
    this.myArtSearchService.reset();
  }

  // Gets called when the user selects a folder from the Art List
  selectFolder(folderId: ID) {
    this.folderService.setFolderActive(folderId);
    this.myArtSearchService.updateFolderId(folderId);
    this.resetSearch();
    this.currentFolderId$.next(folderId);
  }

  resetSearch() {
    this.searchControl.setValue('');
  }

  confirm() {
    this.selectedArtIds$.pipe(take(1)).subscribe((artIds: ID[]) => {
      this.dialogRef.close(artIds);
    });
  }

  navigateToParentFolder(): void {
    this.currentFolder$.pipe(
      take(1)
    ).subscribe((folder: Folder) => {
      const parentFolderId: ID = folder?.parentId || null;
      this.selectFolder(parentFolderId);
    });
  }

  sortArtList(active: string, direction: Order): void {
    this.myArtSearchService.setMyArtActiveQueryParams(
      <MyArtActiveQueryParams>{
        sortBy: <string>active,
        orderDesc: direction === Order.DESC,
        index: 0
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

  selectArts(artIds: ID[]): void {
    this.selectedArtIds$.next(artIds);
  }

  scrolledToBottom(index: number, tableLength: number): void {
    if ((index < 0) || (index !== tableLength - 1)) return;
    this.myArtSearchService.setIndex(this.myArtLength);
  }
}
