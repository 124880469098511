import { finalize } from 'rxjs/operators';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Validators } from '@angular/forms';

import { GlobalHelpers, UserService, FormHelper, UserQuery } from '@graphics-flow/util';
import { Translations } from '@graphics-flow/shared/assets';
import { User, IRoles, InviteTeam } from '@graphics-flow/types';
import { BillingPlansQuery } from '@graphics-flow/util';

@Component({
  selector: 'gf-add-teammate-dialog',
  templateUrl: './add-teammate-dialog.component.html',
  styleUrls: ['./add-teammate-dialog.component.scss']
})
export class AddTeammateDialogComponent {

  userRoles: typeof IRoles = IRoles;
  teamMateForm: FormGroup<ControlsOf<InviteTeam>> = new FormGroup<ControlsOf<InviteTeam>>({
    email: new FormControl(null, {
      validators: [
        Validators.required,
        Validators.pattern(GlobalHelpers.EMAIL_REGEXP),
      ],
      asyncValidators: [
        FormHelper.userNameTakenValidation(this.userService)
      ]
    }),
    role: new FormControl(null, Validators.required)
  });
  inProgress = false;

  constructor(
    private readonly dialogRef: MatDialogRef<AddTeammateDialogComponent>,
    private readonly userService: UserService,
    public readonly billingPlansQuery: BillingPlansQuery,
    public readonly translations: Translations,
    public userQuery: UserQuery
  ) { }

  inviteTeamMate(teamMate: InviteTeam) {
    this.inProgress = true;
    this.userService.inviteTeamMemeber([teamMate]).pipe(
      finalize(() => this.inProgress = false)
    ).subscribe((res: User) => {
      this.dialogRef.close(res);
    }, () => {
      this.dialogRef.close();
    })
  }

  close() {
    this.dialogRef.close();
  }

}
