import { Injectable } from '@angular/core';
import { identity as _identity, isUndefined as _isUndefined, pickBy as _pickBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ApiResponse,
  Art,
  ID,
  ListArtReferences,
  MyArtActiveQueryParams,
  Organization,
  User,
  Watermark,
  WebJob
} from '@graphics-flow/types';
import { FileHelpers } from 'shared/util';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ArtHttpService {

  constructor(private httpService: HttpService) {}

  create(file: File, parentFolderId: ID): Observable<Art> {
    const newArt = _pickBy(<Art>{
      name: file.name.substr(0, file.name.lastIndexOf('.')), // remove ext from file name
      folderId: parentFolderId || null
    }, _identity);

    const params: FormData = new FormData();
    params.append('file', file, file.name);
    params.append('artJson', JSON.stringify(newArt));

    return this.httpService.post(this.getUrl('UploadArt'), params).pipe(
      map((response: ApiResponse<Art>) => response.data)
    );
  }

  getArts(params: MyArtActiveQueryParams, globalFilter?: boolean): Observable<ApiResponse<Art[]>> {
    const artParams: MyArtActiveQueryParams = <MyArtActiveQueryParams>{
      index: params.index ?? 0,
      limit: params.limit,
      sortBy: params.sortBy,
      orderDesc: params.orderDesc,
      isGlobalFilter: !!params.globalSearchText || globalFilter,
      searchText: params.globalSearchText || params?.filterSearchText,
      tags: params.filterTags?.map(tag => tag.canonicalName) ?? [],
      trashed: params?.trashed
    };
    if (!_isUndefined(params?.folderId)) {
      artParams.folderId = params?.folderId;
    }
    if (params?.artType) {
      artParams.artType = params?.artType;
    }
    return this.httpService.post(this.getUrl(`GetArt`), artParams).pipe(
      map((response: ApiResponse<Art[]>) => {
        return response;
      })
    );
  }

  getArt(artId: ID): Observable<Art> {
    return this.httpService.get(this.getUrl(`GetArt/${artId}`)).pipe(
      map((response: ApiResponse<Art>) => response.data)
    );
  }

  update(art: Art): Observable<Art> {
    return this.httpService.post(this.getUrl('UpdateArt'), art).pipe(
      map((response: ApiResponse<Art>) => response.data)
    );
  }

  getApprovalsByArtId(artId: ID): Observable<ListArtReferences> {
    const path: string = 'ListArtReferences\/' + artId?.toString();
    return this.httpService.get(this.getUrl(path)).pipe(
      map((response: ApiResponse<ListArtReferences>) => response.data)
    );
  }

  downloadArt(path: string, name) {
    this.httpService.downloadFile(path, name).subscribe((res) => {
      FileHelpers.downloadFile(res.data, res.filename);
    });
  }

  downloadFile(art: Art, extension: string): Observable<any> {
    const params: string = `?organizationId=${art.assignedOrganizationId}`
      + `&artId=${art.artId}`
      + `&fileExtension=${extension}`
      + `&isStockArt=false`;
    return this.httpService.downloadFile(this.getUrl('DownloadFile') + params, `${art.name}.${extension}`);
  }

  uploadProfilePicture(file: File, isOrganizationProfilePic: boolean = false): Observable<Art> {
    const params: FormData = new FormData();

    params.append('file', file);
    params.append('isOrganizationProfilePic', `${isOrganizationProfilePic}`);

    return this.httpService.post(this.getUrl('UploadProfilePicture'), params).pipe(
      map((response: ApiResponse<Art>) => response.data)
    );
  }

  deleteProfilePicture(): Observable<User> {
    return this.httpService.post(this.getUrl('DeleteProfilePicture')).pipe(
      map((response: ApiResponse<User>) => response.data)
    );
  }

  deleteOrganizatonProfilePicture(): Observable<Organization> {
    return this.httpService.post(this.getUrl('DeleteOrganizationProfilePicture')).pipe(
      map((response: ApiResponse<any>) => response.data)
    );
  }

  getWatermarkPreview(watermark: Watermark): Observable<Blob> {
    return this.httpService.getImage(this.getUrl(`GetWaterMarkPreview?watermarkString=${JSON.stringify(watermark)}&useDefaultLogo=true`));
  }

  getCustomizedStockArts(artId: ID): Observable<Art[]> {
    const params: FormData = new FormData();
    params.append('stockArtId', artId?.toString());
    return this.httpService.post(this.getUrl(`GetCustomizedStockArts`), params).pipe(
      map((response: ApiResponse<Art[]>) => response.data)
    );
  }

  permanentlyDeleteArt(artId: ID): Observable<ID> {
    return this.httpService.post(`${this.getUrl('DeleteArt')}?artId=${artId?.toString()}`).pipe(
      map((response) => response.data)
    );
  }

  recoverFile(artId: ID, folderId: ID, parentFolderId?: ID): Observable<ID> {
    let query = '';

    if (artId) {
      query += `?artId=${artId?.toString()}`;
    } else if (folderId) {
      query += `?folderId=${folderId?.toString()}`;
    }

    if (parentFolderId) {
      query += `&targetFolderId=${parentFolderId?.toString()}`;
    }

    return this.httpService.post(`${this.getUrl('RestoreArtAndFolder')}${query}`).pipe(
      map(response => response.data)
    );
  }

  private getUrl(api: string): string {
    return this.httpService.getUrl(`art/${api}`);
  }

  deleteAllArtsAndFolders(): Observable<WebJob> {
    return this.httpService.post(this.getUrl('DeleteAllArtsAndFolders')).pipe(
      map((response: ApiResponse<WebJob>) => response.data)
    );
  }

  getJobStatus(): Observable<WebJob> {
    return this.httpService.post(this.getUrl('GetJobStatus')).pipe(
      map((response: ApiResponse<WebJob>) => response.data)
    );
  }

  getStockArtKeywords(params): Observable<string[]> {
    return this.httpService.post(this.getUrl('GetStockArtKeywords'), params).pipe(
      map((response: ApiResponse<string[]>) => response.data)
    );
  }

  addToArtPortal(params: { artIdList: ID[]; show: boolean }): Observable<number> {
    return this.httpService.post(this.getUrl('ShowMyArtInArtPortal'), params).pipe(
      map((response: ApiResponse<number>) => response.data)
    )
  }

  favoriteArt(artId: ID, artType: string, favorite: boolean): Observable<ApiResponse<boolean | Art>> {
    const params = new FormData();
    params.append('artId', artId.toString());
    params.append('artType', artType);
    params.append('favorite', String(favorite));
    return this.httpService.post(this.getUrl('ManageFavoriteArt'), params);
  }
}
