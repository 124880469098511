import { Component } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';
import { StockArtService } from '@graphics-flow/util';

@Component({
  selector: 'gf-download-all-fonts',
  templateUrl: './download-all-fonts.component.html',
  styleUrls: []
})
export class DownloadAllFontsComponent {

  constructor(
    public readonly stockArtService: StockArtService,
    public readonly translations: Translations
  ) { }

}
