import { Injectable } from '@angular/core';
import { setProp } from '@ngneat/elf';
import { TranslateService } from '@ngx-translate/core';
import { isEqual as _isEqual } from 'lodash-es';
import { Subject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { ArtApprovalHttpService } from '@graphics-flow/api';
import { Translations } from '@graphics-flow/shared/assets';
import { ApiResponse, ArtApproval, ArtApprovalRequest, NotificationType, Pagination } from '@graphics-flow/types';
import { NotificationService } from 'shared/ui';

import { ART_APPROVAL_BASE_LIMIT, MAX_SCROLL_LIMIT } from '../../constants/infinity-scroller.constants';
import { GlobalHelpers } from '../../helpers/global.helpers';
import { ApprovalQuery } from '../approval/approval.query';
import { ApprovalService } from '../approval/approval.service';
import { ArtApprovalSearchQuery } from './art-approval-search.query';
import { ArtApprovalSearchStore } from './art-approval-search.store';

@Injectable({ providedIn: 'root' })
export class ArtApprovalSearchService {
  destroyed$ = new Subject();
  constructor(
    private readonly artApprovalSearchQuery: ArtApprovalSearchQuery,
    private readonly artApprovalSearchStore: ArtApprovalSearchStore,
    private readonly approvalHttpService: ArtApprovalHttpService,
    private readonly approvalService: ApprovalService,
    private readonly approvalQuery: ApprovalQuery,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    public readonly translations: Translations) {
  }

  updateSearchFocusStatus(focusStatus: boolean): void {
    this.artApprovalSearchStore.updateSearchFocusStatus(focusStatus);
  }

  reset(): void {
    this.artApprovalSearchStore.reset();
  }

  searchArtApproval(searchText?: string): void {
    const artApprovalRequest: ArtApprovalRequest = {
      index: 0,
      limit: ART_APPROVAL_BASE_LIMIT
    };
    if (searchText) {
      artApprovalRequest.searchText = searchText;
    }
    this.setActiveFilters(artApprovalRequest);
  }

  initialize(): void {
    this.artApprovalSearchStore.updateLoading(true);
    this.artApprovalSearchQuery.activeFilters$.pipe(
      debounceTime(200), // Filters get changed a lot, we don't necessarily want to make a call instantly every time
      distinctUntilChanged((oldFilter, newFilter) =>  {
        oldFilter = JSON.parse(JSON.stringify(oldFilter));
        newFilter = JSON.parse(JSON.stringify(newFilter));
        const isEqual = _isEqual(oldFilter, newFilter);
        if (isEqual) {
         this.artApprovalSearchStore.updateLoadingResult(false);
        }
        return isEqual;
      }),
      switchMap((activeFilters: ArtApprovalRequest) => {
        this.artApprovalSearchStore.update(state => ({
          ...state,
          loading: activeFilters.index === 0,
          loadingResults: activeFilters.index !== 0,
        }));
        return this.approvalHttpService.getArtApprovalSummary(activeFilters);
      }),
      takeUntil(this.destroyed$)
    ).subscribe((response: ApiResponse<ArtApproval[]>) => {
      this.artApprovalSearchStore.setPagination(response.pagination);
      if (response.pagination.index === 0) {
        this.approvalService.addApprovals(response.data);
        this.artApprovalSearchStore.updateLoading(false);
      } else {
        this.approvalService.upsertApprovals(response.data);
        this.artApprovalSearchStore.updateLoadingResult(false);
      }
    });
  }

  getBatch(approvalLength: number): void {
    this.setActiveFilters({
      index: approvalLength,
      limit: GlobalHelpers.getInfiniteScrollValidLimit(approvalLength, ART_APPROVAL_BASE_LIMIT)
    });
    this.artApprovalSearchStore.updateLoadingResult(true);
  }

  setActiveFilters(activeFilters: ArtApprovalRequest): void {
    if (activeFilters.index === MAX_SCROLL_LIMIT) {
      this.notificationService.showNotification(NotificationType.WARNING, this.translateService.instant(this.translations.common.warning) ,this.translateService.instant(this.translations.common.infinite_scroll_max_limit_warning_msg));
      this.artApprovalSearchStore.updateLoadingResult(false);
      return;
    }
    this.artApprovalSearchStore.setActiveFilters(activeFilters);
  }

  reduceTotalResultsCountByOne(): void {
    this.artApprovalSearchStore.update(
      setProp('filteredArtApprovalsPagination', (pagination: Pagination) => ({
        ...pagination,
        totalResults: pagination.totalResults - 1
      })
    ));
    combineLatest([this.approvalQuery.approvals$, this.artApprovalSearchQuery.totalResults$]).pipe(
      take(1),
      tap(([artApprovals, totalCount]: [ArtApproval[], number]) => {
        const totalArtApprovals: number = artApprovals?.length;
        if (totalArtApprovals < ART_APPROVAL_BASE_LIMIT && totalArtApprovals < totalCount) {
          this.getBatch(totalArtApprovals);
        }
      })
    ).subscribe();
  }

  clearSubscriptions(): void {
    this.destroyed$.next(null);
  }
}
