import { Pipe, PipeTransform } from '@angular/core';
import { OrganizationResourceUsage } from '@graphics-flow/types';
import { AccountSubscriptionHelper } from '@graphics-flow/util';

@Pipe({
  name: 'isStockArtPlanDowngraded'
})

export class IsStockArtPlanDowngradedPipe implements PipeTransform {

  transform(resourceUsage: OrganizationResourceUsage): boolean {
    if ( !resourceUsage ) {
      return false;
    }
    return AccountSubscriptionHelper.IsStockArtPlanDowngraded(resourceUsage);
  }
}
