import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserQuery } from '../data/user/user.query';

@Injectable({
  providedIn: 'root'
})
export class SignedInGuard {

  constructor(
    private readonly userQuery: UserQuery,
    private readonly router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;
    return this.checkLoggedIn(url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }

  private checkLoggedIn(url: string): Observable<boolean> {
    return this.userQuery.isSignedIn$.pipe(
      tap((isSignedIn: boolean) => {
        if (!isSignedIn) {
          this.router.navigate(['/signin'], {
            queryParams: {
              returnUrl: url
            }
          });
        }
      })
    );
  }
}
