import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Folder, ID } from '@graphics-flow/types';
import { FolderQuery } from '@graphics-flow/util';

@Pipe({
  name: 'folder'
})
export class FolderPipe implements PipeTransform {
  constructor(private readonly folderQuery: FolderQuery) {

  }

  transform(folderId: ID): Observable<Folder> {
    return this.folderQuery.selectFolderEntity(folderId);
  }

}
