
import { Pipe, PipeTransform } from '@angular/core';
import { ArtApprovalComment } from '@graphics-flow/types';

@Pipe({
  name: 'getComments'
})
export class GetCommentsPipe implements PipeTransform {

  transform(comments: ArtApprovalComment[]): ArtApprovalComment[] {
    return comments.filter((comment: ArtApprovalComment) => !comment.parentCommentId);
  }

}
