export const noImagePreviewUrl = {
  csv: '/assets/images/csv-preview.svg',
  docx: '/assets/images/docx-preview.svg',
  draw: '/assets/images/draw-preview.svg',
  emb: '/assets/images/emb-preview.svg',
  exp: '/assets/images/exp-preview.svg',
  pes: '/assets/images/pes-preview.svg',
  xls: '/assets/images/xls-preview.svg',
  zip: '/assets/images/zip-preview.svg'
};
