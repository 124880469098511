import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { GlobalHelpers } from '@graphics-flow/util';

@Component({
  selector: 'gf-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit {
  @ViewChild('fileInput', {static: false})private fileInput: ElementRef;
  @Output() files: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Input() isMutipleFile = false;
  @Input() isArtApproval = false;
  SUPPORTED_FILE_FORMATS = GlobalHelpers.SUPPORTED_FILE_EXTENSIONS.toString();

  ngOnInit(): void {
    this.SUPPORTED_FILE_FORMATS = this.isArtApproval ? GlobalHelpers.SUPPORTED_FILE_EXTENSIONS.toString() : GlobalHelpers.SUPPORTED_MY_ART_FILE_EXTENSIONS.toString();
  }

  open() {
    this.fileInput?.nativeElement.click();
  }

  uploadFiles([...files]: File[]): void {
    this.files.emit(files);
    this.reset();
  }

  reset() {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

}
