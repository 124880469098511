import { DesignRequestForm } from './DesignRequestForm';
import { ID } from './ID';

export interface CatalogSettings {
  enabled: boolean;
  designIdeas: boolean;
  clipArt: boolean;
  fonts: boolean;
  myArt: boolean;
  displayNeedHelps: boolean;
  displayContactUs: boolean;
  headerText: string;
  headerDescription: string;
  logoSize: number;
  buttonColor: string;
  headerTextColor: string;
  headerBackgroundColor: string;
  customLinks: CustomLink[];
  contactUsNotificationUserIds: string[];
  designRequestNotificationUserIds: string[];
  designIdeasSettings: AssetSettings;
  clipArtSettings: AssetSettings;
  designRequestForm: DesignRequestForm;
  myArtSettings: MyArtAssetSettings;
}

export interface CustomLink {
  name: string;
  url: string;
  isLinkVisible?: boolean;
}

export interface AssetSettings {
  status?: boolean; // This is for frontend validation use
  hiddenKeywords: string[];
  defaultCategory: string;
  categoryIds: ID[];
  subCategoryIds: ID[];
}

export interface MyArtAssetSettings {
  sectionName: string,
  displayStockArtFirst: boolean
}

export const emptyAssetSettings = {
  hiddenKeywords: [],
  defaultCategory: null,
  categoryIds: [],
  subCategoryIds: []
}
