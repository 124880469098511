import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Art, ID } from '@graphics-flow/types';
import { ArtQuery } from '@graphics-flow/util';

@Pipe({
  name: 'art'
})
export class ArtPipe implements PipeTransform {
  constructor(private readonly artQuery: ArtQuery) {

  }

  transform(artId: ID): Observable<Art> {
    return this.artQuery.selectArt(artId);
  }

}
