import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderDirective } from './slider.directive';
import { TextDirective } from './text.directive';
import { ColorPickerComponent } from './color-picker.component';

@NgModule({
  declarations: [
    ColorPickerComponent,
    SliderDirective,
    TextDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ColorPickerComponent,
    SliderDirective,
    TextDirective,
  ]
})

export class ColorPickerModule {
}
