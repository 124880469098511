<div *ngIf="teammate$ | async as teammate" class="flex-container flex-1 text-truncate align-middle" [ngClass]="panelClass">
  <ng-container *ngIf="showFullInfo else normalView">
    <div class="flex-container flex-1 text-truncate align-middle">
      <ng-template [ngTemplateOutlet]="avatar"></ng-template>
      <div class="ml-4 text-truncate">
        <p id="teammateName" class="t-14-600-p m-b-0 text-truncate assignee-avatar-name">{{teammate | userDisplayName: false}}</p>
        <p id="teammateEmail" class="t-14-400-h m-b-0 text-truncate">{{teammate?.email}}</p>
      </div>
    </div>
  </ng-container>
  <ng-template #normalView>
    <ng-template [ngTemplateOutlet]="avatar"></ng-template>
    <span id="teammateName" class="text-truncate assignee-avatar-name" [innerHTML]="teammate | userDisplayName : true : showInActive"></span>
  </ng-template>
</div>

<ng-template #avatar>
  <div *ngIf="!nameOnly && teammate$ | async as teammate" class="teammate-profile">
    <div *ngIf="(!imageUrl && !teammate?.profileAssetGuid); else profilePicture"
         id="teammateShortName"
         [ngClass]=" teammate | userDisplayName | shortName | iconBackground"
         [className]="'avatar rounded cursor-pointer '+ profilePicSize"
         #tooltip="matTooltip"
         matTooltipClass="mat-tooltip-below"
         [matTooltip]="teammate.email"
         (click)="tooltip.toggle()">
      {{teammate | userDisplayName | shortName}}
    </div>
    <ng-template #profilePicture>
      <div *ngIf="imageUrl; else profileAssetGuid"
           id="teammateProfileImage"
           [className]="'avatar img-preview rounded cursor-pointer '+ profilePicSize" [style.background-image]="'url('+ imageUrl + ')'">
      </div>
      <ng-template #profileAssetGuid>
        <div id="teammateProfileGradient" [className]="'avatar img-preview rounded cursor-pointer '+ profilePicSize" [style.background-image]="'url('+ userProfilePicturePipe.transform(teammate, size) +')'"></div>
      </ng-template>
    </ng-template>
  </div>
</ng-template>
