import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Translations } from '@graphics-flow/shared/assets';
import { Art, ID, ImagePreviewType, StockArt } from '@graphics-flow/types';
import { ArtService, CustomizeStockArtService, OrganizationQuery } from '@graphics-flow/util';
import { EventHelpers } from 'shared/util';

@Component({
  selector: 'gf-stock-art-item',
  templateUrl: './stock-art-item.component.html',
  styleUrls: ['./stock-art-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StockArtItemComponent {
  @ViewChild('artMenuTrigger') artMenuTrigger: MatMenuTrigger;
  @ViewChild('artImg') artImg: ElementRef;
  @Input() art: Art;
  @Input() small = false;
  @Input() isGraphicsBuilder: boolean;
  @Input() associatedOrgId: ID;
  @Input() showCustomizeStockArt = false;
  @Input() customizeStockArt = false;
  @Input() hideStockArtItemInfoFooter = false;
  @Input() stockArt: StockArt;
  @Output() openStockArtDetails: EventEmitter<void> = new EventEmitter<void>();

  imagePreviewType: typeof ImagePreviewType = ImagePreviewType;

  constructor(
    public readonly translations: Translations,
    public customizeStockArtService: CustomizeStockArtService,
    public readonly organizationQuery : OrganizationQuery,
    public readonly artService: ArtService
  ) {
  }

  stopAndPrevent(event: any) {
    EventHelpers.stopAndPrevent(event);
  }
}
