import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { Translations } from '@graphics-flow/shared/assets';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'gf-welcome-to-gf-dialog',
  templateUrl: './welcome-to-gf-dialog.component.html',
  styleUrls: ['./welcome-to-gf-dialog.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule
  ]
})
export class WelcomeToGFDialogComponent {

  constructor(
    public translations: Translations
  ) { }

}
