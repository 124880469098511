import { Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRoles } from '@graphics-flow/types';
import { UserQuery } from './../data/user/user.query';
import { defaultHomePage } from '../constants/default-route.constants';

@Injectable({
  providedIn: 'root'
})
export class TeamSettingsRouteGuard {

  constructor(
    private readonly userQuery: UserQuery,
    private readonly router: Router
  ) {
  }

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const role = this.userQuery.getUserRole();
    if (role !== IRoles.Support) {
      return true;
    }
    this.router.navigate([defaultHomePage]);
  }
}
