<div id="categoryFilterDialogContent">
  <div class="position-relative">
    <h1 mat-dialog-title class="text-truncate">{{translations.stock.category | translate}}</h1>
  </div>
  <mat-dialog-content [class.mat-dialog-content-padding]="canShowClipArtMobileViewFilter">
    <gf-category-filter [canShowClipArtMobileViewFilter]="canShowClipArtMobileViewFilter" #categoryFilter></gf-category-filter>
  </mat-dialog-content>
  <mat-dialog-actions class="align-right" [class.mat-dialog-actions-padding]="canShowClipArtMobileViewFilter">
    <button id="cancelBtn"
      mat-raised-button
      class="large"
      color="secondary"
      mat-dialog-close>
      {{translations.common.cancel | translate}}
    </button>
    <button id="doneBtn"
      mat-raised-button
      class="large"
      color="primary"
      mat-dialog-close
      (click)="applyFilters()">
      {{translations.common.done | translate}}
    </button>
  </mat-dialog-actions>
</div>
