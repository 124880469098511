export enum ApprovalEventTypes {
  ApprovalCreated = 'approvalCreated',
  ApprovalArchived = 'approvalArchived',
  ApprovalReOpened = 'approvalReOpened',
  AddedComment = 'addedComment',
  DeletedComment = 'deletedComment',
  ItemAdded = 'itemAdded',
  ItemDeleted = 'itemDeleted',
  ItemApproved = 'itemApproved',
  ItemCommentedAndApproved = 'itemCommentedAndApproved',
  ItemRejected = 'itemRejected',
  ItemCommentedAndRejected = 'itemCommentedAndRejected',
  CollaboratorViewed = 'collaboratorViewed',
  ApprovalApproved = 'approvalApproved',
  AssigneeChanged = 'assigneeChanged',
  ResentApproval = 'resentApproval'
}
