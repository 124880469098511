<ng-container *ngIf="stockArtListQuery.activeFilterHeaders$ | async as activeFilters">
  <ng-container *ngIf="stockArtListQuery.filterOptions$ | async as filterOptions">
    <ng-container *ngIf="!canShowClipArtMobileViewFilter">
      <mat-form-field class="filter-form-field show-for-medium" floatLabel="never" color="accent">
        <mat-select multiple
          disableRipple
          panelClass="filter-select-panel"
          [placeholder]="label"
          [ngModel]="activeFilters[filterProperty]"
          (selectionChange)="onSelectionChange($event)">
          <mat-select-trigger class="multi-select-label-trigger">
            {{label}}
          </mat-select-trigger>
          <a *ngIf="activeFilters[filterProperty]?.length"
            id="clearAllSelected"
            mat-button
            color="accent"
            class="m-y-1 p-x-2 clear-all-btn"
            (click)="clearAllSelected()">
            {{translations.stock.clear_all_selected | translate}}
          </a>
          <mat-option *ngFor="let option of filterOptions[filterProperty]" [value]="option" class="text-capitalize">
            <ng-container *ngTemplateOutlet="filterLabel; context:{option: option}"></ng-container>
          </mat-option>
        </mat-select>
        <mat-icon class="hint" matSuffix fontIcon="expand_more"></mat-icon>
      </mat-form-field>
    </ng-container>

    <!--  Style Filter in mobile view-->
    <div *ngIf="(windowService?.smallDown$ | async) || canShowClipArtMobileViewFilter">
      <div *ngFor="let option of filterOptions[filterProperty]" class="m-t-2">
        <mat-checkbox class="style-filter t-16-500-s text-capitalize"
                      [checked]="(activeFilters[filterProperty])?.includes(option) || selectedFilters.includes(option)"
                      (change)="setSelectedFilters(option, $event.checked)">
          <ng-container *ngTemplateOutlet="filterLabel; context:{option: option}"></ng-container>
        </mat-checkbox>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #filterLabel let-option="option">
  <ng-container *ngIf="filterProperty !== 'artLibraries'">
    {{option}}
  </ng-container>
  <ng-container *ngIf="filterProperty === 'artLibraries'">
    {{ ("artLibraries." + option) | translate }}
  </ng-container>
</ng-template>
