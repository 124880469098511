<div *ngIf="approval$ | async" class="flex-container flex-dir-column p-a-4">
  <div *ngIf="canApprove || approvalItem.status"
    class="flex-container align-justify m-b-4"
    #tooltip="matTooltip"
    [matTooltip]="translations.common.viewers_do_not_have | translate"
    [matTooltipPosition]="'above'"
    [matTooltipDisabled]="canApprove"
    matTooltipHideDelay="100"
    (click)="tooltip.toggle()">
    <button *ngIf="canApprove || approvalItem.status === ArtApprovalItemStatus.APPROVED"
      mat-raised-button
      color="secondary"
      class="full-width thick-border large"
      [class.cursor-not-allowed]="approvalItem.status === ArtApprovalItemStatus.APPROVED"
      [disabled]="!canApprove || (isArchived$ | async) || (actionItem.action ===  ArtApprovalItemStatus.APPROVED && approvalItemStatusInProgress) || (!!collaborator && approval | isApprovalApproved)"
      [class.success]="approvalItem?.status === ArtApprovalItemStatus.APPROVED"
      (click)="showActionConfirmation(ArtApprovalItemStatus.APPROVED)">
      <mat-icon *ngIf="!(actionItem.action ===  ArtApprovalItemStatus.APPROVED && approvalItemStatusInProgress); else showSpinner" class="mr-4" fontIcon="done"></mat-icon>
      <ng-template #showSpinner>
        <mat-icon class="mr-4">
          <mat-spinner color="primary" diameter="20"></mat-spinner>
        </mat-icon>
      </ng-template>
      {{((approvalItem.status === ArtApprovalItemStatus.APPROVED) ? translations.common.approved : translations.common.approve) | translate}}
    </button>
    <button *ngIf="canApprove || approvalItem.status === ArtApprovalItemStatus.REJECTED"
      mat-raised-button
      color="secondary"
      class="full-width m-l-1 thick-border large"
      [class.cursor-not-allowed]="approvalItem.status === ArtApprovalItemStatus.REJECTED"
      [disabled]="!canApprove || (isArchived$ | async) || (actionItem.action ===  ArtApprovalItemStatus.REJECTED && approvalItemStatusInProgress) || (!!collaborator && approval | isApprovalApproved)"
      [class.warn]="approvalItem?.status === ArtApprovalItemStatus.REJECTED"
      (click)="showActionConfirmation(ArtApprovalItemStatus.REJECTED)">
      <mat-icon *ngIf="!(actionItem.action ===  ArtApprovalItemStatus.REJECTED && approvalItemStatusInProgress); else showSpinner" class="mr-4" fontIcon="clear"></mat-icon>
      <ng-template #showSpinner>
        <mat-icon class="mr-4">
          <mat-spinner color="primary" diameter="20"></mat-spinner>
        </mat-icon>
      </ng-template>
      {{((approvalItem.status === ArtApprovalItemStatus.REJECTED) ? translations.common.rejected : translations.common.reject) | translate}}
    </button>
  </div>
  <gf-stock-art-downloaded
    *ngIf="((approvalItem.artId | art | async)?.stockArtOrgId || (approvalItem.artId | art | async)?.customizedStockArtId) && !(viewOnly$ | async)"
    [art]="approvalItem.artId | art | async"
    class="p-x-2">
  </gf-stock-art-downloaded>
  <form
    #approvalItemForm
    name="approvalItemForm"
    [formGroup]="formGroup$ | async"
    autocomplete="off">
    <div>
      <mat-form-field
        class="t-20-500-p m-b-1 width-100 approval-detail-input approval-item-title-detail"
        appearance="fill">
        <textarea
          id="name"
          class="approval-input art-approval-name"
          name="name"
          matInput
          maxlength="1024"
          [cdkAutosizeMinRows]="1"
          cdkTextareaAutosize
          #nameAutosize="cdkTextareaAutosize"
          formControlName="name"
          [readonly]="viewOnly$ | async"
          [placeholder]="translations.art.add_art_name | translate"
          required>
        </textarea>
      </mat-form-field>

      <mat-form-field
        *ngIf="!(viewOnly$ | async) || artApprovalItemFormDetail.get('description').value; else onlyViewEmptyDescription"
        class="t-14-400-h width-100 approval-detail-input approval-item-desc-detail textarea"
        appearance="fill">
        <textarea
          id="description"
          matInput
          name="description"
          formControlName="description"
          class="approval-input"
          maxlength="1024"
          [cdkAutosizeMinRows]="1"
          cdkTextareaAutosize
          #descriptionAutosize="cdkTextareaAutosize"
          [readonly]="viewOnly$ | async"
          [placeholder]="translations.common.add_a_description | translate">
        </textarea>
      </mat-form-field>
      <ng-template #onlyViewEmptyDescription>
        <p class="no-description-added">
          {{ translations.common.no_description_added | translate }}
        </p>
      </ng-template>
    </div>
  </form>
</div>
<mat-divider></mat-divider>
