<div class="stock-art-footer-only-mobile">
  <div class="flex-container">
    <div (click)="openStockArtDetailPage.emit(stockArtDetailSections.INFO)" class="flex-container align-center-middle menu-icon flex-1"
      [class.active]="activeSection === stockArtDetailSections.INFO">
      <mat-icon class="material-icons-outlined" fontIcon="info"></mat-icon>
    </div>
    <ng-container *ngIf="showCustomizeStockArt">
      <div id="customizeIconBtn"
        class="flex-container align-center-middle menu-icon flex-1"
        [class.active]="activeSection === stockArtDetailSections.CUSTOMIZE"
        (click)="openStockArtDetailPage.emit(stockArtDetailSections.CUSTOMIZE)">
        <mat-icon class="material-icons-outlined" fontIcon="palette"></mat-icon>
      </div>
      <div *ngIf="showArtCustomizationsIcon"
        id="customizationsIconBtn"
        class="flex-container align-center-middle menu-icon flex-1"
        [class.active]="activeSection === stockArtDetailSections.STOCK_ART_CUSTOMIZATIONS"
        (click)="openStockArtDetailPage.emit(stockArtDetailSections.STOCK_ART_CUSTOMIZATIONS)">
        <mat-icon class="material-icons-outlined" fontIcon="collections_bookmark"></mat-icon>
      </div>
    </ng-container>
  </div>
</div>
