<ng-template>
  <div class="fabulous-menu"
       [ngClass]="_classList"
       [class.open]="_isOpen"
       (click)="closed.emit('click')"
       [@transformMenu]="_panelAnimationState"
       (@transformMenu.start)="_onAnimationStart($event)"
       (@transformMenu.done)="_onAnimationDone($event)">
    <div class="fabulous-content">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
