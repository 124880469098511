import { Injectable } from '@angular/core';
import { createState, Store } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';
import { Organization } from '@graphics-flow/types';


const { state, config } = createState(
  withEntities<Organization, 'organizationId'>({ idKey: 'organizationId', initialValue: [] }),
  withActiveId()
);

@Injectable({
  providedIn: 'root'
})
export class OrganizationStore extends Store {
  constructor() {
    super({ name: 'organizations', state, config });
  }
}
