import { Injectable } from '@angular/core';
import { setProp } from '@ngneat/elf';
import { TranslateService } from '@ngx-translate/core';
import { flatMap as _flatMap, isEqual as _isEqual } from 'lodash-es';
import { Subject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { ArtApprovalHttpService } from '@graphics-flow/api';
import { Translations } from '@graphics-flow/shared/assets';
import { ApiResponse, ArtApproval, ArtApprovalRequest, ArtApprovalStatistics, ArtApprovalStatus, Collaborator, NotificationType, Pagination } from '@graphics-flow/types';
import { NotificationService } from 'shared/ui';

import { ART_APPROVAL_BASE_LIMIT, MAX_SCROLL_LIMIT } from '../../constants/infinity-scroller.constants';
import { GlobalHelpers } from '../../helpers/global.helpers';
import { ApprovalQuery } from '../approval/approval.query';
import { ApprovalService } from '../approval/approval.service';
import { CollaboratorService } from '../collaborator/collaborator.service';
import { ArtApprovalListQuery } from './art-approval-list.query';
import { ArtApprovalListStore } from './art-approval-list.store';

@Injectable({ providedIn: 'root' })
export class ArtApprovalListService {
  destroyed$ = new Subject();

  constructor(
    private readonly artApprovalQuery: ApprovalQuery,
    private readonly artApprovalStore: ApprovalService,
    private readonly artApprovalListQuery: ArtApprovalListQuery,
    private readonly artApprovalListStore: ArtApprovalListStore,
    private readonly approvalHttpService: ArtApprovalHttpService,
    private readonly collaboratorService: CollaboratorService,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    public readonly translations: Translations
  ) {
  }

  initialize() {
    this.artApprovalListStore.updateLoading(true);
    this.artApprovalListQuery.activeFilters$.pipe(
      debounceTime(200), // Filters get changed a lot, we don't necessarily want to make a call instantly every time
      distinctUntilChanged((oldFilter, newFilter) =>  {
        oldFilter = JSON.parse(JSON.stringify(oldFilter));
        newFilter = JSON.parse(JSON.stringify(newFilter));
        return _isEqual(oldFilter, newFilter);
      }),
      switchMap((activeFilters: ArtApprovalRequest) => {
        this.artApprovalListStore.update(state => ({
          ...state,
          loading: activeFilters.index === 0,
          loadingResults: activeFilters.index !== 0
        }));
        return this.approvalHttpService.getArtApprovalSummary(activeFilters);
      }),
      takeUntil(this.destroyed$)
    ).subscribe((response: ApiResponse<ArtApproval[]>) => {
      this.artApprovalListStore.setPagination(response.pagination);

      //Note:- Here we are updating collaborators store with values from art-approval collaborators value
      const collaborators: Collaborator[][] = response.data.map((artApproval: ArtApproval) => {
        if (artApproval?.collaborators?.length) {
          for (const collaborator of artApproval.collaborators) {
            collaborator.artApprovalIds = [ artApproval.artApprovalId ];
          }
        }
        return artApproval.collaborators;
      });

      const collaboratorsAsArray: Collaborator[] = _flatMap(collaborators);

      this.collaboratorService.upsertCollaboratorEntities(collaboratorsAsArray);

      if (response.pagination.index === 0) {
        this.artApprovalListStore.updateLoading(false);
        this.artApprovalStore.addApprovals(response.data);
      } else {
        this.artApprovalListStore.updateLoadingResult(false);
        this.artApprovalStore.upsertApprovals(response.data);
      }
    });
  }

  getBatch(approvalLength: number): void {
    this.setActiveFilters({
      index: approvalLength,
      limit: GlobalHelpers.getInfiniteScrollValidLimit(approvalLength, ART_APPROVAL_BASE_LIMIT)
    });
  }

  setActiveFilters(activeFilters: ArtApprovalRequest): void {
    if (activeFilters.index === MAX_SCROLL_LIMIT) {
      this.notificationService.showNotification(NotificationType.WARNING, this.translateService.instant(this.translations.common.warning) ,this.translateService.instant(this.translations.common.infinite_scroll_max_limit_warning_msg));
      return;
    }
    this.artApprovalListStore.setActiveFilters(activeFilters);
  }

  updateStatusCount() {
    this.artApprovalListQuery.selectedFilterStatus$.pipe(
      debounceTime(200),
      switchMap((statue: ArtApprovalStatus) => {
        return this.approvalHttpService.getArtApprovalStatistics(statue);
      }),
      tap((counts: ArtApprovalStatistics) => {
        this.artApprovalListStore.updateArtApprovalStatistics(counts);
      }),
      takeUntil(this.destroyed$)
    ).subscribe();
  }

  clearSubscriptions(): void {
    this.destroyed$.next(null);
  }

  reduceTotalResultsCountByOne(): void {
    this.artApprovalListStore.update(
      setProp('filteredArtApprovalsPagination', (pagination: Pagination) => ({
        ...pagination,
        totalResults: pagination.totalResults - 1
      }))
    );
    combineLatest([this.artApprovalQuery.approvals$, this.artApprovalListQuery.activeFiltersTotalCount$]).pipe(
      take(1),
      tap(([artApprovals, totalCount]: [ArtApproval[], number]) => {
        const totalArtApprovals: number = artApprovals?.length;
        if (totalArtApprovals < ART_APPROVAL_BASE_LIMIT && totalArtApprovals < totalCount) {
          this.getBatch(totalArtApprovals);
        }
      })
    ).subscribe();
  }

  reset(): void {
    this.artApprovalListStore.reset();
  }

}
