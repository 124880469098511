import { Injectable } from '@angular/core';
import { createState, select, setProps, Store, withProps } from '@ngneat/elf';
import { Observable } from 'rxjs';

import { CustomizeStockArt } from '@graphics-flow/types';

export interface CSAState {
  customizeStockArt?: CustomizeStockArt;
}

export const initialCSAState = {
  customizeStockArt: null
};

/**
 * As of now, I couldn't see anyother workaround to handle the stateHistory for a specific property in an store.
 * In Akita's StateHistory we had "watchProperty" to listen only specified property in a store,
 * But in Elf we don't find any "watchProperty" feature, so to overcome this situation, I had to create a
 * separate store that holds only "customizeStockArt" to handle the stateHistory that works as before.
 *
 * TODO: Once we found any approach to listen only a specific property in Elf's store, the we can to remove this functionality.
 */

const { state, config } = createState(
  withProps<CSAState>(initialCSAState)
);

@Injectable({ providedIn: 'root' })
export class CSAStore extends Store {

  constructor() {
    super({ name: 'csa', state, config });
  }

  updateCSA(csa: CustomizeStockArt) {
    this.update(setProps((state) => {
      state.customizeStockArt = csa;
      return state;
    }));
  }
}

@Injectable({ providedIn: 'root' })
export class CSAQuery {
  csa$: Observable<CustomizeStockArt> = this.store.pipe(select((state) => state.csa));

  constructor(private store: CSAStore) {}

  getValue(): CSAState {
    return this.store.getValue();
  }
}

@Injectable({ providedIn: 'root' })
export class CSAService {

  constructor(
    private store: CSAStore
  ) {}

  updateCSA(csa: CustomizeStockArt): void {
    this.store.updateCSA(csa);
  }
}
