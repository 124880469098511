import { Injectable } from '@angular/core';
import { ArtApprovalItemDetailSection, CollaboratorLinkPackage, DesignRequest, ID } from '@graphics-flow/types';
import { createState, Store, withProps } from '@ngneat/elf';

export interface ApprovalDetailState {
  activeSection: ArtApprovalItemDetailSection;
  approvalId: ID;
  approvalItemId: ID;
  collaboratorId: ID;
  collaboratorLinkId: ID;
  collaboratorLink: CollaboratorLinkPackage;
  isDrawerOpen: boolean;
  relatedDesignRequest: DesignRequest[];
}

const initialState: ApprovalDetailState = {
  activeSection: ArtApprovalItemDetailSection.DETAILS,
  approvalId: null,
  approvalItemId: null,
  collaboratorId: null,
  collaboratorLinkId: null,
  collaboratorLink: null,
  isDrawerOpen: true,
  relatedDesignRequest: []
};

const { state, config } = createState(
  withProps<ApprovalDetailState>(initialState)
);

@Injectable({
  providedIn: 'root'
})
export class ApprovalDetailStore extends Store {
  constructor() {
    super({ name: 'approvalDetail', state, config });
  }
}
