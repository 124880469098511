import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingIndicatorComponent } from './loading-indicator.component';
import { pull as _pull } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  // If this array is not empty, the whole-screen loading overlay should show
  private loadingIds: Array<string | number> = [];
  loadingOverlay: MatDialogRef<LoadingIndicatorComponent>;

  constructor(private dialog: MatDialog) { }


  public showLoader(loadingId: string | number) {
    // setTimeout is necessary until the Angular team fixes https://github.com/angular/angular/issues/15634
    setTimeout(() => {
      this.loadingIds.push(loadingId);

      if (!this.loadingOverlay || !this.loadingOverlay.componentInstance) {
        this.loadingOverlay = this.dialog.open(LoadingIndicatorComponent, {
          disableClose: true,
          panelClass: 'loading-overlay'
        });
      }
    });
  }

  public hideLoader(loadingId: string | number) {
    // setTimeout is necessary until the Angular team fixes https://github.com/angular/angular/issues/15634
    setTimeout(() => {
      _pull(this.loadingIds, loadingId);

      if (!this.loadingIds?.length && this.loadingOverlay) {
        this.loadingOverlay.close();
        this.loadingOverlay = undefined;
      }
    });
  }

  public isLoaderIdExist(loaderId: string | number) {
    return this.loadingIds.includes(loaderId);
  }
}
