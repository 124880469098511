import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { countBy as _countBy, flatten as _flatten, some as _some } from 'lodash-es';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Art, Folder, ID } from '@graphics-flow/types';

import { ArtHelper } from '../../helpers/art.helper';
import { ArtQuery } from '../art/art.query';
import { FolderQuery } from './../folder/folder.query';
import { MyArtSearchQuery } from './../my-art-search/my-art-search.query';
import { MyArt, MyArtBulkSelectionStore } from './my-art-bulk-selection.store';

@Injectable({
  providedIn: 'root'
})
export class MyArtBulkSelectionQuery {

  public selectedCount$ = this.selectStateProps<number>((state: MyArt) => state?.artIds?.length + state?.folderIds?.length);

  public selectedArtAndFolderIds$: Observable<[ID[], ID[]]> = this.selectStateProps<[ID[], ID[]]>((state: MyArt) => [state.artIds, state.folderIds]);

  public selectedArtIds$: Observable<ID[]> = this.selectStateProps<ID[]>((state: MyArt) => state.artIds);

  selectedArts$: Observable<Art[]> = this.selectedArtIds$.pipe(
    switchMap((artIds: ID[]) => this.artQuery.selectManyArts(artIds))
  );

  tagsCollectionFromArts$: Observable<string[]> = this.selectedArts$.pipe(
    map((arts: Art[]) => {
      const tagsCollection = arts.map((art: Art) => art.tags);
      const tagsWithDuplicates = _flatten(tagsCollection);
      return tagsWithDuplicates;
    })
  );

  tagsCountForSelectedArts$: Observable<{ [key: string]: number }> = this.tagsCollectionFromArts$.pipe(
    map((tags: string[]) => _countBy(tags))
  );

  selectedTags$: Observable<string[]> = this.tagsCollectionFromArts$.pipe(
    map((tags: string[]) => {
      return [...new Set(tags)];
    })
  );

  public childrenCount$: Observable<number> = this.myArtSearchQuery.activeChildrenCount$;

  public isUnsupportedFileSelected$: Observable<boolean> = this.selectedArts$.pipe(
    map((arts: Art[]) => _some(arts, (art: Art) => ArtHelper.isNoPreviewImage(art)))
  );

  public isCustomizedStockArtSelected$: Observable<boolean> = this.getSelectedArtsAndFolders().pipe(
    map((res: [Art[], Folder[]]) => {
      if (!res.length) return false;
      return !!res[0].filter((art: Art) => art.customizedStockArtId).length;
    })
  );

  constructor(
    protected store: MyArtBulkSelectionStore,
    private readonly artQuery: ArtQuery,
    private readonly folderQuery: FolderQuery,
    private readonly myArtSearchQuery: MyArtSearchQuery
  ) {
  }

  selectStateProps<T>(predicate): Observable<T> {
    return this.store.pipe(select(predicate));
  }

  getValue(): MyArt {
    return this.store.getValue();
  }

  isSelected(id: ID): Observable<boolean> {
    return this.selectStateProps<boolean>((entity: MyArt) => {
      return entity.folderIds?.includes(id) || entity.artIds?.includes(id);
    });
  }

  getSelectedArtAndFolderIds(): [ID[], ID[]] {
    return [
      this.getValue().artIds,
      this.getValue().folderIds
    ];
  }

  getSelectedArtsAndFolders(): Observable<[Art[], Folder[]]> {
    return this.selectedArtAndFolderIds$.pipe(
      switchMap(([artIds, folderIds]: [ID[], ID[]]) => {
        return combineLatest([
          this.artQuery.selectManyArts(artIds),
          this.folderQuery.selectManyFolders(folderIds)
        ])
      })
    );
  }

  getSelectedArtIds(): ID[] {
    return this.getValue().artIds;
  }
}
