/* tslint:disable:variable-name */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { animate, AnimationEvent, query, stagger, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'fabulous-menu',
  templateUrl: './fabulous-menu.component.html',
  styleUrls: ['./fabulous-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('transformMenu', [
      transition(':enter', [
        query('.fabulous-item', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('200ms linear', style({ opacity: 1 }))
          ])
        ])
      ]),
      transition(':leave', query('.fabulous-item', [
        stagger(-100, [
          animate('200ms linear', style({
            opacity: 0
          }))
        ])
      ]))
    ])
  ]
})
export class FabulousMenuComponent implements OnDestroy, OnInit {
  _panelAnimationState: 'void' | 'enter' = 'void';
  _classList: { [key: string]: boolean } = {};
  /** Class to be applied to the menu's backdrop. */
  backdropClass: string;
  /** Class or list of classes to be applied to the menu's overlay panel. */
  overlayPanelClass?: string | string[];
  /** Whether the menu has a backdrop. */
  hasBackdrop?: boolean;
  /** Emits whenever an animation on the menu completes. */
  _animationDone = new Subject<AnimationEvent>();
  /** Whether the menu is animating. */
  _isAnimating: boolean;
  /** Whether the menu is currently open */
  _isOpen: boolean;

  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;
  @Output() closed: EventEmitter<void | 'click' | 'keydown' | 'tab'> =
    new EventEmitter<void | 'click' | 'keydown' | 'tab'>();

  private _position: MenuPositionX | MenuPositionY  = 'above';

  /** Position of the menu in the Y axis. */
  @Input()
  get position(): MenuPositionX | MenuPositionY {
    return this._position;
  }

  set position(value: MenuPositionX | MenuPositionY) {
    this._position = value;
    this.setPositionClasses();
  }

  ngOnInit() {
    this.setPositionClasses();
  }

  ngOnDestroy() {
    this.closed.complete();
  }

  _resetAnimation() {
    this._panelAnimationState = 'void';
  }

  _startAnimation() {
    this._panelAnimationState = 'enter';
  }

  /** Callback that is invoked when the panel animation completes. */
  _onAnimationDone(event: AnimationEvent) {
    this._animationDone.next(event);
    this._isAnimating = false;
  }

  _onAnimationStart(event: AnimationEvent) {
    this._isAnimating = true;

    // Scroll the content element to the top as soon as the animation starts. This is necessary,
    // because we move focus to the first item while it's still being animated, which can throw
    // the browser off when it determines the scroll position. Alternatively we can move focus
    // when the animation is done, however moving focus asynchronously will interrupt screen
    // readers which are in the process of reading out the menu already. We take the `element`
    // from the `event` since we can't use a `ViewChild` to access the pane.
    if (event.toState === 'enter') {
      event.element.scrollTop = 0;
    }
  }

  setPositionClasses(position: MenuPositionX | MenuPositionY = this.position) {
    const classes = this._classList;
    classes['fabulous-menu-before'] = position === 'before';
    classes['fabulous-menu-after'] = position === 'after';
    classes['fabulous-menu-above'] = position === 'above';
    classes['fabulous-menu-below'] = position === 'below';
  }

  updateIsOpen(isOpen: boolean) {
    this._isOpen = isOpen;
  }
}
