import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { Observable } from 'rxjs';

import { ArtApprovalRequest, ArtApprovalStatus, ArtApprovalViewFilter, ID } from '@graphics-flow/types';
import { ArtApprovalListState, ArtApprovalListStore } from './art-approval-list.store';

@Injectable({ providedIn: 'root' })
export class ArtApprovalListQuery {
  activeFilters$: Observable<ArtApprovalRequest> = this.selectStateProps<ArtApprovalRequest>((store: ArtApprovalListState) => store.activeFilters);
  activeSearchText$: Observable<string> = this.selectStateProps<string>((store: ArtApprovalListState) => store.activeFilters?.searchText || '');
  activeFiltersTotalCount$: Observable<number> = this.selectStateProps<number>((store: ArtApprovalListState) => store.filteredArtApprovalsPagination?.totalResults);
  selectedFilterStatus$: Observable<ArtApprovalStatus> = this.selectStateProps<ArtApprovalStatus>((store: ArtApprovalListState) => store.activeFilters.status);
  approvalsViewFilter$: Observable<ArtApprovalViewFilter> = this.selectStateProps<ArtApprovalViewFilter>((store: ArtApprovalListState) => store.activeFilters.approvalStatus);
  selectedUser$: Observable<ID> = this.selectStateProps<ID>((store: ArtApprovalListState) => store.activeFilters?.assigneeIds[0]);
  loading$: Observable<boolean> = this.selectStateProps<boolean>((store: ArtApprovalListState) => store.loading);
  loadingResults$: Observable<boolean> = this.selectStateProps<boolean>((store: ArtApprovalListState) => store.loadingResults);
  approvedCount$: Observable<number> = this.selectStateProps<number>((store: ArtApprovalListState) => store.artApprovalStatistics.approvedCount);
  notApprovedCount$: Observable<number> = this.selectStateProps<number>((store: ArtApprovalListState) => store.artApprovalStatistics.notApprovedCount);
  assigneeIds$: Observable<ID[]> = this.selectStateProps<ID[]>((store: ArtApprovalListState) => store.artApprovalStatistics.assigneeIds);

  constructor(
    protected store: ArtApprovalListStore
  ) {
  }

  selectStateProps<T>(predicate): Observable<T> {
    return this.store.pipe(select(predicate));
  }

  getTotalResult() {
    return this.store.getValue()?.filteredArtApprovalsPagination?.totalResults;
  }
}
