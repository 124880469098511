<mat-drawer-container [hasBackdrop]="true" (swipeleft)="swipeLeft()">
  <mat-drawer #gfDetailViewDrawer [mode]="'over'" [autoFocus]="false" class="details-view-drawer">
    <div class="grid-y">
      <gf-sidebar [showCloseButton]="true" [isDrawerOpened]="gfDetailViewDrawer.opened"
                  (closeDrawer)="gfDetailViewDrawer.toggle()"></gf-sidebar>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div class="detail-view-container grid-y">
      <div class="detail-header grid-x p-x-1 py-12 align-middle">
        <button mat-icon-button class="mat-24 m-r-1 suppress-hover-style" (click)="gfDetailViewDrawer.toggle()">
          <mat-icon color="primary" fontIcon="menu"></mat-icon>
        </button>
        <gf-logo [size]="imagePreviewType.SMALL" [isDarkLogo]="false"></gf-logo>
        <div class="flex-child-auto"></div>
        <gf-help-center [isDarkHeader]="true" [panelClass]="'mat-24 suppress-hover-style'"></gf-help-center>
        <gf-user-dropdown class="m-l-2" [isDarkHeader]="true"></gf-user-dropdown>
      </div>
      <div class="detail-content">
        <ng-content></ng-content>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
