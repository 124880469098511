import { Injectable } from '@angular/core';
import { selectAllEntities } from '@ngneat/elf-entities';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { GroupedTimelineEvent, GroupedTimelineType, TimelineEvent } from '@graphics-flow/types';
import { TimelineStore } from './timeline.store';

@Injectable({ providedIn: 'root' })
export class TimelineQuery  {

  timelineEvents$: Observable<GroupedTimelineEvent[]> = this.store.pipe(
    selectAllEntities(),
    filter(timelineEvents => timelineEvents.length > 0),
    map((timelineEvents): GroupedTimelineEvent[] => {
      return timelineEvents.reduce((groupedTimelineEvent, timelineEvent) => {
        const date = DateTime.fromISO((timelineEvent.createdDate).toISOString());
        const year = date.toFormat('yyyy'); // eg: '2024'
        const month = date.toFormat('MMMM yyyy'); // eg: 'July 2024'

        // It help us to group the timeline events by year & months
        // also it will be ordered by recent first and it is based on the data that we get from api.
        const yearlyEvent = this.findOrCreateNewGroupedEvent(groupedTimelineEvent, year, GroupedTimelineType.YEAR);
        const monthlyEvent = this.findOrCreateNewGroupedEvent(yearlyEvent.months, month, GroupedTimelineType.MONTH);
        monthlyEvent.events.push(timelineEvent);

        return groupedTimelineEvent;
      }, []);
    })
  )

  // Holds the recent year info and it will return the months from recent year.
  // Primary - refers to recent or current year events
  // Secondary - refers to previous year events
  monthlyEventsPrimary$: Observable<GroupedTimelineEvent[]> = this.timelineEvents$.pipe(
    map((timelineEvents) => {
      return timelineEvents[0]?.months ?? [];
    })
  );

  // Holds the recent month and it will return the events from recent month.
  recentMonthTimelineEvents$: Observable<TimelineEvent[]> = this.monthlyEventsPrimary$.pipe(
    map((monthlyEventsPrimary: GroupedTimelineEvent[]) => {
      const recentMonth: GroupedTimelineEvent = (monthlyEventsPrimary?.length > 0) ? monthlyEventsPrimary[0] : null;
      return recentMonth?.events ?? [];
    })
  );

  // Holds the secondary years info, by slicing the recent year info from the grouped timeline event list.
  yearlyEventsSecondary$: Observable<GroupedTimelineEvent[]> = this.timelineEvents$.pipe(
    map((timelineEvents) => timelineEvents.slice(1))
  );

  constructor(
    protected store: TimelineStore
  ) {
  }

  /**
   * @param groupedEvents: GroupedTimelineEvent[]
   * @param groupBy: String
   * @param type: GroupedTimelineType
   * @returns GroupedTimelineEvent
   *
   * @description Used to find or create new GroupedTimelineEvent data.
   * Also used for both year and month grouping, in year's grouping we push the months info, then in the
   * month's grouping, we will push the actual timeline events.
   */
  findOrCreateNewGroupedEvent(groupedEvents: GroupedTimelineEvent[], groupBy: string, type: GroupedTimelineType): GroupedTimelineEvent {
    let itemIndex = groupedEvents.findIndex(item => item.groupBy === groupBy);

    if (itemIndex === -1) {
      const newItem: GroupedTimelineEvent = this.createGroupedTimelineEventByType(groupBy, type);
      groupedEvents.push(newItem);
      itemIndex = groupedEvents.length - 1;
    }

    return groupedEvents[itemIndex];
  }

  /**
   *
   * @param groupBy: String
   * @param type: GroupedTimelineType
   * @returns GroupedTimelineEvent
   *
   * @description Used to create the GroupedTimelineEvent object, based on the "type".
   *
   * Year  => { type: 'year', groupBy: '2024', months: [] }
   * Month => { type: 'month', groupBy: 'July 2024', events: [] }
   *
   */
  createGroupedTimelineEventByType(groupBy: string,  type: GroupedTimelineType): GroupedTimelineEvent {
    const groupedTimelineEvent: GroupedTimelineEvent = <GroupedTimelineEvent>{
      type,
      groupBy
    };
    groupedTimelineEvent[type === GroupedTimelineType.YEAR ? 'months' : 'events'] = [];
    return groupedTimelineEvent;
  };
}
