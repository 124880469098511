import { Injectable } from '@angular/core';
import { addEntities, deleteEntities, updateEntities, upsertEntities } from '@ngneat/elf-entities';
import { tap } from 'rxjs/operators';

import { StockArtHttpService } from '@graphics-flow/api';
import { ID, StockArtCategory, StockArtHeaderPackage } from '@graphics-flow/types';
import { StockArtCategoriesStore } from './stock-art-categories.store';

@Injectable({ providedIn: 'root' })
export class StockArtCategoriesService {

  constructor(
    private stockArtCategoriesStore: StockArtCategoriesStore,
    private readonly stockArtHttpService: StockArtHttpService
  ) {
  }

  load() {
    this.stockArtHttpService.getStockArtHeaders().pipe(
      tap((pkg: StockArtHeaderPackage) => {
        this.addMany(pkg.categories);
        this.addMany(pkg.subCategories);
      })
    ).subscribe();
  }

  add(stockArtCategory: StockArtCategory) {
    this.stockArtCategoriesStore.update(addEntities(stockArtCategory));
  }

  addMany(stockArtCategories: StockArtCategory[]) {
    this.stockArtCategoriesStore.update(upsertEntities(stockArtCategories));
  }

  update(id: ID, stockArtCategory: Partial<StockArtCategory>) {
    this.stockArtCategoriesStore.update(updateEntities(id, stockArtCategory));
  }

  remove(id: ID) {
    this.stockArtCategoriesStore.update(deleteEntities(id));
  }
}
