import { Injectable } from '@angular/core';
import { createState, setProp, Store, withProps } from '@ngneat/elf';
import { isUndefined as _isUndefined } from 'lodash-es';

import {
  ArtApprovalOrder,
  ArtApprovalRequest,
  ArtApprovalStatistics,
  ArtApprovalStatus,
  ArtApprovalViewFilter,
  Pagination
} from '@graphics-flow/types';
import { ART_APPROVAL_BASE_LIMIT } from '../../constants/infinity-scroller.constants';

export interface ArtApprovalListState {
  activeFilters: ArtApprovalRequest;
  filteredArtApprovalsPagination?: Pagination;
  globalSearch?: string;
  offset?: number;
  loading: boolean;
  loadingResults: boolean;
  artApprovalStatistics: ArtApprovalStatistics;
}

export const initialArtApprovalListActiveFiltersState: ArtApprovalRequest = {
  index: 0,
  limit: ART_APPROVAL_BASE_LIMIT,
  orderBy: ArtApprovalOrder.CreatedDate,
  orderDesc: true,
  searchText: '',
  status: ArtApprovalStatus.OPEN,
  approvalStatus: ArtApprovalViewFilter.All,
  assigneeIds: []
};

export const initialArtApprovalStatistics: ArtApprovalStatistics = {
  approvedCount: 0,
  notApprovedCount: 0,
  assigneeIds: []
};

export const initialArtApprovalState: ArtApprovalListState = {
  activeFilters: initialArtApprovalListActiveFiltersState,
  offset: 0,
  loading: false,
  loadingResults: false,
  artApprovalStatistics: initialArtApprovalStatistics
};

const { state,config } = createState(
  withProps<ArtApprovalListState>(initialArtApprovalState)
);

@Injectable({ providedIn: 'root' })
export class ArtApprovalListStore extends Store {

  constructor() {
    super({name: 'art-approval-list', state, config});
  }

  setStateProps<T>(key: string, value: T) {
    this.update(setProp(key, value));
  }

  setPagination(pagination: Pagination): void {
    this.setStateProps<Pagination>('filteredArtApprovalsPagination', pagination);
  }

  // manage page loader
  updateLoading(loading: boolean): void {
    this.setStateProps<boolean>('loading', loading);

  }
  // manage infinite loader
  updateLoadingResult(loading: boolean): void {
    this.setStateProps<boolean>('loadingResults', loading);
  }

  setActiveFilters(filters: ArtApprovalRequest): void {
    this.update((state) => {
      const activeFilters = {
        index: !_isUndefined(filters.index) ? filters.index : state.activeFilters.index,
        limit: !_isUndefined(filters.limit) ? filters.limit : state.activeFilters.limit,
        orderBy: !_isUndefined(filters.orderBy) ? filters.orderBy : state.activeFilters.orderBy,
        orderDesc: !_isUndefined(filters.orderDesc) ? filters.orderDesc : state.activeFilters.orderDesc,
        searchText: !_isUndefined(filters.searchText) ? filters.searchText : state.activeFilters.searchText,
        status: !_isUndefined(filters.status) ? filters.status : state.activeFilters.status,
        approvalStatus: !_isUndefined(filters.approvalStatus) ? filters.approvalStatus : state.activeFilters.approvalStatus,
        assigneeIds: !_isUndefined(filters.assigneeIds) ? filters.assigneeIds : state.activeFilters.assigneeIds
      };
      return {
        ...state,
        activeFilters
      };
    });
  }

  updateArtApprovalStatistics(counts: ArtApprovalStatistics): void {
    this.setStateProps<ArtApprovalStatistics>('artApprovalStatistics', counts);
  }
}
