<div id="storageLimitExceedDialog" class="grid-y storage-limit-exceed-dialog">
  <mat-dialog-content>
    <div class="storage-limit-exceed">
      <mat-icon fontIcon="info_outline"></mat-icon>
      <p id="modalLabel">{{ translations.organization.storage_limit_exceeded | translate }}</p>
    </div>
    <mat-divider class="m-y-4"></mat-divider>
    <div>
      <p id="usageInfo" class="t-18-400-s" [innerHTML]="translations.art.usage.storage_usage_status | translate : { usage: (billingPlansQuery.usedStorageWithUnits$ | async), limit: (billingPlansQuery.storageLimit$ | async | filesize : { base: 2, standard: 'jedec' } )}"></p>
      <mat-progress-bar mode="determinate" value="100" color="warn" class="progress-bar-large"></mat-progress-bar>
      <p id="limitExceededMsg" class="m-t-2 m-b-0 t-16-400-s">
        {{ ((activeSection === stockArtDetailSections.CUSTOMIZE) ?
          translations.custom_stock_art.save_design_storage_limit_exceeded_message : translations.organization.storage_limit_exceeded_message) | translate}}
      </p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-actions align-right">
    <button id="cancelButton"
      mat-raised-button
      color="secondary"
      mat-dialog-close>
      {{ translations.common.cancel | translate }}
    </button>
    <div #tooltip="matTooltip"
      class="btn-margin"
      [matTooltip]="translations.common.you_do_not_have_permission_to_make_plan_changes | translate"
      [matTooltipPosition]="'above'"
      [matTooltipDisabled]="!((userQuery.isUser$ | async) || (userQuery.isSupport$ | async))"
      (click)="tooltip.toggle()">
      <button id="addStorageBtn"
        mat-raised-button
        color="primary"
        mat-dialog-close
        [disabled]="(userQuery.isUser$ | async) || (userQuery.isSupport$ | async)"
        (click)="navigateUpdateSubscription()">
        {{ translations.art.usage.add_storage | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</div>
