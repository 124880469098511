import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable, combineLatest, filter } from 'rxjs';
import { distinctUntilKeyChanged, map, tap } from 'rxjs/operators';

import { BasePrice, BundlePlanType, Organization, Plan, SelectedPlanInfo, SubscriptionItem } from '@graphics-flow/types';
import { BundlePlans } from '../constants/Plans.constants';
import { BillingPlansQuery } from '../data/billing-plans/billing-plans.query';
import { OrganizationQuery } from '../data/organization/organization.query';

@Injectable({
  providedIn: 'root'
})
export class UpdateSubscriptionPlanService {
  public selectedPlanForm: FormGroup<SelectedPlanInfo> = this.formBuilder.group({
    storagePlan: null,
    stockArtPlan: null,
    privateBranding: null,
    graphicsBuilder: null,
    selectedPlan: null,
    smartDesignerAddOnPlan: null,
    smartDesignerAdditionalDevicesAddOnPlan: null,
    userPlan: null
  });

  public currentPlan$: Observable<Plan>;
  public isProPlanRemovedFromSubscription$: Observable<boolean>;
  public currentPlanName$: Observable<BundlePlanType>;
  isReactivationInProgress: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isReactivationInProgress$: Observable<boolean> = this.isReactivationInProgress.asObservable();

  isSmartDesignerSelectedOrAdded$: Observable<BasePrice> = combineLatest([
    this.selectedPlanForm.value$,
    this.billingPlansQuery.currentSmartDesignerAddOnSubscription$])
    .pipe(
      map(([selectedPlanForm, currentSmartDesignerAddOn]: [SelectedPlanInfo, SubscriptionItem]) => {
        return (selectedPlanForm?.smartDesignerAddOnPlan || currentSmartDesignerAddOn?.price);
      })
    );

  constructor(
    private billingPlansQuery: BillingPlansQuery,
    private formBuilder: FormBuilder,
    private organizationQuery: OrganizationQuery
  ) {
    this.currentPlanName$ = combineLatest([
      this.selectedPlanForm.value$,
      this.organizationQuery.organization$,
      this.billingPlansQuery.getBundlePlans()
    ]).pipe(
      map(([selectedPlanInfo, organization, plans]: [SelectedPlanInfo, Organization, Plan[]]) => {
        if (!selectedPlanInfo?.selectedPlan) {
          return organization?.billingPlan;
        }
        const selectedCurrentPlan: Plan = plans.find((plan) => plan.product?.productMetadata?.plan_name === selectedPlanInfo.selectedPlan?.productMetadata?.plan_name);
        return selectedCurrentPlan?.plan;
      })
    );

    this.isProPlanRemovedFromSubscription$ = combineLatest([
      this.selectedPlanForm.value$,
      this.organizationQuery.isProPlan$
    ]).pipe(
      map(([selectedPlanInfo, isProPlan]: [SelectedPlanInfo, boolean]) => {
        const selectedPlan = BundlePlans.find((plan: Plan) => plan.productName === selectedPlanInfo?.selectedPlan?.productName);
        return isProPlan && selectedPlan?.plan === BundlePlanType.STANDARD;
      })
    );

    this.currentPlan$ = combineLatest([this.currentPlanName$, this.billingPlansQuery.getBundlePlans()]).pipe(
      map(([currentPlanName, plans]: [BundlePlanType, Plan[]]) => plans.find((p: Plan) => p.plan === currentPlanName))
    );

    this.selectedPlanForm.value$.pipe(
      distinctUntilKeyChanged('selectedPlan'),
      filter(() => this.isReactivationInProgress.getValue()),
      tap((selectedPlanForm) => {
        const userPlan: BasePrice = this.billingPlansQuery.getAdditionalUserInfoWithCount(selectedPlanForm?.selectedPlan?.users);
        this.selectedPlanForm.patchValue({
          userPlan
        });
      })
    ).subscribe();
  }

  editSelectedPlanForm(plan: Plan): void {
    const selectedPlan: BasePrice = _cloneDeep(plan.product);
    selectedPlan.quantity = 1;
    this.selectedPlanForm.patchValue({
      selectedPlan
    });
  }
}
