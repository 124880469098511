import { Pipe, PipeTransform } from '@angular/core';
import { ArtApprovalComment, ID } from '@graphics-flow/types';

@Pipe({
  name: 'getCommentReplies'
})
export class GetCommentRepliesPipe implements PipeTransform {

  transform(comments: ArtApprovalComment[], parentId: ID): ArtApprovalComment[] {
    return comments.filter((comment: ArtApprovalComment) => comment.parentCommentId === parentId);
  }

}
