import { Injectable } from '@angular/core';
import { createState, Store, withProps } from '@ngneat/elf';
import { isUndefined as _isUndefined } from 'lodash-es';
import { DesignRequest, DesignRequestQueryParam, DesignRequestStatus, IUser, Pagination } from '@graphics-flow/types';

export interface DesignRequestListState {
  activeFilters: DesignRequestQueryParam;
  filteredDesignRequests: DesignRequest[];
  filteredDesignRequestPagination?: Pagination;
  offset?: number;
  loading: boolean;
  activeDesignRequest?: DesignRequest;
  inProgress: boolean;
  assignedUsers: IUser[];
  downloadInProgress?: boolean;
}

export const designRequestBatchSize = 25;

export const initialDesignRequestListActiveFiltersState: DesignRequestQueryParam = {
  index: 0,
  limit: designRequestBatchSize,
  status: DesignRequestStatus.OPEN
};

export const initialDesignRequestState: DesignRequestListState = {
  activeFilters: initialDesignRequestListActiveFiltersState,
  filteredDesignRequests: [],
  loading: false,
  inProgress: false,
  assignedUsers: []
};

const { state, config } = createState(
  withProps<DesignRequestListState>(initialDesignRequestState)
);

@Injectable({ providedIn: 'root' })
export class DesignRequestListStore extends Store {

  constructor() {
    super({ name: 'design-request-list', state, config });
  }

  setDesignRequests(designRequests: DesignRequest[]) {
    this.update(state => ({
      ...state,
      filteredDesignRequests: designRequests,
      loading: false
    }));
  }

  appendDesignRequests(designRequests: DesignRequest[]) {
    this.update((state: DesignRequestListState) => ({
      ...state,
      filteredDesignRequests: [...state.filteredDesignRequests, ...designRequests],
      inProgress: false
    }));
  }

  setPagination(pagination: Pagination) {
    this.update(state => ({
      ...state,
      filteredDesignRequestPagination: pagination
    }));
  }

  setActiveFilters(filters: DesignRequestQueryParam, inProgress: boolean = false) {
    this.update((state) => {
      const activeFilters = {
        index: !_isUndefined(filters.index) ? filters.index : state.activeFilters.index,
        limit: !_isUndefined(filters.limit) ? filters.limit : state.activeFilters.limit,
        searchText: !_isUndefined(filters.searchText) ? filters.searchText : state.activeFilters.searchText,
        status: !_isUndefined(filters.status) ? filters.status : state.activeFilters.status,
        assigneeUserId: !_isUndefined(filters.assigneeUserId) ? filters.assigneeUserId : state.activeFilters.assigneeUserId,
        assigneeStatus: !_isUndefined(filters.assigneeStatus) ? filters.assigneeStatus : state.activeFilters.assigneeStatus
      };
      return {
        ...state,
        activeFilters,
        loading: activeFilters.index === 0,
        inProgress
      };
    });
  }

  updateDesignRequestDetails(activeDesignRequest: DesignRequest): void {
    this.update((state) => ({
      ...state,
      activeDesignRequest
    }));
  }

  updateDesignRequestInList(updatedDesignRequest: DesignRequest): void {
    this.update((state) => {
      state.filteredDesignRequests = state.filteredDesignRequests.map((filteredDesignRequest) => {
        if (filteredDesignRequest.designRequestId === updatedDesignRequest.designRequestId) {
          const designRequest: DesignRequest = Object.assign({}, filteredDesignRequest, updatedDesignRequest);
          return designRequest;
        }
        return filteredDesignRequest;
      });

      return {...state};
    });
  }

  removeDesignRequestFromList(removedDesignRequest: DesignRequest): void {
    this.update((state) => ({
      ...state,
      filteredDesignRequests: state.filteredDesignRequests.filter((filteredDesignRequest: DesignRequest) => filteredDesignRequest.designRequestId !== removedDesignRequest.designRequestId),
      filteredDesignRequestPagination: { ...state.filteredDesignRequestPagination, totalResults: state.filteredDesignRequestPagination.totalResults - 1 }
    }));
  }

  setDownloadingStatus(status: boolean): void {
    this.update((state) => ({
      ...state,
      downloadInProgress: status
    }));
  }

}
