import { WindowService } from 'shared/util';
import { Component, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Translations } from '@graphics-flow/shared/assets';
import { DecorationMethod, GenericRemovalDialogData, Organization } from '@graphics-flow/types';
import { OrganizationQuery, OrganizationService } from '@graphics-flow/util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep as _cloneDeep, sortBy as _sortBy, toLower as _toLower, omit as _omit } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { GenericDataRemovalDailogComponent } from 'shared/ui';

@UntilDestroy()
@Component({
  selector: 'manage-decoration-methods-modal',
  templateUrl: './manage-decoration-methods-modal.component.html',
  styleUrls: ['./manage-decoration-methods-modal.component.scss']
})
export class ManageDecorationMethodsComponent {
  _decorationMethodsCount: BehaviorSubject<number> = new BehaviorSubject(0);
  @ViewChild('newDecorationMethodForm') newDecorationMethodForm: NgForm;

  addNewDecorationMethod: boolean;
  decorationMethods: DecorationMethod[];
  decorationMethodsCount$: Observable<number> = this._decorationMethodsCount.asObservable();
  newDecorationMethod: string;
  organization: Organization;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Organization,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<ManageDecorationMethodsComponent>,
    public readonly organizationQuery: OrganizationQuery,
    public translations: Translations,
    private organizationService: OrganizationService,
    public readonly windowService: WindowService
  ) {
    this.organization = data;
    this.prepareDecorationMethodsList();
  }

  add() {
    if (this.newDecorationMethod) {
      this.decorationMethods.push({
        name: this.newDecorationMethod,
        backupValue: this.newDecorationMethod,
        isEdit: false,
        deleted: false
      });
      this.newDecorationMethod = '';
      this.hideCreateDecorationMethodForm();
      this.sort();
    }
    this.count();
    this.updateDecorationMethods();
  }

  edit(position: number) {
    this.resetEditOption();
    this.decorationMethods[position].isEdit = true;
  }

  save(position: number) {
    if (!this.decorationMethods[position].name) {
      return;
    }
    this.decorationMethods[position].isEdit = false;
    this.sort();
    this.updateDecorationMethods();
  }

  remove(position: number) {
    this.resetEditOption();
    this.matDialog.open(GenericDataRemovalDailogComponent, {
      autoFocus: false,
      data: <GenericRemovalDialogData>{
        header: this.translations.art.delete_decoration_method,
        body: this.translations.art.delete_decoration_method_warning,
        removalData: this.decorationMethods[position].name,
        note: this.translations.art.delete_decoration_method_warning_note,
        cancelText: this.translations.common.cancel,
        continueText: this.translations.art.delete_decoration_method,
      },
      panelClass: 'mobile-screen-modal'
    }).afterClosed().pipe(
      untilDestroyed(this),
      filter((confirmed: boolean) => confirmed),
      tap(() => {
        if (this.decorationMethods[position].decorationMethodId) {
          this.decorationMethods[position].deleted = true;
        } else {
          this.decorationMethods.splice(position, 1);
        }
        this.count();
        this.updateDecorationMethods();
      })
    ).subscribe();
  }

  close() {
    this.dialogRef.close();
  }

  cancelEdit(position: number) {
    this.decorationMethods[position].name = this.decorationMethods[position].backupValue;
    this.decorationMethods[position].isEdit = false;
  }

  showCreateDecorationMethodForm() {
    this.addNewDecorationMethod = true;
    setTimeout(() => {
      document.getElementById('newDecorationMethodInput')?.focus();
    }, 0);
  }

  hideCreateDecorationMethodForm() {
    this.newDecorationMethod = '';
    this.addNewDecorationMethod = false;
  }

  trackByDecorationMethodName(index: number, decorationMethod: DecorationMethod) {
    return decorationMethod.name;
  }

  sort() {
    this.decorationMethods = _sortBy(this.decorationMethods, (dm: DecorationMethod) => _toLower(dm.name));
  }

  count() {
    this._decorationMethodsCount.next(this.decorationMethods.filter(dm => !dm.deleted)?.length);
  }

  resetEditOption(): void {
    this.decorationMethods.forEach((dm: DecorationMethod) => {
      dm.isEdit = false;
      dm.name = dm.backupValue;
    });
  }

  prepareDecorationMethodsList() {
    this.decorationMethods = [];
    if (this.organization?.decorationMethods.length > 0) {
      this.decorationMethods = this.organization.decorationMethods;
      this.sort();
      this.decorationMethods.forEach(dm => {
        if (!dm.deleted) {
          dm.isEdit = false;
          dm.backupValue = dm.name;
        }
      });
    }

    this.count();
  }

  updateDecorationMethods() {
    const org: Organization = this.organization;
    org.decorationMethods = this.decorationMethods.map(dm => _omit(dm, ['isEdit', 'backupValue']));
    this.organizationService.updateOrganization(org).subscribe((org) => {
      this.organization = _cloneDeep(org);
      this.prepareDecorationMethodsList();
    });
  }

}
