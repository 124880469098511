import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Translations } from '@graphics-flow/shared/assets';
import { Art, Tag } from '@graphics-flow/types';
import { TagQuery } from '@graphics-flow/util';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startsWith as _startsWith, includes as _includes } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';

interface NewTag {
  newTag: string;
}

@UntilDestroy()
@Component({
  selector: 'gf-manage-art-tag',
  templateUrl: './manage-art-tag.component.html',
  styleUrls: ['./manage-art-tag.component.scss']
})
export class ManageArtTagComponent implements OnInit {

  newTagFromGroup: FormGroup<ControlsOf<NewTag>>;
  filteredOptions: Observable<Tag[]>;
  hasTagAlreadyExists: boolean;
  @Input() tags: string[];
  @Input() allowFilterAction: boolean;
  @Input() art: Art;
  @Output() addTag: EventEmitter<string>= new EventEmitter<string>();
  @Output() createTag: EventEmitter<string>= new EventEmitter<string>();
  @Output() removeTag: EventEmitter<string>= new EventEmitter<string>();

  constructor(
    public translations: Translations,
    private tagQuery: TagQuery,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
    this.newTagFromGroup = new FormGroup<ControlsOf<NewTag>>({
      newTag: new FormControl('')
    });

    this.filteredOptions = this.newTagFromGroup.controls.newTag.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        debounceTime(200),
        switchMap((value: string) => this.filterTag(value.toLowerCase().trim())),
      );
  }

  filterTag(value: string): Observable<Tag[]> {
    // If filter value is empty we will return empty array;
    if (!value.length) {
      this.hasTagAlreadyExists = false;
      return of([]);
    }

    const existingTags: string[] = this.tags.map((tag: string) => tag.toLowerCase());
    return this.tagQuery.tags$
      .pipe(
        map((tags: Tag[]) => {
          this.hasTagAlreadyExists = tags.some((tag: Tag) => tag.canonicalName === value || _includes(existingTags, value));
          return tags.filter((tag: Tag) => _startsWith(tag.canonicalName, value) && !_includes(existingTags, tag.canonicalName));
        })
      );
  }

  addTagToArt(tag: string): void {
    this.addTag.emit(tag);
    this.reset();
  }

  removeTagFromArt(tag: string): void {
    this.removeTag.emit(tag);
    
    if (this.newTagFromGroup.controls.newTag.value) {
      // While remove a tage, if tag input has a value then we have to refresh the autocomplete tags list
      const current = this.newTagFromGroup.value;
      this.newTagFromGroup.reset();
      this.newTagFromGroup.patchValue(current);
    }
  }

  createAndAddTag(tag: string): void {
    this.createTag.emit(tag.trim());
    this.reset();
  }

  reset(): void {
    this.newTagFromGroup.controls.newTag.setValue('');
  }

  applyFilter(tagName: string): void {
    if (!this.allowFilterAction) return;
    this.tagQuery.getTagByCanonicalName$(tagName).pipe(
      take(1)
    ).subscribe((tag: Tag) => {
      const routeUrl: string = !(this.art?.folderId) ? `/my` : `/my/${this.art?.folderId}`;
      this.router.navigate([routeUrl], {
        queryParams: {
          filterTags: tag.tagId
        }
      });
    });
  }

}
