import { Component, Input } from '@angular/core';

import { ID, ImagePreviewType } from '@graphics-flow/types';
import { UserProfilePicturePipe } from './../../pipes/user-profile-picture.pipe';


@Component({
  selector: 'gf-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})

export class UserAvatarComponent {

  @Input() userId: ID;
  @Input() showName = false;
  @Input() size: ImagePreviewType; // Image quality
  @Input() imageUrl: string;
  @Input() profilePicSize: 'xsmall' | 'small' | 'thumbnail' | 'medium' | 'default' = 'default'; // img tag resolution


  constructor(public userProfilePicturePipe: UserProfilePicturePipe
  ) {
  }

}
