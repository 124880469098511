import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AccountSubscriptionHelper, UpdateSubscriptionPlanService, UserQuery } from '@graphics-flow/util';
import { StripeSubscriptionStatus } from '@graphics-flow/types';

@Injectable({
  providedIn: 'root'
})
export class UpdateSubscriptionPlanResolverService  {

  constructor(private readonly updateSubscriptionPlanService: UpdateSubscriptionPlanService,
    private readonly userQuery: UserQuery,
    private readonly router: Router) {
  }

  resolve(): boolean {
    const IsSubscriptionCancelled = this.userQuery.getUser().subscriptionStatus === StripeSubscriptionStatus.CANCELLED;
    if (AccountSubscriptionHelper.isSubscriptionIncomplete(this.userQuery.getUser().subscriptionStatus)) {
      this.router.navigateByUrl('/payment-setup');
      return false;
    }

    if ((IsSubscriptionCancelled || this.updateSubscriptionPlanService.isReactivationInProgress.getValue())
      && this.updateSubscriptionPlanService.selectedPlanForm?.value?.selectedPlan === null) {
      this.router.navigateByUrl('/account-reactivation');
      return false;
    }
    return true;
  }
}
