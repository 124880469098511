<div id="collectionFilterDialogContent">
  <div class="position-relative">
    <h1 mat-dialog-title class="text-truncate">{{translations.stock.collection | translate}}</h1>
  </div>
  <mat-dialog-content>
    <gf-stock-art-list-filter #stockArtStyleFilter [canShowClipArtMobileViewFilter]="data.canShowClipArtMobileViewFilter" filterProperty="artLibraries" [label]="translations.stock.collection | translate"></gf-stock-art-list-filter>
  </mat-dialog-content>
  <mat-dialog-actions class="align-right">
    <button id="cancelBtn"
      mat-raised-button
      class="large"
      color="secondary"
      mat-dialog-close>
      {{translations.common.cancel | translate}}
    </button>
    <button id="doneBtn"
      mat-raised-button
      class="large"
      color="primary"
      mat-dialog-close
      (click)="stockArtStyleFilter?.onMobileSelectionChange()">
      {{translations.common.done | translate}}
    </button>
  </mat-dialog-actions>
</div>
