import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { WindowService } from 'shared/util';
import { take } from 'rxjs/operators';
import { ImagePreviewType } from '@graphics-flow/types';

@Component({
  selector: 'gf-detail-view-container',
  templateUrl: './detail-view-container.component.html',
  styleUrls: ['./detail-view-container.component.scss']
})
export class DetailViewContainerComponent {

  @ViewChild('gfDetailViewDrawer') matSideNav: MatSidenav;
  imagePreviewType: typeof ImagePreviewType = ImagePreviewType;

  constructor(
    public windowService: WindowService
  ) {
  }

  swipeLeft() {
    if (this.matSideNav.opened) {
      this.windowService.smallDown$.pipe(take(1)).subscribe((smallDown) => {
        if (smallDown) {
          this.matSideNav.toggle();
        }
      });
    }
  }
}
