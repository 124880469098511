import { Component, Input } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Translations } from '@graphics-flow/shared/assets';
import { IRoles, UsageLevel } from '@graphics-flow/types';
import { UserQuery } from '@graphics-flow/util';

@Component({
  selector: 'usage-warning-status',
  templateUrl: './usage-warning-status.component.html',
  styleUrls: ['./usage-warning-status.component.scss']
})
export class UsageWarningStatusComponent {

  @Input() type: UsageLevel;
  @Input() header: string;
  @Input() subHeader: string;
  @Input() resetInfo: string;
  @Input() updateButtonName: string;
  @Input() usage: string;
  @Input() isScrolledFromTop: boolean;

  usageLevel: typeof UsageLevel = UsageLevel;

  disableUpdateBtn$: Observable<boolean> = this.userQuery.userRole$.pipe(
    map((role: IRoles) => role === IRoles.User || role === IRoles.Support)
  );

  constructor(
    public readonly translations: Translations,
    public readonly userQuery: UserQuery
  ) { }

}
