import { Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ApiError, ArtApproval, NotificationData, NotificationType, ApprovalItemAction, Organization, ID } from '@graphics-flow/types';
import { filter, switchMap, tap, finalize } from 'rxjs/operators';
import { Translations } from '@graphics-flow/shared/assets';
import {
  SelectArtDialogComponent,
  SelectArtDialogData
} from '../components/select-art-dialog/select-art-dialog.component';
import { NotificationComponent, NotificationService, LoadingService } from 'shared/ui';
import { MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DeleteApprovalDialogComponent, DeleteApprovalDialogData } from '../components/delete-approval-dialog/delete-approval-dialog.component';
import { FinalApprovalDialogComponent } from '../components/final-approval-dialog/final-approval-dialog.component';
import { ApprovalService, GlobalHelpers } from '@graphics-flow/util';
import { AddCollaboratorToApprovalDialogComponent } from '../components/add-collaborator-to-approval-dialog/add-collaborator-to-approval-dialog.component';
import { ApprovalItemConfirmationDialogComponent } from '../components/approval-item-confirmation-dialog/approval-item-confirmation-dialog.component';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ApprovalActionsService {

  isApprovalStatusInprogress = false;
  approvalId: ID;
  snackBarRef: MatSnackBarRef<NotificationComponent>;
  snackBarConfig: MatSnackBarConfig = {
    horizontalPosition: 'start',
    verticalPosition: 'bottom',
  };
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly approvalService: ApprovalService,
    private notificationService: NotificationService,
    private ngZone: NgZone,
    private readonly dialog: MatDialog,
    private translations: Translations,
    private translationService: TranslateService,
    private readonly loadingService: LoadingService
  ) { }

  openDeleteApprovalDialog(item: ArtApproval, loadingId?: string): Observable<ArtApproval> {
    return this.dialog.open(DeleteApprovalDialogComponent, <MatDialogConfig>{
      data: <DeleteApprovalDialogData>{
        approval: item
      },
      panelClass: 'mobile-screen-modal'
    }).afterClosed().pipe(
      filter(confirmed => !!confirmed),
      tap(() => loadingId && this.loadingService.showLoader(loadingId)),
      switchMap(() => this.approvalService.deleteArtApproval(item)),
      finalize(() => loadingId && this.loadingService.hideLoader(loadingId))
    );
  }

  openAddArtToApprovalDialog(approvalId: ID, startingFolderId: ID = null): void {
    const loadingId = 'ADD_MY_ART_TO_APPROVAL';
    this.dialog.open(SelectArtDialogComponent, <MatDialogConfig>{
      data: <SelectArtDialogData>{
        parentFolderId: startingFolderId,
        selectMultiple: true,
        checkFileFormat: false
      },
      panelClass: 'mobile-screen-modal',
      autoFocus: false
    }).afterClosed().pipe(
      filter((arts: ID[]) => !!arts?.length),
      tap(() => this.loadingService.showLoader(loadingId)),
      switchMap((artIds: ID[]) => {
        return this.approvalService.addArtToApproval(artIds, approvalId);
      }),
      finalize(() => this.loadingService.hideLoader(loadingId))
    ).subscribe();
  }

  openAddCollaboratorDialog(approvalId: ID): MatDialogRef<AddCollaboratorToApprovalDialogComponent> {
    return this.dialog.open(AddCollaboratorToApprovalDialogComponent, {
      data: {
        approvalId: approvalId
      },
      panelClass: 'mobile-screen-modal',
      autoFocus: false
    });
  }

  uploadFilesToApproval(files: File[], approvalId: ID): void {
    if (!files?.length) {
      return;
    }

    if (!GlobalHelpers.isFileLessThan50MB(files[0])) {
      this.notificationService.showFileNotification(NotificationType.ERROR, this.translationService.instant(this.translations.fileupload.file_size_exceeded), files[0].name);
      return;
    }

    for (const file of files) {
      this.snackBarConfig.data = files[0];
      this.loading$.next(true);
      this.snackBarRef = this.notificationService.showFileNotification(NotificationType.INPROGRESS,
        this.translationService.instant(this.translations.art.uploading),
        files[0].name);
      const subscription = this.approvalService.uploadFileToApproval(file, approvalId).subscribe(() => {
        this.snackBarRef.instance.updateNotification(<NotificationData>{
          type: NotificationType.SUCCESS,
          title: this.translationService.instant(this.translations.art.upload_complete)
        });
        this.closeNotification();
      }, (err: ApiError) => {
        let errorMessage: string = err.message;
        if (err.status === 413) {
          errorMessage = this.translationService.instant(this.translations.fileupload.file_size_exceeded);
        }
        if (err.status === 0 && !navigator.onLine) {
          errorMessage = this.translationService.instant(this.translations.common.no_internet_connection);
        }
        this.snackBarRef.instance.updateNotification(<NotificationData>{
          type: NotificationType.ERROR,
          title: this.translationService.instant(this.translations.art.upload_failed),
          description: errorMessage
        });
        this.closeNotification();
      });

      this.snackBarRef.afterDismissed().subscribe(() => {
        this.loading$.next(false);
        subscription?.unsubscribe();
      });
    }
  }

  closeNotification() {
    this.loading$.next(false);
    this.ngZone.run(() => {
      setTimeout(() => {
        this.snackBarRef.dismissWithAction();
      }, 3000);
    });
  }

  openFinalApprovalDialog(organization?: Organization, approvalName?: string): MatDialogRef<FinalApprovalDialogComponent> {
    return this.dialog.open(FinalApprovalDialogComponent, {
      panelClass: 'mobile-screen-modal',
      data: {
        organization,
        approvalName
      }
    });
  }

  openApprovalItemConfirmationDialog(actionItem: ApprovalItemAction): Observable<ArtApproval> {
    return this.dialog.open(ApprovalItemConfirmationDialogComponent, <MatDialogConfig>{
      data: actionItem,
      panelClass: 'mobile-screen-modal',
      autoFocus: false
    }).afterClosed();
  }

}
