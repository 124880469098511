import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Translations } from '@graphics-flow/shared/assets';
import { Art } from '@graphics-flow/types';

@Component({
  selector: 'gf-style-filter-dialog',
  templateUrl: './style-filter-dialog.component.html',
  styleUrls: ['./style-filter-dialog.component.scss']
})
export class StyleFilterDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Art,
    public matDialogRef: MatDialogRef<StyleFilterDialogComponent>,
    public translations: Translations
  ) {
  }

}
