import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Translations } from '@graphics-flow/shared/assets';
import { corelSessionExpiredCode, downloadLimitExceedErrorCode, storageLimitExceedErrorCode, unauthorizedErrorCodes } from '../constants/errors.constants';
import { GraphicsFlowService } from './graphics-flow.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  constructor(
    private graphicsFlowService: GraphicsFlowService,
    private zone: NgZone,
    private translations: Translations,
    private translateService: TranslateService
  ) {
  }

  handleError(error: any) {
    let message = '';
    let title: string;
    if (unauthorizedErrorCodes.includes(error?.status)) {
      // UNAUTHORIZED! BOOT THEM!
      this.graphicsFlowService.deauthenticate();
    } else if (downloadLimitExceedErrorCode === error.errorCode) {
      // Used to show the download limit exceed modal, while downloading
      this.graphicsFlowService.openDownloadLimitExceedModal();
      return;
    } else if (storageLimitExceedErrorCode === error.errorCode) {
      // Used to show the storage limit exceed modal, while uploading
      this.graphicsFlowService.openStorageLimitExceedModal();
      return;
    } else if (corelSessionExpiredCode === error.errorCode) {
      // TODO:- show modal about session expiry
      title = this.translateService.instant(this.translations.common.session_expired);
    } else if (error instanceof TypeError) {
      // OOPS! Don't tell the user, but log to the console
      console.log('TypeError', error);
      return;
    }

    // SHOTGUN APPROACH!!! YEEHAW, MOTHERFUCKERS!!!
    message = error?.error?.errorMessages?.map(e => e.content)?.join('\n')
      || error?.message
      || error?.error
      || error;

    // No internet connection
    if (!navigator.onLine) {
      message = this.translateService.instant(this.translations.common.no_internet_connection);
    }

    try {
      this.zone.run(() => {
        this.graphicsFlowService.showError(message, title);
      });
    } catch (e) {
      console.log('UNFAMILIAR ERROR: ' + message);
    }

    console.log(error);
  }
}
