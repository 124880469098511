import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ApiResponse, ArtApproval, ArtApprovalOrder, ArtApprovalRequest, ArtApprovalStatus, ID } from '@graphics-flow/types';
import { FormControl } from '@ngneat/reactive-forms';
import { Translations } from '@graphics-flow/shared/assets';
import { ArtApprovalHttpService } from '@graphics-flow/api';

export enum AddArtToApprovalType {
  NEW = 'NEW'
}

@Component({
  selector: 'gf-add-art-to-approval-dialog',
  templateUrl: './add-art-to-approval-dialog.component.html',
  styleUrls: ['./add-art-to-approval-dialog.component.scss']
})
export class AddArtToApprovalDialogComponent implements OnDestroy {
  approvalId: ID;
  selectedApproval: ArtApproval;
  approvals: ArtApproval[];
  searchControl: FormControl<string> = new FormControl<string>('');
  destroyed$ = new Subject();
  loading = false;
  showCreateNewArtApproval: boolean;
  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  constructor(
    private matDialogRef: MatDialogRef<AddArtToApprovalDialogComponent>,
    public translations: Translations,
    private readonly approvalHttpService: ArtApprovalHttpService,
    @Inject(MAT_DIALOG_DATA) public data: boolean,
  ) {
    this.showCreateNewArtApproval = !!data;
    this.searchControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(500),
      tap((search: string) => this.searchText$.next(search))
    ).subscribe();

    this.searchText$.pipe(
      takeUntil(this.destroyed$),
      switchMap((search: string) => {
        this.loading = true;
        const initialState: ArtApprovalRequest =  {
          index: 0,
          limit: 10,
          orderBy: ArtApprovalOrder.ModifiedDate,
          orderDesc: true,
          searchText: search ?? '',
          status: ArtApprovalStatus.OPEN
        };
        return this.approvalHttpService.getArtApprovalSummary(initialState);
      }),
      catchError(err => {
        this.loading = false;
        return throwError(err);
      })
    ).subscribe((response: ApiResponse<ArtApproval[]>) => {
      this.loading = false;
      this.approvals = response.data;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.unsubscribe();
  }

  selectApproval(artApproval: ArtApproval) {
    this.selectedApproval = artApproval;
  }

  submit() {
    this.matDialogRef.close(this.selectedApproval);
  }

  close() {
    this.matDialogRef.close();
  }

  createNewArtApproval() {
    this.matDialogRef.close(AddArtToApprovalType.NEW);
  }

}
