import { Injectable } from '@angular/core';
import { getActiveEntity, selectActiveEntity } from '@ngneat/elf-entities';

import { IRoles, StripeSubscriptionStatus, User, UserSettings } from '@graphics-flow/types';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AccountSubscriptionHelper } from '../../helpers/account-subscription.helper';
import { UserHelper } from '../../helpers/user.helper';
import { UserStore } from './user.state';

@Injectable({
  providedIn: 'root'
})
export class UserQuery {
  user$: Observable<User> = this.selectActiveUserEntity();
  userSettings$: Observable<UserSettings> = this.selectActiveUserEntity().pipe(
    map((user: User) => {
      return user?.userSettings;
    })
  );
  isSignedIn$: Observable<boolean> = this.user$.pipe(map((user) => !!user));

  userRole$: Observable<IRoles> = this.user$.pipe(map((user) => UserHelper.getUserRole(user)));

  isAdmin$: Observable<boolean> = this.userRole$.pipe(map((role: IRoles) => role === IRoles.Admin));

  isUser$: Observable<boolean> = this.userRole$.pipe(map((role: IRoles) => role === IRoles.User));

  isOwner$: Observable<boolean> = this.userRole$.pipe(map((role: IRoles) => role === IRoles.Owner));

  isSupport$: Observable<boolean> = this.userRole$.pipe(map((role: IRoles) => role === IRoles.Support));

  isFirstLogin$: Observable<boolean> = this.user$.pipe(map((user: User) => user?.isFirstLogin));

  isSubscriptionStatusActive$: Observable<boolean> = this.user$.pipe(
    filter((user: User) => !!user),
    map((user: User) => AccountSubscriptionHelper.isSubscriptionActive(user.subscriptionStatus))
  );

  isSubscriptionStatusUnpaid$: Observable<boolean> = this.user$.pipe(
    filter((user: User) => !!user),
    map((user: User) => user?.subscriptionStatus === StripeSubscriptionStatus.UNPAID)
  );

  constructor(protected store: UserStore) {
  }

  selectActiveUserEntity() {
    return this.store.pipe(selectActiveEntity());
  }

  getActiveUserEntity(): User {
    return this.store.query(getActiveEntity());
  }

  getUser(): User {
    return this.getActiveUserEntity();
  }

  getUserSettings(): UserSettings {
    return this.getActiveUserEntity()?.userSettings ?? null;
  }

  getUserRole(): IRoles {
    return UserHelper.getUserRole(this.getActiveUserEntity());
  }

  getActiveUserToken(): string {
    return this.getActiveUserEntity()?.token;
  }
}
