import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';

import { ID, IRoles } from '@graphics-flow/types';
import { TeamQuery } from '@graphics-flow/util';

@Pipe({
  name: 'isOwner'
})
export class IsOwnerPipe implements PipeTransform {

  constructor(
    private readonly teamQuery: TeamQuery
  ) { }

  transform(userId: ID): Observable<boolean> {
    return this.teamQuery.getUserCurrentOrganizationRole(userId)
      .pipe(
        filter((role: IRoles) => role !== undefined && role !== null),
        map((role: IRoles) => role === IRoles.Owner)
      );
  }

}
