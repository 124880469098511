import { BasePrice, OrganizationResourceUsage, StripeSubscriptionStatus, AdditionalPrice, SubscriptionItem } from '@graphics-flow/types';

export class AccountSubscriptionHelper {
  static isSubscriptionActive(status: StripeSubscriptionStatus) {
    return status === StripeSubscriptionStatus.ACTIVE || status === StripeSubscriptionStatus.TRIALING;
  }

  static IsStockArtPlanDowngraded(resourceUsage: OrganizationResourceUsage): boolean {
    if (!resourceUsage) {
      return;
    }
    const currentDownloadLimit: number = resourceUsage.organizationBilling.subscription.items.find((item: SubscriptionItem) => item.price.productName === AdditionalPrice.STOCK_ART)?.price.quantity
    const nextBillingDownloadLimit: number = resourceUsage.organizationBilling?.scheduledSubscriptionChange?.endingStockArtAddOnQuantity;

    return currentDownloadLimit > nextBillingDownloadLimit;
  }

  static buildBundlePlans(basePlanPrice: BasePrice[], bundlePlan: string): BasePrice {
    return basePlanPrice?.find((addOns: BasePrice) => addOns.productMetadata?.plan_name === bundlePlan);
  }

  static isSubscriptionIncomplete(status: StripeSubscriptionStatus): boolean {
    // Don't Panic!!! Because BE finalised and considering these statuses as "incomplete".
    // So we good to go...
    return status === StripeSubscriptionStatus.INCOMPLETE || status === StripeSubscriptionStatus.INCOMPLETE_EXPIRED;
  }

  static isSubscriptionNeedPaymentSetup(status: StripeSubscriptionStatus): boolean {
    // Expect Active, Trailling, & Cancelled, For remaining statuses, We need to navigate the customer to `payment-setup` route.
    return !(this.isSubscriptionActive(status) || status === StripeSubscriptionStatus.CANCELLED);
  }
}
