import { Pipe, PipeTransform } from '@angular/core';
import { ID, User } from '@graphics-flow/types';
import { sortBy as _sortBy, compact as _compact, isNull as _isNull } from 'lodash-es';

@Pipe({
  name: 'getTeamsInfo'
})
export class GetTeamsInfoPipe implements PipeTransform {

  transform(users: User[], assignedUser: ID): User[] | ID[] {
    users = _sortBy(users, user => (user?.firstName || '').toLowerCase() + (user?.lastName || '').toLowerCase());
    // this scope allow us to show the deletedUser for dropdown inactive scenario.
    // otherwise just return userIds.
    // (isNull) - used to handle the unassigned scearnio for dropdown.
    if (assignedUser || _isNull(assignedUser)) {
      return _compact(users.map(user => {
        if (user.deleted && assignedUser !== user.userId) {
          return;
        }
        return user;
      }));
    }

    return users.map(user => user.userId)
  }
}
