export enum StockArtOrder {
  Name = 0,
  Deleted = 1,
  CreatedDate = 2,
  StockArtType = 3,
  Collection = 4,
  Category = 5,
  Subcategory = 6,
  Author = 7,
  UploadType = 8,
  ColorCount = 9,
  ColorMode = 10,
  FolderId = 11
}
