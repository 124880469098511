import { AfterContentInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[autofocus]'
})
export class AutofocusDirective implements AfterContentInit {
  constructor(private element: ElementRef) {}

  ngAfterContentInit() {
    setTimeout(() => {
      this.element.nativeElement.focus();
    }, 100);
  }
}
