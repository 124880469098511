import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[text]',
})
export class TextDirective {
  @Output() newValue = new EventEmitter<any>();
  @Input() text: any;
  @Input() rg: number;

  @HostListener('input', ['$event.target.value']) changeInput(value: string) {
    if (this.rg === undefined) {
      this.newValue.emit(value);
    } else {
      const numeric = parseFloat(value);
      if (!isNaN(numeric) && numeric >= 0 && numeric <= this.rg) {
        this.newValue.emit({ v: numeric, rg: this.rg });
      }
    }
  }
}
