<div id="downloadLimitExceedDialog" class="grid-y">
  <mat-dialog-content>
    <div class="download-limit-exceed">
      <mat-icon fontIcon="info_outline"></mat-icon>
      <p id="modalLabel">{{ translations.organization.download_limit_exceed | translate }}</p>
    </div>
    <mat-divider class="m-y-4"></mat-divider>
    <div>
      <p id="usageInfo" class="t-18-400-s"><span class="font-bold">{{ (billingPlansQuery.userSubscription$ | async).totalStockArtDownloadLimit }}</span>{{ translations.common.of | translate }}<span>{{ (billingPlansQuery.userSubscription$ | async)?.totalStockArtDownloadLimit }}</span> {{ translations.organization.downloads_used | translate }}</p>
      <mat-progress-bar mode="determinate" value="100" color="warn" class="progress-bar-large"></mat-progress-bar>
      <p id="limitExceededMsg" class="m-t-2 m-b-0 t-16-400-s">
        {{ translations.organization.download_limit_exceed_message | translate: { date: (billingPlansQuery.userSubscription$ | async)?.currentPeriodEnd | date: 'MMM d' } }}
      </p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-actions align-right">
    <button id="cancelButton"
      mat-raised-button
      color="secondary"
      mat-dialog-close>
      {{ translations.common.cancel | translate }}
    </button>
    <div #tooltip="matTooltip"
      class="btn-margin"
      [matTooltip]="translations.common.you_do_not_have_permission_to_make_plan_changes | translate"
      [matTooltipPosition]="'above'"
      [matTooltipDisabled]="!((userQuery.isUser$ | async) || (userQuery.isSupport$ | async))"
      (click)="tooltip.toggle()">
      <button id="addDownloadsBtn"
        mat-raised-button
        color="primary"
        mat-dialog-close
        [disabled]="(userQuery.isUser$ | async) || (userQuery.isSupport$ | async)"
        [routerLink]="['/plans-and-billing/update-subscription']">
        {{ translations.stock.usage.add_downloads | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</div>
