import { Component, Input, NgZone, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { Art, Files, Folder, ID, NotificationType } from '@graphics-flow/types';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Observable } from 'rxjs';
import { ArtService, MyArtUploaderService, FolderService, GlobalHelpers, MyArtBulkSelectionService } from '@graphics-flow/util';
import { DropTarget, DropTargetMonitor, DndService } from '@ng-dnd/core';
import { ArtActionsService } from '../../services/art-actions.service';
import { WindowService } from 'shared/util';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@graphics-flow/shared/assets';
import { NotificationService } from 'shared/ui';

@UntilDestroy()
@Component({
  selector: 'gf-folder-drop',
  templateUrl: './folder-drop.component.html',
  styleUrls: ['./folder-drop.component.scss']
})
export class FolderDropComponent implements OnInit, OnDestroy {
  @Input() parentFolderId: ID;
  @Input() showDropIndicator = true;
  @Input() isRootFolder: boolean;
  @Input() allowDrop = true;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onDragging: EventEmitter<boolean> = new  EventEmitter<boolean>();

  folderDropTarget: DropTarget<Files | Art | Folder | DataTransfer>;
  hovering$: Observable<boolean>;
  supportedFileTypes: string = GlobalHelpers.SUPPORTED_MY_ART_FILE_EXTENSIONS.toString();
  isDesktop: boolean;

  constructor(
    private artActionsService: ArtActionsService,
    private readonly artService: ArtService,
    private readonly dnd: DndService,
    private readonly folderService: FolderService,
    private myArtUploaderService: MyArtUploaderService,
    private windowService: WindowService,
    private ngZone: NgZone,
    private myArtBulkSelectionService: MyArtBulkSelectionService,
    private translateService: TranslateService,
    private translations: Translations,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.windowService.largeUp$.pipe(
      untilDestroyed(this),
      tap((isDesktop: boolean) => {
        this.isDesktop = isDesktop
      })
    ).subscribe();
    this.folderDropTarget = this.dnd.dropTarget<Files | Art | Folder | DataTransfer>([NativeTypes.FILE, 'ART', 'FOLDER'], {
      drop: (monitor: DropTargetMonitor<Files | Art | Folder | DataTransfer>) => {
        if (!this.allowDrop) {
          return;
        }
        switch (monitor.getItemType()) {
          case NativeTypes.FILE:
            const files = (monitor.getItem() as Files).files;
            const items = (monitor.getItem() as DataTransfer);
            if (this.isDesktop) {
              this.myArtUploaderService.processDragAndDrop(items, this.parentFolderId);
            } else {
              // mobile, desktop screen
              this.artActionsService.uploadArt(files, this.parentFolderId);
            }
            this.ngZone.run(() => {/**/});
            break;
          case 'ART':
            const art: Art = <Art>monitor.getItem();
            if ((art?.folderId || null) !== this.parentFolderId) {
              this.artService.moveArt(
                [art],
                this.parentFolderId
              ).pipe(
                tap(() =>this.myArtBulkSelectionService.removeArt(art.artId))
              ).subscribe(() => {
                  this.ngZone.run(() => {
                    const notificationMessage = this.translateService.instant(this.translations.common.files_moved_successfully, {x: this.translateService.instant(this.translations.common.file)});
                    this.notificationService.showFileNotification(NotificationType.SUCCESS, notificationMessage, '');
                  });
              });
            }
            break;
          case 'FOLDER':
            const folder = monitor.getItem() as Folder;
            if (folder.folderId !== this.parentFolderId && folder?.parentId !== this.parentFolderId) {
              this.folderService.moveFolder(folder.folderId, this.parentFolderId).pipe(
                tap(() =>this.myArtBulkSelectionService.removeFolder(folder.folderId))
              ).subscribe(() => {
                  this.ngZone.run(() => {
                    const notificationMessage = this.translateService.instant(this.translations.common.files_moved_successfully, {x: this.translateService.instant(this.translations.common.folder)});
                    this.notificationService.showFileNotification(NotificationType.SUCCESS, notificationMessage, '');
                  });
              });
            }
            break;
        }
      },
      canDrop: (monitor: DropTargetMonitor<Files | Art | Folder>): boolean => {
        return monitor.isOver({ shallow: true });
      }
    });

    this.hovering$ = this.folderDropTarget.listen(m => {
      this.onDragging.emit(m.isOver());
      return m.isOver() && m.canDrop();
    });
  }

  ngOnDestroy() {
    this.folderDropTarget.unsubscribe();
  }
}
