import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { getRegistry } from '@ngneat/elf';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, combineLatest, of, zip } from 'rxjs';
import { catchError, delay, finalize, map, switchMap, tap } from 'rxjs/operators';

import { ApiService, OrganizationHttpService } from '@graphics-flow/api';
import { BundlePlanType, CreateUserModel, EnvironmentType, ID, IRoles, LoginResult, NotificationType, Organization, Plan, SelectedPlanInfo, StripeCheckoutSessionItem, StripeSessionSubscriptionCheckout, User } from '@graphics-flow/types';

import { EnvService } from '@graphics-flow/api';
import { Translations } from '@graphics-flow/shared/assets';
import { LoadingService, NotificationService } from 'shared/ui';
import { DownloadLimitExceedDialogComponent } from '../components/download-limit-exceed-dialog/download-limit-exceed-dialog.component';
import { StorageLimitExceedDialogComponent } from '../components/storage-limit-exceed-dialog/storage-limit-exceed-dialog.component';
import { unauthorizedErrorCodes } from '../constants/errors.constants';
import { recaptchaSiteKey, testingRecaptchaSiteKey } from '../constants/recaptcha.constants';
import { OrganizationQuery } from '../data/organization/organization.query';
import { OrganizationService } from '../data/organization/organization.service';
import { TagService } from '../data/tag/tag.service';
import { UserQuery } from '../data/user/user.query';
import { UserService } from '../data/user/user.service';
import { AccountSubscriptionHelper } from '../helpers/account-subscription.helper';
import { UserHelper } from '../helpers/user.helper';
import { BillingPlansQuery } from './../data/billing-plans/billing-plans.query';
import { BillingPlansService } from './../data/billing-plans/billing-plans.service';
import { TeamService } from './../data/team/team.service';

declare let Stripe: any;

export interface GfCookie {
  token: string;
  collaboratorId: string;
  collaboratorLinkId: string;
  orgUri: string;
  userId: string;
  orgId: string;
}

@Injectable({
  providedIn: 'root'
})
export class GraphicsFlowService {
  private readonly COOKIE_NAME: string = 'jiraffeXfloe';
  private readonly pageNotFoundErrorCodes: string[] = [
    '1-4NyiEh', // GetArtApprovalFromSharableLink's errorCode for collaborator removal scenario.
    '1-IIYmkq', // GetArtApprovalFromSharableLink's errorCode for approval deleted scenario.
    '1-zNJkNB' // GetArt API throws an error code, when we try to open the deleted art detail view.
  ];
  // Used to hold the user from reload, tab close or browser close, When Bulk Action is In-Progress...
  public isBulkActionInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isDeleteJobInProgress = false;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private ngZone: NgZone,
    private readonly apiService: ApiService,
    private readonly billingPlansQuery: BillingPlansQuery,
    private readonly envService: EnvService,
    private readonly billingPlansService: BillingPlansService,
    private readonly cookieService: CookieService,
    private readonly dialog: MatDialog,
    private readonly loadingService: LoadingService,
    private readonly location: Location,
    private readonly notificationService: NotificationService,
    private readonly organizationQuery: OrganizationQuery,
    private readonly organizationHttpService: OrganizationHttpService,
    private readonly organizationService: OrganizationService,
    private readonly router: Router,
    private readonly teamService: TeamService,
    private readonly translations: Translations,
    private readonly userQuery: UserQuery,
    private readonly userService: UserService,
    private readonly translateService: TranslateService,
    private tagSerive: TagService
  ) {
    //akitaConfig({ resettable: true })
  }

  initialize(): Promise<boolean> {
    const cookie: string = this.cookieService.get(this.COOKIE_NAME);
    this.envService.init();
    if (cookie) {
      const cookieData: GfCookie = JSON.parse(cookie);
      if (cookieData) {
        this.apiService.setToken(cookieData.token);
        this.apiService.setOrgUri(cookieData.orgUri);
        return this.loadData(cookieData.userId).toPromise();
      }
    }
    return of(false).toPromise();
  }

  authenticate(email: string, password: string): Observable<boolean> {
    return this.userService.authenticate(email, password).pipe(
      map((loginResult: LoginResult): User => {
        const user: User = loginResult.loggedInUser;
        const org: Organization = this.getOrgFromLoginResult(loginResult);
        this.userService.updateUserInStore(user);
        this.userService.setActive(user.userId);

        this.apiService.setToken(user.token);
        this.apiService.setOrgUri(org.uri);

        this.writeCookie({
          token: user.token,
          userId: user.userId.toString(),
          orgId: org.organizationId.toString(),
          orgUri: org.uri,
          collaboratorId: '',
          collaboratorLinkId: ''
        });
        return user;
      }),
      switchMap((user: User) => {
        return this.loadData(user.userId);
      })
    );
  }

  deauthenticate(): void {
    //TODO: need to be removed after elf migration
    this.writeCookie(null);
    this.apiService.reset();
    this.resetStores();
    this.router.navigateByUrl('/signin');
  }

  serviceUnavailablePage(): void {
    this.router.navigateByUrl('/service-not-available');
  }

  loadData(userId: ID): Observable<boolean> {
    const isDeletedFiles = window.location.href.includes('deleted-files') || false;
    const userObs = this.userService.getUser(userId).pipe(
      tap((user: User) => {
        this.userService.setActive(user.userId);
      })
    );

    const orgObs = this.organizationService.getOrganization();
    const teamsObs = this.teamService.getUsers(true);
    const paymentOptionsObs = this.billingPlansService.getOrganizationPaymentOptions();
    const resourceUsageObs = this.billingPlansService.getOrganizationResourceUsage();
    const tagsObs = this.tagSerive.getAllTags(true);

    // Get User & Organization, then get the rest
    return zip(userObs, orgObs).pipe(
      tap(([user]) => {
        const secondaryObs: Observable<any>[] = [tagsObs];
        if ([IRoles.Admin, IRoles.Owner].includes(user.associatedOrganizations[0].role)) {
          secondaryObs.push(paymentOptionsObs);
        }
        this.loadSecondaryDataInBackground(secondaryObs);
      }),
      switchMap(([user]) => {
        const obs: Observable<any>[] = [teamsObs];
        const isSupportRole = UserHelper.getUserRole(user) === IRoles.Support;
        if (!AccountSubscriptionHelper.isSubscriptionIncomplete(user.subscriptionStatus) && (!isDeletedFiles || isSupportRole)) {
          obs.push(resourceUsageObs);
        }
        return zip(...obs);
      }),
      map(() => true),
      catchError((err) => {
        // Unauthorized user should navigate to signin page
        if (!unauthorizedErrorCodes.includes(err?.status)) {
          this.serviceUnavailablePage();
        }
        return of(false);
      })
    );
  }

  writeCookie(gfCookie: GfCookie) {
    this.cookieService.set(this.COOKIE_NAME, gfCookie ? JSON.stringify(gfCookie) : '', 1, '/');
  }

  getOrgFromLoginResult(loginResult: LoginResult): Organization {
    return loginResult.organizations?.[0];
  }

  showError(errorMessage: string, errorTitle: string = null) {
    this.notificationService.showNotification(NotificationType.ERROR,
      errorTitle ?? this.translateService.instant(this.translations.common.error),
      errorMessage);
  }

  navigatePageNotFound(err?: any, stateUrl?: string) {
    if (err?.status === 400 && this.pageNotFoundErrorCodes.includes(err?.errorCode)) {
      if (stateUrl) {
        // tried {skipLocationChange: true}, but it doesn't help me out,
        // So to maintain the exact url from router state, Used location.replaceState when resolver throws error
        // while navigating to approval that has been deleted or collaborator as been removed
        this.router.navigate(['approval-link-expired']).then(() => {
          this.location.replaceState(stateUrl);
        });
      }
    }
  }

  navigateToStripeCustomerPortal(): void {
    this.loadingService.showLoader('NAVIGATE_TO_STRIPE');
    this.organizationHttpService.getViewPaymentHistorySession().pipe(
      tap((url: string) => {
        this.document.location.href = url;
      }),
      // By adding delay, the page loader remains active until the URL switch.
      delay(500),
      finalize(() => this.loadingService.hideLoader('NAVIGATE_TO_STRIPE'))
    ).subscribe();
  }

  createUser(newUser: CreateUserModel): Observable<User> {
    return this.userService.createUser(newUser).pipe(
      map((loginUser: User): User => {
        const orgId: ID = loginUser.associatedOrganizations[0].organizationId;
        this.userService.updateUserInStore(loginUser);
        this.userService.setActive(loginUser.userId);

        this.apiService.setToken(loginUser.token);
        this.apiService.setOrgUri(orgId.toString());

        this.writeCookie({
          token: loginUser.token,
          userId: loginUser.userId.toString(),
          orgId: orgId.toString(),
          orgUri: orgId.toString(),
          collaboratorId: '',
          collaboratorLinkId: ''
        });
        return loginUser;
      })
    );
  }

  navigateToInitialStripeCheckoutPage(): Observable<string> {
    const organizationPaymentOption = this.billingPlansQuery.getOrganizationPaymentOptions();
    return combineLatest([this.userQuery.user$, this.organizationQuery.organization$, this.billingPlansQuery.getBundlePlans()])
    .pipe(
      switchMap(([user, organization, bundlePlans]: [User, Organization, Plan[]]) => {
        const planName: BundlePlanType = organization?.billingPlan || user?.billingPlan || BundlePlanType.STANDARD;
        const plan: Plan = bundlePlans.find((bundlePlan: Plan) => bundlePlan.plan === planName);
        const items: StripeCheckoutSessionItem[] = [
          {
            stripeProductId: plan.product.productId,
            quantity: 1
          }
        ];
        const checkoutSession: StripeSessionSubscriptionCheckout = {
          checkoutSession: {
            organizationId: user?.associatedOrganizations[0]?.organizationId,
            customerEmail: user.email,
            items
          },
          successUrl: `${window.location.origin}/team-setup`,
          cancelUrl: `${window.location.origin}/payment-setup`
        };
        return this.organizationHttpService.createBillingSessionSubscriptionCheckout(checkoutSession)
      }),
      tap((sessionId: string) => {
        const stripe = Stripe(organizationPaymentOption.stripePublishableKey);
        stripe.redirectToCheckout({
          sessionId: sessionId
        });
      })
    );
  }

  navigateToStripeCheckoutPage(successUrl: string, cancelUrl: string): Observable<string> {
    const stripePublicKey = this.billingPlansQuery.getValue().paymentOptions.stripePublishableKey;
    const stripe = Stripe(stripePublicKey);
    return this.organizationHttpService.getCreateBillingSessionUpdatePaymentMethod(successUrl, cancelUrl)
    .pipe(
      tap((sessionId: string) => {
        stripe.redirectToCheckout({
          sessionId
        });
      })
    );
  }

  openDownloadLimitExceedModal(): void {
    // Well, this action should be performed simultaneously when DownloadFile API throws error,
    // So ngZone is used to get rid of the Modal UI breaks while error response in progress.
    this.ngZone.run(() => {
      this.dialog.open(DownloadLimitExceedDialogComponent, {
        closeOnNavigation: true,
        hasBackdrop: true,
        autoFocus: false
      }).afterClosed().pipe(
        switchMap(() => this.billingPlansService.getOrganizationResourceUsage())
      ).subscribe();
    });
  }

  openStorageLimitExceedModal(): void {
    // To avoid opening duplicate storage limit warning dialog
    if (this.dialog.openDialogs?.length) {
      return;
    }
    //ngZone is used to get rid of the Modal UI breaks while error response in progress.
    this.ngZone.run(() => {
      this.dialog.open(StorageLimitExceedDialogComponent, {
        closeOnNavigation: true,
        hasBackdrop: true,
        autoFocus: false,
        panelClass: 'mobile-screen-modal'
      });
    });
  }

  loadSecondaryDataInBackground(secondaryObs: Observable<any>[]): void {
    combineLatest(secondaryObs).subscribe();
  }

  navigateToReactivationStripeCheckoutPage(selectedPlans: SelectedPlanInfo): Observable<string> {
    const organizationPaymentOption = this.billingPlansQuery.getOrganizationPaymentOptions();
    const user = this.userQuery.getUser();
    const items: StripeCheckoutSessionItem[] = Object.values(selectedPlans)
      .filter((selectedPlan) => selectedPlan?.productId)
      .map((selectedPlan) => {
        return {
          stripeProductId: selectedPlan.productId,
          quantity: selectedPlan.quantity
        };
      });
    const checkoutSession: StripeSessionSubscriptionCheckout = {
      checkoutSession: {
        organizationId: user?.associatedOrganizations[0]?.organizationId,
        customerEmail: user.email,
        items
      },
      successUrl: `${window.location.origin}/account-reactivation?status=success`,
      cancelUrl: `${window.location.origin}/account-reactivation`,
      isReactivation: true
    };

    return this.organizationHttpService.createBillingSessionSubscriptionCheckout(checkoutSession).pipe(
      tap((sessionId: string) => {
        const stripe = Stripe(organizationPaymentOption.stripePublishableKey);
        stripe.redirectToCheckout({
          sessionId: sessionId
        });
      })
    );
  }

  getCaptchaSiteKey(): string {
    if([EnvironmentType.QA, EnvironmentType.Dev].includes(this.envService.envType)) {
      return testingRecaptchaSiteKey;
    }
    return recaptchaSiteKey;
  }

  resetStores() {
    getRegistry().forEach(store => store.reset());
  }
}
