<div class="genericDataRemovalDailog">
  <div mat-dialog-title class="flex-container align-justify align-middle">
    <h2 class="m-b-0">{{ data.header | translate }}?</h2>
    <mat-icon mat-dialog-close class="cursor-pointer" fontIcon="close"></mat-icon>
  </div>

  <mat-dialog-content>
    <p class="m-b-2">{{ data.body | translate }}</p>
    <div [title]="data.removalData.length > 70 ? data.removalData : ''"
      class="t-16-700-p text-wrap">
      {{ data.removalData | truncate : 70 | translate }}
    </div>
    <div *ngIf="data.note" class="note t-16-500-s">{{ data.note | translate }}</div>
  </mat-dialog-content>

  <mat-dialog-actions >
    <button mat-raised-button
      id="genericRemoveCancelbtn"
      color="secondary"
      class="large"
      type="button"
      [mat-dialog-close]="false">
      {{ data.cancelText | translate }}
    </button>
    <button mat-raised-button
      id="genericRemovebtn"
      color="warn"
      class="large"
      type="button"
      [mat-dialog-close]="true">
      {{ data.continueText | translate }}
    </button>
  </mat-dialog-actions>
</div>