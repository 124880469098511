<div class="gf-header">
  <div class="gf-header-title">
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="gf-header-right">
    <div class="gf-header-search">
      <ng-content select="[search]"></ng-content>
    </div>

    <div>
      <ng-content select="[usage]"></ng-content>
    </div>

    <div class="gf-header-main">
      <div class="gf-header-logo">
        <mat-icon class="gf-header-hamburger" (click)="toggleSidenav()" fontIcon="menu"></mat-icon>
        <gf-logo [size]="(windowService.mediumUp$ | async) ? 'medium' : 'small'" [isDarkLogo]="false"></gf-logo>
      </div>
      <div class="flex-child-auto"></div>
      <div class="gf-header-action-btn align-self-middle show-for-large">
        <ng-content select="[actionBtn]"></ng-content>
      </div>
      <!-- GF-619 -->
      <!-- <gf-notifications-indicator></gf-notifications-indicator> -->
      <gf-help-center class="m-l-1"></gf-help-center>
      <gf-user-dropdown class="flex-container m-l-1" [isDarkHeader]="(windowService.mediumDown$ | async)"></gf-user-dropdown>
    </div>
  </div>
</div>
