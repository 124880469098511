import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((httpError: HttpErrorResponse) => {
        const msg = {};
        if (!!httpError.error?.errorMessages && !!httpError.error.errorMessages?.length) {
          msg['severity'] = httpError.error?.errorMessages[0].severity;
          msg['message'] = httpError.error?.errorMessages[0].content;
          msg['errorCode'] = httpError.error?.errorMessages[0].errorCode;
        } else {
          msg['severity'] = 'error';
          msg['message'] = httpError?.error;
          msg['errorCode'] = httpError?.statusText;
        }
        const err = {
          ok: httpError.ok,
          status: httpError.status,
          statusText: httpError.statusText,
          url: httpError.url,
          ...msg
        };
        return throwError(err);
      })
    );
  }
}
