import { Pipe, PipeTransform } from '@angular/core';
import { ID, StockArtCategory } from '@graphics-flow/types';

@Pipe({
  name: 'isCategoryIndeterminate'
})
export class IsCategoryIndeterminatePipe implements PipeTransform {

  //category checkbox should appear as indeterminate
  transform(parentCategory: StockArtCategory, selectedCategories: ID[], selectedSubCategories: StockArtCategory[]): boolean {
    const isCatSelected = selectedCategories.includes(parentCategory.folderId);
    const hasSelectedSubCats = !!selectedSubCategories.find(cat => cat.parentId === parentCategory?.folderId);
    return !isCatSelected && hasSelectedSubCats;
  }

}
