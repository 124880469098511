<button mat-flat-button
  color="secondary"
  class="medium show-for-medium"
  id="stockArtTypeFilterTrigger"
  type="button"
  [mat-menu-trigger-for]="stockArtTypeFilterMenu">
  <ng-container>
    <span class="t-14-500-p width-100">{{stockArtListQuery.currentDownloadedFilterString$ | async | translate}}</span>
    <mat-icon class="m-l-1 hint" fontIcon="expand_more"></mat-icon>
  </ng-container>
</button>
<mat-menu #stockArtTypeFilterMenu="matMenu"
  yPosition="below"
  xPosition="after">
  <button id="allBtn" mat-menu-item (click)="changeFilterType(StockArtFilterType.All)"
    [class.selected-option]="(stockArtListQuery.stockArtFilter$ | async) === StockArtFilterType.All">
    {{stockArtListQuery.currentArtTypeString$ | async | translate}}
  </button>

  <button id="favouritesBtn" mat-menu-item (click)="changeFilterType(StockArtFilterType.Favorites)"
    [class.selected-option]="(stockArtListQuery.stockArtFilter$ | async) === StockArtFilterType.Favorites">
    {{ translations.common.favorites | translate }}
  </button>

  <ng-container *ngIf="(stockArtListQuery.activeStockArtType$ | async) !== StockArtType.Font">
    <button id="downloadedBtn" mat-menu-item (click)="changeFilterType(StockArtFilterType.Downloaded)"
      [class.selected-option]="(stockArtListQuery.stockArtFilter$ | async) === StockArtFilterType.Downloaded">
      {{translations.common.downloaded | translate}}
    </button>

    <button id="notDownloadedBtn" mat-menu-item (click)="changeFilterType(StockArtFilterType.NotDownloaded)"
      [class.selected-option]="(stockArtListQuery.stockArtFilter$ | async) === StockArtFilterType.NotDownloaded">
      {{translations.common.not_downloaded | translate}}
    </button>

    <button *ngIf="organizationQuery.isProPlan$ | async" id="hideInArtPortalBtn" type="button" mat-menu-item
      (click)="changeFilterType(StockArtFilterType.HiddenManuallyInArtPortal)"
      [class.selected-option]="(stockArtListQuery.stockArtFilter$ | async) === StockArtFilterType.HiddenManuallyInArtPortal">
      {{translations.common.manually_hidden_in_art_portal | translate}}
    </button>
  </ng-container>
</mat-menu>
