import { Pipe, PipeTransform } from '@angular/core';
import { AdditionalPrice, BasePrice, PlanSubscription, SelectedPlanInfo, SubscriptionItem } from '@graphics-flow/types';
import { BillingPlansQuery } from '@graphics-flow/util';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Pipe({
  name: 'isPlanUpdated'
})
export class IsPlanUpdatedPipe implements PipeTransform {
  constructor(private billingPlansQuery: BillingPlansQuery) {
  }

  transform(selectedPlan: SelectedPlanInfo, type?: string): Observable<boolean> {
    switch (type) {
      case 'storage':
        return this.isStoragePlanUpdated(selectedPlan.storagePlan);
      case 'stockart':
        return this.isStockArtPlanUpdated(selectedPlan.stockArtPlan);
      case 'basePlan':
        return this.isBasePlanUpdated(selectedPlan.selectedPlan);
      case 'smartDesignerAddOnPlan':
        return this.isSmartDesignerAddOnPlanUpdated(selectedPlan.smartDesignerAddOnPlan);
      case 'smartDesignerAdditionalDevicesAddOnPlan':
        return this.isSmartDesignerAdditionalDevicesAddOnPlanUpdated(selectedPlan.smartDesignerAdditionalDevicesAddOnPlan);
      default:
        return this.isSomePlanUpdated(selectedPlan);
    }
  }

  isSomePlanUpdated(selectedPlan: SelectedPlanInfo): Observable<boolean> {
    return combineLatest([
      this.isStoragePlanUpdated(selectedPlan?.storagePlan),
      this.isStockArtPlanUpdated(selectedPlan?.stockArtPlan),
      this.isBasePlanUpdated(selectedPlan?.selectedPlan),
      this.isSmartDesignerAddOnPlanUpdated(selectedPlan?.smartDesignerAddOnPlan),
      this.isSmartDesignerAdditionalDevicesAddOnPlanUpdated(selectedPlan?.smartDesignerAdditionalDevicesAddOnPlan)]).pipe(
      map(([storagePlan, isStockArtPlanUpdated, isBasePlanChanged, isSmartDesignerAddOnPlanUpdated, isSmartDesignerAdditionalDevicesAddOnPlanUpdated]) => {
        selectedPlan.isPlanUpdated = storagePlan || isStockArtPlanUpdated || isBasePlanChanged || isSmartDesignerAddOnPlanUpdated || isSmartDesignerAdditionalDevicesAddOnPlanUpdated;
        return selectedPlan.isPlanUpdated;
      })
    );
  }

  isStoragePlanUpdated(storagePlan: BasePrice): Observable<boolean> {
    return this.billingPlansQuery.userSubscription$.pipe(
      filter((userSubscription: PlanSubscription) => !!userSubscription),
      map((userSubscription: PlanSubscription) => {
        const storageItem: SubscriptionItem = userSubscription.items.find(item => item.price.productName === AdditionalPrice.STORAGE);
        return !!storagePlan && storagePlan.quantity !== storageItem?.quantity;
      })
    );
  }

  isSmartDesignerAddOnPlanUpdated(smartDesignerAddOnPlan: BasePrice): Observable<boolean> {
    return this.billingPlansQuery.currentSmartDesignerAddOnSubscription$.pipe(
      map((currentSmartDesignerPlan: SubscriptionItem) => {
        return !!smartDesignerAddOnPlan && (smartDesignerAddOnPlan.quantity !== currentSmartDesignerPlan?.quantity);
      })
    );
  }

  isSmartDesignerAdditionalDevicesAddOnPlanUpdated(smartDesignerAdditionalDevicesAddOnPlan: BasePrice): Observable<boolean> {
    return this.billingPlansQuery.currentSmartDesignerAdditionalDevicesAddOnSubscription$.pipe(
      map((currentSmartDesignerAdditionalDevicesAddOnPlan: SubscriptionItem) => {
        return !!smartDesignerAdditionalDevicesAddOnPlan && (smartDesignerAdditionalDevicesAddOnPlan.quantity !== currentSmartDesignerAdditionalDevicesAddOnPlan?.quantity);
      })
    );
  }

  isStockArtPlanUpdated(stockArtPlan: BasePrice): Observable<boolean> {
    return this.billingPlansQuery.userSubscription$.pipe(
      filter((userSubscription: PlanSubscription) => !!userSubscription),
      map((userSubscription: PlanSubscription) => {
        const stockArtDownloadItem: SubscriptionItem = userSubscription.items.find(item => item.price.productName === AdditionalPrice.STOCK_ART);
        return !!stockArtPlan && stockArtPlan.quantity !== stockArtDownloadItem?.quantity;
      })
    );
  }

  isBasePlanUpdated(newPlan: BasePrice): Observable<boolean> {
    return this.billingPlansQuery.currentPlan$.pipe(
      map((currentPlan: BasePrice) => {
        return !!newPlan && newPlan.productMetadata?.plan_name !== currentPlan?.productMetadata?.plan_name;
      })
    );
  }
}
