import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';

import { Translations } from '@graphics-flow/shared/assets';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WindowService } from 'shared/util';
import { Pagination, StockArtFilterType, StockArtOrder, StockArtType } from '@graphics-flow/types';
import { MatDialog } from '@angular/material/dialog';
import { CategoryFilterDialogComponent } from '../category-filter-dialog/category-filter-dialog.component';
import { StyleFilterDialogComponent } from '../style-filter-dialog/style-filter-dialog.component';
import { BillingPlansQuery, GlobalHelpers, StockArtListQuery, StockArtListService } from '@graphics-flow/util';
import { CollectionFilterDialogComponent } from '../collection-filter-dialog/collection-filter-dialog.component';


@Component({
  selector: 'gf-stock-art-list-results-header',
  templateUrl: './stock-art-list-results-header.component.html',
  styleUrls: ['./stock-art-list-results-header.component.scss']
})
export class StockArtListResultsHeaderComponent {
  @Input() showCollectionFilter: boolean;
  @Input() showDownloadFilter = true;
  @Input() alignmentClass = '';
  @Input() canShowSortFilter = true;
  @Input() canShowFilterOption = false;

  @Output() sortingIsChanged = new EventEmitter<void>();
  mobileShowFilters: boolean; // whether or not the filters are currently visible at small resolutions
  smallDown$: Observable<boolean> = this.windowService.smallDown$;
  StockArtOrder: typeof StockArtOrder = StockArtOrder;
  StockArtType: typeof StockArtType = StockArtType;
  stockArtFilterType: typeof StockArtFilterType = StockArtFilterType;

  currentSortString$: Observable<string> = combineLatest([
    this.stockArtListQuery.orderBy$,
    this.stockArtListQuery.orderDesc$
  ]).pipe(
    map(([orderBy, orderDesc]: [StockArtOrder, boolean]) => {
      if (orderBy === StockArtOrder.Name) {
        return orderDesc ? this.translations.filter.name_z_to_a : this.translations.filter.name_a_to_z;
      } else if (orderBy === StockArtOrder.CreatedDate) {
        return orderDesc ? this.translations.filter.recent_first : this.translations.filter.oldest_first;
      }

      return '';
    })
  );
  stockArtListTotalResults$: Observable<string> = this.stockArtListQuery.filteredStockArtsPagination$.pipe(
    map((value: Pagination) => {
      return GlobalHelpers.getTotalResultFormat(value?.totalResults, this.locale);
    })
  );

  constructor(
    public readonly billingPlansQuery: BillingPlansQuery,
    public readonly stockArtListQuery: StockArtListQuery,
    public readonly stockArtListService: StockArtListService,
    public readonly translations: Translations,
    public readonly windowService: WindowService,
    public readonly dialog: MatDialog,
    @Inject(LOCALE_ID) public locale: string
    ) {}

  changeSortOrder(orderBy: StockArtOrder, orderDesc: boolean) {
    const stockArtListState = this.stockArtListQuery.getValue();
    if (stockArtListState.activeFilters.orderBy !== orderBy || stockArtListState.activeFilters.orderDesc !== orderDesc) {
      this.stockArtListService.changeSortOrder(orderBy, orderDesc);
    }
  }

  openCategoryFilterDialog(): void {
    // Used to update state for activeFilters.isCategoryModalOpened, when modal is opened
    // So, it won't trigger GetStockArts API, until modal closed.
    this.stockArtListService.setActiveFilters({
      isCategoryModalOpened: true
    });
    // Catergory fitler history created, particularlly for mobile scenario.
    this.stockArtListService.createCategoryFilterHistory();

    this.dialog.open(CategoryFilterDialogComponent, {
      panelClass: 'mobile-screen-modal',
      data: {
        canShowClipArtMobileViewFilter: this.canShowFilterOption
      }
    }).afterClosed()
    .subscribe((isModalSuccess) => {
      // Need to reset the applied filters on cancel & backdrop click.
      // isModalSuccess - set to "true" only by clicking the done btn.
      // On Success - just destroy the histroy,
      // On Cancel - reset the applied filters & then destory the histroy.
      if (!isModalSuccess) {
        this.stockArtListService.resetFilterHistory();
      }
      this.stockArtListService.destroyCategoryFilterHistory();
      // canShowFilterOption is true if filter dialog is open from CSA Replace Clip art
      this.stockArtListService.setActiveFilters({
        isCategoryModalOpened: false
      }, this.canShowFilterOption);
    })
  }

  openStyleFilterDialog(): void {
    this.dialog.open(StyleFilterDialogComponent, {
      panelClass: 'mobile-screen-modal'
    })
  }

  openCollectionFilterDialog(): void {
    this.dialog.open(CollectionFilterDialogComponent, {
      panelClass:'mobile-screen-modal',
      data: {
        canShowClipArtMobileViewFilter: this.canShowFilterOption
      }
    });
  }

  changeFilterType(filterType: StockArtFilterType): void {
    const stockArtListState = this.stockArtListQuery.getValue();
    if (stockArtListState.activeFilters.stockArtFilterType !== filterType) {
      this.stockArtListService.changeFilterType(filterType);
    }
  }
}
