import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ArtApprovalComment, ID, ImagePreviewType } from '@graphics-flow/types';
import { Translations } from '@graphics-flow/shared/assets';

@Component({
  selector: 'gf-approval-comment',
  templateUrl: './approval-comment.component.html',
  styleUrls: ['./approval-comment.component.scss']
})
export class ApprovalCommentComponent {
  @Input() disabled = false;
  @Input() canReply = false;
  @Input() comment: ArtApprovalComment;
  @Input() replies: ArtApprovalComment[];
  @Output() commented: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteComment: EventEmitter<ID> = new EventEmitter<ID>();
  imagePreviewType: typeof ImagePreviewType = ImagePreviewType;

  showReply = false;

  constructor(public readonly translations: Translations) { }

}
