<div id="genericDialog">
  <div class="cell shrink" mat-dialog-title [class.align-center]="!data?.showCloseButton">
    <h2 class="flex-child-shrink">{{data.header | translate}}</h2>
    <ng-container *ngIf="data?.showCloseButton">
      <div class="flex-child-grow"></div>
      <mat-icon class="m-b-2 cursor-pointer flex-child-shrink" (click)="confirm(false)" fontIcon="close"></mat-icon>
    </ng-container>
  </div>
  <mat-dialog-content>
    <div class="secondary"
         [innerHTML]="data.body | translate: data.bodyParams"></div>
      <ng-container *ngTemplateOutlet="data?.templateRef"></ng-container>
  </mat-dialog-content>
  <mat-dialog-actions class="align-right full-width-buttons-mobile" *ngIf="data.cancelText || data.continueText">
    <button *ngIf="data.cancelText"
      mat-raised-button
      color="secondary"
      class="large"
      type="button"
      [ngClass]="data?.cancelClass"
      (click)="confirm(false)">
      {{data.cancelText | translate}}
    </button>
    <button *ngIf="data.continueText"
      mat-raised-button
      class="button-continue large"
      type="button"
      [ngClass]="continueBtnClass"
      (click)="confirm(true)">
      {{data.continueText | translate}}
    </button>
  </mat-dialog-actions>
</div>
