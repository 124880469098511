import { Component, Input } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';
import { Art } from '@graphics-flow/types';

@Component({
  selector: 'gf-stock-art-downloaded',
  templateUrl: './stock-art-downloaded.component.html',
  styleUrls: ['./stock-art-downloaded.component.scss']
})
export class StockArtDownloadedComponent {

  @Input() art: Art;

  constructor(public translations: Translations) { }

}
