<div class="stock-art-list cell grid-y height-100">
  <div class="filters-and-chips">
    <div class="filters-container cell shrink" [ngClass]="filterClass"
         [class.align-center]="type === StockArtType.Font" [class.align-justify]="!selectable">
      <gf-stock-art-filters [alignmentClass]="selectable ? 'align-center-middle' : '' "></gf-stock-art-filters>
      <gf-download-all-fonts *ngIf="type === StockArtType.Font && (isLarge$ | async)"></gf-download-all-fonts>
    </div>
    <div class="chips-container cell shrink m-b-1"
         *ngIf="stockArtListQuery.activeFilterHeaders$ | async as activeFilterHeaders">
      <gf-stock-art-chips></gf-stock-art-chips>
    </div>
  </div>
  <gf-stock-art-list-results-header></gf-stock-art-list-results-header>
  <div #scrollingBlock class="stock-art-list-wrapper cell auto position-relative">
    <div *ngIf="stockArtListQuery.loading$ | async" class="catalog-loader position-relative">
      <loading-indicator id="catalogLoader"></loading-indicator>
    </div>
    <ng-container *ngIf="infinite$ | async as stockArtList">
      <ng-container *ngIf="!stockArtList?.length">
        <stock-art-search-no-result></stock-art-search-no-result>
      </ng-container>
      <virtual-scroller *ngIf="!!stockArtList?.length"
        #scroll
        [items]="stockArtList"
        [parentScroll]="scrollingBlock"
        [enableUnequalChildrenSizes]="true"
        (vsEnd)="scrolledToBottom($event.endIndex, stockArtList.length)">
        <!-- Arts -->
        <div *ngIf="stockArtList?.length" class="stock-art-list-container">

          <!-- Design Ideas & Clip Art (not Fonts) -->
          <div #container class="grid-x grid-margin-x mobile-margin-none"
               *ngIf="!((stockArtListQuery.activeStockArtType$ | async) === StockArtType.Font); else fontTemplate">
            <div *ngFor="let stockArt of scroll.viewPortItems; trackBy: trackStockArt"
                 class="item-card cell xlarge-3 large-4 medium-6 small-12 m-b-2 draw meet"
                 [class.selected]="(stockArtListQuery.selectedStockArtIds$ | async)?.includes(stockArt.stockArtId)">
                 <!-- hideStockArtItemInfoFooter is set to selectable, because If list view is open with selectable has "true"
                then we don't need to show the footer of the item. -->
              <gf-stock-art-item [art]="stockArt | stockArtArt | async"
                [associatedOrgId]="(stockArt | stockArtArt | async).assignedOrganizationId"
                [isGraphicsBuilder]="!!stockArt.stockArtRecord?.artLibrary"
                [showCustomizeStockArt]="billingPlansQuery.isCustomizeStockArtEnabled$ | async"
                [hideStockArtItemInfoFooter]="selectable"
                (openStockArtDetails)="openStockArtDetail(stockArt.stockArtId, stockArtDetailSections.CUSTOMIZE)"
                (click)="onClick(stockArt)">
              </gf-stock-art-item>
              <div class="selection-layer" *ngIf="selectable">
                <!-- TODO: Find a better way to make the whole card clickable for selection -->
                <label [for]="'selectStockArt' + stockArt.stockArtId + '-input'" class="display-block">
                  <mat-checkbox name="selectStockArt"
                                id="selectStockArt{{stockArt.stockArtId}}"
                                class="select-stock-art-checkbox"
                                [checked]="(stockArtListQuery.selectedStockArtIds$ | async)?.includes(stockArt.stockArtId)"
                                (change)="select(stockArt, $event.checked)"></mat-checkbox>
                </label>
              </div>
            </div>
          </div>

          <!-- Fonts -->
          <ng-template #fontTemplate>
            <div #container class="grid-x grid-margin-x mobile-margin-none">
              <div *ngFor="let stockArt of scroll.viewPortItems; trackBy: trackStockArt"
                   class="font-item-card cell large-6 small-12 m-b-2 draw meet"
                   [class.selected]="(stockArtListQuery.selectedStockArtIds$ | async)?.includes(stockArt.stockArtId)">
                <gf-font-card [font]="stockArt"
                              [selectable]="selectable"
                              [art]="stockArt | stockArtArt | async"></gf-font-card>
                <div class="selection-layer font-item" *ngIf="selectable">
                  <label for="selectStockArt{{stockArt.stockArtId}}-input" class="display-block">
                    <mat-checkbox name="selectStockArt"
                                    id="selectStockArt{{stockArt.stockArtId}}"
                                    class="select-stock-art-checkbox"
                                    [checked]="(stockArtListQuery.selectedStockArtIds$ | async)?.includes(stockArt.stockArtId)"
                                    (change)="select(stockArt, $event.checked)"></mat-checkbox>
                  </label>
                </div>
              </div>
            </div>
          </ng-template>

          <div *ngIf="(stockArtListQuery.loadingResults$ | async)" class="flex-container align-center-middle m-b-3 m-t-2">
            <loading-indicator [diameter]="28" [showInfiniteScrollLoader]="true" [panelClass]="'loader m-r-1'"></loading-indicator>
          </div>
        </div>
        <loading-indicator *ngIf="stockArtListQuery.loading$ | async" id="listLoader"></loading-indicator>
      </virtual-scroller>
    </ng-container>
  </div>
</div>
