<div *ngIf="art" [ngClass]="{'only-view': viewOnly}">
  <ng-container *ngIf="art.stockArtType !== stockArtType.Font">
    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0" (opened)="artDetailsPanel = true" (closed)="artDetailsPanel = false"
                           [expanded]="true">
        <mat-expansion-panel-header [expandedHeight]="'56px'" [collapsedHeight]="'56px'">
          <mat-panel-title class="t-16-500-p">
            {{translations.art.art_details | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form #artDescription name="artDescription" [formGroup]="artDescriptionForm" autocomplete="off">
          <mat-form-field appearance="fill" class="width-100">
            <mat-label>{{translations.art.decoration_method | translate}}</mat-label>
            <mat-select id="decorationMethod" name="decorationMethod" formControlName="decorationMethodId"
                        [panelClass]="'outline-none'" #dmList>
              <div class="decoration-list-panel">
                <mat-option [value]="NONE">{{translations.common.none | translate}}</mat-option>
                <ng-container *ngFor="let decorationMethod of decorationMethods">
                  <mat-option *ngIf="!decorationMethod.deleted" [value]="decorationMethod.decorationMethodId"
                              class="t-14-400-p">
                    {{decorationMethod.name}}
                  </mat-option>
                </ng-container>
              </div>
              <div class="decoration-method-list-footer grid-x t-14-500-p align-middle cursor-pointer link-color"
                   (click)="dmList?.close(); openDecorationMethodModal()">
                <mat-icon class="material-icons-outlined" fontIcon="create"></mat-icon>
                <p class="m-b-0 m-l-1">{{translations.art.add_or_edit_decoration_method | translate}}</p>
              </div>
            </mat-select>
          </mat-form-field>

          <div class="grid-x grid-margin-x">
            <div class="cell large-6">
              <mat-form-field appearance="fill" class="width-100">
                <mat-label>{{translations.common.width_with_unit | translate : {
                  x:
                  artDescriptionForm.controls['unit'].value
                } }}</mat-label>
                <input matInput maxlength="8" formControlName="width" [onlyNumber]="true" [readonly]="viewOnly">
              </mat-form-field>
            </div>

            <div class="cell large-6">
              <mat-form-field appearance="fill" class="width-100">
                <mat-label>{{translations.common.height_with_unit | translate : {
                  x:
                  artDescriptionForm.controls['unit'].value
                } }}</mat-label>
                <input matInput maxlength="8" formControlName="height" [onlyNumber]="true" [readonly]="viewOnly">
              </mat-form-field>
            </div>
          </div>

          <mat-form-field appearance="fill" class="width-100 error-none m-b-2">
            <mat-label>{{translations.common.unit | translate}}</mat-label>
            <mat-select id="unit" name="unit" formControlName="unit">
              <mat-option value="mm">{{translations.common.mm | translate}}</mat-option>
              <mat-option value="cm">{{translations.common.cm | translate}}</mat-option>
              <mat-option value="in">{{translations.common.in | translate}}</mat-option>
            </mat-select>
          </mat-form-field>

          <ng-container *ngIf="!viewOnly || colorCodesForm.controls.length" >
            <div class="t-14-500-p pb-4 m-y-1 dark-primary-text">{{translations.art.colors_with_count | translate: { colors: colorCodesForm?.controls?.length } }}</div>
            <div formArrayName="colors">
              <div *ngFor="let artColor of colorCodesForm.controls; index as i;" [formGroupName]="i"
                  class="flex-container m-b-1">
                <add-art-color [color]="artColor?.value?.color || '#ffffff'" [disabled]="viewOnly" (colorChange)="editColor($event, i)"></add-art-color>
                <div class="flex-child-auto m-l-1">
                  <mat-form-field appearance="fill" class="icon-input width-100 error-none">
                    <input matInput type="text" formControlName="name" maxLength="132" autocomplete="off" [placeholder]="translations.art.enter_color_name | translate" [readonly]="viewOnly">
                    <mat-icon class="mat-20 hint cursor-pointer" *ngIf="!viewOnly" matSuffix (click)="removeColor(i)">remove_circle_outline
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </ng-container>

          <button *ngIf="!viewOnly"
            id="addColorBtn"
            mat-raised-button
            color="secondary"
            class="m-t-1 full-width large"
            (click)="addColor()">
            {{translations.art.add_color | translate}}
          </button>
        </form>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-divider></mat-divider>
  </ng-container>

  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0" (opened)="artDetailsPanel = true" (closed)="artDetailsPanel = false" [expanded]="art.stockArtType === stockArtType.Font">
      <mat-expansion-panel-header [expandedHeight]="'56px'" [collapsedHeight]="'56px'">
        <mat-panel-title class="t-16-500-p">
          {{translations.art.file_details | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="flex-container">
        <div>
          <div class="t-14-400-s pb-4">{{translations.common.name | translate}}</div>
          <div class="t-14-400-s pb-4">{{translations.common.type | translate}}</div>
          <div class="t-14-400-s pb-4">{{translations.common.size | translate}}</div>
          <div class="t-14-400-s pb-4">{{translations.common.created | translate}}</div>
          <div class="t-14-400-s pb-4">{{translations.common.modified | translate}}</div>
          <div *ngIf="art?.artExtensions[2]?.width && art?.artExtensions[2]?.height" class="t-14-400-s pb-4">{{translations.common.dimensions | translate}}</div>
        </div>
        <div class="flex-child-auto m-l-3">
          <div class="t-14-400-p pb-4 text-truncate file-name-width">{{art?.fileName}}</div>
          <div class="t-14-400-p pb-4">{{(art?.stockArtType === stockArtType.Font ? art?.name : art?.fileName) | fileExtension | uppercase}}</div>
          <div class="t-14-400-p pb-4">{{(art?.artExtensions[2]?.memorySize || art?.fileStorageUsage) | filesize : { base: 2, standard: 'jedec' } }}</div>
          <div class="t-14-400-p pb-4">{{art?.createdDate | fromNowDate}}</div>
          <div class="t-14-400-p pb-4">{{art?.modifiedDate | fromNowDate}}</div>
          <div *ngIf="art?.artExtensions[2]?.width && art?.artExtensions[2]?.height" class="t-14-400-p pb-4">{{art?.artExtensions[2]?.width + ' x ' + art?.artExtensions[2]?.height}}</div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-divider></mat-divider>

  <!--hide for now GF-514 -->
  <!-- <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0" (opened)="artDetailsPanel = true"
                         (closed)="artDetailsPanel = false">
      <mat-expansion-panel-header [expandedHeight]="'56px'" [collapsedHeight]="'56px'">
        <mat-panel-title class="t-16-500-p">
          Activity
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>On the way!</p>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-divider></mat-divider> -->
</div>
