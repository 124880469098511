import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Art, Files, ID } from '@graphics-flow/types';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTarget, DropTargetMonitor, DndService } from '@ng-dnd/core';
import { Observable } from 'rxjs';
import { ApprovalService } from '@graphics-flow/util';
import { ApprovalActionsService } from '../../services/approval-actions.service';

@Component({
  selector: 'gf-approval-drop',
  templateUrl: './approval-drop.component.html',
  styleUrls: ['./approval-drop.component.scss']
})
export class ApprovalDropComponent implements OnInit, OnDestroy {
  @Input() approvalId: ID;
  @Input() disabled: boolean;

  approvalDropTarget: DropTarget<Files | Art>;
  hovering$: Observable<boolean>;

  constructor(
    private readonly approvalService: ApprovalService,
    private readonly approvalActionService: ApprovalActionsService,
    private readonly dnd: DndService
  ) { }

  ngOnInit(): void {
    this.approvalDropTarget = this.dnd.dropTarget<Files | Art>([NativeTypes.FILE, 'ART'], {
      drop: (monitor: DropTargetMonitor<Files | Art>) => {
        switch (monitor.getItemType()) {
          case NativeTypes.FILE:
            const files = (monitor.getItem() as Files).files;
            if (files?.length) {
              this.approvalActionService.uploadFilesToApproval(files, this.approvalId);
            }
            break;
          case 'ART':
            this.approvalService.addArtToApproval(
              [monitor.getItem() as Art].map(art => art.artId),
              this.approvalId
            ).subscribe();
            break;
        }
      },
      canDrop: () => {
        return !this.disabled;
      }
    });

    this.hovering$ = this.approvalDropTarget.listen(m => m.isOver() && m.canDrop());
  }

  ngOnDestroy() {
    this.approvalDropTarget.unsubscribe();
  }
}
