import { Injectable } from '@angular/core';
import { addEntities, deleteEntities, updateEntities, upsertEntities } from '@ngneat/elf-entities';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { TagHttpService } from '@graphics-flow/api';
import { Tag } from '@graphics-flow/types';
import { TagStore } from './tag.store';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  addDefaultValuesToTag = (tag: Tag) => {
    tag.isEdit = false;
    tag.backupValue = tag.displayName;
  };

  constructor(
    readonly tagStore: TagStore,
    private tagHttpService: TagHttpService
  ) {}

  addTagToStore(tags: Tag[]) {
    tags.forEach((t) => {
      this.addDefaultValuesToTag(t);
    });
    this.tagStore.update(upsertEntities(tags));
  }

  getAllTags(includeArtCount: boolean): Observable<Tag[]> {
    return this.tagHttpService.getOrganizationTags(includeArtCount).pipe(
      tap((tags: Tag[]) => {
        this.addTagToStore(tags);
      })
    );
  }

  addNewTag(tagName: string): Observable<Tag> {
    return this.tagHttpService.createTag(tagName).pipe(
      tap((tag: Tag) => {
        this.addDefaultValuesToTag(tag);
        this.tagStore.update(
          addEntities(tag),
        );
      })
    );
  }

  deleteTag(tagName: string): Observable<Tag> {
    return this.tagHttpService.deleteTag(tagName).pipe(
      tap((tag: Tag) => {
        this.tagStore.update(deleteEntities(tag.tagId));
      })
    );
  }

  editTag(oldTag: string, newTag: string): Observable<Tag> {
    return this.tagHttpService.editTag(oldTag, newTag).pipe(
      tap((tag: Tag) => {
        this.addDefaultValuesToTag(tag);
        this.tagStore.update(updateEntities(tag.tagId, tag));
      })
    );
  }

  mergeTags(tagToBeMerged: Tag): Observable<Tag> {
    return this.tagHttpService.mergeTags(tagToBeMerged.backupValue, tagToBeMerged.displayName).pipe(
      tap((destinationTag: Tag) => {
        this.tagStore.update(deleteEntities(tagToBeMerged.tagId));
        this.tagStore.update(updateEntities(destinationTag.tagId, destinationTag));
      })
    );
  }

  updateTag(tag: Tag): void {
    this.tagStore.update(updateEntities(tag.tagId, tag));
  }
}
