import { Injectable } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { select } from '@ngneat/elf';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import {
  ArtApproval,
  ArtApprovalItemDetailSection,
  Collaborator,
  CollaboratorLinkPackage,
  CollaboratorRole,
  DesignRequest,
  ID,
  User
} from '@graphics-flow/types';
import { WindowService } from 'shared/util';

import { ApprovalHelper } from '../../../helpers/approval.helper';
import { CollaboratorQuery } from '../../collaborator/collaborator.query';
import { UserQuery } from '../../user/user.query';
import { ApprovalQuery } from '../approval.query';
import { ApprovalDetailState, ApprovalDetailStore } from './approval-detail.state';

@Injectable({
  providedIn: 'root'
})
export class ApprovalDetailQuery {
  collaboratorLink$: Observable<CollaboratorLinkPackage> = this.selectStateProps<CollaboratorLinkPackage>((store: ApprovalDetailState) => store.collaboratorLink);
  collaboratorId$: Observable<ID> = this.selectStateProps<ID>((store: ApprovalDetailState) => store.collaboratorId);

  drawerIsOpen$: Observable<boolean> = this.selectStateProps<boolean>((state: ApprovalDetailState) => state.isDrawerOpen);
  detailActiveSection$: Observable<ArtApprovalItemDetailSection> = this.selectStateProps<ArtApprovalItemDetailSection>((state: ApprovalDetailState) => state.activeSection);
  sidebarMode$: Observable<MatDrawerMode> = this.windowService.mediumDown$.pipe(
    map((mediumDown: boolean) => {
      return mediumDown ? 'over' : 'side';
    })
  );

  approvalId$: Observable<ID> = this.selectStateProps<ID>((store: ApprovalDetailState) => store.approvalId);

  approval$: Observable<ArtApproval> = this.approvalId$.pipe(
    switchMap((approvalId: ID) => {
      return this.approvalQuery.selectApprovalEntity(approvalId);
    })
  );

  collaborators$: Observable<Collaborator[]> = this.approvalId$.pipe(
    switchMap((approvalId: ID) => {
      return this.collaboratorQuery.getArtApprovalCollaborator(approvalId);
    })
  );

  collaborator$: Observable<Collaborator> = this.collaboratorLink$.pipe(
    map((cl: CollaboratorLinkPackage) => {
      return cl?.collaborator;
    })
  );

  isCollaborator$: Observable<boolean> = this.collaboratorLink$.pipe(
    map((cl: CollaboratorLinkPackage) => {
      // You can access to the ArtApproval as viewer without have a Collaborator
      return !!cl;
    })
  );

  isTeamMember$: Observable<boolean> = this.userQuery.user$.pipe(
    map((user: User) => {
      return !!user;
    })
  );

  identification$: Observable<[Collaborator, User]> = combineLatest([
    this.collaborator$,
    this.userQuery.user$
  ]);

  canApprove$: Observable<boolean> = this.identification$.pipe(
    map(([collaborator, user]: [Collaborator, User]) => {
      return !!((collaborator?.role === CollaboratorRole.EDIT) || user?.userId);
    })
  );

  isGuest$: Observable<boolean> = this.identification$.pipe(
    map(([collaborator, user]: [Collaborator, User]) => {
      return ((!collaborator && !user) || (collaborator?.role === CollaboratorRole.READ_ONLY));
    })
  );

  approvalItemId$: Observable<ID> = this.selectStateProps<ID>((store: ApprovalDetailState) => store.approvalItemId);

  approvalItem$ = combineLatest([
    this.approval$,
    this.approvalItemId$
  ]).pipe(
    map(([approval, approvalItemId]) => {
      return ApprovalHelper.getItem(approval, approvalItemId);
    })
  );

  drawerOpen$: Observable<boolean> = this.selectStateProps<boolean>((store: ApprovalDetailState) => store.isDrawerOpen);

  isArchived$: Observable<boolean> = this.approval$.pipe(
    filter((approval: ArtApproval) => !!approval),
    map((approval: ArtApproval) => ApprovalHelper.isArchived(approval))
  );

  role$: Observable<CollaboratorRole> = this.identification$.pipe(
    map(([collaborator, user]: [Collaborator, User]) => {
      if (user) {
        return CollaboratorRole.EDIT;
      }
      return collaborator.role;
    })
  );

  relatedDesignRequests$: Observable<DesignRequest[]> = this.selectStateProps<DesignRequest[]>((store: ApprovalDetailState) => store.relatedDesignRequest);

  constructor(
    protected store: ApprovalDetailStore,
    private readonly approvalQuery: ApprovalQuery,
    private readonly userQuery: UserQuery,
    private readonly windowService: WindowService,
    private readonly collaboratorQuery: CollaboratorQuery) {
  }

  selectStateProps<T>(predicate): Observable<T> {
    return this.store.pipe(select(predicate));
  }

  getCollaboratorRole(): CollaboratorRole {
    return this.store.getValue().collaboratorLink?.collaboratorLink?.role;
  }
}
