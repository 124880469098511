import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Translations, MatIconService } from '@graphics-flow/shared/assets';
import { MyArtUploaderService } from '@graphics-flow/util';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrientationNotSupportedDialogComponent } from '@graphics-flow/ui';

@Component({
  selector: 'graphics-flow-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  landscape: MediaQueryList = window.matchMedia("(orientation: landscape)");
  smallWidth: MediaQueryList = window.matchMedia("(max-width: 1024px)");
  smallHeight: MediaQueryList = window.matchMedia("(max-height: 440px)");
  warningDialog: MatDialogRef<OrientationNotSupportedDialogComponent>;
  isTablet: MediaQueryList = window.matchMedia("screen and (min-width: 768px) and (max-width: 1200px)");

  constructor(
    private translate: TranslateService,
    public translations: Translations,
    private matIconService: MatIconService,
    private myArtUploaderService: MyArtUploaderService,
    private matDialog: MatDialog,
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
    // Open rotate device warning model on mobile landscape orientation
    // Used to open model when app opened in landscape mode(in mobile).
    this.viewRotateDeviceWarning();
    // Used to open model when device rotated after the application load.
    this.landscape.addEventListener('change', () => {
      this.viewRotateDeviceWarning();
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  checkForFileUploadProgress($event) {
    if (this.myArtUploaderService.remainingFilesCount()) {
      const warning: string = this.translate.instant('common.bulk_upload_in_progress_message');
      $event.preventDefault(); // Docs recommend you do this
      $event.returnValue = warning; // Some browsers require this
      return warning; // Other browsers require this
    }
  }

  viewRotateDeviceWarning(): void {
    const isLandscape: boolean = this.landscape.matches;
    const isSmall: boolean = this.smallWidth.matches && this.smallHeight.matches;

    if (isLandscape && isSmall) {
      this.warningDialog = this.matDialog.open(OrientationNotSupportedDialogComponent, {
        autoFocus: false,
        panelClass: 'mobile-screen-modal',
        disableClose: true
      })
    } else {
      this.warningDialog?.close();
    }

    setTimeout(() => {
      this.calculateViewHeight(isLandscape);
    }, 100);
  }

  // Mobile device 100vh issue with address bar. calculating view height with window inner height.
  calculateViewHeight(isLandscape: boolean): void {
    if (!isLandscape  || this.isTablet.matches) {
      const doc = document?.documentElement;
      doc.style.setProperty('--app-vh', `${window.innerHeight}px`);
    }
  }
}
