import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StockArtDetailSections } from '@graphics-flow/types';

@Component({
  selector: 'gf-footer-view',
  templateUrl: './footer-view.component.html',
  styleUrls: ['./footer-view.component.scss']
})
export class FooterViewComponent {
  stockArtDetailSections: typeof StockArtDetailSections = StockArtDetailSections;
  @Input() activeSection: StockArtDetailSections;
  @Input() showCustomizeStockArt = false;
  @Input() showArtCustomizationsIcon = false;
  @Output() openStockArtDetailPage: EventEmitter<StockArtDetailSections> = new EventEmitter();
}
