import { Injectable } from '@angular/core';
import { addEntities, deleteEntities, deleteEntitiesByPredicate, updateEntities, upsertEntities } from '@ngneat/elf-entities';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ArtHttpService, FolderHttpService, TagHttpService } from '@graphics-flow/api';
import { Translations } from '@graphics-flow/shared/assets';
import { ApiResponse, Art, ArtFolderPackage, ArtType, ID, ListArtReferences, MyArtActiveQueryParams, NotificationType, Watermark, WebJob } from '@graphics-flow/types';
import { NotificationService } from 'shared/ui';
import { EventHelpers, FileHelpers } from 'shared/util';
import { ArtHelper } from '../../helpers/art.helper';
import { GlobalHelpers } from '../../helpers/global.helpers';
import { BillingPlansService } from '../billing-plans/billing-plans.service';
import { MyArtSearchStore } from '../my-art-search/my-art-search.store';
import { TagService } from '../tag/tag.service';
import { MAX_SCROLL_LIMIT, MY_ART_BASE_LIMIT } from './../../constants/infinity-scroller.constants';
import { ArtQuery } from './art.query';
import { ArtStore } from './art.state';
import { MyArtBulkSelectionStore } from '../my-art-bulk-selection/my-art-bulk-selection.store';
import { FolderStore } from '../folder/folder.state';

const artPortalFilterTypes: string[] = [ArtType.ADDED_TO_ART_PORTAL, ArtType.NOT_IN_ART_PORTAL];

@Injectable({
  providedIn: 'root'
})
export class ArtService {
  constructor(
    private billingPlansService: BillingPlansService,
    private readonly artHttpService: ArtHttpService,
    private readonly artStore: ArtStore,
    private readonly artQuery: ArtQuery,
    private readonly billingPlanService: BillingPlansService,
    private readonly folderHttpService: FolderHttpService,
    private readonly tagHttpService: TagHttpService,
    public translations: Translations,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    private readonly tagService: TagService,
    private readonly folderStore: FolderStore,
    private readonly myArtSearchStore: MyArtSearchStore,
    private readonly myArtBulkSelectionStore: MyArtBulkSelectionStore
  ) {
  }

  addArtToStore(arts: Art | Art[]) {
    return this.artStore.update(upsertEntities(arts));
  }

  addArtEntities(arts: Art | Art[]): void {
    this.artStore.update(addEntities(arts));
  }

  updateArtEntities(artIds: ID | ID[], partialArt: Partial<Art>): void {
    this.artStore.update(updateEntities(artIds, partialArt));
  }

  deleteArtEntities(artIds: ID | ID[]): void {
    this.artStore.update(deleteEntities(artIds));
  }

  deleteArtEntitiesByPredicate(predicate): void {
    this.artStore.update(deleteEntitiesByPredicate(predicate));
  }

  getArt(id: ID): Observable<Art> {
    return this.artQuery.selectArt(id);
  }

  getArtById(id: ID): Observable<Art> {
    return this.artHttpService.getArt(id).pipe(
      tap((art: Art) => {
        this.addArtToStore([art]);
      })
    );
  }

  getArts(params: MyArtActiveQueryParams): Observable<ApiResponse<Art[]>> {
    if (params?.limit && params?.limit <= MY_ART_BASE_LIMIT) {
      params.limit = GlobalHelpers.getInfiniteScrollValidLimit(params?.index ?? 0, MY_ART_BASE_LIMIT);
    }
    // This condition will true when we applied view all having more than 10,000 records.
    if (params?.limit && params?.limit <= MAX_SCROLL_LIMIT && !(params?.limit <= MY_ART_BASE_LIMIT)) {
      params.limit = params?.index === 0 ? MAX_SCROLL_LIMIT : params.limit;
    }

    return this.artHttpService.getArts(params).pipe(
      tap((arts: ApiResponse<Art[]>) => {
        this.addArtToStore(arts.data);
      })
    );
  }

  getApprovalsByArtId(artId: ID): Observable<ListArtReferences> {
    return this.artHttpService.getApprovalsByArtId(artId);
  }

  uploadArt(files: File[], parentFolderId?: ID): Observable<Art[]> {
    return forkJoin(files.map((file: File) => {
      return this.createArt(file, parentFolderId);
    }));
  }

  createArt(file: File, parentFolderId: ID): Observable<Art> {
    return this.artHttpService.create(file, parentFolderId).pipe(
      tap((art: Art) => {
        this.billingPlanService.updateResourceUsageInBackground();
        this.addArtEntities(art);
      })
    );
  }

  deleteArt(art: Art): Observable<ArtFolderPackage> {
    return this.folderHttpService.deleteArtAndFolders([art?.artId], []).pipe(
      tap(() => {
        this.deleteArtEntities(art?.artId);
        this.billingPlansService.updateResourceUsageInBackground();
        if (art.tags?.length) {
          this.tagService.getAllTags(true).subscribe();
        }
      })
    );
  }

  moveArt(arts: Art[], parentFolderId: ID): Observable<Art[]> {
    const artIds = arts.map((art: Art) => art.artId);
    return this.folderHttpService.moveToFolder(artIds, [], parentFolderId).pipe(
      tap((artFolderPackage: ArtFolderPackage) => {
        this.myArtSearchStore.reduceTotalResultsCount(1);
        this.addArtToStore(artFolderPackage.art);
      }),
      map((artFolderPackage: ArtFolderPackage) => {
        this.billingPlansService.updateResourceUsageInBackground();
        return artFolderPackage.art;
      })
    );
  }

  saveCopyToMyArt(artId: ID, parentFolderId: ID) {
    return this.folderHttpService.saveCopyToMyArt(artId, parentFolderId);
  }

  updateArt(art: Art): Observable<Art> {
    return this.artHttpService.update(art).pipe(
      tap((updatedArt: Art) => {
        this.updateArtEntities(updatedArt.artId, updatedArt);
      })
    );
  }

  getWatermarkPreview(watermarkString: Watermark): Observable<any> {
    return this.artHttpService.getWatermarkPreview(watermarkString);
  }

  downloadOriginal(art: Art) {
    const imageType = ArtHelper.isNoPreviewImage(art) ? 'preview' : 'original';
    this.downloadFile(art, imageType);
  }

  downloadFile(art: Art, displayType: 'preview' | 'full-size' | 'original') {
    let extension = ArtHelper.getArtExtension(art, displayType);
    extension = extension?.startsWith('.') ? extension.substring(1) : extension;
    this.notificationService.showNotification(NotificationType.INPROGRESS, this.translateService.instant(this.translations.common.downloading), '');
    return this.artHttpService.downloadFile(art, extension)
      .subscribe(res => {
        // Note: Use original file name when download original source file
        FileHelpers.downloadFile(res.data, displayType === 'original' ? art.fileName : res.filename);
        this.notificationService.showNotification(NotificationType.SUCCESS, this.translateService.instant(this.translations.common.downloaded), '');
      });
  }

  assignTagToSelectedArts(tagName: string, artIds: ID[]): Observable<boolean> {
    this.artStore.update(updateEntities(artIds, (art: Art) => ({
      ...art,
      tags: [...art.tags, tagName],
      modifiedDate: new Date()
    })));
    return this.tagHttpService.assignTagToSelectedArts(tagName, artIds);
  }

  removeTagFromSelectedArts(tagName: string, artIds: ID[]): Observable<boolean> {
    this.artStore.update(updateEntities(artIds, (art: Art) => {
      const newTags: string[] = art.tags.filter((tag: string) => tag !== tagName);
      return {
        ...art,
        tags: newTags,
        modifiedDate: new Date()
      };
    }));
    return this.tagHttpService.removeTagFromSelectedArts(tagName, artIds);
  }

  updateArtInStore(updatedArt: Art): void {
    this.addArtToStore(updatedArt);
  }

  updateArtInProgress(artIds: ID[], status: boolean): void {
    this.updateArtEntities(artIds, { inProgress: status });
  }

  permanentlyDeleteArt(artId: ID): Observable<ID> {
    return this.artHttpService.permanentlyDeleteArt(artId).pipe(
      tap(((artId: ID) => {
        this.myArtSearchStore.reduceTotalResultsCount(1);
        this.deleteArtEntities(artId);
      })
    ));
  }

  recoverFile(artId: ID, folderId: ID, parentFolderId?: ID): Observable<ID> {
    return this.artHttpService.recoverFile(artId, folderId, parentFolderId).pipe(
      tap((id: ID) => {
        if (artId) {
          this.myArtSearchStore.reduceTotalResultsCount(1);
          this.deleteArtEntities(id);
        } else {
          this.folderStore.update(deleteEntities(folderId));
        }
      })
    );
  }

  getCustomizedStockArts(id: ID): Observable<Art[]> {
    return this.artHttpService.getCustomizedStockArts(id).pipe(
      tap((arts: Art[]) => {
        this.addArtToStore(arts);
      })
    );
  }

  reset(): void {
    this.artStore.reset();
  }

  updateArtDownloadedStatus(artIds: ID[], status: boolean): void {
    this.updateArtEntities(artIds, { isDownloaded: status });
  }

  deleteAllArtsAndFolders(): Observable<WebJob> {
    return this.artHttpService.deleteAllArtsAndFolders();
  }

  getJobStatus(): Observable<WebJob> {
    return this.artHttpService.getJobStatus();
  }

  removeTrashedArts(): void {
    this.deleteArtEntitiesByPredicate((art: Art) => art?.trashed);
  }

  addRemoveFromArtPortal(art: Art, show: boolean) {
    this.artHttpService.addToArtPortal({
      artIdList: [art.artId],
      show
    }).pipe(
      tap(() => {
        const successMessage = show ? this.translateService.instant(this.translations.art.file_successfully_added_to_art_portal) : this.translateService.instant(this.translations.art.file_successfully_removed_from_art_portal);
        this.notificationService.showNotification(NotificationType.SUCCESS, successMessage, '');
        const currentFilterType = this.myArtSearchStore.getValue().activeQueryParams.artType;
        if (artPortalFilterTypes.includes(currentFilterType)) {
          this.myArtSearchStore.reduceTotalResultsCount(1);
          this.deleteArtEntities(art.artId);
          // When multiple art items are selected, individual items can be removed or added to the art portal.
          // In this case, the total selected count isn't updated as expected.
          // To fix this issue, we need to remove the selected item from the bulk selection store.
          this.myArtBulkSelectionStore.removeFoldersAndArts([], [art.artId]);
          return;
        }
        const updatedArt = _cloneDeep(art);
        updatedArt.showInArtPortal = show;
        this.updateArtEntities(art.artId, updatedArt);
      })
    ).subscribe();
  }

  favoriteArt(art: Art, type: string = null, event?: Event): void {
    if (event) {
      EventHelpers.stopAndPrevent(event);
    }
    this.artHttpService.favoriteArt(art.artId, type, !art?.isFavorite).pipe(
      tap((response) => {
        if (type) {
          const updateHiddenArt: Art = _cloneDeep(art);
          updateHiddenArt['isFavorite'] = !art?.isFavorite;
          this.updateArtInStore(updateHiddenArt);
          return;
        }
        this.updateArtEntities(art.artId, response.data as Art);
      })
    ).subscribe();
  }

  removeArtsByFolderId(folderId: ID): void {
    this.deleteArtEntitiesByPredicate((art: Art) => art?.folderId === folderId);
  }
}
