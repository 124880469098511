<div id="addCollaboratorToApprovalDialog" class="grid-y">
  <div class="cell shrink" mat-dialog-title>
    <span class="t-20-500-p flex-child-shrink">
      {{translations.approval.invite_people | translate}}
    </span>
    <div class="flex-child-grow"></div>
    <mat-icon class="cursor-pointer flex-child-shrink" mat-dialog-close fontIcon="close"></mat-icon>
  </div>
  <mat-dialog-content>
    <form autocomplete="off">
      <mat-form-field appearance="fill" class="search-form-field rounded-fill width-100 m-b-3" color="secondary">
        <mat-chip-listbox *ngIf="emails.length">
          <mat-chip-option *ngFor="let email of emails" class="is-email"
            [class.email-warn]="existingEmailIds.includes(email.toLowerCase())" [removable]="true"
            [selectable]="false"
            (focus)="emailInput.focus()">
            {{email}}
            <mat-icon matChipRemove (click)="removeEmail(email)">cancel</mat-icon>
          </mat-chip-option>
        </mat-chip-listbox>
        <mat-divider *ngIf="emails?.length" class="m-y-1"></mat-divider>
        <input matInput multipleEmailInput #emailInput id="emailInput" class="width-100"
          [formControl]="emailFormControl" [class.default-color]="!showError"
          [class.danger-color]="showError && emailFormControl.hasError('pattern')" name="emailInput" type="email"
          [allowSpace]="true" maxlength="255" (blur)="[createEmail(), enableValidation()]"
          (spacePress)="createEmail()" (commaPress)="createEmail()" (enterPress)="createEmail()"
          [matAutocomplete]="auto" [placeholder]="translations.common.enter_email_address | translate">
        <mat-autocomplete #auto="matAutocomplete">
          <div [class.hide]="!((filteredOptions | async)?.length && emailFormControl.value)"
            class="m-y-1 collaborator-options-lists">
            <mat-option *ngFor="let option of (filteredOptions | async)" class="autocomplete-option" [value]="option"
              (click)="createEmail()" [innerHTML]="option | highlight : emailFormControl.value">
            </mat-option>
          </div>
        </mat-autocomplete>
      </mat-form-field>
    </form>
    <div *ngIf="emails.length > 0 || newEmail?.length > 0; else noEmails" class="flex-container flex-dir-column">
      <mat-form-field appearance="fill" class="t-14-400-s width-100 no-borders">
        <textarea class="invite-text" matInput [(ngModel)]="message" name="inviteText"
          [placeholder]="translations.approval.message | translate"></textarea>
      </mat-form-field>
      <div class="flex-child-auto flex-container" [ngClass]="{'flex-dir-column align-center': (windowService.smallDown$ | async), 'align-right': !(windowService.smallDown$ | async) }">

        <button id="collaboratorRoleMenuTrigger"
          mat-raised-button
          color="secondary"
          [mat-menu-trigger-for]="collaboratorRoleMenu"
          class="medium p-r-1 m-r-1"
          [ngClass]="{'full-width m-b-2': (windowService.smallDown$ | async), 'icon-button': !(windowService.smallDown$ | async) }">
          <ng-container>
            <span class="mr-4">{{(role | getCollaboratorPermissionOrDescription) | translate}}</span>
            <mat-icon class="secondary" fontIcon="keyboard_arrow_down"></mat-icon>
          </ng-container>
        </button>
        <mat-menu #collaboratorRoleMenu>
          <button *ngIf="canApprove$ | async" mat-menu-item
            (click)="updateCollaboratorRole(CollaboratorRole.EDIT)">{{translations.approval.can_comment_and_approve | translate}}
          </button>
          <button mat-menu-item (click)="updateCollaboratorRole(CollaboratorRole.VIEW)">
            {{translations.approval.can_comment | translate}}
          </button>
          <button mat-menu-item (click)="updateCollaboratorRole(CollaboratorRole.READ_ONLY)">
            {{translations.approval.can_view | translate}}
          </button>
        </mat-menu>
        <span [matTooltip]="translations.approval.dont_allow_duplicate_collaborators | translate"
          [matTooltipPosition]="'above'" [matTooltipDisabled]="!emailAlreadyExist" #inviteTooltip="matTooltip"
          (click)="inviteTooltip.toggle()">
          <button id="sendInvitesBtn"
            mat-raised-button
            class="mr-4 p-x-3 medium"
            color="primary"
            [disabled]="!emails.length || emailAlreadyExist"
            [ngClass]="{'full-width': (windowService.smallDown$ | async)}"
            (click)="sendInvites()">
            {{translations.common.send_invites | translate}}
          </button>
        </span>
      </div>

    </div>
    <ng-template #noEmails>
      <ng-container *ngIf="!(collaborators$ | async).length">
        <div class="m-t-1 text-center">
          <p class="t-14-600-s m-b-0">{{translations.approval.no_collaborators_invited_yet | translate}}</p>
          <p class="t-14-400-s mt-4 m-b-0">{{translations.approval.no_collaborators_invited_yet_note | translate}}</p>
        </div>
      </ng-container>
      <div *ngIf="collaborators$ | async as collaborators" class="flex-container flex-dir-column">
        <gf-approval-collaborator-item *ngFor="let collaborator of collaborators"
          class="flex-container align-justify m-y-1"
          [activeCollaboratorId]="collaboratorId"
          [allowChange]="!isCollaborator"
          [collaborator]="collaborator"
          (copyToClipboard)="copyToClipboard($event)"
          (removeCollaborator)="removeCollaborator($event)"
          (updateCollaboratorRole)="updateCollaborator(collaborator, $event)">
        </gf-approval-collaborator-item>
      </div>
      <div *ngIf="!isCollaborator">
        <mat-divider class="cell"></mat-divider>
        <div  class="cell flex-container flex-dir-column">
          <div class="t-20-500-p m-b-2">
            {{translations.approval.shareable_link | translate}}
          </div>
          <div class="flex-container align-justify flex-container"
            [ngClass]="{'flex-dir-column align-right': (windowService.smallDown$ | async), 'align-middle': !(windowService.smallDown$ | async)}">
            <div class="t-14-500-s">
              {{translations.approval.anyone_with_the_link | translate}}
            </div>
            <div id="linkPermission" class="flex-container"
              [ngClass]="{'flex-dir-column m-t-2': (windowService.smallDown$ | async)}">
              <button *ngIf="!isCollaborator; else onlyView"
                id="linkPermissionBtn"
                mat-raised-button
                color="secondary"
                [mat-menu-trigger-for]="linkCollaboratorRoleMenu"
                class="medium"
                [ngClass]="{'m-b-1': (windowService.smallDown$ | async), 'icon-button': !(windowService.smallDown$ | async)}">
                <ng-container>
                  <span class="mr-4">{{(shareableLinkPermission | getCollaboratorPermissionOrDescription) | translate}}</span>
                  <mat-icon class="secondary" fontIcon="keyboard_arrow_down"></mat-icon>
                </ng-container>
              </button>
              <mat-menu #linkCollaboratorRoleMenu>
                <button mat-menu-item (click)="updateShareableLinkRole(CollaboratorRole.EDIT)">
                  {{translations.approval.can_comment_and_approve| translate}}
                </button>
                <button mat-menu-item (click)="updateShareableLinkRole(CollaboratorRole.VIEW)">
                  {{translations.approval.can_comment | translate}}
                </button>
                <button mat-menu-item (click)="updateShareableLinkRole(CollaboratorRole.READ_ONLY)">
                  {{translations.approval.can_view | translate}}
                </button>
              </mat-menu>
              <ng-template #onlyView>
                <span class="t-14-500-p m-b-3 m-r-3" [ngClass]="{'m-t-2': !(windowService.smallDown$ | async)}">
                  {{(shareableLinkPermission | getCollaboratorPermissionOrDescription) | translate}}
                </span>
              </ng-template>

              <ng-container *ngIf="sharedLink$| async as link">
                <div id="copyBtnContainer" class="flex-container" [class.m-l-1]="!(windowService.smallDown$ | async)">
                    <button mat-raised-button
                      id="copyBtn"
                      class="medium notification-btn elevate"
                      (click)="copyShareableLink()"
                      [ngClass]="{'full-width': (windowService.smallDown$ | async), 'icon-button': !(windowService.smallDown$ | async)}">
                      <div #tooltip="matTooltip"
                        class="m-r-1"
                        (mouseenter)="$event.stopImmediatePropagation()"
                        (mouseleave)="$event.stopImmediatePropagation()"
                        matTooltipPosition="above"
                        matTooltip="{{translations.approval.link_copied | translate}}">
                          {{translations.approval.copy_link | translate}}
                      </div>
                    </button>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="t-12-400-s m-t-3">
            {{(shareableLinkPermission | getCollaboratorPermissionOrDescription: true) | translate}}</div>
        </div>
      </div>
    </ng-template>
  </mat-dialog-content>
</div>
