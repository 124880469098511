import { Pipe, PipeTransform } from '@angular/core';
import { unionBy as _unionBy } from 'lodash-es';

@Pipe({
  name: 'unique'
})

export class UniquePipe implements PipeTransform {
  transform(value: any[], key: string): any[] {
    return _unionBy(value, key);
  }
}