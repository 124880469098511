<ng-container *ngIf="(userId | user | async) as user">
  <div class="flex-container align-center-middle"
    matTooltipClass="mat-tooltip-below"
    [matTooltip]="user.email" #tooltip="matTooltip" (click)="tooltip.toggle()">
    <div *ngIf="(!imageUrl && !user?.profileAssetExts?.length); else profilePicture"
         [className]="'avatar rounded cursor-pointer '+ profilePicSize"
         [ngClass]=" user | userDisplayName | shortName | iconBackground">
      {{user | userDisplayName | shortName}}
    </div>
    <ng-template #profilePicture>
      <div *ngIf="imageUrl; else profileAssetGuid"
           [className]="'avatar img-preview rounded cursor-pointer '+ profilePicSize" [style.background-image]="'url('+ imageUrl + ')'">
      </div>
      <ng-template #profileAssetGuid>
        <div [className]="'avatar img-preview rounded cursor-pointer '+ profilePicSize" [style.background-image]="'url('+ userProfilePicturePipe.transform(user, size) +')'"></div>
      </ng-template>
    </ng-template>
    <div></div>
    <div *ngIf="showName">
      <div class="t-14-600-p p-l-1" [innerHTML]="user | userDisplayName : true"></div>
      <div class="t-14-400-s p-l-1">{{user.email }}</div>
    </div>
  </div>
</ng-container>
