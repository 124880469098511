import { Pipe, PipeTransform } from '@angular/core';
import { EnvService } from '@graphics-flow/api';

@Pipe({
  name: 'addDomain'
})
export class AddDomainPipe implements PipeTransform {
  constructor(private readonly envServive: EnvService) {
  }

  transform(url: string): string {
    return this.envServive.domainUri + url;
  }
}
