import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { DateHelpers } from '@graphics-flow/util';

@Pipe({
  name: 'dateWithTimeZone'
})
export class DateWithTimeZone implements PipeTransform {

  transform(value: Date, isTimeline: boolean = false): string {
    const isoString = DateHelpers.getDateAsIsoString(value);
    const date = DateTime.fromISO(isoString);
    if (isTimeline) {
      return `${date.toFormat('dd MMMM yyyy')} at ${date.toFormat('h:mm')} ${date.toFormat('a')} ${DateHelpers.getTimeZoneAbbreviation()}`;
    }
    return `${date.toFormat('ccc MMM dd yyyy h:mm')}${date.toFormat('a').toLowerCase()} ${DateHelpers.getTimeZoneAbbreviation()}`;
  }

}
