import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { Folder, ID } from '@graphics-flow/types';
import { FolderQuery } from '@graphics-flow/util';
import { Translations } from '@graphics-flow/shared/assets';
import { WindowService } from 'shared/util';

@Component({
  selector: 'gf-folder-breadcrumbs',
  templateUrl: './folder-breadcrumbs.component.html',
  styleUrls: ['./folder-breadcrumbs.component.scss']
})
export class FolderBreadcrumbsComponent {
  @Input() showHomeIcon = false;
  @Input() isDeletedFiles = false;
  @Output() folderClick: EventEmitter<ID> = new EventEmitter<ID>();
  @Input() folders$: Observable<Folder[]> = this.folderQuery.activeAncestry$;

  constructor(
    private readonly folderQuery: FolderQuery,
    public translations: Translations,
    public windowService: WindowService) {
  }
}
