import { Injectable } from '@angular/core';
import { createState, Store, withProps } from '@ngneat/elf';
import { BillingPlansState } from '@graphics-flow/types';

const { state, config } = createState(
  withProps<BillingPlansState>({
    resourceUsage: null,
    paymentOptions: null
  })
);

@Injectable({ providedIn: 'root' })
export class BillingPlansStore extends Store {

  constructor() {
    super({ name: 'billing-plans', state, config });
  }
}
