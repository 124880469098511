import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Translations } from '@graphics-flow/shared/assets';
import { ArtService, MyArtBulkSelectionQuery, MyArtSearchService, TagService } from '@graphics-flow/util';
import { delay, filter, switchMap, tap } from 'rxjs/operators';
import { GenericRemovalDialogData, ID, Tag } from '@graphics-flow/types';
import { remove as _remove } from 'lodash-es';
import { GenericDataRemovalDailogComponent } from 'shared/ui';

@Component({
  selector: 'gf-edit-tags-dialog',
  templateUrl: './edit-tags-dialog.component.html',
  styleUrls: ['./edit-tags-dialog.component.scss']
})
export class EditTagsDialogComponent {

  artIds: ID[];
  tags: string[];
  tagsCountHash: {[key: string]: number};
  editTagsDialogRef: MatDialogRef<EditTagsDialogComponent>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { artIds: ID[], tags: string[]},
    private matDialogRef: MatDialogRef<EditTagsDialogComponent>,
    private dialog: MatDialog,
    public translations: Translations,
    private artService: ArtService,
    private tagService: TagService,
    public myArtBulkSelectionQuery: MyArtBulkSelectionQuery,
    private myArtSearchService: MyArtSearchService,
  ) {
    this.artIds = data.artIds;
    this.tags = [...data.tags];
    this.myArtBulkSelectionQuery.tagsCountForSelectedArts$.subscribe((tagsCountHash) => {
      this.tagsCountHash = tagsCountHash;
    });
  }

  createAndAssignTag(tagName: string): void {
    this.tagService.addNewTag(tagName).subscribe((newTag: Tag) => {
      this.assignTag(newTag.canonicalName);
    });
  }

  assignTag(tagName: string): void {
    this.tags.push(tagName);
    this.artService.assignTagToSelectedArts(tagName, this.artIds).subscribe();
  }

  removeTag(tagName: string): void {
    if (this.tagsCountHash[tagName] > 1) {
      this.editTagsDialogRef = this.dialog.openDialogs.find((dialog: MatDialogRef<any>) => dialog.id === 'EDIT_TAGS_DIALOG');
      if (this.editTagsDialogRef) {
        this.editTagsDialogRef.addPanelClass('hide-dialog');
      }
      this.dialog.open(GenericDataRemovalDailogComponent, <MatDialogConfig> {
        data: <GenericRemovalDialogData>{
          header: this.translations.tag.remove_tag,
          body: this.translations.tag.remove_tag_warning,
          removalData: tagName,
          note: this.translations.tag.remove_tag_warning_note,
          cancelText: this.translations.common.cancel,
          continueText: this.translations.tag.remove_tag
        },
        autoFocus: false,
        panelClass: 'mobile-screen-modal',
        hasBackdrop: false,
        closeOnNavigation: true,
        disableClose: true,
      }).afterClosed()
        .pipe(
          tap(() => {
            if (this.editTagsDialogRef) {
              this.editTagsDialogRef.removePanelClass('hide-dialog');
            }
          }),
          filter((isDelete: boolean) => isDelete),
          tap(() => _remove(this.tags, (tag: string) => tag === tagName)),
          switchMap(() => this.artService.removeTagFromSelectedArts(tagName, this.artIds)),
          // Adding a delay as temporary fix, for an issue in Elastic Search
          delay(500)
        )
        .subscribe(() => this.myArtSearchService.refreshArtSection());
    } else {
      _remove(this.tags, (tag: string) => tag === tagName);
      this.artService.removeTagFromSelectedArts(tagName, this.artIds)
        // Adding a delay as temporary fix, for an issue in Elastic Search
        .pipe(delay(500))
        .subscribe(() => this.myArtSearchService.refreshArtSection());
    }
  }

  close(): void {
    this.matDialogRef.close();
  }

}
