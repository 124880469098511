import { Component, Inject } from '@angular/core';
import { NotificationType, NotificationData } from '@graphics-flow/types';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'graphics-flow-notification-component',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {

  notificationTypes = NotificationType;
  notification: NotificationData;

  constructor(
    public snackBarRef: MatSnackBarRef<NotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA) data: NotificationData
  ) {
    this.notification = data;
  }

  updateNotification(data: NotificationData) {
    this.notification = {
      ...this.notification,
      ...data
    };
  }

}
