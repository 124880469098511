import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Translations } from '@graphics-flow/shared/assets';

@Component({
  selector: 'gf-rename-art-dialog',
  templateUrl: './rename-art-dialog.component.html',
  styleUrls: ['./rename-art-dialog.component.scss']
})
export class RenameArtDialogComponent {
  name: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    public dialogRef: MatDialogRef<RenameArtDialogComponent>,
    public translations: Translations
  ) {
    this.name = data;
  }

  submit() {
    if (!this.name) {
      return;
    }
    this.dialogRef.close(this.name);
  }

  close() {
    this.dialogRef.close();
  }

}
