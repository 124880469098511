import { Component, Input } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';

@Component({
  selector: 'loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent {

  @Input() diameter = 28;
  @Input() showOverlay = false;
  @Input() showInfiniteScrollLoader = false;
  @Input() panelClass: string;

  constructor(public readonly translations: Translations) { }

}
