<div id="folderMoveDialog">
  <div mat-dialog-title class="custom-dialog-title">
    <div class="text-truncate flex-child-auto">{{data.title | translate}}</div>
    <mat-icon class="mat-icon-close cursor-pointer" (click)="close()" fontIcon="close"></mat-icon>
  </div>
  <mat-dialog-content>
    <div *ngIf="showFolderLoader; else folderList" class="flex-container align-center-middle loader-state">
      <loading-indicator [diameter]="30"></loading-indicator>
    </div>
    <ng-template #folderList>
      <div *ngIf="folders$ | async as folders" #dialogElement
           class="folder-list-container p-t-1"
           [class.m-b-4]="windowService.smallDown$ | async">

        <ng-container *ngIf="windowService.mediumUp$ | async; else mobileView">
          <div (click)="selectRootFolder()"
               [class.selected]="selectedFolder && !selectedFolder?.folderId"
               class="align-middle cursor-pointer flex-container move-to-top root-folder p-x-2 p-y-1">
            <mat-icon class="m-r-2" svgIcon="folder-icon"></mat-icon>
            <span class="t-14-600-p">{{translations.art.my_art | translate}}</span>
          </div>
          <ng-container *ngFor="let folder of folders">
            <gf-folder-move-list-item *ngIf="!folder.trashed" [folder]="folder" [sourceFolderIds]="sourceFolderIds"></gf-folder-move-list-item>
          </ng-container>
        </ng-container>

        <ng-template #mobileView>
          <div class="folder-navigation m-b-4">
            <div *ngIf="!!(folderMoveDialogQuery.navigationLevel$ | async); else showFolderIcon" class="folder-level-wrap">
              <mat-icon id="arrowBackIcon" class="m-r-1" (click)="goToParent()" fontIcon="arrow_back"></mat-icon>
              <p id="parentFolderTxt" class="t-18-400-p text-truncate m-b-0">
                {{ (folderMoveDialogQuery.navigationLevelFolder$ | async)?.name }}
              </p>
            </div>
            <ng-template #showFolderIcon>
              <div class="folder-level-wrap folder-item flex-container m-x-3 p-l-2"
                [class.selected]="selectedFolder && !selectedFolder?.folderId" (click)="selectRootFolder()">
                <mat-icon id="folderIcon" class="m-r-2" svgIcon="folder-icon"></mat-icon>
                <p id="parentFolderTxt" class="t-18-400-p text-truncate m-b-0">{{translations.art.my_art | translate}}</p>
              </div>
            </ng-template>
          </div>
          <gf-mobile-view-folder-move-list-item [folders]="folders" [sourceFolderIds]="sourceFolderIds">
          </gf-mobile-view-folder-move-list-item>
        </ng-template>
      </div>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions class="align-right"
                      [class.border]="windowService.smallDown$ | async">
    <button id="cancelBtn"
      class="large"
      mat-dialog-close
      mat-raised-button
      color="secondary">
      <span class="t-15-600-p">{{data.cancelText | translate}}</span>
    </button>
    <button id="submitBtn"
      [disabled]="!selectedFolder"
      [mat-dialog-close]="selectedFolder"
      mat-raised-button
      color="primary"
      class="large">
      <span class="t-15-600-h" [class.white-color]="selectedFolder">{{data.confirmText | translate}}</span>
    </button>
  </mat-dialog-actions>
</div>
