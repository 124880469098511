import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Translations } from '@graphics-flow/shared/assets';
import { CreateArtApprovalForm, DesignRequest, DesignRequestStatus, ID, ImagePreviewType, User } from '@graphics-flow/types';
import { FormHelper, TeamQuery, UserQuery } from '@graphics-flow/util';
import { ControlsOf, FormGroup } from '@ngneat/reactive-forms';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'gf-create-art-approval-dialog',
  templateUrl: './create-art-approval-dialog.component.html',
  styleUrls: ['./create-art-approval-dialog.component.scss']
})
export class CreateArtApprovalDialogComponent implements OnInit {

  artApprovalForm: FormGroup<ControlsOf<CreateArtApprovalForm>>;
  designRequestStatus: typeof DesignRequestStatus = DesignRequestStatus;
  ImagePreviewType: typeof ImagePreviewType = ImagePreviewType;

  constructor(private matDialogRef: MatDialogRef<CreateArtApprovalDialogComponent>,
    public translations: Translations,
    @Inject(MAT_DIALOG_DATA) public data: DesignRequest,
    public readonly teamQuery: TeamQuery,
    public readonly userQuery: UserQuery) {
  }

  ngOnInit(): void {
    this.artApprovalForm = FormHelper.getCreateArtApprovalForm(this.data);
    this.teamQuery.activeTeam$.pipe(
      take(1),
      tap((users: User[]) => {
        const assigneeUser = users.find((user: User) => user.userId === this.data?.assigneeUserId);
        if (assigneeUser) {
          this.setAssigneeUser(assigneeUser.userId);
        } else {
          this.setAssigneeUser(this.userQuery.getActiveUserEntity()?.userId);
        }
      })
    ).subscribe();
  }

  setAssigneeUser(userId: ID): void {
    this.artApprovalForm.get('assigneeId').setValue(userId);
  }

  createApproval(): void {
    this.matDialogRef.close(this.artApprovalForm.value);
  }

  close(): void {
    this.matDialogRef.close();
  }

}
