import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ArtActionsService } from '../../services/art-actions.service';
import { Translations } from '@graphics-flow/shared/assets';
import { Art } from '@graphics-flow/types';
import { OrganizationQuery, StockArtService, UserQuery } from '@graphics-flow/util';

@Component({
  selector: 'gf-stock-art-menu-actions',
  templateUrl: './stock-art-menu-actions.component.html',
  styleUrls: ['./stock-art-menu-actions.component.scss']
})
export class StockArtMenuActionsComponent {
  @Input() art: Art;
  @Input() showCustomizeStockArtOption = false;
  @Output() openStockArtDetails: EventEmitter<void> = new  EventEmitter<void>();

  constructor(
    public readonly artActionsService: ArtActionsService,
    public readonly stockArtService: StockArtService,
    public readonly translations: Translations,
    public readonly organizationQuery: OrganizationQuery,
    public readonly userQuery: UserQuery
    ) { }

  downloadStockArt(approvalItemArt: Art, extensionName: string): void {
    this.stockArtService.downloadStockArt(approvalItemArt, extensionName, false, true, false).subscribe();
  }

  updateStockArtVisibility(isHide: boolean): void {
    this.stockArtService.hideStockArt(this.art, isHide);
  }

}
