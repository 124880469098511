export const colorPalette: string[] = ['#800080', '#FF00FF', '#000080', '#0000FF', '#008080', '#00FFFF', '#008000',
  '#800080', '#FF00FF', '#000080', '#0000FF', '#008080', '#00FFFF', '#008000',
  '#800080', '#FF00FF', '#000080', '#0000FF', '#008080', '#00FFFF', '#008000',
  '#800080', '#FF00FF', '#000080', '#0000FF', '#008080', '#00FFFF', '#008000',
  '#800080', '#FF00FF', '#000080', '#0000FF', '#008080', '#00FFFF', '#008000',
  '#800080', '#FF00FF', '#000080', '#0000FF', '#008080', '#00FFFF', '#008000',
  '#800080', '#FF00FF', '#000080', '#0000FF', '#008080', '#00FFFF', '#008000',
  '#800080', '#FF00FF', '#000080', '#0000FF', '#008080', '#00FFFF', '#008000',
  '#800080', '#FF00FF', '#000080', '#0000FF', '#008080', '#00FFFF', '#008000',
  '#800080', '#FF00FF', '#000080', '#0000FF', '#008080', '#00FFFF', '#008000'];
