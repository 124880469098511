import { Injectable, NgZone } from '@angular/core';
import { MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { isUndefined as _isUndefined } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';

import {
  ApiResponse,
  ArtFolderPackage,
  BulkActionPostData,
  WebJob,
  Folder,
  MyArtActiveQueryParams,
  NotificationData,
  NotificationType,
  ID
} from '@graphics-flow/types';
import { NotificationComponent, NotificationService } from 'shared/ui';
import { Translations } from '@graphics-flow/shared/assets';
import { FileHelpers } from 'shared/util';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class FolderHttpService {
  snackBarRef: MatSnackBarRef<NotificationComponent>;
  snackBarConfig: MatSnackBarConfig = {
    horizontalPosition: 'start',
    verticalPosition: 'bottom',
  }

  constructor(
    private readonly httpService: HttpService,
    private ngZone: NgZone,
    private notificationService: NotificationService,
    private translations: Translations,
    private translationService: TranslateService
  ) {}

  getFolders(params: MyArtActiveQueryParams): Observable<Folder[]> {
    const folderParams: MyArtActiveQueryParams = <MyArtActiveQueryParams>{
      isGlobalFilter: !!params?.globalSearchText,
      sortBy: params?.sortBy,
      orderDesc: params?.orderDesc || false,
      searchText: (params?.globalSearchText || params?.filterSearchText) ?? '',
      trashed: params?.trashed
    };

    if (!_isUndefined(params?.folderId)) {
      folderParams.parentId = params?.folderId;
    }
    return this.httpService.post(this.getUrl('GetFolders'), folderParams).pipe(
      map((response: ApiResponse<Folder[]>) => response.data)
    );
  }

  create(folder: Folder): Observable<Folder> {
    return this.httpService.post(this.getUrl('CreateFolder'), folder).pipe(
      map((response: ApiResponse<Folder>) => response.data)
    );
  }

  update(folder: Folder): Observable<Folder> {
    return this.httpService.post(this.getUrl('UpdateFolder'), folder).pipe(
      map((response: ApiResponse<Folder>) => response.data)
    );
  }

  delete(folderId: ID): Observable<ArtFolderPackage> {
    return this.deleteArtAndFolders([], [folderId]);
  }

  getApprovalsByFolderId(folderId: ID): Observable<any> {
    const path: string = 'ListArtReferencesByFolder\/' + folderId?.toString();
    return this.httpService.get(this.getUrl(path)).pipe(
      map((response: ApiResponse<any>) => response.data)
    )
  }

  downloadArtsAndFolders(artIds: ID[], folderIds: ID[], name: string, includeAllExtentions: boolean = false, includeAllVersions: boolean = false) {

    this.snackBarRef = this.notificationService.showFileNotification(NotificationType.INPROGRESS,
      this.translationService.instant(this.translations.art.downloading),
      name
    );

    const path: string = this.getUrl('DownloadZipPackage');
    const postData: BulkActionPostData = {
      artIds,
      folderIds,
      includeAllExtentions,
      includeAllVersions
    };
    this.downloadFiles(path, postData, `${name}.zip`);
  }

  closeNotification() {
    this.ngZone.run(() => {
      setTimeout(() => {
        this.snackBarRef.dismissWithAction();
      }, 3000);
    });
  }

  deleteArtAndFolders(artIds: ID[], folderIds: ID[]): Observable<ArtFolderPackage> {
    const postData: BulkActionPostData = {
      artIds: artIds || [],
      folderIds: folderIds || [],
      trashed : true
    };
    return this.httpService.post(this.getUrl('TrashArtAndFolders'), postData).pipe(
      map((response: ApiResponse<ArtFolderPackage>) => response.data)
    );
  }

  moveToFolder(artIds: ID[], folderIds: ID[], parentFolderId?: ID): Observable<ArtFolderPackage> {
    const postData: BulkActionPostData = {
      artIds: artIds || [],
      folderIds: folderIds || [],
      parentFolderId: parentFolderId ? parentFolderId : null
    };

    return this.httpService.post(this.getUrl('MoveToFolder'), postData).pipe(
      map((response: ApiResponse<any>) => response.data)
    );
  }

  saveCopyToMyArt(artId: ID, parentFolderId: ID = null) {
    const params = new FormData();
    params.append('artId', artId?.toString());
    params.append('parentFolderId', parentFolderId?.toString());
    return this.httpService.post(this.getUrl('SaveCopyToMyArt'), params).pipe(
      map(response => response.data)
    );
  }

  downloadFiles(path: string, postData: BulkActionPostData, name: string) {
    this.httpService.downloadFileViaPost(path, postData, name)
      .pipe(
        tap((res) => {
          FileHelpers.downloadFile(res.data, res.filename);
          // TODO: Move this out of the http service
          this.snackBarRef.instance.updateNotification(<NotificationData>{
            type: NotificationType.SUCCESS,
            title: this.translationService.instant(this.translations.art.download_complete)
          });
        }),
        finalize(() => {
          this.closeNotification()
        })
      ).subscribe();
  }

  getBreadCrumbs(folderId?: ID): Observable<Folder[]> {
    const params: string = folderId ? `folderId=${folderId}` : '';
    return this.httpService.get(this.getUrl(`GetBreadCrumbs?${params}`)).pipe(
      map((response: ApiResponse<Folder[]>) => response.data)
    );
  }

  getAllArtIds(params: MyArtActiveQueryParams): Observable<ID[]> {
    const artParams: MyArtActiveQueryParams = <MyArtActiveQueryParams>{
      isGlobalFilter: !!params?.globalSearchText,
      sortBy: params.sortBy,
      orderDesc: params?.orderDesc || false,
      searchText: (params?.globalSearchText || params?.filterSearchText) ?? '',
      trashed: params?.trashed,
      index: 0,
      limit: 9999,
      tags: params.filterTags?.map(tag => tag.canonicalName) ?? [],
      folderId: params?.folderId
    };

    if (params?.artType) {
      artParams.artType = params?.artType;
    }

    return this.httpService.post(this.getUrl('GetAllArtIds'), artParams).pipe(
      map((response: ApiResponse<ID[]>) => response.data)
    );
  }

  permanentlyDeleteFolder(folderId: ID): Observable<WebJob> {
    const params = new FormData();
    params.append('parentFolderId', folderId?.toString());
    return this.httpService.post(this.getUrl('DeleteFolder'), params).pipe(
      map((response: ApiResponse<WebJob>) => response.data)
    )
  }

  private getUrl(api: string): string {
    return this.httpService.getUrl(`art/${api}`);
  }
}
