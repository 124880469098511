import { Injectable } from '@angular/core';
import { pull as _pull } from 'lodash-es';
import { setProp } from '@ngneat/elf';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { delay, filter, switchMap, take, tap } from 'rxjs/operators';

import { OrganizationHttpService } from '@graphics-flow/api';
import { OrganizationPaymentOption, OrganizationResourceUsage, UpdateSubscriptionData } from '@graphics-flow/types';
import { BillingPlansQuery } from './billing-plans.query';
import { BillingPlansStore } from './billing-plans.store';

@Injectable({ providedIn: 'root' })
export class BillingPlansService {
  resourceUpdate$: Subscription;
  ignoreSmartDesignerAdditionalDeviceOnSubmit = false;

  constructor(
    private billingQuery: BillingPlansQuery,
    private billingPlansStore: BillingPlansStore,
    private organizationHttpService: OrganizationHttpService
  ) { }

  getOrganizationResourceUsage(): Observable<OrganizationResourceUsage> {
    return this.organizationHttpService.getOrganizationResourceUsage()
      .pipe(
        tap((resourceUsage: OrganizationResourceUsage) => {
          this.billingPlansStore.update(setProp('resourceUsage', resourceUsage));
        })
      );
  }

  getOrganizationPaymentOptions(): Observable<OrganizationPaymentOption> {
    return this.organizationHttpService.getOrganizationPaymentOptions().pipe(
      tap((paymentOptions: OrganizationPaymentOption) => {
        this.billingPlansStore.update(setProp('paymentOptions', paymentOptions));
      })
    );
  }

  updateOrganizationSubscription(updateSubscription: UpdateSubscriptionData): Observable<OrganizationResourceUsage> {
    return this.organizationHttpService.updateOrganizationSubscription(updateSubscription);
  }

  updateResourceUsageInBackground() {
    this.resourceUpdate$?.unsubscribe();

    this.resourceUpdate$ = combineLatest([this.billingQuery.storageUseInPercentage$, this.billingQuery.stockArtDownloadUseInPercentage$]).pipe(
      take(1),
      filter(([storage, stockArtDownload]: number[]) => {
        return storage >= 85 || stockArtDownload >= 85;
      }),
      delay(10000),
      switchMap(() => this.getOrganizationResourceUsage())
    ).subscribe();
  }

  incrementStockArtDownloads() {
    this.billingPlansStore.update(
      setProp('resourceUsage', (state: OrganizationResourceUsage) => ({
        ...state,
        stockArtDownloads: state.stockArtDownloads + 1
      }))
    );
  }

  sendSmartDesignerEmail(emails: string[]): Observable<boolean> {
    return this.organizationHttpService.sendSmartDesignerEmail(emails);
  }

  removeInstalledDevice(deviceName: string): Observable<boolean> {
    return this.organizationHttpService.removeInstalledDevice(deviceName);
  }
  // Used to validate the addition of SdAdditionalDeviceOn Add on
  updateSdAdditionalDeviceOnSubmit(value): void {
    this.ignoreSmartDesignerAdditionalDeviceOnSubmit = value;
  }

  updateSdMachineInfo(resourceUsage: OrganizationResourceUsage, deviceName: string): void {
    if (!deviceName) {
      return;
    }
    resourceUsage.smartDesignerMachines = _pull(resourceUsage.smartDesignerMachines, deviceName);
    this.billingPlansStore.update(setProp('resourceUsage', resourceUsage));
  }

  isAddOnUpdatedAndPartOfSubscription(resourceUsage: OrganizationResourceUsage, updatedAddOnQty: number, productName: string): boolean {
    const subscription = resourceUsage?.organizationBilling?.subscription;
    const selectedAddOn = subscription?.items.find((item) => item.price.productName === productName);
    return !!selectedAddOn && selectedAddOn?.quantity === updatedAddOnQty;
  }

  resetCurrentSubscriptionItems(): void {
    const currentResourceUsage: OrganizationResourceUsage = this.billingPlansStore.getValue().resourceUsage;
    if (currentResourceUsage) {
      currentResourceUsage.organizationBilling.subscription.items = [];
      this.billingPlansStore.update(setProp('resourceUsage', currentResourceUsage));
    }
  }
}
