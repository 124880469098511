import { Injectable } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { ImagePreviewType, User } from '@graphics-flow/types';
import { ImagePreviewPipe } from './image-preview.pipe';

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'userProfilePicture'
})

export class UserProfilePicturePipe implements PipeTransform {
  constructor(
    private readonly previewUrlPipe: ImagePreviewPipe
  ) {
  }
  transform(user: User, imagePreviewType: ImagePreviewType) {
    if (!user || !user?.profileAssetExts) {
      return;
    }

    const url = this.previewUrlPipe.transform(user.assignedOrganizationId, user.profileAssetGuid.toString(), imagePreviewType, false, false, 'png');
    return url;
  }
}
