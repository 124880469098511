import { Pipe, PipeTransform } from '@angular/core';
import { GlobalHelpers } from '@graphics-flow/util';

@Pipe({
  name: 'borderColor'
})
export class BorderColorPipe implements PipeTransform {

  transform(value: string): any {
    return GlobalHelpers.getBorderColor(value);
  }

}
