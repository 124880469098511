import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ApiResponse,
  Organization,
  OrganizationPaymentOption,
  OrganizationResourceUsage,
  SearchResults,
  UpdateSubscriptionData,
  StripeSessionSubscriptionCheckout,
  Watermark,
  ContactUsForm,
  CustomerViewCatalogInfo
} from '@graphics-flow/types';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationHttpService {

  constructor(private readonly httpService: HttpService) {}

  createOrganization(org: Partial<Organization>): Observable<Organization> {
    return this.httpService.put('TODO', org).pipe(
      map((response: ApiResponse<Organization>) => response.data)
    );
  }

  getOrganization(): Observable<Organization> {
    return this.httpService.get(this.getUrl(`GetOrganization`)).pipe(
      map((response: ApiResponse<Organization>) => response.data)
    );
  }

  getOrganizationPaymentOptions(): Observable<OrganizationPaymentOption> {
    return this.httpService.get(this.httpService.getUrl(`GetPaymentOptions`, false)).pipe(
      map((response: ApiResponse<OrganizationPaymentOption>) => response.data)
    );
  }

  updateOrganization(organization: Organization): Observable<Organization> {
    return this.httpService.post(this.getUrl(`UpdateOrganization`), organization).pipe(
      map((response: ApiResponse<Organization>) => response.data)
    );
  }

  updateOrganizationSubscription(updateSubscription: UpdateSubscriptionData): Observable<OrganizationResourceUsage> {
    return this.httpService.post(this.getUrl(`UpdateOrganizationSubscription`), updateSubscription).pipe(
      map((response: ApiResponse<OrganizationResourceUsage>) => response.data)
    );
  }

  searchOrganization(
    searchString: string,
    includeArt: boolean,
    includeArtApprovals: boolean,
    includeFolders: boolean
  ): Observable<SearchResults> {
    const params: FormData = new FormData();
    params.append('searchString', searchString);
    params.append('includeArt', JSON.stringify(includeArt));
    params.append('includeArtApprovals', JSON.stringify(includeArtApprovals));
    params.append('includeFolders', JSON.stringify(includeFolders));
    return this.httpService.post(this.getUrl('SearchOrganization'), params).pipe(
      map((response: ApiResponse<SearchResults>) => response.data)
    );
  }

  saveWatermark(watermark: Watermark): Observable<Organization> {
    return this.httpService.post(this.getUrl(`SaveWatermark`), watermark).pipe(
      map((response: ApiResponse<Organization>) => response.data)
    )
  }

  getOrganizationResourceUsage(): Observable<OrganizationResourceUsage> {
    return this.httpService.get(this.getUrl('GetOrganizationResourceUsage')).pipe(
      map((response: ApiResponse<OrganizationResourceUsage>) => response.data)
    );
  }

  createBillingSessionSubscriptionCheckout(checkoutSession: StripeSessionSubscriptionCheckout): Observable<string> {
    return this.httpService.post(this.getUrl('CreateBillingSessionSubscriptionCheckout'), checkoutSession).pipe(
      map((response: ApiResponse<string>) => response.data)
    );
  }

  getCreateBillingSessionUpdatePaymentMethod(successUrl: string, cancelUrl: string): Observable<string> {
    const formData = new FormData();
    formData.append('successUrl', successUrl);
    formData.append('cancelUrl', cancelUrl);
    return this.httpService.post(this.getUrl('CreateBillingSessionUpdatePaymentMethod'), formData).pipe(
      map((response: ApiResponse<string>): string => response.data)
    );
  }

  getViewPaymentHistorySession(): Observable<string> {
    const params = new HttpParams().set('returnUrl', `${window.location.origin}/team-settings/plans-and-billing`);
    return this.httpService.get(this.getUrl(`GetViewPaymentHistorySession?${params.toString()}`)).pipe(
      map((response: ApiResponse<string>): string => response.data)
    );
  }

  getCatalogSettings(): Observable<CustomerViewCatalogInfo> {
    return this.httpService.get(this.httpService.getUrl(`organizations/GetCatalogSettings`)).pipe(
      map((response: ApiResponse<CustomerViewCatalogInfo>) => response.data)
    );
  }

  contactUsRequest(contactUs: ContactUsForm): Observable<boolean> {
    return this.httpService.post(this.httpService.getUrl(`organizations/ContactUsRequest/${contactUs.recaptcha}`), contactUs).pipe(
      map((response: ApiResponse<boolean>) => response.data)
    )
  }

  sendSmartDesignerEmail(emails: string[]): Observable<boolean> {
    const formData: FormData = new FormData();
    emails.forEach((email: string) => {
      formData.append('emails', `[${JSON.stringify(email)}]`);
    });
    return this.httpService.post(this.getUrl('SendSmartDesignerEmail'), formData).pipe(
      map((response: ApiResponse<boolean>) => response.data)
    );
  }

  removeInstalledDevice(deviceName: string): Observable<boolean> {
    const formData: FormData = new FormData();
    formData.append('machineName', deviceName.toString());
    return this.httpService.post(this.getUrl('RemoveMachine'), formData).pipe(
      map((response: ApiResponse<boolean>) => response.data)
    );
  }

  private getUrl(api: string): string {
    return this.httpService.getUrl(`organizations/${api}`);
  }
}
