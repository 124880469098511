import { Injectable } from '@angular/core';
import { getEntity, selectAllEntitiesApply, selectEntitiesCountByPredicate, selectEntity, selectMany } from '@ngneat/elf-entities';
import { Observable } from 'rxjs';
import { Art, ID } from '@graphics-flow/types';
import { ArtStore } from './art.state';

@Injectable({
  providedIn: 'root'
}) // Provided in the module you want to use it so we can have multiple instances
export class ArtQuery {
  customizedArts$: Observable<Art[]> = this.selectAllArtEntitiesByApply({
    filterEntity: (art: Art) => !!art?.customizedStockArtId
  });

  constructor(protected artStore: ArtStore) {
  }

  // Query Methods.
  // Select Methods - returns value as Observable.
  selectArt(artId: ID): Observable<Art> {
    return this.artStore.pipe(selectEntity(artId));
  }

  selectManyArts(artIds: ID[]): Observable<Art[]> {
    return this.artStore.pipe(selectMany(artIds));
  }

  selectAllArtEntitiesByApply(predicate): Observable<Art[]> {
    return this.artStore.pipe(selectAllEntitiesApply(predicate));
  }

  selectArtCountByPredicate(predicate):  Observable<number> {
    return this.artStore.pipe(selectEntitiesCountByPredicate(predicate));
  }

  // Get Methods - returns Raw Value.
  getArtById(id: ID): Art {
    return this.artStore.query(getEntity(id));
  }
}
