import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ApiResponse,
  ArtApproval,
  ArtApprovalStatus,
  CreateArtApprovalForm,
  DesignRequest,
  DesignRequestQueryParam,
  ID,
  IUser,
  UploadArt
} from '@graphics-flow/types';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class DesignRequestHttpService {

  constructor(private readonly httpService: HttpService) {}

  getDesignRequestSummary(designRequestFilter: DesignRequestQueryParam): Observable<ApiResponse<DesignRequest[]>> {
    const postData: DesignRequestQueryParam = {
      index: designRequestFilter.index,
      limit: designRequestFilter.limit,
    };

    // While filtering by search text, we need to disable other filters
    if (designRequestFilter?.searchText) {
      postData.searchText = designRequestFilter.searchText;
    } else {
      if (designRequestFilter?.status) {
        postData.status = designRequestFilter.status;
      }
      if (designRequestFilter?.assigneeStatus) {
        postData.assigneeStatus = designRequestFilter.assigneeStatus;
        postData.assigneeUserId = designRequestFilter.assigneeUserId;
      }
    }

    return this.httpService.post(this.getUrl(`GetDesignRequestSummary`), postData).pipe(
      map((response: ApiResponse<DesignRequest[]>) => {
        return response;
      })
    );
  }

  getDesignRequest(id: ID): Observable<DesignRequest> {
    if (!id) {
      return;
    }
    const params: string = id ? `designRequestId=${id}` : '';
    return this.httpService.get(this.getUrl(`GetDesignRequest?${params}`)).pipe(
      map((response: ApiResponse<DesignRequest>) => response.data)
    )
  }

  submitDesignRequest(designRequest: DesignRequest, captchaToken: string): Observable<DesignRequest> {
    return this.httpService.post(this.getUrl(`SubmitDesignRequest/${captchaToken}`), designRequest).pipe(
      map((response: ApiResponse<DesignRequest>) => response.data)
    )
  }

  saveDesignRequest(designRequest: DesignRequest): Observable<DesignRequest> {
    return this.httpService.post(this.getUrl(`SaveDesignRequest`), designRequest).pipe(
      map((response: ApiResponse<DesignRequest>) => response.data)
    );
  }

  downloadDesignRequestArt(id: ID, fileName: string) {
    return this.httpService.downloadFile(this.getUrl(`DownloadDesignRequestArt?designRequestId=${id}`), fileName);
  }

  deleteDesignRequest(id: ID): Observable<DesignRequest> {
    const params = new FormData();
    params.append('designRequestId', id?.toString());
    return this.httpService.post(this.getUrl('DeleteDesignRequest'), params).pipe(
      map((response: ApiResponse<DesignRequest>) => response.data)
    )
  }

  addDesignRequestToArtApproval(id: ID, artApprovalId: ID): Observable<DesignRequest> {
    const params = new FormData();
    params.append('designRequestId', id?.toString());
    params.append('artApprovalId', artApprovalId?.toString());
    return this.httpService.post(this.getUrl('AddDesignRequestToArtApproval'), params).pipe(
      map((response: ApiResponse<DesignRequest>) => response.data)
    )
  }

  updateDesignRequest(designRequest: DesignRequest): Observable<DesignRequest> {
    return this.httpService.post(this.getUrl(`UpdateDesignRequest`), designRequest).pipe(
      map((response: ApiResponse<DesignRequest>) => response.data)
    )
  }

  updateDesignRequestStatus(id: ID, status: ArtApprovalStatus): Observable<DesignRequest> {
    const params = new FormData();
    params.append('designRequestId', id?.toString());
    params.append('status', status);
    return this.httpService.post(this.getUrl('UpdateDesignRequestStatus'), params).pipe(
      map((response: ApiResponse<DesignRequest>) => response.data)
    );
  }

  saveDesignRequestNotes(id: ID, notes: string): Observable<DesignRequest> {
    const params = new FormData();
    params.append('designRequestId', id?.toString());
    params.append('internalNotes', notes?.toString());
    return this.httpService.post(this.getUrl('SaveDesignRequestNotes'), params).pipe(
      map((response: ApiResponse<DesignRequest>) => response.data)
    );
  }

  createArtApprovalFromDesignRequest(artApprovalForm: CreateArtApprovalForm): Observable<ArtApproval> {
    const params = new FormData();
    params.append('designRequestId', artApprovalForm.designRequestId.toString());
    params.append('assigneeId', artApprovalForm.assigneeId.toString());
    params.append('artApprovalName', artApprovalForm.artApprovalName);
    params.append('description', artApprovalForm.description);
    params.append('archive', artApprovalForm?.archive?.toString());
    return this.httpService.post(this.getUrl('CreateArtApprovalFromDesignRequest'), params).pipe(
      map((response: ApiResponse<ArtApproval>) => response.data)
    );
  }

  getDesignRequestAssignedUsers(): Observable<IUser[]> {
    return this.httpService.get(this.getUrl('GetDesignRequestAssignees')).pipe(
      map((response: ApiResponse<IUser[]>) => response.data)
    )
  }

  getDesignRequests(artApprovalId: ID): Observable<DesignRequest[]> {
    const params: URLSearchParams = new URLSearchParams();
    params.append('artApprovalId', artApprovalId?.toString());
    return this.httpService.get(this.getUrl(`GetDesignRequests?${params.toString()}`)).pipe(
      map((response: ApiResponse<DesignRequest[]>) => response.data)
    );
  }

  deleteUploadAttachmentInDesignRequest(artId: ID) {
    return this.httpService.post(`${this.getUrl('DeleteUploadedDRArt')}?artId=${artId}`).pipe(
      map((response) => response.data)
    );
  }

  uploadAttachmentInDesignRequest(file: File, designRequestId: ID): Observable<UploadArt> {
    const params = new FormData();
    params.append('file', file, file.name);
    params.append('designRequestId', designRequestId.toString());
    return this.httpService.post(`${this.getUrl('UploadDesignRequestArt')}`, params).pipe(
      map((response: ApiResponse<UploadArt>) => response.data)
    );
  }

  private getUrl(api: string): string {
    return this.httpService.getUrl(`DesignRequest/${api}`);
  }
}
