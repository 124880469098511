import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Translations } from '@graphics-flow/shared/assets';
import { FileQueueStatus } from '@graphics-flow/types';
import { FileQueueObject, MyArtUploaderService } from '@graphics-flow/util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ArtActionsService } from './../../services/art-actions.service';

@UntilDestroy()
@Component({
  selector: 'gf-my-art-file-upload',
  templateUrl: './my-art-file-upload.component.html',
  styleUrls: ['./my-art-file-upload.component.scss']
})
export class MyArtFileUploadComponent implements OnInit, AfterViewInit {

  @ViewChild('myArtFileUploadTemplate') myArtFileUploadTemplate: CdkPortal;
  config: OverlayConfig;
  count = 0;
  fileStatus: typeof FileQueueStatus = FileQueueStatus;
  myArtUploadStratergy;
  overlayRef: OverlayRef;
  queue$: Observable<FileQueueObject[]>;
  uploadedFiles$: Observable<FileQueueObject[]>;
  watchOverUploadPanel$: Observable<[FileQueueObject[], boolean, boolean]>;

  fileUploadMessageMap: { [k: string]: string } = {
    '=0': this.translations.fileupload.uploaded_zero_file,
    '=1': this.translations.fileupload.uploaded_single_file,
    'other': this.translations.fileupload.uploaded_n_files,
  };

  fileUploadFailMessage: { [k: string]: string } = {
    '=1': this.translations.fileupload.upload_single_file_failed,
    'other': this.translations.fileupload.upload_n_files_failed,
  };

  fileUploadingMessageMap: { [k: string]: string } = {
    '=1': this.translations.fileupload.uploading_single_file,
    'other': this.translations.fileupload.uploading_n_files,
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public overlay: Overlay,
    public artActionsService: ArtActionsService,
    public readonly myArtUploaderService: MyArtUploaderService,
    public readonly translations: Translations,
  ) {
    this.router.events.pipe(
      untilDestroyed(this),
      filter((e) => e instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while(child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['showFileUploadPanel']) {
            return child.snapshot.data['showFileUploadPanel'];
          } else {
            return false;
          }
        }
      })
    ).subscribe((showFileUploadPanel: boolean) => {
      if (!this.overlayRef || !this.overlayRef.hasAttached()) {
        return;
      }
      if (!showFileUploadPanel) {
        this.myArtUploaderService.hidePanel();
      }
    });
  }

  ngOnInit(): void {
    this.uploadedFiles$ = this.myArtUploaderService.queue;
    this.watchOverUploadPanel$ = combineLatest([this.myArtUploaderService.queue, this.myArtUploaderService.isShowPanel$, this.myArtUploaderService.isUploadPreparing$]);
  }

  ngAfterViewInit() {
    this.myArtUploadStratergy = this.overlay
      .position()
      .global()
      .left('270px')
      .bottom('30px');

    this.config = new OverlayConfig({
      positionStrategy: this.myArtUploadStratergy
    });

    this.overlayRef = this.overlay.create(this.config);

    this.uploadedFiles$.pipe(
      untilDestroyed(this),
      filter((files: FileQueueObject[]) => {
        // avoid empty array and same count again and again
        return (this.count !== files.length)
      })
    ).subscribe((fileQueueObjects: FileQueueObject[]) => {
      if (fileQueueObjects.length <= 5) {
        // very first time
        this.myArtUploaderService.paralledUploadUpToFive(0, fileQueueObjects.length);
        this.count = fileQueueObjects.length;
        return;
      }

      // second or nth time
      const inProgressFileCount = this.myArtUploaderService.inProgressCount();
      // If already 5 files are in process then don't upload anyting immedietly.. the queue will take care of the next one.
      if (inProgressFileCount < 5) {
        // less than 5 files are in upload now
        // eligible for parrallel upload
        const firstPendingFilePos = this.myArtUploaderService.firstPendingFilePosition();
        let limit = (firstPendingFilePos + (5 - inProgressFileCount)); // Get Next Number of files
        // Make sure it didn't go beyand queue length
        if (limit > fileQueueObjects.length) {
          limit = fileQueueObjects.length;
        }
        this.myArtUploaderService.paralledUploadUpToFive(firstPendingFilePos, limit);
      }
      this.count = fileQueueObjects.length;
    });

    this.watchOverUploadPanel$.pipe(
      untilDestroyed(this),
    ).subscribe(([queue, status, inProgress]: [FileQueueObject[], boolean, boolean]) => {
      if ((queue?.length > 0 || inProgress) && status && !this.overlayRef.hasAttached()) {
        this.overlayRef.attach(this.myArtFileUploadTemplate);
      } else if (!status && this.overlayRef.hasAttached()) {
        this.overlayRef.detach();
      }
    });

    this.queue$ = this.myArtUploaderService.queue.pipe(
      map((files: FileQueueObject[]) => {
        const orderedFiles = files.filter(f => f.inProgress());
        orderedFiles.push(...files.filter(f => f.isPending()));
        orderedFiles.push(...files.filter(f => f.isSuccess()));
        orderedFiles.push(...files.filter(f => f.isError()));
        return orderedFiles;
      })
    );
  }

  openFile(file: FileQueueObject): void {
    if (file.status === this.fileStatus.Success && file.showFolder) {
      this.router.navigate(['/my/detail/' + file.artId]);
    }
  }
}
