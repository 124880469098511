import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Art, StockArt } from '@graphics-flow/types';
import { EventHelpers } from 'shared/util';
import { Translations } from '@graphics-flow/shared/assets';
import { ArtService, CustomizeStockArtService, StockArtService } from '@graphics-flow/util';
import { ArtActionsService } from '../../services/art-actions.service';

@Component({
  selector: 'gf-font-card',
  templateUrl: './font-card.component.html',
  styleUrls: ['./font-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FontCardComponent {
  @Input() font: StockArt;
  @Input() art: Art;
  @Input() small = false;
  @Input() selectable: boolean;
  @Input() customizeStockArt = false;

  constructor(
    public readonly translations: Translations,
    private readonly stockArtService: StockArtService,
    public readonly artActionsService: ArtActionsService,
    public customizeStockArtService: CustomizeStockArtService,
    public readonly artService: ArtService
  ) {
  }

  stopAndPrevent(event: any) {
    EventHelpers.stopAndPrevent(event);
  }

  downloadFont() {
    this.stockArtService.downloadFont(this.art);
  }
}
