import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxFilesizeModule } from 'ngx-filesize';
import { DownloadLimitExceedDialogComponent } from './components/download-limit-exceed-dialog/download-limit-exceed-dialog.component';
import { StorageLimitExceedDialogComponent } from './components/storage-limit-exceed-dialog/storage-limit-exceed-dialog.component';
import { IntersectionObserverDirective } from './directives/intersection-observer.directive';
import { MultipleEmailInputDirective } from './directives/multiple-email-input.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { TrimDirective } from './directives/trim.directive';

const DIRECTIVES = [
  MultipleEmailInputDirective,
  OnlyNumberDirective,
  IntersectionObserverDirective,
  TrimDirective
];

const COMPONENTS = [
  DownloadLimitExceedDialogComponent,
  StorageLimitExceedDialogComponent
];

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    RouterModule,
    MatProgressBarModule,
    MatDialogModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    NgxFilesizeModule,
    TranslateModule
  ],
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES
  ],
  exports: [
    ...COMPONENTS,
    ...DIRECTIVES
  ],
  providers: [
    DatePipe
  ]
})
export class GfUtilModule {}
