import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
 ApiResponse,
 Art,
 ID,
 StockArtHeaderPackage,
 StockArtPackage,
 StockArtRequest,
 StockArtType,
 UploadArt
} from '@graphics-flow/types';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class StockArtHttpService {

  constructor(private httpService: HttpService) {
  }

  getStockArts(request: StockArtRequest): Observable<ApiResponse<StockArtPackage>> {
    return this.httpService.post(this.getUrl(`GetStockArts`), request);
  }

  getStockArtHeaders(type: StockArtType = StockArtType.DesignIdea): Observable<StockArtHeaderPackage> {
    return this.httpService.get(this.getUrl(`GetStockArtHeaders`) + `?type=${type}`).pipe(
      map((response: ApiResponse<StockArtHeaderPackage>) => response.data)
    );
  }

  downloadStockArt(art: Art, extension: string, useParentId: boolean, isStockArt?: boolean): Observable<{ data, filename: string }> {
    const params: string = `?organizationId=${useParentId ? art.stockArtOrgId : art.assignedOrganizationId}`
      + `&artId=${useParentId ? art.parentId : art.artId}`
      + `&fileExtension=${extension}`
      + `&isStockArt=${isStockArt}`;
    return this.httpService.downloadFile(this.getUrl('DownloadFile') + params, `${art.name}.${extension}`);
  }

  downloadAllFonts(fileName: string) {
    return this.httpService.downloadFile(this.getUrl(`DownloadAllFonts`), fileName);
  }

  downloadStockArtFonts(stockArtId: ID, fileName: string) {
    const params = `?stockArtId=${stockArtId}`;
    return this.httpService.downloadFile(this.getUrl('downloadFonts') + params, fileName);
  }

  getStockArtDetail(stockArtId: ID): Observable<ApiResponse<StockArtPackage>> {
    return this.httpService.get(this.getUrl(`GetStockArt?stockArtId=${stockArtId}`));
  }

  hideStockArt(stockArtId: ID, stockArtTypeNum: number, isHide: boolean): Observable<ApiResponse<boolean>> {
    const params: FormData = new FormData();
    params.append('artId', stockArtId?.toString());
    params.append('hide', `${isHide}`);
    params.append('stockArtType', stockArtTypeNum.toString());
    return this.httpService.post(this.getUrl(`HideStockArtInAP`), params);
  }

  private getUrl(api: string): string {
    return this.httpService.getUrl(`art/${api}`);
  }

  downloadDesignRequestUploadedArt(art: UploadArt, assignedOrganizationId: ID): Observable<{ data: Blob, filename: string }> {
    const params: string = `?organizationId=${assignedOrganizationId}`
      + `&artId=${art.artId}`
      + `&fileExtension=${art.fileName.substr(art.fileName.lastIndexOf('.') + 1)}`
      + `&isStockArt=${false}`;
    return this.httpService.downloadFile(this.getUrl('DownloadFile') + params, `${art.fileName}`);
  }
}
