import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { BillingPlansQuery } from '../data/billing-plans/billing-plans.query';
import { StockArtDetailSections } from '@graphics-flow/types';
import { GlobalHelpers } from '../helpers/global.helpers';

@Injectable({
  providedIn: 'root'
})
export class ActiveSectionQueryParamGuard {
  constructor(
    private billingPlansQuery: BillingPlansQuery,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const currentStateUrl = state.url.split('?')[0];
    const activeSection = <StockArtDetailSections>GlobalHelpers.getValidQueryParamForDetailView(route.queryParams?.activeSection,
      route.data?.isMyArtDetailView);

    if (!this.isQueryParamValidForDetailView(route.queryParams?.activeSection)
      || (route.data?.isMyArtDetailView && route.queryParams?.activeSection === StockArtDetailSections.STOCK_ART_CUSTOMIZATIONS)) {
      this.navigateToDefaultInfoSection(currentStateUrl, activeSection);
      return false;
    }

    if (this.isQueryParamValidForDetailView(activeSection, 1)) {
      return this.billingPlansQuery.isCustomizeStockArtEnabled$.pipe(
        tap((isCustomizeStockArtEnabled) => {
          if (!isCustomizeStockArtEnabled) {
            this.navigateToDefaultInfoSection(currentStateUrl);
          }
        })
      );
    }

    return true;
  }

  navigateToDefaultInfoSection(url: string, activeSection: StockArtDetailSections = StockArtDetailSections.INFO): void {
    this.router.navigate([url], {
      queryParams: {
        activeSection
      },
      replaceUrl: true
    });
  }

  /**
   * @param activeSection
   * @param checkFromGivenIndex - "includes" method checks from the given index.
   * @returns TRUE, If the given activeSection is available & valid to proceed.
   *
   * @description: Used to check whether the given activeSection is valid or not. When we need to check only the
   * [CUSTOMIZE, STOCK_ART_CUSTOMIZATIONS], then we need to pass "checkFromGivenIndex" as "1".
   * So the "includes" method starts searching from this given INDEX, so we can achieve checking only the customize related options.
   *
   */
  isQueryParamValidForDetailView(activeSection: StockArtDetailSections, checkFromGivenIndex: number = 0): boolean {
    // I'm restricted, stay away from me...
    return ([StockArtDetailSections.INFO, StockArtDetailSections.CUSTOMIZE,
      StockArtDetailSections.STOCK_ART_CUSTOMIZATIONS]).includes(activeSection, checkFromGivenIndex);
  }
}
