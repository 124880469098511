import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { uniq as _uniq } from 'lodash-es';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { Translations } from '@graphics-flow/shared/assets';
import {
  ID,
  Pagination, StockArt, StockArtCategory, StockArtFilterType,
  StockArtHeaderPackage, StockArtOrder, StockArtRequest, StockArtType
} from '@graphics-flow/types';

import { StockArtHelper } from '../../helpers/stock-art.helper';
import { ArtQuery } from '../art/art.query';
import { StockArtCategoriesQuery } from '../stock-art-categories/stock-art-categories.query';
import { StockArtQuery } from '../stock-art/stock-art.query';
import {
  StockArtListState, StockArtListStore,
  initialStockArtListActiveFiltersState
} from './stock-art-list-store.service';

@Injectable({ providedIn: 'root' })
export class StockArtListQuery {
  activeStockArtType$: Observable<StockArtType> = this.selectStateProps<StockArtType>((store) => {
    return store.activeFilters?.filterHeaders?.stockArtType || StockArtType.DesignIdea;
  });
  activeFilters$: Observable<StockArtRequest> = this.selectStateProps<StockArtRequest>((store) => store.activeFilters);
  activeFilterHeaders$: Observable<StockArtHeaderPackage> = this.selectStateProps<StockArtHeaderPackage>((store) => store.activeFilters.filterHeaders);
  activeSearchText$: Observable<string> = this.selectStateProps<string>((store) => store.activeFilters?.searchText || '');
  filteredStockArts$: Observable<StockArt[]> = this.selectStateProps<StockArt[]>((store) => store.filteredStockArts);
  filteredStockArtsPagination$: Observable<Pagination> = this.selectStateProps<Pagination>((store) => store.filteredStockArtsPagination);
  orderBy$: Observable<StockArtOrder> = this.selectStateProps<StockArtOrder>((store) => store.activeFilters.orderBy);
  orderDesc$: Observable<boolean> = this.selectStateProps<boolean>((store) => store.activeFilters.orderDesc);
  filterOptionsForDesignIdeas$: Observable<StockArtHeaderPackage> = this.selectStateProps<StockArtHeaderPackage>((state) => state.filterOptionsForDesignIdeas);
  filterOptionsForClipArt$: Observable<StockArtHeaderPackage> = this.selectStateProps<StockArtHeaderPackage>((state) => state.filterOptionsForClipArt);
  filterOptionsForFonts$: Observable<StockArtHeaderPackage> = this.selectStateProps<StockArtHeaderPackage>((state) => state.filterOptionsForFonts);
  stockArtFilter$: Observable<StockArtFilterType> = this.selectStateProps<StockArtFilterType>((store) => store.activeFilters?.stockArtFilterType || StockArtFilterType.All);
  artPortalCategoires$: Observable<StockArtCategory[]> = this.selectStateProps<StockArtCategory[]>((store) => store.activeFilters.artPortalCategories);
  artPortalSubCategoires$: Observable<StockArtCategory[]> = this.selectStateProps<StockArtCategory[]>((store) => store.activeFilters.artPortalSubCategories);
  artPortalCategoriesIds$: Observable<ID[]> = combineLatest([this.artPortalCategoires$, this.artPortalSubCategoires$]).pipe(
    map(([categories, subCategories]: [StockArtCategory[], StockArtCategory[]]) => {
      return _uniq([...categories.map((cat) => cat.folderId), ...subCategories.map((cat) => cat.parentId)]);
    })
  );
  artPortalSubCategoriesIds$: Observable<ID[]> = combineLatest([this.artPortalCategoires$, this.artPortalSubCategoires$]).pipe(
    map(([categories, subCategories]: [StockArtCategory[], StockArtCategory[]]) => {
      return _uniq([...categories.map((cat) => cat.folderId), ...subCategories.map((cat) => cat.folderId)]);
    })
  );

  filterOptions$: Observable<StockArtHeaderPackage> = this.activeStockArtType$.pipe(
    switchMap((type: StockArtType) => {
      if (type === StockArtType.DesignIdea) {
        return this.filterOptionsForDesignIdeas$;
      } else if (type === StockArtType.ClipArt) {
        return this.filterOptionsForClipArt$;
      } else if (type === StockArtType.Font) {
        return this.filterOptionsForFonts$;
      }

      return this.filterOptionsForDesignIdeas$;
    })
  );

  isDesignIdeasActive$: Observable<boolean> = this.activeStockArtType$.pipe(
    map((type: StockArtType) => {
      return type === StockArtType.DesignIdea;
    })
  );

  selectedCategories$: Observable<StockArtCategory[]> = this.selectStateProps<ID[]>((state) => state.selectedCategoryIds).pipe(
    switchMap((ids: ID[]) => {
      return this.stockArtCategoriesQuery.selectManyCategoryEntities(ids);
    })
  );
  selectedSubCategories$: Observable<StockArtCategory[]> = this.selectStateProps<ID[]>((state) => state.selectedSubCategoryIds).pipe(
    switchMap((ids: ID[]) => {
      return this.stockArtCategoriesQuery.selectManyCategoryEntities(ids);
    })
  );

  chipCount$ = this.activeFilters$.pipe(
    map((activeFilters: StockArtRequest) => {
      return (activeFilters.filterHeaders.categories?.length || 0)
        + (activeFilters.filterHeaders.subCategories?.length || 0)
        + (activeFilters.filterHeaders.styles?.length || 0)
        + (activeFilters.filterHeaders.colorCount?.length || 0)
        + (activeFilters.filterHeaders.artLibraries?.length || 0)
        + (activeFilters.stockArtFilterType !== StockArtFilterType.All ? 1 : 0);

    })
  );

  filtersAsQueryParams$: Observable<any> = this.activeFilters$.pipe(
    map((filters: StockArtRequest) => {
      return StockArtHelper.convertStockArtRequestToQueryParams(filters, initialStockArtListActiveFiltersState);
    }),
    map((params) => {
      // If there are no params, return null so the URL gets cleaned up
      return Object.keys(params)?.length > 0 ? params : null;
    }),
    distinctUntilChanged()
  );

  selectedStockArtIds$: Observable<ID[]> = this.selectStateProps<ID[]>((store) => store.selectedStockArtIds);
  selectedStockArts$: Observable<StockArt[]> = this.selectedStockArtIds$.pipe(
    filter((stockArtIds) => !!(stockArtIds || []).length),
    switchMap((stockArtIds: ID[]) => { // Get the list of StockArts from their IDs
      return this.stockArtQuery.selectManyStockArt(stockArtIds);
    })
  );

  currentDownloadedFilterString$: Observable<string> = this.activeFilters$.pipe(
    map((activeFilter: StockArtRequest) => {
      switch (activeFilter.stockArtFilterType) {
        case StockArtFilterType.Downloaded:
          return this.translations.common.downloaded;
        case StockArtFilterType.NotDownloaded:
          return this.translations.common.not_downloaded;
        case StockArtFilterType.HiddenManuallyInArtPortal:
          return this.translations.common.manually_hidden_in_art_portal;
        case StockArtFilterType.Favorites:
          return this.translations.common.favorites;
        default:
          return this.getAllArtString(activeFilter.filterHeaders.stockArtType);
      }
    })
  );
  currentArtTypeString$: Observable<string> = this.activeStockArtType$.pipe(
    map((stockArtType: StockArtType) => this.getAllArtString(stockArtType))
  );

  loading$: Observable<boolean> = this.selectStateProps<boolean>((store) => store.loading);

  loadingResults$: Observable<boolean> = this.selectStateProps<boolean>((store) => store.loadingResults);

  activeStockArtTypeCategories$: Observable<StockArtCategory[]> = combineLatest([this.activeStockArtType$, this.stockArtCategoriesQuery.categories$]).pipe(
    map(([stockArtType, categories]: [StockArtType, StockArtCategory[]]) => {
      return categories.filter((category) => category.stockArtType === stockArtType);
    })
  );

  getStockArt(stockArtId: ID): Observable<StockArt> {
    return this.stockArtQuery.selectStockArtEntity(stockArtId);
  }

  constructor(
    protected store: StockArtListStore,
    protected readonly stockArtQuery: StockArtQuery,
    protected readonly stockArtCategoriesQuery: StockArtCategoriesQuery,
    protected readonly artQuery: ArtQuery,
    protected readonly translations: Translations
  ) {
  }

  selectStateProps<T>(predicate): Observable<T> {
    return this.store.pipe(select(predicate));
  }

  getValue(): StockArtListState {
    return this.store.getValue();
  }

  getActiveType(): StockArtType {
    return this.getValue()?.activeFilters?.filterHeaders?.stockArtType || StockArtType.DesignIdea;
  }

  getSearchText(): string {
    return this.getValue()?.activeFilters?.searchText;
  }

  getAllArtString(stockArtType: StockArtType): string {
    switch (stockArtType) {
      case StockArtType.DesignIdea:
        return this.translations.stock.all_design_ideas;
      case StockArtType.ClipArt:
        return this.translations.stock.all_clip_art;
      case StockArtType.Font:
        return this.translations.stock.all_fonts;
    }
  }

  getStockArtsPagination(): Pagination {
    return this.getValue().filteredStockArtsPagination;
  }
}
