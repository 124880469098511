<div id="deleteApprovalActionsDialog">
  <mat-dialog-content>
    <div class="flex-container flex-dir-column align-center-middle">
      <mat-icon class="m-t-4 mat-40 material-icons-outlined" color="warn" fontIcon="error_outline"></mat-icon>
      <h1 mat-dialog-title>{{ translations.approval.delete_art_approval | translate }}?</h1>
    </div>
    <mat-divider class="width-100"></mat-divider>
    <p class="m-t-3 m-b-2">{{ translations.approval.delete_art_approval_description | translate }}</p>
    <p class="t-16-700-p text-wrap" [title]="(data.approval.name.length > maxApprovalNameLength) ? data.approval.name : ''">{{ data.approval.name | truncate : maxApprovalNameLength }}</p>
    <section class="approval-delete-options">
      <ul class="no-bullet">
        <li class="flex-container">
          <mat-checkbox #checkA color="warn" id="allShare" class="flex-container">
            <span class="checkbox-label">{{translations.approval.all_share_links_inaccessible | translate}}</span>
          </mat-checkbox>
        </li>
        <li class="m-y-1 flex-container">
          <mat-checkbox #checkB color="warn" id="allActivity" class="flex-container">
            <span class="checkbox-label">{{translations.approval.all_activity_and_comments_will_lost | translate}}</span>
          </mat-checkbox>
        </li>
        <li class="flex-container">
          <mat-checkbox #checkC color="warn" id="uploadedFiles" class="flex-container">
            <span class="checkbox-label">{{translations.approval.uploaded_files_deleted | translate}}</span>
          </mat-checkbox>
        </li>
      </ul>
    </section>
    <p class="t-16-600-p warning-cannot-undone">{{ translations.common.warning_cannot_undone | translate }}</p>
  </mat-dialog-content>

  <mat-dialog-actions class="align-right">
    <button id="cancelBtn"
      mat-dialog-close
      color="secondary"
      mat-raised-button>
      {{ translations.common.cancel | translate }}
    </button>

    <button id="submitBtn"
      color="warn"
      mat-raised-button
      [disabled]="!checkA.checked || !checkB.checked || !checkC.checked"
      (click)="deleteArtApproval()">
      {{ translations.approval.delete_art_approval | translate }}
    </button>
  </mat-dialog-actions>
</div>
