import { Injectable } from '@angular/core';
import { createState, Store } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { User } from '@graphics-flow/types';

const { state, config } = createState(
  withEntities<User, 'userId'>({ idKey: 'userId', initialValue: [] })
);

@Injectable({
  providedIn: 'root'
})
export class TeamStore extends Store {
  constructor() {
    super({ name: 'team', state, config });
  }
}
