import { FormArray, FormBuilder, FormControl, FormGroup, ControlsOf } from '@ngneat/reactive-forms';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ArtApproval, ArtApprovalItem, ArtApprovalItemStatus, Art, Watermark,
  Organization, CatalogSettings, CustomLink, ContactUsForm, DesignRequest,
  CreateArtApprovalForm, DesignRequestStatus, CustomizeStockArtTextShape,
  CustomizeStockArtPosition, CustomizeStockArtTransformation, CustomizeStockArt, CustomizeStockArtFormGroupControls,
  CustomizeStockArtCDRShape, CustomizeStockArtShape, CustomizeStockArtSize, CustomizeStockArtCurveShapeColor,
  AssetSettings, CustomField, RequestUploadForm, DesignRequestForm
} from '@graphics-flow/types';

import { UserService } from '../data/user/user.service';
import { GlobalHelpers } from './global.helpers';

export interface ArtApprovalForm {
  name: string;
  description: string;
}

export interface ArtApprovalItemForm {
  backgroundColor: string;
  name: string;
  description: string;
  status: ArtApprovalItemStatus;
  sortOrder: number;
  watermark?: Watermark;
  comment?: string;
}

export interface ArtDetailForm {
  name: string;
  description: string;
}

export interface NotificationReceiverForm {
  contactUsNotifyReceivers: string[];
  designRequestNotifyReceivers: string[];
}

export interface NotificationReceiverFormGroupControls {
  contactUsNotifyReceivers: FormControl<string[]>;
  designRequestNotifyReceivers: FormControl<string[]>;
}

export interface NavigationForm {
  customLinks: CustomLink[];
  displayNeedHelps: boolean;
  displayContactUs: boolean;
}

export class FormHelper {

  static formBuilder: FormBuilder = new FormBuilder();

  static getArtApprovalDetailForm(approval: ArtApproval): FormGroup<ControlsOf<ArtApprovalForm>> {
    const approvalDetailForm = new FormGroup<ControlsOf<ArtApprovalForm>>({
      name: new FormControl(approval.name, {
        updateOn: 'blur'
      }),
      description: new FormControl(approval.description, {
        updateOn: 'blur'
      })
    });
    return approvalDetailForm;
  }

  static getArtApprovalItemDetailForm(approvalItem: ArtApprovalItem,
    loadWatermark: boolean = false, disableWatermarkSettings: boolean = false, comment: string = null): FormGroup<ControlsOf<ArtApprovalItemForm>> {

    const approvalDetailForm = new FormGroup<ControlsOf<ArtApprovalItemForm>>({
      name: new FormControl(approvalItem.name, {
        updateOn: 'blur'
      }),
      description: new FormControl(approvalItem.description || '', {
        updateOn: 'blur'
      }),
      backgroundColor: new FormControl(approvalItem.backgroundColor || null, {
        updateOn: 'blur'
      }),
      status: new FormControl(approvalItem.status, {
        updateOn: 'blur'
      }),
      sortOrder: new FormControl(approvalItem.sortOrder),
      comment: new FormControl(comment)
    });
    if (loadWatermark) {
      approvalDetailForm.addControl('watermark', this.getWaterMarkArtItemForm(approvalItem.watermark, disableWatermarkSettings));
    }

    return approvalDetailForm;
  }

  static getArtDetailForm(art: Art): FormGroup<ControlsOf<ArtDetailForm>> {

    const artDetailForm = new FormGroup<ControlsOf<ArtDetailForm>>({
      name: new FormControl(art.name, {
        validators: [Validators.required],
        updateOn: 'blur'
      }),
      description: new FormControl(art.description, {
        updateOn: 'blur'
      })
    });
    return artDetailForm;
  }

  static getWaterMarkArtItemForm(watermark: Watermark, disabled: boolean = false): FormGroup<ControlsOf<Watermark>> {
    const waterMarkForm = new FormGroup<ControlsOf<Watermark>>({
      active: new FormControl({ value: !disabled && watermark.active, disabled: disabled }),
      darkText: new FormControl({ value: disabled || watermark.darkText, disabled: disabled }),
      text: new FormControl(watermark.text, {
        validators: [Validators.required]
      }),
      textSize: new FormControl(watermark.textSize),
      opacity: new FormControl(watermark.opacity),
      rotation: new FormControl(watermark.rotation),
      repeat: new FormControl(watermark.repeat)
    });
    return waterMarkForm;
  }

  static userNameTakenValidation(userService: UserService): AsyncValidatorFn {
    return (control: FormControl<string>): Observable<ValidationErrors | null> => {
      if (!control.value || !GlobalHelpers.EMAIL_REGEXP.test(control.value)) {
        return of(null);
      }
      return userService.userExist(control.value).pipe(
        map((userExists) => {
          return userExists ? { 'userEmailTaken': true } : null
        })
      );
    };
  }

  static removeWhiteSpaces(control: AbstractControl): ValidationErrors | null {
    if (control && control.value && !control.value.replace(/\s/g, '')?.length) {
      control.setValue('');
      return { required: true };
    }
    return null;
  }

  static passwordMatchValidator(control: AbstractControl): ValidatorFn | null {
    if (!control?.parent || !control?.parent?.get('confirmPassword')?.touched) {
      return null;
    }

    if (control?.value !== control.parent.get('confirmPassword').value) {
      control?.parent?.get('confirmPassword').setErrors({ pwdMatchError: true });
    } else {
      control?.parent?.get('confirmPassword').setErrors(null);
    }
  }

  static confirmPasswordMatchValidator(control: AbstractControl): ValidationErrors | null {
    if (!control?.parent) {
      return null;
    }

    if (control?.value !== control.parent.get('password').value) {
      return {
        pwdMatchError: true
      };
    }

    return null;
  }

  static notificationReceiverForm(organization: Organization): FormGroup<NotificationReceiverFormGroupControls> {
    const catalogSettings: CatalogSettings = organization?.catalogSettings;
    const notificationReceiverForm: FormGroup<NotificationReceiverFormGroupControls> = new FormGroup<NotificationReceiverFormGroupControls>({
      contactUsNotifyReceivers: new FormControl<string[]>((catalogSettings.contactUsNotificationUserIds) || [], Validators.required),
      designRequestNotifyReceivers: new FormControl<string[]>((catalogSettings.designRequestNotificationUserIds) || [])
    });
    return notificationReceiverForm;
  }

  static navigationForm(organization: Organization): FormGroup<ControlsOf<NavigationForm>> {
    const catalogSettings: CatalogSettings = organization?.catalogSettings;
    // TODO: Just leaving it as any for now! Since it will break our workflow.
    const customLinkFormArray: FormArray<any> = new FormArray([]);
    catalogSettings.customLinks?.forEach((customLinks: CustomLink) => {
      customLinkFormArray.push(FormHelper.buildCustomLink(customLinks.name, customLinks.url));
    });

    const navigationForm = new FormGroup<ControlsOf<NavigationForm>>({
      customLinks: <any>customLinkFormArray,
      displayContactUs: new FormControl(catalogSettings.displayContactUs, {
        validators: [Validators.required]
      }),
      displayNeedHelps: new FormControl(catalogSettings.displayNeedHelps, {
        validators: [Validators.required]
      })
    });

    return navigationForm;
  }

  static buildCustomLink(name: string = '', url: string = ''): FormGroup<ControlsOf<CustomLink>> {
    return new FormGroup<ControlsOf<CustomLink>>({
      name: new FormControl(name, {
        validators: [
          FormHelper.removeWhiteSpaces,
          Validators.required
        ]
      }),
      url: new FormControl(url, {
        validators: [
          Validators.pattern(GlobalHelpers.WEBSITE_REGEXP),
          Validators.required
        ]
      })
    });
  }

  static getContactUsForm(): FormGroup<ControlsOf<ContactUsForm>> {
    const contactUs = new FormGroup<ControlsOf<ContactUsForm>>({
      firstName: new FormControl('', {
        validators: [
          Validators.required,
          FormHelper.removeWhiteSpaces
        ]
      }),
      lastName: new FormControl('', {
        validators: [
          Validators.required,
          FormHelper.removeWhiteSpaces
        ]
      }),
      email: new FormControl('', {
        validators: [
          Validators.required,
          Validators.pattern(GlobalHelpers.EMAIL_REGEXP)
        ]
      }),
      phone: new FormControl('', {
        validators: [
          Validators.required,
          Validators.pattern(GlobalHelpers.PHONE_REGXP)
        ]
      }),
      message: new FormControl('', {}),
      recaptcha: new FormControl('', {
        validators: [
          Validators.required
        ]
      })
    });
    return contactUs;
  }

  static getCreateArtApprovalForm(designRequest: DesignRequest): FormGroup<ControlsOf<CreateArtApprovalForm>> {
    const approvalName: string = designRequest?.customerFirstName ? `${designRequest.customerFirstName}'s Art Approval` : '';
    const createArtApprovalForm = new FormGroup<ControlsOf<CreateArtApprovalForm>>({
      assigneeId: new FormControl('', {
        validators: [Validators.required]
      }),
      artApprovalName: new FormControl(approvalName, {
        validators: [FormHelper.removeWhiteSpaces]
      }),
      description: new FormControl('', {
        validators: [FormHelper.removeWhiteSpaces]
      })
    });

    if (designRequest?.designRequestId) {
      createArtApprovalForm.addControl('designRequestId', new FormControl(designRequest.designRequestId));
    }

    if (designRequest?.status === DesignRequestStatus.OPEN) {
      createArtApprovalForm.addControl('archive', new FormControl(false));
    }
    return createArtApprovalForm;
  }

  static getCustomizeStockArtForm(stockArt: CustomizeStockArt): FormGroup<CustomizeStockArtFormGroupControls> {
    const textShapeFormArray: FormArray<ControlsOf<CustomizeStockArtTextShape>> = new FormArray<ControlsOf<CustomizeStockArtTextShape>>([]);
    stockArt.cdrShapes.textShapes.forEach((text: CustomizeStockArtTextShape) => {
      textShapeFormArray.push(FormHelper.getCustomizeStockArtTextShape(text));
    });
    const colorsFormArray: FormArray<ControlsOf<CustomizeStockArtCurveShapeColor>> = new FormArray<ControlsOf<CustomizeStockArtCurveShapeColor>>([]);
    stockArt.cdrShapes.colors.forEach((color: CustomizeStockArtCurveShapeColor) => {
      colorsFormArray.push(FormHelper.getCustomizeStockArtCurveShapeColors(color));
    });
    const artShapesFormArray: FormArray<ControlsOf<CustomizeStockArtShape>> = new FormArray<ControlsOf<CustomizeStockArtShape>>([]);
    stockArt.cdrShapes.artShapes.forEach((artShape: CustomizeStockArtShape) => {
      artShapesFormArray.push(FormHelper.getCustomizeStockArtShapes(artShape));
    });
    const customShapesFormArray: FormArray<ControlsOf<CustomizeStockArtShape>> = new FormArray<ControlsOf<CustomizeStockArtShape>>([]);
    stockArt.cdrShapes.customShapes.forEach((customShapes: CustomizeStockArtShape) => {
      customShapesFormArray.push(FormHelper.getCustomizeStockArtShapes(customShapes));
    });
    const cdrShapesForm: FormGroup<ControlsOf<CustomizeStockArtCDRShape>> = new FormGroup<ControlsOf<CustomizeStockArtCDRShape>>({
      textShapes: textShapeFormArray,
      colors: colorsFormArray,
      artShapes: artShapesFormArray,
      customShapes: customShapesFormArray
    });

    const stockArtForm: FormGroup<CustomizeStockArtFormGroupControls> = new FormGroup<CustomizeStockArtFormGroupControls>({
      userId: new FormControl(stockArt.userId),
      pngPreview: new FormControl(stockArt.pngPreview),
      cdrFileName: new FormControl(stockArt.cdrFileName),
      lastActiveTimestamp: new FormControl<Date>(stockArt.lastActiveTimestamp),
      cdrShapes: cdrShapesForm,
      createdDate: new FormControl<Date>(stockArt.createdDate),
    });

    return stockArtForm;
  }

  static getCustomizeStockArtTextShape(artTextShape: CustomizeStockArtTextShape): FormGroup<ControlsOf<CustomizeStockArtTextShape>> {
    const newArtTextShape = new FormGroup<ControlsOf<CustomizeStockArtTextShape>>({
      text: new FormControl(artTextShape.text, {
        updateOn: 'blur'
      }),
      font: new FormControl(artTextShape.font),
      size: new FormControl(artTextShape.size, {
        updateOn: 'blur'
      }),
      color: new FormControl(artTextShape.color),
      outlineSize: new FormControl(artTextShape.outlineSize, {
        updateOn: 'blur'
      }),
      outlineColor: new FormControl(artTextShape.outlineColor),
      position: FormHelper.getArtPositionFormGroup(artTextShape.position),
      transformation: FormHelper.getArtTransformationFormGroup(artTextShape.transformation),
      hide: new FormControl(artTextShape.hide),
      layerPosition: new FormControl(artTextShape.layerPosition),
      shapeId: new FormControl(artTextShape.shapeId)
    });
    return newArtTextShape;
  }

  static getCustomizeStockArtShapes(artShape: CustomizeStockArtShape): FormGroup<ControlsOf<CustomizeStockArtShape>> {
    const artCurveShapeColorsFormArray: FormArray<ControlsOf<CustomizeStockArtCurveShapeColor>> = new FormArray<ControlsOf<CustomizeStockArtCurveShapeColor>>([]);

    if (artShape.curveShapeColors?.length) {
      artShape.curveShapeColors.forEach((artCurveShape: CustomizeStockArtCurveShapeColor) => {
        artCurveShapeColorsFormArray.push(FormHelper.getCustomizeStockArtCurveShapeColors(artCurveShape));
      });
    } else {
      artCurveShapeColorsFormArray.push(FormHelper.getCustomizeStockArtCurveShapeColors({ colorId: artShape.shapeId, hexValue: null }));
    }

    const newArtShape = new FormGroup<ControlsOf<CustomizeStockArtShape>>({
      artPreview: new FormControl(artShape.artPreview),
      replaceContent: new FormControl(artShape.replaceContent),
      size: FormHelper.getArtSizeFormGroup(artShape.size),
      position: FormHelper.getArtPositionFormGroup(artShape.position),
      transformation: FormHelper.getArtTransformationFormGroup(artShape.transformation),
      type: new FormControl(artShape.type),
      hide: new FormControl(artShape.hide),
      curveShapeColors: artCurveShapeColorsFormArray,
      layerPosition: new FormControl(artShape.layerPosition),
      color: new FormControl(artShape.color),
      shapeId: new FormControl(artShape.shapeId)
    });

    return newArtShape;
  }

  static getCustomizeStockArtCurveShapeColors(artCurveShapeColor: CustomizeStockArtCurveShapeColor): FormGroup<ControlsOf<CustomizeStockArtCurveShapeColor>> {
    const newArtCurveShapeColor = new FormGroup<ControlsOf<CustomizeStockArtCurveShapeColor>>({
      colorId: new FormControl(artCurveShapeColor.colorId),
      hexValue: new FormControl(artCurveShapeColor.hexValue)
    });

    return newArtCurveShapeColor;
  }

  static getArtPositionFormGroup(position: CustomizeStockArtPosition): FormGroup<ControlsOf<CustomizeStockArtPosition>> {
    return new FormGroup<ControlsOf<CustomizeStockArtPosition>>({
      x: new FormControl(position.x, {
        updateOn: 'blur'
      }),
      y: new FormControl(position.y, {
        updateOn: 'blur'
      })
    });
  }

  static getArtTransformationFormGroup(transformation: CustomizeStockArtTransformation): FormGroup<ControlsOf<CustomizeStockArtTransformation>> {
    return new FormGroup<ControlsOf<CustomizeStockArtTransformation>>({
      isMirrored: new FormControl(transformation.isMirrored),
      rotationAngle: new FormControl(transformation.rotationAngle, {
        updateOn: 'blur'
      }),
      FlipHorizontally: new FormControl(transformation.FlipHorizontally || false),
      FlipVertically: new FormControl(transformation.FlipVertically || false)
    });
  }

  static getArtSizeFormGroup(size: CustomizeStockArtSize): FormGroup<ControlsOf<CustomizeStockArtSize>> {
    return new FormGroup<ControlsOf<CustomizeStockArtSize>>({
      height: new FormControl(size.height),
      width: new FormControl(size.width),
      scale: new FormControl(size.scale)
    });
  }

  static categorySelectionValidator(control: AbstractControl): ValidationErrors | null {
    if (!control?.value?.categoryIds.length && !control?.value?.subCategoryIds.length && control.value.status) {
      return {required: true};
    }
    return null;
  }

  static createAssetSettings(settings: AssetSettings): FormGroup<ControlsOf<AssetSettings>> {
    const settingFormGroup: FormGroup<ControlsOf<AssetSettings>> = new FormGroup({
      status: new FormControl(settings.status),
      hiddenKeywords: new FormArray([]),
      defaultCategory: new FormControl(settings.defaultCategory || ''),
      categoryIds: new FormArray([]),
      subCategoryIds: new FormArray([])
    }, FormHelper.categorySelectionValidator);
    settings.categoryIds.forEach((categoryId: string) => {
      settingFormGroup.controls.categoryIds.push(new FormControl(categoryId));
    });
    settings.subCategoryIds.forEach((subCategoryId: string) => {
      settingFormGroup.controls.subCategoryIds.push(new FormControl(subCategoryId));
    });
    settings.hiddenKeywords.forEach((keyword: string) => {
      settingFormGroup.controls.hiddenKeywords.push(new FormControl(keyword));
    });
    return settingFormGroup;
  }
  static designRequestForm(orgCustomField: CustomField[], orgUploadArt: RequestUploadForm) {
    const customFields: FormArray<CustomField> = new FormArray([]);

    orgCustomField?.forEach((field: CustomField) => {
      customFields.push(FormHelper.buildCustomField(field));
    });

    const designRequestForm = new FormGroup<ControlsOf<DesignRequestForm>>({
      customFields,
      uploadArt: new FormGroup<ControlsOf<RequestUploadForm>>({
        enabled: new FormControl(orgUploadArt?.enabled ?? false),
        description: new FormControl(orgUploadArt?.description ?? '')
      })
    });

    return designRequestForm;
  }

  static buildCustomField(field: CustomField): FormGroup<ControlsOf<CustomField>> {
    const { label, value, options, required, type } = field;
    const formControlOptions: FormControl<string>[] = options?.length ? options?.map((option) => new FormControl(option, { validators: [Validators.required] })) : [];

    return new FormGroup<ControlsOf<CustomField>>({
      label: new FormControl(label, {
        validators: [
          FormHelper.removeWhiteSpaces,
          Validators.required
        ]
      }),
      value: new FormControl(value ?? ''),
      type: new FormControl(type, {
        validators: [Validators.required]
      }),
      options: new FormArray<string, FormControl<string>>(formControlOptions),
      required: new FormControl(required)
    });
  }

  static buildRendererForm(customFields: CustomField[],onBlur: boolean): FormArray<ControlsOf<CustomField>> {
    const generateForm = new FormArray<ControlsOf<CustomField>>([], {
      validators: customFields?.length ? Validators.required : null,
      updateOn: onBlur ? 'blur' : 'change'
    });

    customFields.forEach((field) => {
      generateForm.push(FormHelper.buildRendererFields(field));
    });

    return generateForm;
  }

  static buildRendererFields(field: CustomField): FormGroup<ControlsOf<CustomField>> {
    const { label, value, options, required, type } = field;
    const formControlOptions: FormControl<string>[] = options?.length ? options?.map((value) => new FormControl(value)) : [];

    return new FormGroup<ControlsOf<CustomField>>({
      label: new FormControl(label),
      value: new FormControl(value ?? '', {
        validators: required ? Validators.required : null
      }),
      type: new FormControl(type),
      options: new FormArray<string, FormControl<string>>(formControlOptions),
      required: new FormControl(required)
    });
  }

  static minSelectedOptions(min: number): ValidatorFn {
    return (control: FormControl<string[]>) => {
      return control.value.length >= min ? null : { minSelectedOptions: true };
    };
  }
}
