export enum ShapeProperty {
  text = 'text',
  color = 'color',
  positionX = 'positionX',
  positionY = 'positionY',
  rotation = 'rotation',
  textSize = 'textSize',
  clipArtSize = 'clipArtSize',
  flip = 'flip',
  layer = 'layer',
  textFont = 'textFont',
  hide = 'hide',
  outlineSize = 'outlineSize',
  outlineColor = 'outlineColor',
  remove = 'remove'
}
