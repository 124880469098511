import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';
import { Folder, ID } from '@graphics-flow/types';
import { FolderQuery, FolderService } from '@graphics-flow/util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { filter, first, switchMap, take, tap } from 'rxjs/operators';
import { FolderMoveDialogQuery } from '../folder-move-dialog/folder-move-dialog.query';
import { FolderMoveDialogService } from '../folder-move-dialog/folder-move-dialog.service';

@UntilDestroy()
@Component({
  selector: 'gf-save-in-folder-selection',
  templateUrl: './save-in-folder-selection.component.html',
  styleUrls: ['./save-in-folder-selection.component.scss']
})
export class SaveInFolderSelectionComponent implements OnInit {
  @ViewChild(CdkConnectedOverlay) overlay: CdkConnectedOverlay;
  @Input() selectedFolderId: ID = null;
  @Output() selectedFolderChange: EventEmitter<ID> = new EventEmitter(null);
  isOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showCreateFolderAction = false;
  newFolderName = '';
  inProgress = false;
  selectedFolder: Folder = null;
  showFolderLoader = false;

  constructor(public readonly translations: Translations,
    public readonly folderQuery: FolderQuery,
    public readonly folderMoveDialogQuery: FolderMoveDialogQuery,
    private readonly folderService: FolderService,
    public readonly folderMoveDialogService: FolderMoveDialogService) { }

  ngOnInit(): void {
    this.folderMoveDialogQuery.selectedFolder$.pipe(
      tap((selectedFolder) => {
        this.selectedFolder = selectedFolder;
        this.resetFolderInfo();
      })
    ).subscribe();
  }

  open(): void {
    const parentId: ID = this.folderQuery.getParentId(this.selectedFolderId);
    this.loadFolder(parentId);
    if (this.selectedFolderId) {
      this.folderMoveDialogService.setSelectedFolderId(this.selectedFolderId);
      this.folderMoveDialogService.setNavigationLevel(parentId);
    }
    this.isOpen$.next(true);
  }

  close(): void {
    this.isOpen$.next(false);
    this.overlay.overlayRef.detach();
    this.resetFolderInfo();
    this.folderMoveDialogService.setSelectedFolderId(null);
  }

  toggle(): void {
    if (this.isOpen$.getValue()) {
      this.close();
      return;
    }
    this.open();
  }

  goToParent(): void {
    this.folderMoveDialogQuery.navigationLevelFolder$.pipe(
      take(1),
      untilDestroyed(this)
    ).subscribe((folder: Folder) => {
      this.folderMoveDialogService.setSelectedFolderId(null);
      this.folderMoveDialogService.setNavigationLevel(folder?.parentId);
    });
  }

  createNewFolder(): void {
    this.folderMoveDialogQuery.navigationLevelFolder$.pipe(
      take(1),
      untilDestroyed(this),
      filter(() => !!this.newFolderName),
      switchMap((parentFolder: Folder) => {
        this.inProgress = true;
        const folder: Folder = <Folder>{
          name: this.newFolderName,
          parentId: parentFolder?.folderId || null
        }
        return this.folderService.createFolder(folder);
      }),
      tap(() => {
        this.inProgress = false;
        this.resetFolderInfo();
      })
    ).subscribe();
  }

  resetFolderInfo(): void {
    this.showCreateFolderAction = false;
    this.newFolderName = '';
  }

  setSelectedFolder(folderId: ID = null): void {
    this.selectedFolderChange.emit(folderId);
    this.close();
  }

  loadFolder(folderId: ID = null): void {
    if (!this.folderService.isExistingRootFoldersLoaded || !this.folderQuery.getFolderChildren(folderId)?.length) {
      this.showFolderLoader = true;
      this.folderService.loadFolders().pipe(
        first(),
      ).subscribe(() => {
        this.showFolderLoader = false;
        this.folderService.isExistingRootFoldersLoaded = true;
      });
    }
  }

}
