import { Pipe, PipeTransform } from '@angular/core';
import { DateHelpers } from '@graphics-flow/util';
import { DateTime } from 'luxon';

@Pipe({
  name: 'fromNowDate'
})
export class FromNowDatePipe implements PipeTransform {

  transform(value: Date): string {
    const isoString = DateHelpers.getDateAsIsoString(value);
    return DateTime.fromISO(isoString).toRelative();
  }

}
