import { Injectable } from '@angular/core';
import { getAllEntitiesApply, selectAllEntitiesApply, selectEntity, selectMany } from '@ngneat/elf-entities';
import { isNil as _isNil, sortBy as _sortBy, uniq as _uniq } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ID, StockArtCategory, StockArtType } from '@graphics-flow/types';
import { StockArtCategoriesStore } from './stock-art-categories.store';

@Injectable({ providedIn: 'root' })
export class StockArtCategoriesQuery {
  categories$: Observable<StockArtCategory[]> = this.selectAllCategoryEntitiesApply({
    filterEntity: (cat) => !cat.parentId
  });
  subCategories$: Observable<StockArtCategory[]> = this.selectAllCategoryEntitiesApply({
    filterEntity: (cat) => !!cat.parentId
  });

  constructor(protected store: StockArtCategoriesStore) {}

  selectCategoryEntity(categoryId: ID): Observable<StockArtCategory> {
    return this.store.pipe(selectEntity(categoryId));
  }

  selectAllCategoryEntitiesApply(predicate): Observable<StockArtCategory[]> {
    return this.store.pipe(
      selectAllEntitiesApply(predicate),
      map((cats: StockArtCategory[]) => _sortBy(cats, 'name'))
    );
  }

  selectManyCategoryEntities(categoryIds: ID[]): Observable<StockArtCategory[]> {
    return this.store.pipe(
      selectMany(categoryIds),
      map((cats: StockArtCategory[]) => _sortBy(cats, 'name'))
    );
  }

  getAllCategoryEntitiesApply(predicate): StockArtCategory[] {
    const allCategories: StockArtCategory[] = this.store.query(getAllEntitiesApply(predicate));
    return _sortBy(allCategories, 'name');
  }

  getCategoriesByParentId(parentId: ID): Observable<StockArtCategory[]> {
    return this.selectAllCategoryEntitiesApply({
      filterEntity: (category: StockArtCategory) => {
        if (_isNil(parentId)) {
          return !category.parentId;
        } else {
          return category.parentId === parentId;
        }
      }
    });
  }

  searchCategories(searchString: string, stockArtType: StockArtType, artPortalCategoriesIds: ID[] = []): Observable<StockArtCategory[]> {
    const filteredCategories: StockArtCategory[] = this.getAllCategoryEntitiesApply({
      filterEntity: (cat: StockArtCategory) => {
        const artPortalValidation = artPortalCategoriesIds?.length ? artPortalCategoriesIds.includes(cat.folderId) : true;
        const searchValidation = searchString?.length ? cat.name.toLowerCase().includes(searchString.toLowerCase()) : true;
        return artPortalValidation && searchValidation && cat.stockArtType === stockArtType;
      }
    });
    const matches: ID[] = _uniq(filteredCategories.map((cat) => cat.parentId || cat.folderId));
    return this.selectManyCategoryEntities(matches);
  }

  searchSubCategories(parentId: ID, searchString: string, artPortalSubCategoriesIds: ID[] = []): Observable<StockArtCategory[]> {
    return this.selectAllCategoryEntitiesApply({
      filterEntity: (category: StockArtCategory) => {
        const artPortalValidation = artPortalSubCategoriesIds?.length ? artPortalSubCategoriesIds.includes(category.parentId) || artPortalSubCategoriesIds.includes(category.folderId) : true;
        return artPortalValidation && category.parentId === parentId && (!searchString || category.name.toLowerCase().includes(searchString.toLowerCase()))
      }
    });
  }

  getCategoriesBySubCategoriesIDs(categoires: ID[], subCategories: ID[]): Observable<StockArtCategory[]> {
    const filteredCategories: StockArtCategory[] = this.getAllCategoryEntitiesApply({
      filterEntity: (cat: StockArtCategory) => subCategories.includes(cat.folderId)
    });
    const matches: ID[] = _uniq(filteredCategories.map((cat: StockArtCategory) => cat.parentId));
    return this.selectManyCategoryEntities([...matches, ...categoires]);
  }

  getSubCategoryByParentId(parentId: ID, artPortalSubCategoriesIds: ID[] = []): StockArtCategory[] {
    return this.getAllCategoryEntitiesApply({
      filterEntity: (category: StockArtCategory) => {
        return this.artPortalValidation(artPortalSubCategoriesIds, category) && category.parentId === parentId;
      }
    });
  }

  artPortalValidation(artPortalSubCategoriesIds: ID[], category: StockArtCategory): boolean {
    const artPortalValidation = artPortalSubCategoriesIds?.length
      ? artPortalSubCategoriesIds.includes(category.parentId) || artPortalSubCategoriesIds.includes(category.folderId)
      : true;
    return artPortalValidation;
  }

}
