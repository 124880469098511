import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Translations } from '@graphics-flow/shared/assets';
import { GenericDialogComponent } from 'shared/ui';
import { GenericDialogData } from '@graphics-flow/types';
import { DEFAULT_COLOR_DISCLAIMER, OrganizationQuery } from '@graphics-flow/util';

@Injectable({
  providedIn: 'root'
})
export class OrganizationActionsService {

  constructor(private readonly dialog: MatDialog,
              private readonly organizationQuery: OrganizationQuery,
              private translations: Translations) {
  }

  openTermsAndConditionsDialog() {
    this.dialog.open(GenericDialogComponent, <MatDialogConfig> {
      autoFocus: false,
      data: <GenericDialogData> {
        header: this.translations.organization.terms_and_conditions,
        body: this.organizationQuery.getActive().termsAndConditions || DEFAULT_COLOR_DISCLAIMER,
        continueText: 'OK'
      },
      panelClass: 'mobile-screen-modal'
    });
  }

  openColorDisclaimerDialog() {
    this.dialog.open(GenericDialogComponent, <MatDialogConfig> {
      autoFocus: false,
      data: <GenericDialogData> {
        header: this.translations.organization.color_disclaimer,
        body: this.organizationQuery.getActive().organizationColorDisclaimer || DEFAULT_COLOR_DISCLAIMER,
        continueText: 'OK'
      },
      panelClass: 'mobile-screen-modal'
    });
  }


}
