import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Translations } from '@graphics-flow/shared/assets';
import { UntilDestroy } from '@ngneat/until-destroy';

import { WindowService } from 'shared/util';

@UntilDestroy()
@Component({
  selector: 'gf-help-and-resources-dialog',
  templateUrl: './help-and-resources-dialog.component.html',
  styleUrls: ['./help-and-resources-dialog.component.scss']
})
export class HelpAndResourcesDialogComponent {

  constructor(
    public translations: Translations,
    public dialogRef: MatDialogRef<HelpAndResourcesDialogComponent>,
    public windowService: WindowService
  ) {
  }

  close() {
    this.dialogRef.close();
  }
}
