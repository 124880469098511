import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Translations } from '@graphics-flow/shared/assets';
import { StockArtListService } from '@graphics-flow/util';

@Component({
  selector: 'gf-logo',
  templateUrl: './gf-logo.component.html',
  styleUrls: ['./gf-logo.component.scss']
})
export class GfLogoComponent {
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() routerLinkUrl = '/';
  @Input() isDarkLogo = true;

  constructor(
    private router: Router,
    private stockArtListService: StockArtListService,
    public translations: Translations
  ) { }

  navigateToHome() {
    const url = this.router.url;
    // While clicking logo from home route, active filters have to be removed
    if (url.startsWith('/stock/design-ideas') && url.split('?')[1]) {
      this.stockArtListService.resetFilters();
    }
    this.router.navigate([this.routerLinkUrl]);
  }
}
