import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Translations } from '@graphics-flow/shared/assets';
import { TagManagerDialogComponent } from '../tag-manager-dialog/tag-manager-dialog.component';

@Component({
  selector: 'gf-merge-tags-dialog',
  templateUrl: './merge-tags-dialog.component.html',
  styleUrls: ['./merge-tags-dialog.component.scss']
})
export class MergeTagsDialogComponent implements OnInit {

  tagName: string;
  tagManagerDialogRef: MatDialogRef<TagManagerDialogComponent>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: string,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<MergeTagsDialogComponent>,
    public readonly translations: Translations,
  ) {
    this.tagName = this.data;
  }

  ngOnInit(): void {
    this.tagManagerDialogRef = this.dialog.openDialogs.find((dialog: MatDialogRef<any>) => dialog.id === 'TAG_MANAGER_DIALOG');

    if (this.tagManagerDialogRef) {
      this.tagManagerDialogRef.addPanelClass('hide-dialog');
    }
  }

  close(isMerge: boolean = false) {
    if (this.tagManagerDialogRef) {
      this.tagManagerDialogRef.removePanelClass('hide-dialog');
    }
    this.dialogRef.close(isMerge);
  }

}
