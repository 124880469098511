<div class="welcome-gf-modal-wrap grid-y align-middle">
  <p class="t-28-700-p text-center">{{translations.welcome_modal.welcome_to_gf | translate}}</p>
  <p class="m-b-1 t-18-400-p text-center">{{translations.welcome_modal.watch_this_video_to_learn | translate}}</p>
  <div class="video-wrap m-t-4">
    <iframe class="youtube-iframe" width="560" height="315" src="https://www.youtube.com/embed/AXy8CgXMmJw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
  <button id="letsGoBtn"
    mat-raised-button
    class="m-t-3 t-15-600-p"
    color="primary"
    [mat-dialog-close]="true">
    {{translations.welcome_modal.ok_lets_go | translate}}
  </button>
</div>
