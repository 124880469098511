import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Translations } from '@graphics-flow/shared/assets';
import { BillingPlansQuery } from '../../data/billing-plans/billing-plans.query';
import { UserQuery } from '../../data/user/user.query';

@Component({
  selector: 'gf-download-limit-exceed-dialog',
  templateUrl: './download-limit-exceed-dialog.component.html',
  styleUrls: ['./download-limit-exceed-dialog.component.scss']
})
export class DownloadLimitExceedDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DownloadLimitExceedDialogComponent>,
    public translations: Translations,
    public userQuery: UserQuery,
    public billingPlansQuery: BillingPlansQuery
  ) {
  }

}
