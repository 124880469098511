import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MatIconService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon('folder-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icon-folder.svg')
    );
    this.matIconRegistry.addSvgIcon('graphics-builder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icon-graphics-builder.svg')
    );
    this.matIconRegistry.addSvgIcon('text-box-search-outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/text-box-search-outline.svg')
    );
    this.matIconRegistry.addSvgIcon('text-box-multiple-outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/text-box-multiple-outline.svg')
    );
    this.matIconRegistry.addSvgIcon('smart-designer',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/sd-icon.svg')
    );
    this.matIconRegistry.addSvgIcon('link-varient',
    this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/link-varient.svg')
    );
    this.matIconRegistry.addSvgIcon('rotate-device',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icon-rotate-device.svg')
    );
  }
}
