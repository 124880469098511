<ng-template #artLinkTemplate>
  <a id="artLink" class="link m-t-1 outline-none text-decoration-none link-color cursor-pointer"
    target="_self" [href]="link">
    {{ translations.custom_stock_art.view_in_my_art | translate }}
  </a>
</ng-template>

<ng-template #artFileUnsupported>
  <p class="error-color">{{ translations.art.files_are_unsupported | translate }}</p>
</ng-template>
