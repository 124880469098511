<div class="grid-y" id="selectArtDialog">
  <div class="cell shrink" mat-dialog-title>
    <form class="flex-container align-middle align-justify" autocomplete="off">
      <div class="flex-container align-middle">
        <mat-icon *ngIf="(myArtSearchQuery.searchString$ | async) as pepe" class="secondary m-x-1 cursor-pointer" (click)="resetSearch()" fontIcon="arrow_back"></mat-icon>
        <span class="t-24-500-p flex-child-shrink">
          {{translations.art.add_art | translate}}
        </span>
      </div>
      <div class="flex-child-auto file-search-mobile-after">
        <mat-form-field appearance="fill" class="search-form-field rounded-fill width-100" color="secondary">
          <mat-icon matPrefix fontIcon="search" class="dark"></mat-icon>
          <input id="searchInput" name="searchInput" [formControl]="searchControl"
            [placeholder]="translations.art.search_my_art | translate" matInput>
        </mat-form-field>
      </div>
      <mat-icon class="close-button cursor-pointer flex-child-shrink" (click)="close()" fontIcon="close"></mat-icon>
    </form>

    <form autocomplete="off" class="file-search-mobile-only m-b-1 m-t-2">
      <mat-form-field appearance="fill" class="search-form-field rounded-fill flex-child-auto width-100" color="secondary">
        <mat-icon matPrefix fontIcon="search" class="dark"></mat-icon>
        <input id="searchInput" name="searchInput" [formControl]="searchControl"
          [placeholder]="translations.art.search_my_art | translate" matInput>
      </mat-form-field>
    </form>

    <div *ngIf="!(myArtSearchQuery.searchString$ | async)" class="align-justify breadcrumbs flex-container">
      <gf-folder-breadcrumbs *ngIf="windowService.mediumUp$ | async" (folderClick)="selectFolder($event)" [folders$]="breadcrumbFolders$">
      </gf-folder-breadcrumbs>
      <ng-container *ngIf="windowService.smallDown$ | async">
        <div class="flex-container align-middle">
          <mat-icon *ngIf="currentFolder$ | async" class="secondary cursor-pointer mr-12" (click)="navigateToParentFolder()">
            arrow_back
          </mat-icon>
          <span class="t-18-400-p flex-child-shrink">
            {{(currentFolder$ | async)?.name || translations.art.my_art | translate}}
          </span>
        </div>

        <div class="sort-menu-container">
          <button mat-button id="folderFilterMenu" [mat-menu-trigger-for]="folderFilterMenu" class="p-a-0">
            <ng-container>
              {{translations.common.sortby | translate}}
              <mat-icon fontIcon="keyboard_arrow_down"></mat-icon>
            </ng-container>
          </button>
          <mat-menu #folderFilterMenu>
            <button mat-menu-item (click)="sortArtList('modifiedDate', Order.DESC)">{{translations.common.last_modified | translate}}</button>
            <button mat-menu-item (click)="sortArtList('name', Order.ASC)">{{translations.filter.name_a_to_z | translate}}</button>
            <button mat-menu-item (click)="sortArtList('name', Order.DESC)">{{translations.filter.name_z_to_a | translate}}</button>
          </mat-menu>
        </div>
      </ng-container>
    </div>
  </div>
  <mat-dialog-content  #scrollingBlock class="position-relative cell auto">
    <loading-indicator [diameter]="48" *ngIf="(myArtSearchQuery.showOverAllLoader$ | async); else artListContainer"></loading-indicator>
    <ng-template #artListContainer>
      <ng-container *ngIf="dataSource$ | async as dataSource">
        <gf-art-list #artList
          [class.hide]="dataSource.length === 0"
          [dataSource]="dataSource"
          [showActions]="false"
          [selectMultiple]="selectMultiple"
          [scrollingBlock]="scrollingBlock"
          [checkFileFormat]="checkFileFormat"
          (selectFolder)="selectFolder($event)"
          (selectArts)="selectArts($event)"
          (scrolledToBottom)="scrolledToBottom($event, dataSource.length)">
        </gf-art-list>
        <div *ngIf="dataSource.length === 0"
             class="no-results height-100 flex-container align-center-middle">
          <mat-icon fontIcon="image_search"></mat-icon>{{translations.common.no_results_found | translate}}
        </div>
      </ng-container>
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-actions class="align-right p-b-3">
    <button id="cancelBtn"
      mat-dialog-close
      mat-raised-button
      color="secondary"
      class="large">
      {{translations.common.cancel | translate}}
    </button>

    <button id="submitBtn"
      [disabled]="(!(selectedArtIds$ | async)?.length)"
      color="primary"
      mat-raised-button
      (click)="confirm()"
      class="large">
      {{((selectedArtIds$ | async)?.length) | i18nPlural: uploadFileMessageMap | translate}}
    </button>
  </mat-dialog-actions>
</div>
