export const froalaKey = 'OXC1lD3H3A16B7B8C4kOPVi1b1b2Le2e1G1AWMSGSAFDTGHWsE7D7D6E5A1H4E3C3B6A4==';
export const froalaDefaultToolbarButtons: string[] = [
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'insertLink',
  '|',
  'fontSize',
  'paragraphFormat',
  'align',
  'formatOLSimple',
  'formatUL',
  'indent',
  'outdent',
  '|',
  'undo',
  'redo'
];

export const froalaDefaultOptions: object = {
  key: froalaKey,
  toolbarButtons: froalaDefaultToolbarButtons,
  toolbarButtonsXS: froalaDefaultToolbarButtons,
  toolbarButtonsSM: froalaDefaultToolbarButtons,
  toolbarButtonsMD: froalaDefaultToolbarButtons,
  attribution: false
};

export const froalaPluginsNormal: string[] = [
  'align',
  'charCounter',
  'fontFamily',
  'fontSize',
  'lineBreaker',
  'link',
  'lists',
  'paragraphFormat',
  'paragraphStyle',
  'url',
  'wordPaste'
];

export const froalaPluginsMinimal: string[] = [
  'charCounter',
  'fontFamily',
  'fontSize',
  'lineBreaker',
  'link',
  'paragraphStyle',
  'url',
  'wordPaste'
];
