import { Component } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';

@Component({
  selector: 'gf-orientation-not-supported-dialog',
  templateUrl: './orientation-not-supported-dialog.component.html',
  styleUrls: ['./orientation-not-supported-dialog.component.scss']
})
export class OrientationNotSupportedDialogComponent {

  constructor(
    public translations: Translations,
  ) { }

}
