import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ArtDetailSections, ID } from '@graphics-flow/types';
import { MyArtDetailState, MyArtDetailStore } from './my-art-detail.store';
import { setProps } from '@ngneat/elf';

@Injectable({ providedIn: 'root' })
export class MyArtDetailService {

  constructor(
    private myArtDetailStore: MyArtDetailStore,
    private router: Router
  ) {
  }

  setArtDetailProps(partialStateValue: Partial<MyArtDetailState>): void {
    this.myArtDetailStore.update(setProps(partialStateValue))
  }

  show(artId: ID, activeSection: ArtDetailSections = ArtDetailSections.INFO): void {
    this.updateActiveArtInfo(activeSection, artId);
    this.router.navigate([`/my/detail/${artId}`], {
      queryParams: {
        activeSection
      }
    });
  }

  updateActiveArtInfo(activeSection: ArtDetailSections, artId: ID): void {
    this.setArtDetailProps({ artId: artId });
    this.setActiveSection(activeSection);
  }

  updateState(myArtDetailState: MyArtDetailState) {
    this.myArtDetailStore.updateState(myArtDetailState);
  }

  toggleDrawerStatus() {
    this.myArtDetailStore.update((state) => ({
      ...state,
      isDrawerOpen: !state.isDrawerOpen
    }));
  }

  closeDrawer() {
    this.setArtDetailProps({isDrawerOpen: false});
  }

  setActiveSection(activeSection: ArtDetailSections): void {
    this.myArtDetailStore.setActiveSection(activeSection);
  }

  reset() {
    this.myArtDetailStore.reset();
  }

}
