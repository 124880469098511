import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';

@Component({
  selector: 'add-art-color',
  templateUrl: './add-art-color.component.html',
  styleUrls: ['./add-art-color.component.scss']
})
export class AddArtColorComponent {

  @Input() color;
  @Input() disabled;
  @Output() colorChange: EventEmitter<string> = new EventEmitter();

  constructor(public translations: Translations) { }

  colorPickerClosed() {
    this.colorChange.emit(this.color);
  }

}
