import { Art, ArtExtension, FilesRoutes, Folder, Pagination, TableFile } from '@graphics-flow/types';
import { first as _first, intersection as _intersection } from 'lodash-es';
import { GlobalHelpers } from './global.helpers';

export class ArtHelper {
  static getTableFiles(folders: Folder[], arts: Art[]) {
    const tableFiles: TableFile[] = [];

    if (folders?.length) {
      folders.forEach((folder: Folder) => {
        const file: TableFile = Object.assign(<TableFile>{}, folder);
        tableFiles.push(file);
      });
    }

    if (arts?.length) {
      arts.forEach((art: Art) => {
        tableFiles.push(Object.assign(<TableFile>{}, art));
      });
    }
    return tableFiles;
  }

  static getPNGExtension(art: Art): string {
    return art.artExtensions[0].extension;
  }

  static getCDRExtension(art: Art): string {
    return art.artExtensions[1].extension;
  }

  static getAIExtension(art: Art): string {
    return art.artExtensions[2].extension;
  }

  static getArtExtension(art: Art, displayType: 'preview' | 'full-size' | 'original' = 'preview'): string {
    if (!art) return '';
    const artExtensions = art.artExtensions.map((artExt: ArtExtension) => artExt.name.substr(artExt.name.lastIndexOf('.'))?.toLowerCase());
    const extension: string = _first(_intersection(artExtensions, GlobalHelpers.SUPPORTED_MY_ART_FILE_EXTENSIONS));

    if (!extension) {
      return;
    }

    switch (displayType) {
      case 'preview':
        return art.artExtensions[0].name;
      case 'full-size':
        return art.artExtensions[1].name;
      case 'original':
        return art.artExtensions[2].name;
    }
  }

  static getFontFileExtension(art: Art): string {
    // Because :inksoft: we like to re-use the artExtensions property for all sorts of things. In the case of fonts,
    // the font file (eg. TTF/WOFF/etc) is at index 2
    return art.artExtensions[2].name;
  }

  static getFileExtension(fileName: string): string {
    return fileName?.substr(fileName?.lastIndexOf('.') + 1);
  }

  static isNoPreviewImage(art: Art): boolean {
    const fileFormat: string = ArtHelper.getFileExtension(art?.fileName);
    return GlobalHelpers.NON_IMAGE_FILE_EXTENSIONS.includes(`.${fileFormat?.toLowerCase()}`);
  }

  static getSearchResultCount(folders: Folder[] = [], pagination: Pagination): number {
    const visibleFoldersCount: number = folders?.filter((folder: Folder) => !folder.inProgress).length;
    return (visibleFoldersCount + pagination?.totalResults);
  }

  static getCDRExtensionName(art: Art, useExtensionName: boolean = true): string {
    if (!useExtensionName) {
      return art.artExtensions[4]?.extension;
    }
    return art?.artExtensions[1].name;
  }

  // From corel API we will get only png preview base64 image
  static buildCustomizeStockArtPngPreview(data: string): string {
    return data ? `data:image/png;base64,${data}` : data;
  }

  static getRouteUrlBasedOnArt(art: Art): string {
    return (art && art.folderId) ? `/my/${art?.folderId}` : '/my';
  }

  static getFileSearchRoute(isDeletedFiles: boolean): FilesRoutes {
    return isDeletedFiles ? FilesRoutes.DeletedFiles : FilesRoutes.MyArt;
  }

  static isCSASupportedFile(art: Art): boolean {
    // Child folder arts are needed to be checked
    if (art?.folderId && !art.artId) {
      return false;
    }
    const isSupportedArt = art.artExtensions.some((art) => GlobalHelpers.SUPPORTED_CSA_FILE_EXTENSIONS.includes(art.extension.toLowerCase()));
    return !isSupportedArt;
  }

}
