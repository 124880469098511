import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

export const SIGNIN_ROUTE_LABEL = 'signin';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private sidenav: MatSidenav;

  openSidenav(): void {
    this.sidenav?.open();
  }

  closeSidenav(): void {
    if (this.sidenav?.mode !== 'side') {
      this.sidenav?.close();
    }
  }

  toggleSidenav(): void {
    if (this.sidenav?.mode !== 'side') {
      this.sidenav?.toggle();
    }
  }

  setSidenav(nav: MatSidenav): void {
    this.sidenav = nav;
  }

  get isSideNavOpened(): boolean {
    if (this.sidenav) {
      return this.sidenav.opened;
    }
    return true;
  }
}
