import { Observable } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';

import { MyArtBulkSelectionQuery } from '@graphics-flow/util';
import { ID } from '@graphics-flow/types';

@Pipe({
  name: 'isMyArtSelected'
})
export class IsMyArtSelectedPipe implements PipeTransform {
  constructor(private myArtBulkSelectionQuery: MyArtBulkSelectionQuery) {}

  transform(id: ID): Observable<boolean> {
    return this.myArtBulkSelectionQuery.isSelected(id);
  }
}
