interface Department {
  key: string;  // Refers to the key of the translation service.
  value: string;
}

export const DEPARTMENTS: Department[] = [
  {
    key: 'graphic_design',
    value: 'Graphic Design'
  },
  {
    key: 'sale_and_marketing',
    value: 'Sales & Marketing'
  },
  {
    key: 'customer_servie',
    value: 'Customer Service'
  },
  {
    key: 'print_tech_or_press_operator',
    value: 'Print Tech / Press Operator'
  },
  {
    key: 'production',
    value: 'Production'
  },
  {
    key: 'management',
    value: 'Management'
  }
];
