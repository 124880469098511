import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@graphics-flow/types';
import { UserHelper } from '@graphics-flow/util';

@Pipe({
  name: 'userDisplayName'
})
export class UserDisplayNamePipe implements PipeTransform {

  transform(user: User, isInnerHtml: boolean = false, showInActive: boolean = true): string {
    if (!user) {
      return 'Anonymous';
    }

    let userName: string = UserHelper.getUserName(user);

    if (user?.deleted && showInActive) {
      userName = isInnerHtml ? `${userName} <i>[inactive]</i>` : `${userName} [inactive]`;
    }
    return userName;
  }

}
