<div class="font-card flex-dir-column"
     [class.small]="small">
  <div class="font-details grid-x" *ngIf="!small">
    <div class="cell auto">
      <div class="font-name text-truncate">{{art?.name | fileName}}</div>
      <div class="style-count">{{translations.stock.x_styles | translate : { x: font.stockArtRecord.styles.length } }}</div>
    </div>
    <div *ngIf="!selectable && !customizeStockArt" class="cell shrink flex-container">
      <button id="favoriteBtn"
        color="secondary"
        class="mat-40 m-r-1"
        mat-icon-button
        (click)="artService.favoriteArt(art, art.stockArtType, $event)">
        <mat-icon [fontIcon]="art?.isFavorite ? 'favorite' : 'favorite_border'"></mat-icon>
      </button>
      <button
        mat-icon-button
        color="secondary"
        class="mat-40"
        [mat-menu-trigger-for]="fontMenu"
        #artMenuTrigger="matMenuTrigger"
        (click)="stopAndPrevent($event)">
        <mat-icon class="material-icons-outlined" fontIcon="more_vert"></mat-icon>
      </button>
      <mat-menu #fontMenu="matMenu" yPosition="below" xPosition="before">
        <button mat-menu-item
          (click)="artActionsService.addStockArtToApproval(art?.artId, approvalMsg.artApprovalMsgRef);">
          {{translations.art.add_art_to_approval | translate}}
        </button>
        <mat-divider class="m-y-1"></mat-divider>
        <button mat-menu-item
          (click)="downloadFont()">
          {{translations.common.download | translate}}
        </button>
      </mat-menu>
    </div>
    <mat-icon *ngIf="customizeStockArt && (customizeStockArtService.selectedFontData$ | async)?.stockArtId === font.stockArtId" class="done-icon" fontIcon="check_circle"></mat-icon>
  </div>
  <div class="font-preview-container position-relative cursor-pointer">
    <div class="font-preview position-relative">
      <img [src]="art | fontPreviewUrl : small ? 'ab': 'full'" #fontImg fetchpriority="auto" loading="auto" brokenImage [alt]="art?.name | fileName">
    </div>
  </div>
</div>

<gf-add-to-approval-msg #approvalMsg></gf-add-to-approval-msg>
