export class StringHelpers {
  /**
   * Converts a string to a two character abbreviation. Examples:
   * 'Jake Hawkes' => 'JH'
   * 'Graphics Flow' => 'GF'
   * 'Apple' => 'AP'
   */


  public static shortName(str: string, onlyFirst?: boolean): string {
    str = str || 'AA';
    const firstCharacters = str.match(/\b([a-zA-Z0-9])/g).join(''); // first character of each word
    const secondCharacter = str.charAt(1); // second character, in case there's only one word
    const upperCasedText = (firstCharacters + secondCharacter).toUpperCase();
    // onlyFirst is used to return only firstletter of the text.
    return onlyFirst ? upperCasedText.slice(0, 1) : upperCasedText.slice(0, 2);
  }

  // Takes in a string and spits out a corresponding number to it. Mainly used for picking a random color to apply
  // to a UI element based on the string being displayed in that element
  public static strToNum(str: string): number {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    //TODO instead of use map/reduce use only array.reduce
    return str.split('').map((c: string) => c.charCodeAt(0) - 65).reduce(reducer);
  }

  // Convert the value to string, if is null will return and empty string
  public static toString(value: any): string {
    return value === null ||  value === undefined ? '' : value?.toString();
  }
}
