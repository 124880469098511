<div id="mergeTagDialog">
  <div mat-dialog-title>
    <div class="flex-child-auto">{{translations.tag.merge_tags | translate}}</div>
    <mat-icon class="mat-icon-close cursor-pointer" (click)="close()" fontIcon="close"></mat-icon>
  </div>

  <mat-dialog-content>
    <div class="m-b-3">
      <p class="t-16-400-p m-b-0" [innerHTML]="translations.tag.merge_tag_warning | translate : { tagName: tagName }"></p>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="align-right">
    <button id="editNameBtn"
      mat-raised-button
      color="secondary"
      (click)="close()">
      {{translations.tag.edit_name | translate}}
    </button>
    <button id="mergeTagBtn"
      mat-raised-button
      color="primary"
      (click)="close(true)">
      {{translations.tag.merge_tags | translate}}
    </button>
  </mat-dialog-actions>
</div>
