import { Injectable } from '@angular/core';
import { withEntities } from '@ngneat/elf-entities';
import { createState, Store } from '@ngneat/elf';
import { Art } from '@graphics-flow/types';

const { state, config } = createState(
  withEntities<Art, 'artId'>({ idKey: 'artId', initialValue: [] })
);

@Injectable({
  providedIn: 'root'
})
export class ArtStore extends Store {
  constructor() {
    super({ name: 'arts', state, config })
  }
}
