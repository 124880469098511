import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ArtApprovalItemStatus, ApprovalItemAction } from '@graphics-flow/types';
import { Translations } from '@graphics-flow/shared/assets';

@Component({
  selector: 'gf-approval-item-confirmation-dialog',
  templateUrl: './approval-item-confirmation-dialog.component.html',
  styleUrls: ['./approval-item-confirmation-dialog.component.scss']
})
export class ApprovalItemConfirmationDialogComponent {

  ArtApprovalItemStatus = ArtApprovalItemStatus;
  constructor(
    public dialogRef: MatDialogRef<ApprovalItemConfirmationDialogComponent>,
    public translations: Translations,
    @Inject(MAT_DIALOG_DATA) public data: ApprovalItemAction
  ) {}

  delete() {
    this.dialogRef.close(true);
  }

}
