import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';
import { DateTime } from 'luxon';
import { DateHelpers } from '@graphics-flow/util';

@Pipe({
  name: 'formatDateByDifference'
})
export class FormatDateByDifferencePipe implements PipeTransform {

  constructor(private readonly translateService: TranslateService,
              private translations: Translations) {
  }

  transform(value: Date): string {
    const now = DateTime.now();
    const isoString = DateHelpers.getDateAsIsoString(value);
    const date = DateTime.fromISO(isoString);
    const { days, years } = now.diff(date, ['years', 'days']).toObject();

    if (days === 0) {
      return date.toFormat('h:mm a');
    } else if (days === 1) {
      return this.translateService.instant(this.translations.common.yesterday);
    } else if (days < 7) {
      return date.toRelative();
    } else if (years >= 1) {
      return date.toFormat('MMM dd yyyy');
    } else {
      return date.toFormat('MMM dd');
    }
  }
}
