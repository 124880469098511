<virtual-scroller #scroll [items]="dataSource" [enableUnequalChildrenSizes]="true" [parentScroll]="scrollingBlock" (vsEnd)="virtualScrollEnd($event)">
  <mat-table *ngIf="!(windowService.smallDown$ | async)"
    matSort
    #container
    class="art-list show-for-medium"
    [dataSource]="scroll.viewPortItems"
    [matSortActive]="(myArtSearchQuery.activeQueryParams$ | async)?.sortBy"
    [matSortDirection]="(myArtSearchQuery.activeQueryParams$  | async)?.orderDesc ? Order.DESC : Order.ASC"
    [trackBy]="trackTableFiles">
    <ng-container matColumnDef="type">
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let element" class="img-col" mat-cell>
        <ng-container *ngIf="element?.artId; else folderName;">
          <div class="art-img trans-bg position-relative" [class.non-image-preview]="element | isNoPreviewImage">
            <img #artImg [src]="(organizationQuery.activeOrganizationUri$ | async) | myArtImagePreview :(element.artId | art | async) : imagePreviewType.THUMBNAIL : false :false : 'png'" class="img-centered" [alt]="element?.name | fileName"/>
          </div>
        </ng-container>
        <ng-template #folderName>
          <mat-icon class="folder-img" svgIcon="folder-icon"></mat-icon>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{translations.common.name | translate}}</th>
      <td *matCellDef="let element" class="art-name" mat-cell>
        <ng-container *ngIf="element?.artId; else folderName;">
          <div class="outline-none">
            <p class="m-b-0 t-13-600-p text-truncate">{{element.name}}</p>
            <p *ngIf="!element?.customizedStockArtId" class="m-b-0 t-12-300-p hint text-truncate">
              {{(element?.artExtensions[2]?.extension || element?.fileName) | fileExtension | uppercase}}</p>
          </div>
        </ng-container>
        <ng-template #folderName>
          <p class="m-b-0 t-13-600-p text-truncate outline-none">
            {{element.name}}</p>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="creator">
      <th *matHeaderCellDef mat-header-cell>{{translations.common.creator | translate}}</th>
      <td *matCellDef="let element" class="list-view-col" mat-cell>
        <p class="m-b-0 text-truncate" [innerHTML]="element?.createdBy | user| async | userDisplayName : true"></p>
      </td>
    </ng-container>

    <ng-container matColumnDef="modifiedDate">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{translations.common.last_modified | translate}}
      </th>
      <td *matCellDef="let element" class="list-view-col" mat-cell> {{element.modifiedDate | date: 'dd MMM yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="size">
      <th *matHeaderCellDef mat-header-cell>{{translations.common.size | translate}}</th>
      <td *matCellDef="let element" class="size-col" mat-cell>
        {{ element?.fileStorageUsage ? ((element?.artExtensions[2]?.memorySize || element?.fileStorageUsage) | filesize : { base: 2, standard: 'jedec' } ) : '—'}} </td>
    </ng-container>

    <ng-container matColumnDef="favorite">
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let element" class="size-col" mat-cell>
        <mat-icon *ngIf="element.isFavorite" class="mat-18 m-x-2 m-t-1" fontIcon="favorite"></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef='actions'>
      <th *matHeaderCellDef class="text-center" mat-header-cell></th>
      <td *matCellDef="let element" [matMenuTriggerFor]="folderMenu" class="col-actions text-center" mat-cell>
        <mat-icon class="dark-secondary-text cursor-pointer p-a-1 folder-actions" fontIcon="more_vert"></mat-icon>
        <mat-menu #folderMenu="matMenu">
          <ng-container *ngIf="element?.artId; else folderMenuActions">
            <button mat-menu-item>{{translations.art.add_art_to_approval | translate}}</button>
            <button mat-menu-item>{{translations.common.rename | translate}}</button>
            <button mat-menu-item>{{translations.common.move | translate}}</button>
            <mat-divider class="m-y-1"></mat-divider>
            <button mat-menu-item>{{translations.art.download_art | translate}}</button>
            <button mat-menu-item>{{translations.art.download_pdf | translate}}</button>
            <mat-divider class="m-y-1"></mat-divider>
            <button class="danger-color" mat-menu-item>{{translations.common.delete | translate}}</button>
          </ng-container>
          <ng-template #folderMenuActions>
            <button (click)="renameFolder.emit(element?.folderId)"
                    mat-menu-item>{{translations.common.rename | translate}}</button>
            <button (click)="moveFolder.emit(element?.folderId)"
                    mat-menu-item>{{translations.common.move | translate}}</button>
            <mat-divider class="m-y-1"></mat-divider>
            <button mat-menu-item>{{translations.common.download | translate}}</button>
            <mat-divider class="m-y-1"></mat-divider>
            <button class="danger-color" mat-menu-item>{{translations.common.delete | translate}}</button>
          </ng-template>
        </mat-menu>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;"
        class="cursor-pointer"
        [class.selected]="artSelectionModel.isSelected(row?.artId)"
        [class.disabled-art]="(row | isNoPreviewImage) || (checkFileFormat && (row | isCSASupportedFile))"
        mat-row
        (click)="select(row)"></tr>
  </mat-table>

  <div *ngIf="(windowService.smallDown$ | async)" #container class="art-list-mobile">
    <div *ngFor="let art of scroll.viewPortItems; trackBy: trackTableFiles"
      class="art-list-items flex-container cursor-pointer align-center-middle"
      [class.selected]="artSelectionModel.isSelected(art?.artId)"
      [class.disabled-art]="(art | isNoPreviewImage) || (checkFileFormat && (art | isCSASupportedFile))"
      (click)="select(art)">
      <div class="art-list-img-container">
        <ng-container *ngIf="art?.artId; else folderNames;">
          <div class="art-img trans-bg position-relative" [class.non-image-preview]="art | isNoPreviewImage">
            <img #artImg [src]="(organizationQuery.activeOrganizationUri$ | async) | myArtImagePreview :(art.artId | art | async) : imagePreviewType.THUMBNAIL : false :false : 'png'" class="img-centered" [alt]="element?.name | fileName"/>
          </div>
        </ng-container>
        <ng-template #folderNames>
          <mat-icon class="folder-img" svgIcon="folder-icon"></mat-icon>
        </ng-template>
      </div>
      <div class="flex-child-auto m-l-1 text-truncate">
        <ng-container *ngIf="art?.artId; else folderName;">
          <div class="outline-none">
            <p class="m-b-0 t-13-600-p text-truncate">{{art.name}}</p>
            <div class="flex-container align-middle">
              <p class="m-b-0 t-12-400-h text-truncate">
                {{(art?.artExtensions[2]?.extension || art?.fileName) | fileExtension | uppercase}}
              </p>
              <div class="circle-division"></div>
              <p class="m-b-0 t-12-400-h">{{art.modifiedDate | date: 'dd MMM yyyy'}}</p>
            </div>
          </div>
        </ng-container>
        <ng-template #folderName>
          <p class="mb-4 outline-none t-12-600-h text-truncate dark-text">
            {{art.name}}
          </p>
          <p class="m-b-0 t-12-400-h">{{art.modifiedDate | date: 'dd MMM yyyy'}}</p>
        </ng-template>
      </div>
      <mat-icon *ngIf="art?.artId && art?.isFavorite" class="mat-18 m-x-2" fontIcon="favorite"></mat-icon>
    </div>
  </div>

  <div *ngIf="myArtSearchQuery.showInfiniteScrollLoader$ | async" class="flex-container align-center-middle p-a-1">
    <loading-indicator [diameter]="28"
      [showInfiniteScrollLoader]="true"
      [panelClass]="'loader m-r-1'"
      class="infinite-loader">
    </loading-indicator>
  </div>
</virtual-scroller>
