import { Pipe, PipeTransform } from '@angular/core';
import { Art, StockArt } from '@graphics-flow/types';
import { ArtQuery } from '@graphics-flow/util';
import { Observable } from 'rxjs';

@Pipe({
  name: 'stockArtArt'
})
export class StockArtArtPipe implements PipeTransform {
  constructor(
    private artQuery: ArtQuery
  ) {
  }

  transform(stockArt: StockArt): Observable<Art> {
    return this.artQuery.selectArt(stockArt.artIdList[0]);
  }

}
