import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { BehaviorSubject, Observable } from 'rxjs';
import { Art, MyArtActiveQueryParams, Pagination } from '@graphics-flow/types';
import { FeaturedDesignState, FeaturedDesignStore } from './featured-design.state';

@Injectable({ providedIn: 'root' })
export class FeaturedDesignQuery {
  activeFilters$: Observable<MyArtActiveQueryParams> = this.selectStateProps(({ activeQueryParams }) => activeQueryParams);
  arts$: Observable<Art[]> = this.selectStateProps(({ arts }) => arts);
  showOverAllLoader$: Observable<boolean> = this.selectStateProps(({ showOverAllLoader }) => showOverAllLoader);
  showInfiniteScrollLoader$: Observable<boolean> = this.selectStateProps(({ showInfiniteScrollLoader }) => showInfiniteScrollLoader);
  totalResultCount$: Observable<number> = this.selectStateProps(({ pagination }) => pagination.totalResults);
  _showMyArtFooterMesg$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showMyArtFooterMesg$: Observable<boolean> = this._showMyArtFooterMesg$.asObservable();

  constructor(
    public store: FeaturedDesignStore
  ) {
  }

  selectStateProps<T>(predicate): Observable<T> {
    return this.store.pipe(select(predicate));
  }

  getValue(): FeaturedDesignState {
    return this.store.getValue();
  }

  getMyArtPagination(): Pagination {
    return this.getValue().pagination;
  }

  getMyArtActiveFilters(): MyArtActiveQueryParams {
    return this.getValue().activeQueryParams;
  }
}
