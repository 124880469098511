import { Injectable } from '@angular/core';
import { addEntities, deleteEntities, setEntities, updateEntities, upsertEntities } from '@ngneat/elf-entities';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ID, User } from '@graphics-flow/types';
import { UserHttpService } from '@graphics-flow/api';
import { TeamStore } from './team.store';

@Injectable({ providedIn: 'root' })
export class TeamService {

  constructor(
    private teamStore: TeamStore,
    private userHttpService: UserHttpService
  ) {
  }

  setUserEntities(users: User[]): void {
    this.teamStore.update(setEntities(users));
  }

  upsertUserEntities(users: User | User[]): void {
    this.teamStore.update(upsertEntities(users));
  }

  getUsers(includeDeleted: boolean = false): Observable<User[]> {
    return this.userHttpService.getUsers(includeDeleted).pipe(
      tap((users: User[]) => this.setUserEntities(users))
    );
  }

  add(team: User): void {
    this.teamStore.update(addEntities(team));
  }

  update(id: ID, team: Partial<User>): void {
    this.teamStore.update(updateEntities(id, team));
  }

  remove(id: ID): void {
    this.teamStore.update(deleteEntities(id));
  }

}
