import { Injectable } from '@angular/core';
import { addEntities, setEntities } from '@ngneat/elf-entities';
import { tap } from 'rxjs/operators';
import { ArtApprovalHttpService } from '@graphics-flow/api';
import { ID, TimelineEvent } from '@graphics-flow/types';
import { TimelineStore } from './timeline.store';

@Injectable({ providedIn: 'root' })
export class TimelineService {

  constructor(
    private artApprovalHttpService: ArtApprovalHttpService,
    private timelineStore: TimelineStore,
  ) {}

  getAATimeline(approvalId: ID): void {
    this.artApprovalHttpService.getAATimelineEvents(approvalId).pipe(
      tap((timelineEvents: TimelineEvent[]) => {
        this.timelineStore.update(setEntities(timelineEvents));
      })
    ).subscribe();
  }

  clearAllTimelineEvents(): void {
    // This will remove all the entities in the store.
    this.timelineStore.reset();
  }

  updateTimelineEvent(timelineEvents: TimelineEvent[]): void {
    this.timelineStore.update(addEntities(timelineEvents, { prepend: true }));
  }
}
