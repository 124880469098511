import { MyArtBulkActionsService } from '@graphics-flow/ui';
import { Injectable } from '@angular/core';
import { IRoles, User, WebJob } from '@graphics-flow/types';
import { ArtService, BillingPlansService, GraphicsFlowService, UserHelper, UserQuery, defaultHomePage } from '@graphics-flow/util';
import { Observable, tap } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DeletedFilesResolverService  {

  constructor(private artService: ArtService,
    private myArtBulkActionsService: MyArtBulkActionsService,
    private billingPlansService: BillingPlansService,
    private readonly graphicsFlowService: GraphicsFlowService,
    private readonly userQuery: UserQuery,
    private readonly router: Router
  ){ }

  resolve(): Observable<WebJob> {
    const user: User = this.userQuery.getActiveUserEntity();
    const role: IRoles = UserHelper.getUserRole(user);
    // Restrict access to deleted pages for support users
    if (role === IRoles.Support) {
      this.router.navigate([defaultHomePage]);
      return null;
    }
    return this.artService.getJobStatus().pipe(
      tap((jobStatus: WebJob) => {
        this.graphicsFlowService.isDeleteJobInProgress = jobStatus?.active || false;
        if (jobStatus?.active) {
          // have to reset `--art-header-height` while navigating to `My Art` and returning to `Deleted Files` page
          const doc = document?.documentElement;
          doc.style.setProperty('--art-header-height', `374px`);
          this.myArtBulkActionsService.startCheckingWebJobStatus();
        }
        this.loadDataInBackground();
      })
    );
  }

  loadDataInBackground(): void {
    this.billingPlansService.getOrganizationResourceUsage().subscribe();
  }
}
