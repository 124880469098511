import { Injectable } from '@angular/core';
import { setActiveId, updateEntities, upsertEntities } from '@ngneat/elf-entities';
import { Observable } from 'rxjs';

import { ArtHttpService, UserHttpService } from '@graphics-flow/api';
import { CreateUserModel, ID, InviteTeam, IRoles, LoginResult, User, UserSettings } from '@graphics-flow/types';
import { switchMap, tap } from 'rxjs/operators';

import { TeamService } from '../team/team.service';
import { UserQuery } from './user.query';
import { UserStore } from './user.state';
import { UserHelper } from '../../helpers/user.helper';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private readonly teamService: TeamService,
    private readonly userHttpService: UserHttpService,
    private readonly userStore: UserStore,
    private readonly userQuery: UserQuery,
    private readonly artHttpService: ArtHttpService
  ) {
  }

  authenticate(email: string, password: string): Observable<LoginResult> {
    return this.userHttpService.authenticate(email, password);
  }

  createUser(createUserModel: CreateUserModel): Observable<User> {
    return this.userHttpService.createUser(createUserModel);
  }

  getUser(userId: ID, includeDeleted: boolean = false): Observable<User> {
    return this.userHttpService.get(userId, includeDeleted).pipe(
      tap((updatedUser: User) => {
        this.updateUserInStore(updatedUser);
      })
    );
  }

  deleteUser(userId: ID): Observable<User> {
    return this.userHttpService.deleteUser(userId).pipe(
      tap((user: User) => {
        this.teamService.update(userId, user);
      })
    );
  }

  changePassword(currentPassword: string, newPassword: string): Observable<User> {
    const user = this.userQuery.getUser();
    return this.userHttpService.changePassword(user.email, currentPassword, newPassword);
  }

  updateUser(user: User): Observable<User> {
    return this.userHttpService.updateUser(user).pipe(
      switchMap((updatedUser: User) => {
        return this.getUser(updatedUser.userId);
      })
    );
  }

  updateUserSettings(userId: ID, userSettings: UserSettings): void {
    this.updateUserEntities(userId, { userSettings });
  }

  updateUserInStore(user: User): void {
    const existingSettings = this.userQuery.getUserSettings();
    user['userSettings'] = !existingSettings ? UserHelper.getDefaultUserSettings() : existingSettings;
    this.userStore.update(upsertEntities(user));
  }

  isAuthenticated(): boolean {
    return !!this.userQuery.getActiveUserToken();
  }

  setActive(userId: ID): void {
    return this.userStore.update(setActiveId(userId));
  }

  changeProfilePicture(userId: ID, file: File): Observable<User> {
    return this.artHttpService.uploadProfilePicture(file).pipe(
      switchMap(() => this.getUser(userId)));
  }

  removeProfilePicture(user: User): Observable<User> {
    return this.artHttpService.deleteProfilePicture().pipe(
      tap(() => {
        this.updateUserEntities(user.userId,
          {
            profileArtOrgId: null,
            profileArtGuid: null,
            profileAssetGuid: null,
            profileAssetExts: []
          }
        );
      })
    );
  }

  forgotPassword(email: string): Observable<any> {
    return this.userHttpService.forgotPassword(email);
  }

  resetPassword(email: string, resetPasswordToken: string, newPassword: string): Observable<User> {
    return this.userHttpService.resetPassword(email, resetPasswordToken, newPassword);
  }

  inviteTeamMemeber(inviteTeams: InviteTeam[]): Observable<User> {
    return this.userHttpService.inviteTeamMemeber(inviteTeams);
  }

  changeUserRoleInOrganization(userId: ID, role: IRoles): Observable<User> {
    return this.userHttpService.changeUserRoleInOrganization(userId, role);
  }

  transferOwnership(userId: ID): Observable<User> {
    return this.userHttpService.transferOwnership(userId);
  }

  userExist(email: string): Observable<boolean> {
    return this.userHttpService.userExists(email);
  }

  changeUserFirstLoginStatus(userId: ID, status: boolean) {
    this.updateUserEntities(userId, {
      isFirstLogin: status
    });
  }

  changeReceivedDesignRequestStatus(userId: ID, status: boolean) {
    this.updateUserEntities(userId, {
      receivedDesignRequest: status
    });
  }

  updateUserEntities(id: ID, user: Partial<User> ) {
    this.userStore.update(updateEntities(id, user));
  }
}
