<ng-container *ngIf="stockArtListQuery.activeFilters$ | async as activeFilter">
  <mat-chip-listbox selectable="false">

    <ng-container *ngIf="(windowService.smallDown$ | async) || showDownloadFilterChips">
      <mat-chip-option *ngIf="activeFilter.stockArtFilterType === stockArtFilterType.Downloaded"
                id="downloadedChip"
                [value]="stockArtFilterType.Downloaded"
                [selectable]="false"
                [removable]="true"
                (removed)="removeStockArtFilterType()">{{translations.common.downloaded | translate}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>

      <mat-chip-option *ngIf="activeFilter.stockArtFilterType === stockArtFilterType.NotDownloaded"
                id="notDownloadedChip"
                [value]="stockArtFilterType.NotDownloaded"
                [selectable]="false"
                [removable]="true"
                (removed)="removeStockArtFilterType()">{{translations.common.not_downloaded | translate}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>

      <mat-chip-option *ngIf="activeFilter.stockArtFilterType === stockArtFilterType.HiddenManuallyInArtPortal" id="hideInArtPortalChip"
        [value]="stockArtFilterType.HiddenManuallyInArtPortal" [selectable]="false" [removable]="true"
        (removed)="removeStockArtFilterType()">{{translations.common.manually_hidden_in_art_portal | translate}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>

      <mat-chip-option *ngIf="activeFilter.stockArtFilterType === stockArtFilterType.Favorites" id="favouritesChip"
        [value]="stockArtFilterType.Favorites" [selectable]="false" [removable]="true"
        (removed)="removeStockArtFilterType()">{{translations.common.favorites | translate}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
    </ng-container>

    <mat-chip-option *ngFor="let category of activeFilter.filterHeaders.categories"
              [value]="category.folderId"
              [selectable]="false"
              [removable]="true"
              (removed)="removeCategoryFilter(category)">{{ category.name }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-option>

    <mat-chip-option *ngFor="let category of activeFilter.filterHeaders.subCategories"
              [value]="category.folderId"
              [selectable]="false"
              [removable]="true"
              (removed)="removeSubCategoryFilter(category)">{{ category.name }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-option>


    <mat-chip-option *ngFor="let style of activeFilter.filterHeaders.styles"
              [value]="style"
              [selectable]="false"
              [removable]="true"
              (removed)="removeFilter('styles', style)">{{ style }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-option>

    <mat-chip-option *ngFor="let colorCount of activeFilter.filterHeaders.colorCount"
              [value]="colorCount"
              [selectable]="false"
              [removable]="true"
              (removed)="removeFilter('colorCount', colorCount)">{{ colorCount }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-option>

    <mat-chip-option *ngFor="let artLibrary of activeFilter.filterHeaders?.artLibraries"
              [value]="artLibrary"
              [selectable]="false"
              [removable]="true"
              (removed)="removeFilter('artLibraries', artLibrary)"
              class="text-capitalize">{{ ("artLibraries." + artLibrary) | translate }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-option>
  </mat-chip-listbox>
</ng-container>
