<div id="gfSidebar"
  [class.uploading]="((myArtUploaderService.queue | async)?.length && !(myArtUploaderService?.isQueueProcessed$ | async)) || (myArtUploaderService.isUploadPreparing$ | async)"
  [class.completed]="(myArtUploaderService.queue | async)?.length && (myArtUploaderService?.isQueueProcessed$ | async)">
  <button *ngIf="showCloseButton" mat-icon-button class="close-drawer-btn suppress-hover-style mt-4 ml-4" [autofocus]="false" (click)="closeDrawer.emit()" tabIndex="-1">
    <mat-icon fontIcon="close"></mat-icon>
  </button>
  <div class="gf-logo-container" [class.with-close-btn]="showCloseButton">
    <gf-logo [size]="'medium'" [isDarkLogo]="false"></gf-logo>
  </div>

  <div class="sidebar-section-header">
    <div>{{translations.navigation.stock_art | translate}}</div>
  </div>

  <div class="sidebar-link-wrapper">
    <a id="designIdeasLink"
       class="sidebar-link"
       [class.active]="designIdeas.isActive"
       #designIdeas="routerLinkActive"
       routerLink="/stock/design-ideas"
       routerLinkActive
       (click)="goToStockArt(StockArtType.DesignIdea)">
      <mat-icon class="m-r-2"
                [class]="designIdeas.isActive ? 'material-icons' : 'material-icons-outlined'" fontIcon="collections"></mat-icon>
      <span>{{translations.navigation.design_ideas | translate}}</span>
    </a>
  </div>

  <div class="sidebar-link-wrapper">
    <a id="clipArtLink"
       class="sidebar-link"
       [class.active]="clipArt.isActive"
       #clipArt="routerLinkActive"
       routerLink="/stock/clip-art"
       routerLinkActive
       (click)="goToStockArt(StockArtType.ClipArt)">
      <mat-icon class="m-r-2"
                [class]="clipArt.isActive ? 'material-icons' : 'material-icons-outlined'" fontIcon="landscape"></mat-icon>
      <span>{{translations.navigation.clip_art | translate}}</span>
    </a>
  </div>

  <div class="sidebar-link-wrapper">
    <a id="fontsLink"
       class="sidebar-link"
       [class.active]="fonts.isActive"
       #fonts="routerLinkActive"
       routerLink="/stock/fonts"
       routerLinkActive
       (click)="goToStockArt(StockArtType.Font)">
      <mat-icon class="m-r-2"
                [class]="fonts.isActive ? 'material-icons' : 'material-icons-outlined'" fontIcon="font_download"></mat-icon>
      <span>{{translations.navigation.fonts | translate}}</span>
    </a>
  </div>

  <mat-divider class="sidebar-section-divider"></mat-divider>

  <div class="sidebar-link-wrapper">
    <a id="myArtLink"
       class="sidebar-link"
       [class.active]="my.isActive"
       #my="routerLinkActive"
       routerLink="/my"
       routerLinkActive>
      <mat-icon class="m-r-2"
                [class]="my?.isActive ? 'material-icons' : 'material-icons-outlined'" fontIcon="folder"></mat-icon>
      <span>{{translations.navigation.my_art | translate}}</span>
    </a>
  </div>
  <div *ngIf="!(userQuery.isSupport$ | async)" class="sidebar-link-wrapper">
    <a id="deletedFilesLink"
       class="sidebar-link"
       [class.active]="trash.isActive"
       #trash="routerLinkActive"
       [routerLink]="'/' + fileRoutes.DeletedFiles"
       routerLinkActive>
      <mat-icon class="m-r-2"
                [class]="trash?.isActive ? 'material-icons' : 'material-icons-outlined'" fontIcon="delete"></mat-icon>
      <span>{{translations.art.deleted_files | translate}}</span>
    </a>
  </div>

  <mat-divider class="sidebar-section-divider"></mat-divider>

  <div *ngIf="organizationQuery?.isProPlan$ | async" class="sidebar-link-wrapper">
    <div class="sidebar-link art-portal-link-container">
      <a id="viewArtPortalLink"
        class="flex-container flex-child-grow align-middle art-portal-link"
        [matTooltipPosition]="'above'"
        [matTooltip]="translations.public_stock_art.view_art_portal | translate"
        (click)="openCatalog()">
        <mat-icon class="sidebar-link-icon m-r-2" fontIcon="important_devices"></mat-icon>
        <span>{{translations.public_stock_art.art_portal | translate}}</span>
        <mat-icon class="new-tab-icon" fontIcon="open_in_new"></mat-icon>
      </a>
      <div class="flex-container m-l-4 art-portal-menu">
        <mat-icon id="artPortalMenuTrigger" class="cursor-pointer" [mat-menu-trigger-for]="publicCatalogMenu" fontIcon="more_vert"></mat-icon>
      </div>
      <div #linkCopiedToolTip="matTooltip" matTooltipClass="link-copied"
      [matTooltip]="translations.public_stock_art.link_copied | translate"
      matTooltipPosition="after"></div>
      <mat-menu #publicCatalogMenu="matMenu"
        yPosition="below"
        xPosition="before"
        class="art-portal-menu">
        <button mat-menu-item (click)="copyPublicArtLink()">{{translations.public_stock_art.copy_art_portal_link | translate}}</button>
        <button mat-menu-item (click)="openCatalog()">{{translations.public_stock_art.view_art_portal | translate}}</button>
        <button *ngIf="!(userQuery.isSupport$ | async)" mat-menu-item routerLink="/team-settings/art-portal">{{translations.public_stock_art.manage_settings | translate}}</button>
      </mat-menu>
    </div>
  </div>

  <div *ngIf="organizationQuery.isProPlan$ | async" class="sidebar-link-wrapper">
    <a id="designRequestsLink"
       class="sidebar-link"
       [class.active]="designRequests.isActive"
       #designRequests="routerLinkActive"
       routerLink="/design-requests"
       routerLinkActive>
      <mat-icon class="m-r-2"
                [class]="designRequests.isActive ? 'material-icons' : 'material-icons-outlined'" fontIcon="new_releases"></mat-icon>
      <span>{{translations.navigation.design_requests | translate}}</span>
    </a>
  </div>

  <div class="sidebar-link-wrapper">
    <a id="approvalsLink"
       class="sidebar-link"
       [class.active]="approvals.isActive"
       #approvals="routerLinkActive"
       routerLink="/approvals"
       routerLinkActive>
      <mat-icon class="m-r-2"
                [class]="approvals.isActive ? 'material-icons' : 'material-icons-outlined'" fontIcon="thumb_up"></mat-icon>
      <span>{{translations.navigation.approvals | translate}}</span>
    </a>
  </div>

  <mat-divider class="sidebar-section-divider"></mat-divider>

  <div class="sidebar-link-wrapper">
    <a id="myTeamLink"
      class="sidebar-link"
      [class.active]="myTeam.isActive"
      #myTeam="routerLinkActive"
      routerLink="/my-team"
      routerLinkActive>
      <mat-icon class="m-r-2" fontIcon="group"></mat-icon>
      <span>{{translations.navigation.my_team | translate}}</span>
    </a>
  </div>

  <div *ngIf="!(userQuery.isSupport$ | async)" class="sidebar-link-wrapper">
    <a id="teamSettingsLink"
      class="sidebar-link"
      [class.active]="teamSettingsLink.isActive"
      #teamSettingsLink="routerLinkActive"
      routerLink="/team-settings"
      routerLinkActive>
      <mat-icon class="m-r-2" fontIcon="miscellaneous_services"></mat-icon>
      <span>{{translations.navigation.account_settings | translate}}</span>
    </a>
  </div>
</div>
<div *ngIf="(myArtUploaderService.queue | async)?.length || (myArtUploaderService.isUploadPreparing$ | async)" class="sticky-upload">
  <div *ngIf="myArtUploaderService?.isQueueProcessed$ | async" class="dismiss-upload cursor-pointer" (click)="myArtUploaderService.clearQueue()">
    <mat-icon class="close-icon" fontIcon="close"></mat-icon>
    <p class="m-b-0">Dismiss</p>
  </div>
  <div class="upload-card flex-container cursor-pointer" [class.queue-closed]="(queueClosedWhileUploadInProgress$ | async)"
    (click)="myArtUploaderService.displayPanel()">
    <ng-container *ngIf="myArtUploaderService?.isQueueProcessed$ | async">
      <span *ngIf="!(myArtUploaderService?.failedFilesCount$ | async)" class="upload-status">
        <mat-icon class="success" fontIcon="check_circle"></mat-icon>
      </span>
      <span *ngIf="(myArtUploaderService?.failedFilesCount$ | async)" class="upload-status">
        <mat-icon class="fail" fontIcon="error"></mat-icon>
      </span>
    </ng-container>
    <div class="flex-1" [ngClass]="{'m-l-2': !(myArtUploaderService?.isQueueProcessed$ | async), 'm-l-1': myArtUploaderService?.isQueueProcessed$ | async}">
      <p *ngIf="!(myArtUploaderService?.isQueueProcessed$ | async)" class="upload-header m-b-0">{{translations.fileupload.upload_in_process | translate}}</p>
      <p *ngIf="(myArtUploaderService?.isQueueProcessed$ | async)" class="upload-header m-b-0">{{translations.fileupload.upload_completed | translate}}</p>
      <p class="upload-text-count">
        <span *ngIf="!(myArtUploaderService?.isQueueProcessed$ | async)">{{translations.fileupload.uploading | translate}}</span>
        <ng-container *ngIf="!(myArtUploaderService.isUploadPreparing$ | async)">
          ({{myArtUploaderService?.successFilesCount$ | async}}/{{(myArtUploaderService.queue | async)?.length}})
        </ng-container>
        <span *ngIf="(myArtUploaderService?.failedFilesCount$ | async) && (myArtUploaderService?.isQueueProcessed$ | async)" class="failed-info">
          {{ (myArtUploaderService.failedFilesCount$ | async) | i18nPlural : fileUploadFailMessage | translate : {x: (myArtUploaderService.failedFilesCount$ | async)} }}
        </span>
      </p>
    </div>
    <mat-icon class="upload-detail cursor-pointer" fontIcon="navigate_next"></mat-icon>
  </div>
</div>
<p class="copy-rights">
  {{translations.common.copy_right_graphicsflow | translate : {x: currentYear} }}
</p>
<div id="inktavoLogo" class="inktavo-logo flex-container align-center">
  <a href="https://www.inktavo.com/" target="_blank">
    <img src="/assets/images/an_inktavo_co_white.svg" [alt]="translations.common.inktavo_logo | translate">
  </a>
</div>
