import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HammerModule } from '@angular/platform-browser';
import { DndModule } from '@ng-dnd/core';
import { TranslateModule } from '@ngx-translate/core';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { GfUiModule } from '@graphics-flow/ui';
import {
  AuthInterceptor,
  ErrorHandlerService,
  GfUtilModule,
  GraphicsFlowService,
  HttpBlobErrorInterceptor,
  HttpErrorInterceptor
} from '@graphics-flow/util';
import { SharedUiModule } from 'shared/ui';
import { DateInterceptor } from 'shared/util';
import { MainComponent } from './main.component';
import { mainRoutesModule } from './main.routes';

@NgModule({
  imports: [
    CommonModule,
    DndModule.forRoot({ backend: HTML5Backend }),
    FormsModule,
    GfUiModule,
    GfUtilModule,
    HammerModule,
    HttpClientModule,
    mainRoutesModule,
    SharedUiModule,
    TranslateModule.forChild()
  ],
  declarations: [ MainComponent ],
  exports: [ MainComponent ],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [GraphicsFlowService],
      useFactory: (appInitializerFactory),
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpBlobErrorInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MainModule {}

export function appInitializerFactory(
  graphicsFlowService: GraphicsFlowService) {
  return () => {
    return graphicsFlowService.initialize();
  }
}
