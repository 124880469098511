import { Pipe, PipeTransform } from '@angular/core';
import { ID, StockArt } from '@graphics-flow/types';
import { StockArtQuery } from '@graphics-flow/util';

@Pipe({
  name: 'stockArtPipe'
})
export class StockArtPipe implements PipeTransform {
  constructor(private stockArtQuery: StockArtQuery) {
  }
  transform(stockArtId: ID): StockArt {
    return this.stockArtQuery.getStockArtEntity(stockArtId);
  }
}
