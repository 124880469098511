import { Pipe, PipeTransform } from '@angular/core';
import { Art, ID, ImagePreviewType } from '@graphics-flow/types';
import { ImagePreviewPipe } from './image-preview.pipe';
import { ArtHelper, noImagePreviewUrl } from '@graphics-flow/util';

@Pipe({
  name: 'myArtImagePreview'
})
export class MyArtImagePreviewPipe implements PipeTransform {

  constructor(private imagePreviewPipe: ImagePreviewPipe) {
  }

  transform(
    orgId: ID,
    art: Art,
    previewType: ImagePreviewType,
    isWaterMark: boolean = false,
    isStockArt: boolean = false,
    extName?: string,
    watermarkId?: string,
  ): string {
    if (ArtHelper.isNoPreviewImage(art)) {
      const extension = ArtHelper.getFileExtension(art.fileName);
      return noImagePreviewUrl[extension?.toLowerCase()];
    }
    const blockCache = !!watermarkId || !!art.customizedStockArtId;
    return this.imagePreviewPipe.transform(orgId, art.assetId?.toString(), previewType, isWaterMark, isStockArt, extName, watermarkId, false, blockCache, art.modifiedDate.toString());
  }

}
