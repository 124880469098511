import { Injectable } from '@angular/core';
import { createState, setProp, Store, withProps } from '@ngneat/elf';
import { isUndefined as _isUndefined } from 'lodash-es';
import { ArtApprovalRequest, Pagination } from '@graphics-flow/types';
import { ART_APPROVAL_BASE_LIMIT } from '../../constants/infinity-scroller.constants';

export interface ArtApprovalSearchState {
  isSearchInFocus?: boolean;
  activeFilters: ArtApprovalRequest;
  filteredArtApprovalsPagination?: Pagination;
  loading: boolean;
  loadingResults: boolean;
}

export const initialArtApprovalSearchValue = {
  isSearchInFocus: false,
  loading: false,
  loadingResults: false,
  activeFilters: {
    index: 0,
    limit: ART_APPROVAL_BASE_LIMIT,
    searchText: '',
    isGlobalFilter: true
  }
};

const { state, config } = createState(
  withProps<ArtApprovalSearchState>(initialArtApprovalSearchValue)
);

@Injectable({ providedIn: 'root' })
export class ArtApprovalSearchStore extends Store {
  constructor() {
    super({ name: 'art-approval-search', state, config });
  }

  setStateProp<T>(key: string, value: T) {
    this.update(setProp(key, value));
  }

  setPagination(pagination: Pagination): void {
    this.setStateProp<Pagination>('filteredArtApprovalsPagination', pagination);
  }

  updateLoading(loading: boolean): void {
    this.setStateProp<boolean>('loading', loading);
  }

  updateLoadingResult(loadingResults: boolean): void {
    this.setStateProp<boolean>('loadingResults', loadingResults);
  }

  updateSearchFocusStatus(focusStatus: boolean): void {
    this.setStateProp<boolean>('isSearchInFocus', focusStatus);
  }

  setActiveFilters(filters: ArtApprovalRequest): void {
    this.update((state) => {
      const activeFilters = {
        index: !_isUndefined(filters.index) ? filters.index : state.activeFilters.index,
        limit: !_isUndefined(filters.limit) ? filters.limit : state.activeFilters.limit,
        searchText: !_isUndefined(filters.searchText) ? filters.searchText : state.activeFilters.searchText,
        isGlobalFilter: true
      };
      return {
        ...state,
        activeFilters
      };
    });
  }
}
