import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericDialogData } from '@graphics-flow/types';

@Component({
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss']
})
export class GenericDialogComponent {
  data: GenericDialogData;
  continueBtnClass: string | string[];

  constructor(
    public dialogRef: MatDialogRef<GenericDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: GenericDialogData) {
    this.data = data || {};
    this.data.id = this.data.id || 'genericConfirmDialog';
    this.continueBtnClass = this.getContinueBtnClass();
  }

  confirm (val: boolean) {
    this.dialogRef.close(val);
  }

  getContinueBtnClass() {
    return this.data?.continueClass?.length ? this.data.continueClass : ['mat-primary'];
  }

}
