// For More:- https://stripe.com/docs/api/subscriptions/object
export enum StripeSubscriptionStatus {
  ACTIVE = 'active',
  ALL = 'all',
  CANCELLED = 'cancelled',
  ENDED = 'ended',
  INCOMPLETE = 'incomplete', // if the initial payment attempt fails
  INCOMPLETE_EXPIRED = 'incompleteExpired', //  If the first invoice is not paid within 23 hours, the subscription transitions to incomplete_expired
  PAST_DUE = 'pastDue',
  TRIALING = 'trialing',
  UNPAID = 'unpaid',
}
