import { Pipe, PipeTransform } from '@angular/core';
import { AdditionalPrice, OrganizationResourceUsage, Plan } from '@graphics-flow/types';
import { BillingPlansQuery } from '@graphics-flow/util';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

@Pipe({
  name: 'isDowngradeAllowed'
})
export class IsDowngradeAllowedPipe implements PipeTransform {
  constructor(private billingQuery: BillingPlansQuery) {
  }
  transform(targetPlan: Plan): Observable<boolean> {

    return this.billingQuery.organizationResourceUsage$.pipe(
      map((usage: OrganizationResourceUsage) => {

        // Check Downloads
        let addOnDownloads = 0;
        const additionalStockArtAddOn = usage.organizationBilling.subscription.items.find(item => item.price.productName === AdditionalPrice.STOCK_ART);

        if (additionalStockArtAddOn) {
        // Under subscription items for stock art downloads we are getting the calulated monthlydownloads,
        // so just adding it to the addonDownloads.
          addOnDownloads += (additionalStockArtAddOn.price.monthlyDownloads);
        }

        if (usage.stockArtDownloads > (targetPlan?.downloads + addOnDownloads)) {
          return false;
        }

        // Check Storage
        let addOnStorage = 0;
        const additionalStorageAddOn = usage.organizationBilling.subscription.items.find(item => item.price.productName === AdditionalPrice.STORAGE);

        if (additionalStorageAddOn) {
          addOnStorage += (additionalStorageAddOn.quantity * additionalStorageAddOn.price.storageBytes);
        }

        if (usage.storageBytes > (targetPlan.storage + addOnStorage)) {
          return false;
        }


        // Check Users
        let addOnUsers = 0;
        const additionalUserAddOn = usage.organizationBilling.subscription.items.find(item => item.price.productName === AdditionalPrice.USER);

        if (additionalUserAddOn) {
          addOnUsers += additionalUserAddOn.quantity;
        }

        if (usage.userCount > (targetPlan?.users + addOnUsers)) {
          return false;
        }

        return true;
      })
    );
  }
}
