import { Pipe, PipeTransform } from '@angular/core';
import { StringHelpers } from 'shared/util';

@Pipe({
  name: 'iconBackground'
})
export class IconBackgroundPipe implements PipeTransform {
  transform(value: string): unknown {
    if (!value) {
      return;
    }
    const index: number = StringHelpers.strToNum(StringHelpers.shortName(value));
    const numColors = 12;
    return 'icon-bg-' + Math.abs(index % numColors);
  }

}
