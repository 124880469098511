import { Component } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';
import { StockArtFilterType, StockArtType } from '@graphics-flow/types';
import { OrganizationQuery, StockArtListQuery, StockArtListService } from '@graphics-flow/util';

@Component({
  selector: 'gf-stock-art-type-filter',
  templateUrl: './stock-art-type-filter.component.html',
  styleUrls: ['./stock-art-type-filter.component.scss']
})
export class StockArtTypeFilterComponent {

  StockArtFilterType: typeof StockArtFilterType = StockArtFilterType;
  StockArtType: typeof StockArtType = StockArtType;

  constructor(
    public readonly stockArtListQuery: StockArtListQuery,
    public readonly stockArtListService: StockArtListService,
    public readonly translations: Translations,
    public readonly organizationQuery: OrganizationQuery
  ) { }

  changeFilterType(filterType: StockArtFilterType): void {
    const stockArtListState = this.stockArtListQuery.getValue();
    if (stockArtListState.activeFilters.stockArtFilterType !== filterType) {
      this.stockArtListService.changeFilterType(filterType);
    }
  }

}
