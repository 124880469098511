import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Folder, ID } from '@graphics-flow/types';
import { FolderQuery } from '@graphics-flow/util';
import { FolderMoveDialogState, FolderMoveDialogStore } from './folder-move-dialog.store';

@Injectable({ providedIn: 'root' })
export class FolderMoveDialogQuery {
  selectedFolder$: Observable<Folder> = this.selectStateProps<ID>((store: FolderMoveDialogState) => store.selectedFolderId).pipe(
    switchMap((folderId: ID) => this.folderQuery.selectFolderEntity(folderId))
  );

  navigationLevel$: Observable<ID> = this.selectStateProps<ID>((store: FolderMoveDialogState) => store.navigationLevel);

  navigationLevelFolder$: Observable<Folder> = this.navigationLevel$.pipe(
    switchMap((folderId: ID) => {
      if (folderId) {
        return of(this.folderQuery.getFolderById(folderId))
      }
      return of(null);
    })
  );

  constructor(protected store: FolderMoveDialogStore,
              private folderQuery: FolderQuery) {
  }

  selectStateProps<T>(predicate): Observable<T> {
    return this.store.pipe(select(predicate));
  }
}
