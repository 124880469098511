import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { setProp } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Translations } from '@graphics-flow/shared/assets';
import { Art, Folder, ID, UploadArt } from '@graphics-flow/types';
import { FolderMoveDialogComponent, FolderMoveDialogData } from './folder-move-dialog.component';
import { FolderMoveDialogStore } from './folder-move-dialog.store';

@Injectable({
  providedIn: 'root'
})
export class FolderMoveDialogService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly translations: Translations,
    private readonly folderMoveDialogStore: FolderMoveDialogStore
  ) { }

  openMoveFolderDialog(arts: Art[], folders: Folder[]): Observable<Folder> {
    return this.dialog.open(FolderMoveDialogComponent, <MatDialogConfig>{
      data: <FolderMoveDialogData> {
        art: arts,
        folders: folders,
        title: this.translations.folder.move_to,
        cancelText: this.translations.common.cancel,
        confirmText: this.translations.folder.move_selection
      },
      disableClose: true,
      autoFocus: false,
      panelClass: 'mobile-screen-modal',
    }).afterClosed().pipe(
      filter(confirmed => !!confirmed),
      filter((destinationFolder: Folder) => {
        let notSameDestination = true;

        if (!destinationFolder.folderId) {
          notSameDestination = folders?.length ? !!folders[0]?.parentId : !!arts[0]?.folderId;
        } else {
          notSameDestination = (folders?.length ? folders[0]?.parentId : arts[0]?.folderId) !== destinationFolder?.folderId;
        }
        return destinationFolder.folderId !== (folders?.length ? folders[0]?.parentId : arts[0]?.folderId) && notSameDestination;  // Can't be your own daddy
      }),
      // TODO: Make sure they aren't moving a folder to one of its descendents either
    )
  }

  chooseLocationToRecover(arts: Art[], folders: Folder[]): Observable<Folder> {
    return this.dialog.open(FolderMoveDialogComponent, <MatDialogConfig>{
      data: <FolderMoveDialogData> {
        art: arts,
        folders: folders,
        title: this.translations.recover.choose_location,
        confirmText: this.translations.recover.recover,
        cancelText: this.translations.common.cancel,
      },
      disableClose: true,
      autoFocus: false,
      panelClass: 'mobile-screen-modal',
    }).afterClosed().pipe(
      filter(confirmed => !!confirmed),
      filter((destinationFolder: Folder) => {
        return destinationFolder.folderId !== (folders?.length ? folders[0]?.parentId : arts[0]?.folderId);  // Can't be your own daddy
      }),
      // TODO: Make sure they aren't moving a folder to one of its descendents either
    )
  }

  openAddArtApprovalArtToFolder(arts: Art[] | UploadArt[], folders: Folder[]): Observable<Folder> {
    return this.dialog.open(FolderMoveDialogComponent, <MatDialogConfig>{
      data: <FolderMoveDialogData> {
        art: arts,
        folders: folders,
        title: this.translations.approval.save_copy_to_my_art,
        cancelText: this.translations.common.cancel,
        confirmText: this.translations.common.save_copy
      },
      autoFocus: false,
      panelClass: 'mobile-screen-modal',
    }).afterClosed().pipe(
      filter(confirmed => !!confirmed)
    )
  }

  setSelectedFolderId(folderId: ID) {
    this.folderMoveDialogStore.update(setProp('selectedFolderId', folderId));
  }

  setNavigationLevel(folderId: ID) {
    this.folderMoveDialogStore.update(setProp('navigationLevel', folderId));
  }

  reset() {
    this.folderMoveDialogStore.reset();
  }
}
