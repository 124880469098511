import { TimeZone, getTimeZones } from '@vvo/tzdb';

export class DateHelpers {

  public static getDateAsIsoString(value: Date): string {
    return new Date(value).toISOString();
  }

  public static getTimeZoneAbbreviation(): string {

    const timeZones: TimeZone[] = getTimeZones();

    const locale: Intl.ResolvedDateTimeFormatOptions = Intl.DateTimeFormat().resolvedOptions();

    const zoneDetails: TimeZone = timeZones.find((timeZone: TimeZone) => {
      return locale.timeZone === timeZone.name || timeZone.group.includes(locale.timeZone);
    });

    return zoneDetails.abbreviation;
  }
}
