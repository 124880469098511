import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';
import { ArtActionsService } from '../../services/art-actions.service';

@Component({
  selector: 'link-notification-message',
  templateUrl: './link-notification-message.component.html',
  styleUrls: ['./link-notification-message.component.scss']
})
export class LinkNotificationMessageComponent {
  @Input() link = '';
  @ViewChild('artLinkTemplate', { static: true }) public artLinkRef: TemplateRef<any>;
  @ViewChild('artFileUnsupported', { static: true }) public artFileUnsupportedRef: TemplateRef<any>;
  constructor(
    public artActionsService: ArtActionsService,
    public translations: Translations
  ) { }
}
