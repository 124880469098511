import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';
import { ID, ImagePreviewType } from '@graphics-flow/types';

@Component({
  selector: 'stock-art-image',
  templateUrl: './stock-art-image.component.html',
  styleUrls: ['./stock-art-image.component.scss']
})
export class StockArtImageComponent implements OnChanges {
  @Input() artId: ID;
  @Input() showTools = true;
  @Input() showCustomizeStockArt = false;
  @Input() imageUrl: string;
  @Input() showUndoControls: boolean;
  @Input() disableUndo: boolean;
  @Input() disableRedo: boolean;
  @Output() activeCustomizeStockArt: EventEmitter<void> = new EventEmitter<void>();
  @Output() undo: EventEmitter<void> = new EventEmitter<void>();
  @Output() redo: EventEmitter<void> = new EventEmitter<void>();
  imagePreviewType: typeof ImagePreviewType = ImagePreviewType;
  showDarkPreview = false;

  constructor(public readonly translations: Translations) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.showUndoControls?.currentValue) {
      this.showDarkPreview = false;
    }
  }

  togglePreview(): void {
    this.showDarkPreview = !this.showDarkPreview;
  }

}
