import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ArtApproval, ArtApprovalItem, ID, ImagePreviewType, StockArtType } from '@graphics-flow/types';
import { ArtQuery, OrganizationQuery } from '@graphics-flow/util';

@Component({
  selector: 'art-image',
  templateUrl: './art-image.component.html',
  styleUrls: ['./art-image.component.scss']
})
export class ArtImageComponent {
  @Input() artId: ID;
  @Input() showTools = true;
  @Input() approvalItem: ArtApprovalItem;
  @Input() approval: ArtApproval;
  @Input() background: string;
  @Input() imagePreviewType: ImagePreviewType;
  @Input() hasWatermark = false;
  @Input() watermarkId: string;
  @ViewChild('artImage') artImage: ElementRef;
  stockArtType: typeof StockArtType = StockArtType;

  constructor(
    public readonly artQuery: ArtQuery,
    public readonly organizationQuery: OrganizationQuery
  ) {
  }

}
