import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Folder, ID, MyArtActiveQueryParams } from '@graphics-flow/types';
import { FolderQuery, FolderService } from '@graphics-flow/util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { delay, first, map, switchMap, tap } from 'rxjs/operators';
import { WindowService } from 'shared/util';
import { FolderMoveDialogQuery } from '../folder-move-dialog.query';
import { FolderMoveDialogService } from '../folder-move-dialog.service';

@UntilDestroy()
@Component({
  selector: 'gf-mobile-view-folder-move-list-item',
  templateUrl: './mobile-folder-move-list-item.component.html',
  styleUrls: ['./mobile-folder-move-list-item.component.scss']
})
export class MobileFolderMoveListItemComponent {
  readonly Math = Math;
  @Input() sourceFolderIds: ID[] = [];
  @Input() folders: Folder[];
  @Input() depth = 0; // Depth in the category tree, just used for indentation

  @Output() selectedFolderChange: EventEmitter<void> = new EventEmitter();

  children$: Observable<Folder[]> = this.folderMoveDialogQuery.navigationLevel$.pipe(
    delay(150),
    switchMap((folderId: ID) => this.folderQuery.selectFolderChildren(folderId || null)),
    map(folders => folders.filter(folder => !this.sourceFolderIds.includes(folder.folderId)))
  );
  showFolderLoaderById: ID;

  constructor(
    private readonly folderQuery: FolderQuery,
    public readonly folderMoveDialogQuery: FolderMoveDialogQuery,
    public readonly windowService: WindowService,
    public readonly folderService: FolderService,
    public readonly folderMoveDialogService: FolderMoveDialogService
  ) {
  }

  toggleExpanded(folderId: ID): void {
    const children: Folder[] = this.folderQuery.getFolderChildren(folderId || null);
    if (children?.length) {
      this.folderMoveDialogService.setNavigationLevel(folderId || null);
      return;
    }
    this.showFolderLoaderById = folderId;
    this.folderService.loadFolders(<MyArtActiveQueryParams>{folderId}).pipe(
      first(),
      untilDestroyed(this),
      tap(() => {
        this.folderMoveDialogService.setNavigationLevel(folderId || null);
        this.showFolderLoaderById = '';
      })
    ).subscribe();
  }

  setSelectedFolder(folderId: ID = null): void {
    this.folderMoveDialogService.setSelectedFolderId(folderId);
    this.selectedFolderChange.emit();
  }

}
