export enum AdditionalPrice {
  GRAPHICS_BUILDER = 'Graphics Builder',
  PRIVATE_BRANDING = 'Private Branding',
  STOCK_ART = 'Additional StockArt Downloads',
  STORAGE = 'Additional 1TB Storage',
  USER = 'Additional User',
  GRAPHICS_BUILDER_BUNDLE = 'Graphics Builder Bundle',
  SMART_DESIGNER_ADD_ON = 'SmartDesigner Add On',
  SMART_DESIGNER_ADDITIONAL_DEVICES_ADD_ON = 'SmartDesigner Additional Devices',
}
