export enum CustomizeStockArtSections {
  HOME = 'home',
  SAVE = 'save',
  EDIT_TEXT_DETAILS = 'editTextDetails',
  SELECT_COLOR = 'selectColor',
  EDIT_CLIP_ART = 'editClipArt',
  GLOBAL_COLOR = 'globalColor',
  SAVE_DESIGN_TO_MY_ART_VIEW = 'saveDesignToMyArtView',
  REPLACE_CLIP_ART = 'replaceClipArt',
  EDIT_MY_IMAGE = 'editMyImage'
}
