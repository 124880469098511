import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ArtApprovalComment, ID, ImagePreviewType } from '@graphics-flow/types';
import { Translations } from '@graphics-flow/shared/assets';

@Component({
  selector: 'gf-approval-comment-reply',
  templateUrl: './approval-comment-reply.component.html',
  styleUrls: ['./approval-comment-reply.component.scss']
})
export class ApprovalCommentReplyComponent {
  @Input() reply: ArtApprovalComment;
  @Output() commented: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteComment: EventEmitter<ID> = new EventEmitter<ID>();
  imagePreviewType: typeof ImagePreviewType = ImagePreviewType;

  constructor(public readonly translations: Translations) { }

}
