import { Injectable } from '@angular/core';
import { createState, setProps, Store, withProps } from '@ngneat/elf';
import { difference as _difference, isUndefined as _isUndefined, uniqBy as _uniqBy } from 'lodash-es';

import {
  ID,
  Pagination, StockArt,
  StockArtFilterType,
  StockArtHeaderPackage, StockArtOrder, StockArtRequest, StockArtType
} from '@graphics-flow/types';
import { GlobalHelpers } from '../../helpers/global.helpers';

export interface StockArtListState {
  activeFilters: StockArtRequest;
  activeFiltersFontStyles?: string[];
  filteredStockArts: StockArt[];
  filteredStockArtsPagination?: Pagination; // Pagination for StockArtList
  filterOptionsForDesignIdeas: StockArtHeaderPackage;
  filterOptionsForClipArt: StockArtHeaderPackage;
  filterOptionsForFonts: StockArtHeaderPackage;
  selectedCategoryIds?: ID[];
  selectedSubCategoryIds?: ID[];
  selectedStockArtIds?: ID[];
  globalSearch?: string;
  offset?: number;
  loading: boolean;
  loadingResults: boolean;
}

export const stockArtListBatchSize = 36;

export const initialStockArtListActiveFiltersState: StockArtRequest = {
  index: 0,
  limit: stockArtListBatchSize,
  orderBy: StockArtOrder.CreatedDate,
  orderDesc: true,
  filterHeaders: {
    stockArtType: StockArtType.DesignIdea
  },
  searchText: '',
  isCategoryModalOpened: false,
  stockArtFilterType: StockArtFilterType.All,
  isArtPortal: false,
  defaultCategory: null,
  artPortalCategories: [],
  artPortalSubCategories: [],
  hasArtPortalCategoryFilter: false
};

export const initialStockArtState: StockArtListState = {
  filterOptionsForDesignIdeas: {},
  filterOptionsForClipArt: {},
  filterOptionsForFonts: {},
  activeFilters: initialStockArtListActiveFiltersState,
  filteredStockArts: [],
  selectedStockArtIds: [],
  loading: false,
  loadingResults: false,
  selectedCategoryIds: [],
  selectedSubCategoryIds: []
};

const { state, config } = createState(
  withProps<StockArtListState>(initialStockArtState)
);

@Injectable({ providedIn: 'root' })
export class StockArtListStore extends Store {

  constructor() {
    super({ name: 'stock-art-list', state, config });
  }

  updateState(stockArtListState: StockArtListState) {
    this.update((state) => ({
      ...state,
      ...stockArtListState
    }));
  }

  setActiveFilters(filters: StockArtRequest, bypassIndexValidation: boolean = false, showInfiniteLoader: boolean = false) {
    this.update((state) => {
      const activeFilters = {
        index: !_isUndefined(filters.index) ? filters.index : state.activeFilters.index,
        limit: !_isUndefined(filters.limit) ? filters.limit : state.activeFilters.limit,
        orderBy: !_isUndefined(filters.orderBy) ? filters.orderBy : state.activeFilters.orderBy,
        orderDesc: !_isUndefined(filters.orderDesc) ? filters.orderDesc : state.activeFilters.orderDesc,
        searchText: !_isUndefined(filters.searchText) ? filters.searchText : state.activeFilters.searchText,
        stockArtFilterType: !_isUndefined(filters.stockArtFilterType) ? filters.stockArtFilterType : state.activeFilters.stockArtFilterType,
        isCategoryModalOpened: !_isUndefined(filters.isCategoryModalOpened) ? filters.isCategoryModalOpened : state.activeFilters.isCategoryModalOpened,
        isArtPortal: !_isUndefined(filters.isArtPortal) ? filters.isArtPortal : state.activeFilters.isArtPortal,
        defaultCategory:  !_isUndefined(filters.defaultCategory) ? filters.defaultCategory : state.activeFilters.defaultCategory,
        artPortalCategories:  !_isUndefined(filters.artPortalCategories) ? filters.artPortalCategories : state.activeFilters.artPortalCategories,
        artPortalSubCategories:  !_isUndefined(filters.artPortalSubCategories) ? filters.artPortalSubCategories : state.activeFilters.artPortalSubCategories,
        hasArtPortalCategoryFilter:  !_isUndefined(filters.hasArtPortalCategoryFilter) ? filters.hasArtPortalCategoryFilter : state.activeFilters.hasArtPortalCategoryFilter,
        filterHeaders: {
          ...state.activeFilters.filterHeaders,
          ...filters.filterHeaders
        }
      };
      return {
        ...state,
        activeFilters,
        filteredStockArts: (activeFilters.index === 0 && !bypassIndexValidation) ? [] : state.filteredStockArts,
        loading: (activeFilters.index === 0 && !bypassIndexValidation),
        loadingResults: showInfiniteLoader
      };
    });
  }

  removeActiveFilterHeader(key: string, value: ID) {
    this.update((state) => ({
      ...state,
      activeFilters: {
        ...state.activeFilters,
        filterHeaders: {
          ...state.activeFilters.filterHeaders,
          [key]: GlobalHelpers.arrayRemove(state.activeFilters.filterHeaders[key], value)
        },
        index: 0
      },
      loading: true,
      filteredStockArts: []
    }));
  }

  resetActiveFilters(type: StockArtType = StockArtType.DesignIdea) {
    const req: StockArtRequest = <StockArtRequest>{
      activeFilters: {
        index: 0
      },
      filterHeaders: {
        stockArtType: type
      }
    };
    this.update(setProps({
      activeFilters: Object.assign({}, initialStockArtListActiveFiltersState, req),
      loading: true,
      filteredStockArts: []
    }));
  }

  resetCategorySelection() {
    this.update((state) => ({
      ...state,
      activeFilters: {
        ...state.activeFilters,
        index: 0
      },
      selectedCategoryIds: [],
      selectedSubCategoryIds: [],
      loading: true,
      filteredStockArts: []
    }));
  }

  setStockArts(stockArts: StockArt[]) {
    this.update(setProps({
      filteredStockArts: stockArts,
      loading: false,
      loadingResults: false
    }));
  }

  appendStockArts(stockArts: StockArt[]) {
    this.update((state) => ({
      ...state,
      filteredStockArts: _uniqBy([...state.filteredStockArts, ...stockArts], 'stockArtId'),
      loadingResults: false
    }));
  }

  selectCategories(catIds: ID[]) {
    this.update((state) => ({
      ...state,
      selectedCategoryIds: GlobalHelpers.arrayAdd<ID>(state.selectedCategoryIds, catIds),
      loading: true,
      filteredStockArts: [],
      activeFilters: {
        ...state.activeFilters,
        index: 0
      }
    }));
  }

  deselectCategories(catIds: ID[]) {
    this.update((state) => ({
      ...state,
      selectedCategoryIds: GlobalHelpers.arrayRemove((state.selectedCategoryIds || []), catIds),
      loading: true,
      filteredStockArts: [],
      activeFilters: {
        ...state.activeFilters,
        index: 0
      }
    }));
  }

  selectSubCategories(catIds: ID[]) {
    this.update((state) => ({
      ...state,
      selectedSubCategoryIds: GlobalHelpers.arrayAdd<ID>(
        state.selectedSubCategoryIds,
        _difference(catIds, state?.selectedSubCategoryIds)
      ),
      loading: true,
      filteredStockArts: [],
      activeFilters: {
        ...state.activeFilters,
        index: 0
      }
    }));
  }

  deselectSubCategories(catIds: ID[]) {
    this.update((state) => ({
      ...state,
      selectedSubCategoryIds: GlobalHelpers.arrayRemove((state.selectedSubCategoryIds || []), catIds),
      loading: true,
      filteredStockArts: [],
      activeFilters: {
        ...state.activeFilters,
        index: 0
      }
    }));
  }

  setPagination(pagination: Pagination) {
    this.update(setProps({
      filteredStockArtsPagination: pagination
    }));
  }

  searchStockArt(searchString: string) {
    this.update((state) => ({
      ...state,
      globalSearch: searchString,
      loading: true,
      filteredStockArts: [],
      activeFilters: {
        ...state.activeFilters,
        index: 0
      }
    }));
  }

  setFilterOptions(type: StockArtType, pkg: StockArtHeaderPackage) {
    const sortedPackage: StockArtHeaderPackage = {
      ...pkg,
      uploadType: pkg.uploadType.sort(),
      collection: pkg.collection.sort(),
      styles: pkg.styles.sort(),
      colorCount: pkg.colorCount.sort((a, b) => a - b),
      colorMode: pkg.colorMode.sort()
    };
    if (type === StockArtType.DesignIdea) {
      this.update(setProps({ filterOptionsForDesignIdeas: sortedPackage }));
    } else if (type === StockArtType.ClipArt) {
      this.update(setProps({ filterOptionsForClipArt: sortedPackage }));
    } else if (type === StockArtType.Font) {
      this.update(setProps({ filterOptionsForFonts: sortedPackage }));
    }
  }
}

