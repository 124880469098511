import { Injectable } from '@angular/core';
import { Store, createState } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { Tag } from '@graphics-flow/types';

const { state, config } = createState(
  withEntities<Tag, 'tagId'>({ idKey: 'tagId', initialValue: [] })
);

@Injectable({
  providedIn: 'root'
})
export class TagStore extends Store {
  constructor() {
    super({ name: 'Tag', state, config })
  }
}
