import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { DesignRequest, DesignRequestQueryParam, DesignRequestStatus, ID } from '@graphics-flow/types';
import { Observable } from 'rxjs';
import { DesignRequestListState, DesignRequestListStore } from './design-request-list.state';

@Injectable({ providedIn: 'root' })
export class DesignRequestListQuery {
  activeFilters$: Observable<DesignRequestQueryParam> = this.selectStateProps<DesignRequestQueryParam>((store) => store.activeFilters);
  filteredDesignRequests$: Observable<DesignRequest[]> = this.selectStateProps<DesignRequest[]>((store) => {
    if (store.activeFilters?.searchText?.length) {
      return store.filteredDesignRequests;
    } else {
      return store.filteredDesignRequests.filter((dr: DesignRequest) => dr.status === store.activeFilters.status);
    }
  });
  activeFilterStatus$: Observable<DesignRequestStatus> = this.selectStateProps<DesignRequestStatus>((store) => store.activeFilters.status);
  activeFilterAssignee$: Observable<ID> = this.selectStateProps<ID>((store) => store.activeFilters.assigneeUserId);
  activeDesignRequest$: Observable<DesignRequest> = this.selectStateProps<DesignRequest>((store) => store.activeDesignRequest);
  loading$: Observable<boolean> = this.selectStateProps<boolean>((store) => store.loading);
  inProgress$: Observable<boolean> = this.selectStateProps<boolean>((store) => store.inProgress);
  resultCount$: Observable<number> = this.selectStateProps<number>((store) => store.filteredDesignRequestPagination?.totalResults);
  hideFilters$: Observable<boolean> = this.selectStateProps<boolean>((store) => !store.activeFilters?.searchText?.length);
  activeFilterAssigneeStatus$: Observable<ID> = this.selectStateProps<ID>((store) => store.activeFilters.assigneeStatus);
  assignedUsers$: Observable<DesignRequest[]> = this.selectStateProps<DesignRequest[]>((store) => store.assignedUsers);
  downloadInProgress$: Observable<boolean> = this.selectStateProps<boolean>((store) => store?.downloadInProgress);

  constructor(protected store: DesignRequestListStore) {}

  getTotalResults(): number {
    return this.store.getValue().filteredDesignRequestPagination?.totalResults;
  }

  selectStateProps<T>(predicate): Observable<T> {
    return this.store.pipe(select(predicate));
  }

  getValue(): DesignRequestListState {
    return this.store.getValue();
  }
}
