import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Translations } from '@graphics-flow/shared/assets';
import { StockArtListService } from '@graphics-flow/util';

@Component({
  selector: 'gf-category-dialog',
  templateUrl: './category-filter-dialog.component.html',
  styleUrls: ['./category-filter-dialog.component.scss']
})
export class CategoryFilterDialogComponent {
  canShowClipArtMobileViewFilter = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {canShowClipArtMobileViewFilter: boolean},
    public matDialogRef: MatDialogRef<CategoryFilterDialogComponent>,
    public translations: Translations,
    public stockArtListService: StockArtListService,
  ) {
    this.canShowClipArtMobileViewFilter = data.canShowClipArtMobileViewFilter;
  }

  applyFilters(): void {
    this.matDialogRef.close(true);
  }

}
