import { Pipe, PipeTransform } from '@angular/core';
import { ImagePreviewType, Organization } from '@graphics-flow/types';
import { ImagePreviewPipe } from './image-preview.pipe';

@Pipe({
  name: 'organizationImage'
})
export class OrganizationImageUrlPipe implements PipeTransform {
  constructor(private readonly previewUrlPipe: ImagePreviewPipe) {
  }

  transform(organization: Organization, imagePreviewType: ImagePreviewType) {
    if (!organization) return;

    return this.previewUrlPipe.transform(organization.organizationId, organization.imageAssetGuid.toString(), imagePreviewType, false, false, 'png');
  }

}
