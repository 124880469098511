import { Injectable } from '@angular/core';
import { getAllEntities, getEntity, selectAllEntitiesApply, selectEntity, selectMany } from '@ngneat/elf-entities';
import { Observable } from 'rxjs';

import { ID, StockArt, StockArtRequest, StockArtType } from '@graphics-flow/types';
import { StockArtStore } from './stock-art.store';

@Injectable({ providedIn: 'root' })
export class StockArtQuery {
  designIdeas$: Observable<StockArt[]> = this.selectAllStockArtEntitiesApply({
    filterEntity: (stock: StockArt) => {
      return stock.stockArtType === StockArtType.DesignIdea;
    }
  });
  clipArt$: Observable<StockArt[]> = this.selectAllStockArtEntitiesApply({
    filterEntity: (stock: StockArt) => {
      return stock.stockArtType === StockArtType.ClipArt;
    }
  });

  constructor(protected store: StockArtStore) {
  }

  selectStockArtEntity(id: ID): Observable<StockArt> {
    return this.store.pipe(selectEntity(id));
  }

  selectAllStockArtEntitiesApply(predicate): Observable<StockArt[]> {
    return this.store.pipe(selectAllEntitiesApply(predicate));
  }

  selectManyStockArt(value: ID[]): Observable<StockArt[]> {
    return this.store.pipe(selectMany(value));
  }

  getStockArtEntity(id: ID): StockArt {
    return this.store.query(getEntity(id));
  }

  getAllStockArtEntities(): StockArt[] {
    return this.store.query(getAllEntities());
  }

  filterStockArt(filters: StockArtRequest) {
    return this.selectAllStockArtEntitiesApply({
      filterEntity: (stock: StockArt) => {
        return stock.stockArtType === filters.filterHeaders.stockArtType;
      }
    });
  }
}
