import { Injectable } from '@angular/core';
import { getAllEntitiesApply, selectAllEntities } from '@ngneat/elf-entities';
import { Observable, map } from 'rxjs';
import { ID, Tag } from '@graphics-flow/types';
import { TagStore } from './tag.store';

@Injectable({
  providedIn: 'root'
})
export class TagQuery {
  tags$: Observable<Tag[]> = this.tagStore.pipe(selectAllEntities());

  constructor(readonly tagStore: TagStore){}

  getTagByCanonicalName$(tagName: string): Observable<Tag> {
    return this.tags$.pipe(
      map((tags: Tag[]) => {
        return tags.find((tag: Tag) => tag.canonicalName === tagName);
      })
    );
  }

  getSelectedFilterTags(tagIds: ID[]): Tag[] {
    return this.tagStore.query(
      getAllEntitiesApply({
        filterEntity: (tag) => tagIds?.includes(tag.tagId)
      })
    );
  }
}
