import { Injectable } from '@angular/core';
import { Environment, EnvironmentType } from '@graphics-flow/types';

const local: Environment = {
  production: false,
  apiEndpoint: '/api',
  blobStorageUri: 'https://graphicsflowqastorage.blob.core.windows.net',
  domain: 'http://localhost:4200'
};

const dev: Environment = {
  production: true,
  apiEndpoint: 'https://graphicsflowdevapi.azurewebsites.net',
  blobStorageUri: 'https://graphicsflowqastorage.blob.core.windows.net',
  domain: 'https://graphicsflowdevangular.azurewebsites.net'
};

const qa: Environment = {
  production: true,
  apiEndpoint: 'https://graphicsflowqaapi.azurewebsites.net',
  blobStorageUri: 'https://graphicsflowqastorage.blob.core.windows.net',
  domain: 'https://qa.graphicsflow.com'
};

const staging: Environment = {
  production: true,
  apiEndpoint: 'https://graphicsflowprodapi-staging.azurewebsites.net',
  blobStorageUri: 'https://graphicsflowprodstorage.blob.core.windows.net',
  domain: 'https://staging.graphicsflow.com'
};

const prod: Environment = {
  production: true,
  apiEndpoint: 'https://graphicsflowprodapi.azurewebsites.net',
  blobStorageUri: 'https://graphicsflowprodstorage.blob.core.windows.net',
  domain: 'https://app.graphicsflow.com'
};

export const environmentConstants: { [key in EnvironmentType]: Environment } = {
  local,
  qa,
  prod,
  staging,
  dev,
};

@Injectable({ providedIn: 'root' })
export class EnvService {
  private _value: Environment;
  private _env: EnvironmentType;

  get envType(): EnvironmentType {
    return this._env;
  }

  get apiEndpoint(): string {
    return this._value.apiEndpoint;
  }

  get blobStorageUri(): string {
    return this._value.blobStorageUri;
  }

  get domainUri(): string {
    return this._value.domain;
  }

  init(env?: EnvironmentType): void {

    // For embed we are manully setting env
    if (env) {
      this._env = env;
      this._value = environmentConstants[this._env];
      return;
    }

    const hostname = window && window.location && window.location.hostname;

    if (/^qa.graphicsflow.com/.test(hostname)) {
      this._env = EnvironmentType.QA;
    } else if (/^graphicsflowdevangular.azurewebsites.net/.test(hostname)) {
      this._env = EnvironmentType.Dev;
    } else if (/^staging.graphicsflow.com/.test(hostname)) {
      this._env = EnvironmentType.Staging;
    } else if (/^app.graphicsflow.com/.test(hostname)) {
      this._env = EnvironmentType.Prod;
    } else {
      this._env = EnvironmentType.Local;
    }

    if (this._env) {
      this._value = environmentConstants[this._env];
    }
  }
}
