import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { UntilDestroy } from '@ngneat/until-destroy';
import { orderBy as _orderBy } from 'lodash-es';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { Translations } from '@graphics-flow/shared/assets';
import { Art, ArtType, Folder, ID,
  MyArtActiveQueryParams, Order, Pagination,
  Tag, ViewingResult } from '@graphics-flow/types';
import { MAX_SCROLL_LIMIT } from '../../constants/infinity-scroller.constants';
import { ArtHelper } from '../../helpers/art.helper';
import { FolderHelper } from '../../helpers/folder.helpers';
import { GlobalHelpers } from '../../helpers/global.helpers';
import { ArtQuery } from './../art/art.query';
import { ArtService } from './../art/art.service';
import { FolderQuery } from './../folder/folder.query';
import { MyArtSearchState, MyArtSearchStore } from './my-art-search.store';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class MyArtSearchQuery {
  searchString$: Observable<string> = this.store.pipe(select((store) => store.activeQueryParams.globalSearchText));
  filterSearch$: Observable<string> = this.store.pipe(select((store) => store.activeQueryParams.filterSearchText));
  filterTags$: Observable<Tag[]> = this.store.pipe(select((store) => store.activeQueryParams.filterTags));
  activeQueryParams$: Observable<MyArtActiveQueryParams> = this.store.pipe(select((store) => store.activeQueryParams));
  showOverAllLoader$: Observable<boolean> = this.store.pipe(select((store) => store.showOverAllLoader));
  showInfiniteScrollLoader$: Observable<boolean> = this.store.pipe(select((store) => store.showInfiniteScrollLoader));
  searchFocus$: Observable<boolean> = this.store.pipe(select((store) => store.isSearchInFocus));
  pagination$: Observable<Pagination> = this.store.pipe(select((store) => store.myArtPagination));
  artIds$: Observable<ID[]> = this.store.pipe(select((store) => store.artIds));
  folderIds$: Observable<ID[]> = this.store.pipe(select((store) => store.folderIds));
  activeChildrenCount$: Observable<number> = this.store.pipe(select((store) => store.activeArtsCount + store.activeFoldersCount));
  filterArtType$: Observable<ArtType> = this.store.pipe(select((store) => <ArtType>store.activeQueryParams.artType));
  isDeletedFiles$: Observable<boolean> = this.store.pipe(select((store) => store.activeQueryParams.trashed));

  chipCount$: Observable<number> = combineLatest([this.filterTags$, this.filterArtType$]).pipe(
    map(([tag, artType]: [Tag[], ArtType]) => {
      if (artType) {
        return tag.length + 1;
      }
      return tag.length;
    })
  );

  currentArtTypeFilterString$: Observable<string> = this.filterArtType$.pipe(
    map((stockArtType: ArtType) => this.getAllArtString(stockArtType))
  );

  arts$: Observable<Art[]> = this.artIds$.pipe(
    distinctUntilChanged(),
    switchMap((artIds: ID[]) => {
      if (!artIds.length) {
        this.store.update((state) => ({ ...state, activeArtsCount: 0 }));
        return of([]);
      }
      return this.loadArts(artIds, this.store.getValue().activeQueryParams);
    })
  );

  folders$: Observable<Folder[]> = this.folderIds$.pipe(
    distinctUntilChanged(),
    switchMap((folderIds: ID[]) => {
      if (!folderIds.length) {
        this.store.update((state) => ({ ...state, activeFoldersCount: 0 }));
        return of([]);
      }
      return this.loadFolders(folderIds, this.store.getValue().activeQueryParams);
    })
  );

  activeArtsAndFolders$: Observable<[Folder[], Art[]]> = combineLatest([this.folders$, this.arts$]);

  viewingResult$: Observable<ViewingResult> = combineLatest([this.folders$, this.pagination$, this.arts$]).pipe(
    map(([folders, pagination, arts]: [Folder[], Pagination, Art[]]) => {
      return { currentLoadedResult: folders.length + arts.length, totalResults: ArtHelper.getSearchResultCount(folders, pagination), currentArtLoaded: arts.length };
    })
  );
  showViewAllBtn$: Observable<boolean> = this.viewingResult$.pipe(
    map((viewingResult: ViewingResult) => (viewingResult.currentLoadedResult !== viewingResult.totalResults && viewingResult.currentArtLoaded !== MAX_SCROLL_LIMIT))
  );

  showClearSearch$: Observable<boolean> = this.store.pipe(select((val: MyArtSearchState) => val.isSearchInFocus || !!val?.activeQueryParams?.globalSearchText));

  searchFolders(): Observable<Folder[]> {
    const folderIds: ID[] = this.store.getValue().folderIds;
    // we don't need to show the folders, While these filters were applied!.
    if ((this.getActiveFilterTags().length || !!this.getFilterArtType())) {
      return of([]);
    }
    return this.folderQuery.selectAllFolderEntitiesByApply({
      filterBy: entity => folderIds.includes(entity.folderId)
    }).pipe(
      map((folders: Folder[]) => {
        return folders;
      })
    );
  }

  constructor(
    protected store: MyArtSearchStore,
    protected artService: ArtService,
    private folderQuery: FolderQuery,
    private artQuery: ArtQuery,
    private translations: Translations
  ) {
  }

  getFilterSearchText(): string {
    return this.store.getValue().activeQueryParams?.filterSearchText;
  }

  getFilterTagCanonicalNames(): string[] {
    return this.store.getValue().activeQueryParams?.filterTags.map((tag) => tag?.canonicalName);
  }

  getSearchValue(): string {
    return this.store.getValue().activeQueryParams?.globalSearchText;
  }

  getActiveFilterTags(): Tag[] {
    return this.store.getValue().activeQueryParams?.filterTags;
  }

  isFilterApplied(): boolean {
    return !(!this.getSearchValue() && !this.getFilterSearchText() && !this.getFilterTagCanonicalNames().length);
  }

  getPagination(): Pagination {
    return this.store.getValue().myArtPagination;
  }

  loadArts(artIds: ID[], activeQueryParam: MyArtActiveQueryParams) {
    return this.artQuery.selectAllArtEntitiesByApply({
      filterEntity: (art: Art) => {
        // We using Art Store to get the art info! so to get rid of any confusion, below conditions are mandate.
        // 1. !!this.getSearchValue() - Used to return all the available artIds, when user try to use global search.
        // 2. (art?.folderId ?? null) === this.folderQuery.getActiveId() - Used to return artIds based on active folderId.
        // 3. artIds.includes(art.artId) - Will return only when the artId is available in my art search store.
        return (!!this.getSearchValue() || FolderHelper.getArtFromParent(art, activeQueryParam.folderId))
          && artIds.includes(art.artId);
      }
    }).pipe(
      map((activeArts: Art[]) => {
        setTimeout(() => {
          this.store.update((state) => ({ ...state, activeArtsCount: activeArts.length }));
        }, 0);
        return _orderBy(activeArts, GlobalHelpers.getProperSortByKey<Art>(activeQueryParam?.sortBy), activeQueryParam.orderDesc ? Order.DESC : Order.ASC);
      })
    )
  }

  loadFolders(folderIds: ID[], activeQueryParam: MyArtActiveQueryParams) {
    return this.folderQuery.selectAllFolderEntitiesByApply({
      filterEntity: (folder: Folder) => {
        // The same applies here! as above mentioned for arts$.
        return (!!this.getSearchValue() || FolderHelper.getFolderFromParent(folder, activeQueryParam.folderId))
          && folderIds.includes(folder.folderId);
      }
    }).pipe(
      map((activeFolders) => {
        setTimeout(() => {
          this.store.update((state) => ({ ...state, activeFoldersCount: activeFolders.length }));
        }, 0);
        return _orderBy(activeFolders, GlobalHelpers.getProperSortByKey<Folder>(activeQueryParam?.sortBy), activeQueryParam.orderDesc ? Order.DESC : Order.ASC);
      }
      )
    )
  }

  getActiveFolderId(): ID {
    return this.store.getValue().activeQueryParams.folderId;
  }

  getFilterArtType(): ArtType {
    return <ArtType>this.store.getValue().activeQueryParams.artType;
  }

  getAllArtString(artType: ArtType): string {
    switch (artType) {
      case ArtType.UPLOADED_ART:
        return this.translations.common.uploaded_files;
      case ArtType.CUSTOMIZED_STOCK_ART:
        return this.translations.common.customized_stock_art;
      case ArtType.ADDED_TO_ART_PORTAL:
        return this.translations.art.added_to_art_portal;
      case ArtType.NOT_IN_ART_PORTAL:
        return this.translations.art.not_in_art_portal;
      case ArtType.FAVORITES:
        return this.translations.common.favorites;
      default:
        return this.translations.art.all_art_types;
    }
  }

  defaultCompare(previousValue: any, currentValue: any) {
    return JSON.stringify(previousValue) === JSON.stringify(currentValue);
  }

  getLoadedFolderIds(): ID[] {
    return this.store.getValue().folderIds;
  }

  getActiveQueryParams() {
    return this.store.getValue().activeQueryParams;
  }
}
