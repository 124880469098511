import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse, BulkActionPostData, ID, Tag } from '@graphics-flow/types';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class TagHttpService {
  constructor(private httpService: HttpService) { }

  createTag(tagName: string): Observable<Tag> {
    if (tagName) {
      const formData = new FormData();
      formData.append('newTagName', tagName);
      return this.httpService.post(this.getUrl('createTag'), formData).pipe(
        map((response: ApiResponse<Tag>) => response.data)
      );
    }
  }

  deleteTag(tagName: string): Observable<Tag> {
    if (tagName) {
      const formData = new FormData();
      formData.append('tagToDelete', tagName);
      return this.httpService.post(this.getUrl('deleteTag'), formData).pipe(
        map((response: ApiResponse<Tag>) => response.data)
      );
    }
  }

  editTag(oldTag: string, newTag: string): Observable<Tag> {
    if (!oldTag || !newTag) {
      return;
    }

    const formData = new FormData();
    formData.append('oldTag', oldTag);
    formData.append('newTag', newTag);

    return this.httpService.post(this.getUrl('renameTag'), formData).pipe(
      map((response: ApiResponse<Tag>) => response.data)
    );
  }

  mergeTags(oldTag: string, newTag: string): Observable<Tag> {
    if (!oldTag || !newTag) {
      return;
    }

    const formData = new FormData();
    formData.append('oldTag', oldTag);
    formData.append('newTag', newTag);

    return this.httpService.post(this.getUrl('mergeTags'), formData).pipe(
      map((response: ApiResponse<Tag>) => response.data)
    );
  }

  getOrganizationTags(includeArtCount: boolean): Observable<Tag[]> {
    const params = `includeArtCount=${includeArtCount}`;
    return this.httpService.get(this.getUrl(`GetOrganizationTags?${params}`)).pipe(
      map((response: ApiResponse<Tag[]>) => response.data)
    );
  }

  assignTagToSelectedArts(tagName: string, artIds: ID[]): Observable<boolean> {
    const postData: BulkActionPostData = {
      tagName,
      artIds
    };
    return this.httpService.post(this.getUrl('BulkAssignTagToArts'), postData).pipe(
      map((response: ApiResponse<boolean>) => response.data)
    );
  }

  removeTagFromSelectedArts(tagName: string, artIds: ID[]): Observable<boolean> {
    const postData: BulkActionPostData = {
      tagName,
      artIds
    };
    return this.httpService.post(this.getUrl('RemoveTagFromArts'), postData).pipe(
      map((response: ApiResponse<boolean>) => response.data)
    );
  }

  private getUrl(api: string): string {
    return this.httpService.getUrl(`tag/${api}`);
  }
}
