import { Injectable } from '@angular/core';
import { createState, setProp, Store, withProps } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { ArtApproval, ArtApprovalUI } from '@graphics-flow/types';

export interface ApprovalState {
  ui: ArtApprovalUI;
}

const initialState = {
  ui: {
    approvalDetailItem: {
      isDrawerOpen: false
    }
  }
};

const { state, config } = createState(
  withEntities<ArtApproval, 'artApprovalId'>({ idKey: 'artApprovalId', initialValue: [] }),
  withProps<ApprovalState>(initialState)
);

@Injectable({
  providedIn: 'root'
})
export class ApprovalStore extends Store {
  constructor() {
    super({ name: 'artApprovals', state, config });
  }

  updateUI(ui: ArtApprovalUI) {
    this.update(setProp('ui', (state: ArtApprovalUI) => ({ ...state, ui })));
  }

  resetUI() {
    this.updateUI(initialState.ui);
  }
}
