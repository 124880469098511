import { Injectable } from '@angular/core';
import { isEqual as _isEqual } from 'lodash-es';
import { Subscription, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { ArtHttpService } from '@graphics-flow/api';
import { ApiResponse, Art, MyArtActiveQueryParams } from '@graphics-flow/types';
import { ArtService } from '../art/art.service';
import { FeaturedDesignQuery } from './featured-design.query';
import { FeaturedDesignStore } from './featured-design.state';

@Injectable({
  providedIn: 'root'
})
export class FeaturedDesignService {
  featuredDesignSubscription$: Subscription;

  constructor(
    public featuredDesignQuery: FeaturedDesignQuery,
    public featuredDesignStore: FeaturedDesignStore,
    public artService: ArtService,
    public artHttpService: ArtHttpService) {
  }

  initialize(): void {
    this.featuredDesignSubscription$ = this.featuredDesignQuery.activeFilters$.pipe(
      debounceTime(200),
      distinctUntilChanged((oldFilter, newFilter) => {
        oldFilter = JSON.parse(JSON.stringify(oldFilter));
        newFilter = JSON.parse(JSON.stringify(newFilter));
        return _isEqual(oldFilter, newFilter);
      }),
      switchMap((activeFilters: MyArtActiveQueryParams) =>
        this.artHttpService.getArts(activeFilters, true)
      )
    ).subscribe((response: ApiResponse<Art[]>) => {
      this.featuredDesignStore.addAppendState(response);
      this.artService.addArtToStore(response.data);
    })
  }

  getBatch(offset: number): void {
    const index: number = this.featuredDesignStore.getValue()?.activeQueryParams?.index;
    this.featuredDesignStore.setActiveFilter(<MyArtActiveQueryParams>{
      index: (offset > 0) ? offset : (index ?? offset)
    });
  }

  scrolledToBottom(offset: number): void {
    const pagination = this.featuredDesignQuery.getMyArtPagination();
    this.featuredDesignQuery._showMyArtFooterMesg$.next(pagination.totalResults === offset);
    if (pagination.totalResults !== offset) {
      this.getBatch(offset);
    }
  }

  resetFeaturedDesign(): void {
    this.featuredDesignSubscription$?.unsubscribe();
    this.featuredDesignStore.reset();
  }

  setActiveFilter(activeFilters: MyArtActiveQueryParams): void {
    this.featuredDesignStore.setActiveFilter(activeFilters);
  }
}
