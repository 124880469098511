import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericRemovalDialogData } from '@graphics-flow/types';

@Component({
  templateUrl: './generic-remove-dialog.component.html',
  styleUrls: ['./generic-remove-dialog.component.scss']
})
export class GenericDataRemovalDailogComponent {

  constructor(
    public dialogRef: MatDialogRef<GenericDataRemovalDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericRemovalDialogData
  ) {
  }
  
}
