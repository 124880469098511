import { Pipe, PipeTransform } from '@angular/core';
import { ArtApproval } from '@graphics-flow/types';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@graphics-flow/shared/assets';

@Pipe({
  name: 'getApprovalDisplayName'
})
export class ApprovalGetDisplayNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService,
              private translations: Translations) {
  }
  transform(value: ArtApproval): string {
    return value?.name?.trim() || this.translateService.instant(this.translations.approval.untitled_art_approval);
  }
}
