<div id="addCollaboratorItemWrap" class="flex-container width-100">

  <div class="flex-container flex-child-auto" [class.align-center-middle]="(windowService.smallDown$ | async) || !collaborator.name">
    <gf-collaborator-avatar [collaborator]="collaborator" [size]="'large'"
                            class="flex-child-shrink avatar rounded cursor-pointer">
    </gf-collaborator-avatar>
    <div class="flex-child-auto m-x-1 trim-text" [title]="collaborator.email">
      <ng-container *ngIf="collaborator.name else isPending">
        <span class="t-14-600-p">{{collaborator.name}}</span>
        <span>{{collaborator.email}}</span>
      </ng-container>
      <ng-template #isPending>
        <div class="t-14-600-p">
          <p class="m-b-0">{{collaborator.email}}</p>
        </div>
      </ng-template>
    </div>
    <button *ngIf="allowChange" mat-mini-fab color="secondary" class="m-r-1 link-btn" (click)="copyCollaboratorLink()">
      <div #tooltip="matTooltip"
        (mouseenter)="$event.stopImmediatePropagation()"
        (mouseleave)="$event.stopImmediatePropagation()"
        matTooltipPosition="above"
        matTooltip="{{translations.approval.link_copied | translate}}">
        <mat-icon class="link-icon" svgIcon="link-varient"></mat-icon>
      </div>
    </button>
  </div>

  <div class="flex-child-shrink flex-container" [class.align-center-middle]="!(windowService.smallDown$ | async)">
    <ng-container>
      <button id="collaboratorRoleMenuTrigger"
        mat-raised-button
        color="secondary"
        [mat-menu-trigger-for]="collaboratorRoleMenu"
        class="medium action-btn"
        [disabled]="activeCollaboratorId === collaborator.collaboratorId">
        <ng-container>
          <span class="mr-4">{{(collaborator.role | getCollaboratorPermissionOrDescription) | translate}}</span>
          <mat-icon [class.secondary]="activeCollaboratorId !== collaborator.collaboratorId" fontIcon="keyboard_arrow_down"></mat-icon>
        </ng-container>
      </button>
      <mat-menu #collaboratorRoleMenu>
        <ng-container *ngIf="allowChange">
          <button mat-menu-item (click)="changeRole(CollaboratorRole.EDIT)">{{translations.approval.can_comment_and_approve | translate}}</button>
          <button mat-menu-item (click)="changeRole(CollaboratorRole.VIEW)">{{translations.approval.can_comment | translate}}</button>
          <button mat-menu-item (click)="changeRole(CollaboratorRole.READ_ONLY)">{{translations.approval.can_view | translate}}</button>
        </ng-container>
        <button mat-menu-item (click)="remove()" class="danger-color">{{translations.approval.remove_collaborator | translate}}</button>
      </mat-menu>
    </ng-container>
  </div>
</div>
