import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  AdditionalPrice,
  BasePrice,
  BillingPlansState,
  ID,
  OrganizationPaymentOption,
  OrganizationResourceUsage,
  Plan,
  PlanSubscription,
  ScheduledSubscriptionChange,
  SubscriptionItem,
  UsageLevel
} from '@graphics-flow/types';

import { BundlePlans } from '../../constants/Plans.constants';
import { AccountSubscriptionHelper } from '../../helpers/account-subscription.helper';
import { GlobalHelpers } from '../../helpers/global.helpers';
import { OrganizationQuery } from '../organization/organization.query';
import { BillingPlansStore } from './billing-plans.store';

@Injectable({ providedIn: 'root' })
export class BillingPlansQuery {
  organizationPaymentOptions$: Observable<OrganizationPaymentOption> = this.selectStateProps<OrganizationPaymentOption>(({ paymentOptions }: BillingPlansState) => paymentOptions);
  organizationResourceUsage$: Observable<OrganizationResourceUsage> = this.selectStateProps<OrganizationResourceUsage>(({ resourceUsage }: BillingPlansState) => resourceUsage);
  scheduledSubscription$: Observable<ScheduledSubscriptionChange> = this.selectStateProps<ScheduledSubscriptionChange>(({ resourceUsage }: BillingPlansState) => resourceUsage.organizationBilling.scheduledSubscriptionChange);
  userSubscription$: Observable<PlanSubscription> = this.selectStateProps<PlanSubscription>(({ resourceUsage }: BillingPlansState) => resourceUsage?.organizationBilling?.subscription);
  currentSubscriptionItems$: Observable<SubscriptionItem[]> = this.userSubscription$.pipe(
    filter((subscription: PlanSubscription) => !!subscription),
    map((subscription: PlanSubscription) => subscription?.items)
  );

  // Base Plan & Add On Plan Prices.
  additionalPrices$: Observable<BasePrice[]> = this.organizationPaymentOptions$.pipe(map(organizationPaymentOption => organizationPaymentOption?.additional));
  storageBasePlans$: Observable<BasePrice[]> = this.organizationPaymentOptions$.pipe(map(organizationPaymentOption => organizationPaymentOption?.basePrices));
  additionalStockArtBasePrices$: Observable<BasePrice> = this.additionalPrices$.pipe(map(paymentOptions => paymentOptions?.find((price) => price.productName === AdditionalPrice.STOCK_ART)));
  additionalStorageBasePrice$: Observable<BasePrice> = this.additionalPrices$.pipe(map(paymentOptions => paymentOptions?.find((price) => price.productName === AdditionalPrice.STORAGE)));
  additionalUserBasePrices$: Observable<BasePrice> = this.additionalPrices$.pipe(map(paymentOptions => paymentOptions?.find((price) => price.productName === AdditionalPrice.USER)));
  additionalSmartDesignerAddOnBasePrice$: Observable<BasePrice> = this.additionalPrices$.pipe(map(paymentOptions => paymentOptions?.find((price) => price.productName === AdditionalPrice.SMART_DESIGNER_ADD_ON)));
  additionalSmartDesignerAdditionalDevicesAddOnBasePrice$: Observable<BasePrice> = this.additionalPrices$.pipe(map(paymentOptions => paymentOptions?.find((price) => price.productName === AdditionalPrice.SMART_DESIGNER_ADDITIONAL_DEVICES_ADD_ON)));

  // Subscription Item Information.
  currentPlan$: Observable<BasePrice> = this.currentSubscriptionItems$.pipe(map((items: SubscriptionItem[]) => items.find((item) => item?.isBasePlan)?.price));
  currentStockArtSubscription$: Observable<SubscriptionItem> = this.currentSubscriptionItems$.pipe(map((items: SubscriptionItem[]) => items.find((item) => item.price.productName === AdditionalPrice.STOCK_ART)));
  currentStorageSubscription$: Observable<SubscriptionItem> = this.currentSubscriptionItems$.pipe(map((items: SubscriptionItem[]) => items.find((item) => item.price.productName === AdditionalPrice.STORAGE)));
  currentGraphicsBundleSubscription$: Observable<SubscriptionItem> = this.currentSubscriptionItems$.pipe(map((items: SubscriptionItem[]) => items.find((item) => item.price.productName === AdditionalPrice.GRAPHICS_BUILDER_BUNDLE)));
  currentUserSubscription$: Observable<SubscriptionItem> = this.currentSubscriptionItems$.pipe(map((items: SubscriptionItem[]) => items.find((item) => item.price.productName === AdditionalPrice.USER)));
  currentSmartDesignerAddOnSubscription$: Observable<SubscriptionItem> = this.currentSubscriptionItems$.pipe(map((items: SubscriptionItem[]) => items.find((item) => item.price.productName === AdditionalPrice.SMART_DESIGNER_ADD_ON)));
  currentSmartDesignerAdditionalDevicesAddOnSubscription$: Observable<SubscriptionItem> = this.currentSubscriptionItems$.pipe(map((items: SubscriptionItem[]) => items.find((item) => item.price.productName === AdditionalPrice.SMART_DESIGNER_ADDITIONAL_DEVICES_ADD_ON)));
  subscriptionTotalUsers$: Observable<number> = combineLatest([this.currentPlan$, this.currentSubscriptionItems$]).pipe(
    map(([currentPlan, subscriptionItems]) => {
      const additionallyAddedUsers: BasePrice = subscriptionItems?.find(item => item.price.productName === AdditionalPrice.USER)?.price;
      return Number(currentPlan?.productMetadata.users) + (additionallyAddedUsers?.quantity || 0);
    })
  );

  // graphics builder
  isGraphicsBuilderPartOfAddOn$: Observable<boolean> = this.userSubscription$.pipe(
    map(subscriptionPlan => subscriptionPlan?.items.some((item: SubscriptionItem) => item.price.productMetadata?.graphics_builder === 'yes'))
  );

  // User Limit Related Information.
  userLimitReached$: Observable<boolean> = combineLatest([this.organizationResourceUsage$, this.currentPlan$]).pipe(
    map(([resourceUsage, currentPlan]: [OrganizationResourceUsage, BasePrice]) => resourceUsage.userCount >= currentPlan.users)
  );
  userLimitExceeded$: Observable<boolean> = combineLatest([this.organizationResourceUsage$, this.currentPlan$]).pipe(
    map(([resourceUsage, currentPlan]: [OrganizationResourceUsage, BasePrice]) => resourceUsage.userCount > currentPlan.users)
  );

  // Storage Usage Related Information.
  storageUseInPercentage$: Observable<number> = this.organizationResourceUsage$.pipe(
    map((resource: OrganizationResourceUsage) => GlobalHelpers.formatDecimals(((resource?.storageBytes / resource?.organizationBilling.subscription.totalStorageLimitBytes) * 100), 4))
  );
  storageLimit$: Observable<number> = this.userSubscription$.pipe(map(userSubscription => userSubscription?.totalStorageLimitBytes ?? 0));
  storageStatus$: Observable<UsageLevel> = this.getStatusBasedOnPercentage(this.storageUseInPercentage$);
  usedStorage$: Observable<number> = this.selectStateProps<number>(({ resourceUsage }: BillingPlansState) => resourceUsage?.storageBytes);
  usedStorageWithUnits$: Observable<string> = this.usedStorage$.pipe((map(storage => GlobalHelpers.fileSizeWithUnits(storage ?? 0))));
  showStorageWarning$: Observable<boolean> = this.storageUseInPercentage$.pipe(map((percentage: number) => percentage >= 90));

  // Stock Art Download Usage Related Information.
  stockArtDownloadUseInPercentage$: Observable<number> = this.organizationResourceUsage$.pipe(
    map((resource: OrganizationResourceUsage) => (resource?.stockArtDownloads / resource?.organizationBilling.subscription.totalStockArtDownloadLimit) * 100)
  );
  stockArtDownloadUsageFull$: Observable<boolean> = this.organizationResourceUsage$.pipe(
    map((resource: OrganizationResourceUsage) => (resource?.stockArtDownloads === resource?.organizationBilling.subscription.totalStockArtDownloadLimit))
  );
  stockArtDownloadCount$: Observable<number> = this.selectStateProps<number>(({ resourceUsage }: BillingPlansState) => resourceUsage?.stockArtDownloads)
  stockArtDownloadLimit$: Observable<number> = this.selectStateProps<number>(({ resourceUsage }: BillingPlansState) => resourceUsage?.organizationBilling.subscription.totalStockArtDownloadLimit);
  stockArtDownloadStatus$: Observable<UsageLevel> = this.getStatusBasedOnPercentage(this.stockArtDownloadUseInPercentage$);

  storageAddOn$: Observable<number> = this.currentSubscriptionItems$.pipe(
    map((items: SubscriptionItem[]) => {
      const subscriptionItem: SubscriptionItem = items.find((item: SubscriptionItem) => item.price.productName === AdditionalPrice.STORAGE);
      return subscriptionItem ? subscriptionItem.quantity : 0;
    })
  );

  stockArtDownloadAddOn$: Observable<number> = this.currentSubscriptionItems$.pipe(
    map((items: SubscriptionItem[]) => {
      const subscriptionItem: SubscriptionItem = items.find((item: SubscriptionItem) => item.price.productName === AdditionalPrice.STOCK_ART);
      return subscriptionItem ? subscriptionItem.quantity : 0
    })
  );

  // Add Ons Related Information
  hasAddOns$: Observable<boolean> = combineLatest([this.storageAddOn$, this.stockArtDownloadAddOn$, this.currentSmartDesignerAddOnSubscription$]).pipe(
    map(([storageAddOn, stockArtDownloadAddOn, smartDesignerAddOn]: [number, number, SubscriptionItem]) => !!storageAddOn || !!stockArtDownloadAddOn || !!smartDesignerAddOn)
  );

  // Smart Designer Related Information
  totalMachinesCount$: Observable<number> = combineLatest([this.currentSmartDesignerAddOnSubscription$, this.currentSmartDesignerAdditionalDevicesAddOnSubscription$]).pipe(
    map(([smartDesignerAddOn, smartDesignerAdditionalDevicesAddOn]: [SubscriptionItem, SubscriptionItem]) => {
      const smartDesignerAddOnMachinesCount: number = (smartDesignerAddOn?.price?.quantity * Number(smartDesignerAddOn?.price?.productMetadata?.machines))
      const smartDesignerAdditionalDevicesAddOnMachinesCount: number = (smartDesignerAdditionalDevicesAddOn?.price?.quantity * Number(smartDesignerAdditionalDevicesAddOn?.price?.productMetadata?.machines))
      return smartDesignerAddOnMachinesCount + (smartDesignerAdditionalDevicesAddOnMachinesCount || 0);
    })
  );
  availableMachinesCount$: Observable<number> = combineLatest([this.totalMachinesCount$, this.organizationResourceUsage$]).pipe(
    map(([totalMachinesCount, resourceUsage]) => {
      const usedInstallsCount: number = resourceUsage?.smartDesignerMachines?.length;
      return (totalMachinesCount - usedInstallsCount) || 0;
    })
  );
  isSmartDesignerScheduledChanged$: Observable<boolean> = this.scheduledSubscription$.pipe(
    map(scheduledChanges => scheduledChanges?.smartDesignerDowngrade)
  );

  isCustomizeStockArtEnabled$: Observable<boolean> = this.organizationResourceUsage$.pipe(
    map((resource) => resource?.organizationBilling.subscription.customizeStockArtEnabled)
  );

  selectedPlan$: Observable<Plan> = combineLatest([this.getBundlePlans(), this.currentPlan$]).pipe(
    map(([plans, currentPlan]: [Plan[], BasePrice]) => plans.find((plan: Plan) => plan.product.productMetadata?.plan_name === currentPlan.productMetadata?.plan_name))
  );

  constructor(protected store: BillingPlansStore,
    public readonly organizationQuery: OrganizationQuery
    ) {
  }

  selectStateProps<T>(predicate): Observable<T> {
    return this.store.pipe(select(predicate));
  }

  getValue() {
    return this.store.getValue();
  }

  getOrganizationId(): ID {
    return this.getValue().resourceUsage.organizationBilling.organizationId;
  }

  getOrganizationPaymentOptions(): OrganizationPaymentOption {
    return this.getValue().paymentOptions;
  }

  getStatusBasedOnPercentage(addOnsUsedPercentage: Observable<number>): Observable<UsageLevel> {
    return addOnsUsedPercentage.pipe(
      map((percentage: number) => {
        if (percentage < 90) {
          return UsageLevel.AVAILABLE;
        } else if (percentage >= 90 && percentage < 100) {
          return UsageLevel.ALMOST_USED;
        }
        return UsageLevel.USAGE_FULL;
      })
    );
  }

  getBundlePlans(): Observable<Plan[]> {
    return this.storageBasePlans$.pipe(
      filter((basePricePlans) => basePricePlans && (basePricePlans.length !== 0)),
      map((basePricePlans: BasePrice[]) => {
        const plans: Plan[] = [];
        BundlePlans.forEach((plan: Plan) => {
          const product: BasePrice = AccountSubscriptionHelper.buildBundlePlans(basePricePlans, plan.productName);
          const bundlePlan: Plan = {
            plan: plan.plan,
            name: plan.name,
            uriName: plan.uriName,
            users: product.users,
            storage: product.storageBytes,
            downloads: product.monthlyDownloads,
            graphicBuilder: product?.productMetadata?.graphics_builder === 'yes',
            privateBranding: product?.productMetadata?.private_branding === 'yes',
            publicCatalog: product?.productMetadata?.public_catalog === 'yes',
            customizeStockArt: product?.productMetadata?.customize_stockart === 'yes',
            price: product.amount,
            product: product,
            totalPrice: 0,
            savedPrice: 0
          };
          plans.push(bundlePlan);
        });
        return plans;
      })
    );
  }

  getOrganizationResourceUsageValue(): OrganizationResourceUsage {
    return this.getValue()?.resourceUsage;
  }

  getAdditionalUserInfoWithCount(selectedPlanUserCount: number): BasePrice {
    const organizationResourceUsage: OrganizationResourceUsage = this.getOrganizationResourceUsageValue();
    const organizationPaymentOption: OrganizationPaymentOption = this.getOrganizationPaymentOptions();
    const userPriceInfo: BasePrice = _cloneDeep(organizationPaymentOption?.additional?.find((price) => price.productName === AdditionalPrice.USER));
    if ((organizationResourceUsage?.userCount || 0) > selectedPlanUserCount) {
      const additionalUserCount = organizationResourceUsage.userCount - selectedPlanUserCount;
      userPriceInfo.quantity = additionalUserCount;
      return userPriceInfo;
    }
    return null;
  }
}
