import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import {
  ArtApproval,
  Collaborator,
  CollaboratorLink,
  CollaboratorRole,
  DialogData, ID, ImagePreviewType
} from '@graphics-flow/types';
import { Translations } from '@graphics-flow/shared/assets';
import {
  ApprovalDetailQuery,
  ApprovalQuery,
  ApprovalService,
  CollaboratorQuery,
  CollaboratorService,
  GlobalHelpers
} from '@graphics-flow/util';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { FormControl } from '@ngneat/reactive-forms';
import { Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiService, ArtApprovalHttpService } from '@graphics-flow/api';
import { cloneDeep as _cloneDeep, intersection as _intersection, toLower as _toLower, startsWith as _startsWith } from 'lodash-es';
import { WindowService } from 'shared/util';
import { MatTooltip } from '@angular/material/tooltip';

export interface AddCollaboratorToApprovalDialogData extends DialogData {
  approvalId: ID;
  collaboratorId: ID;
}

@UntilDestroy()
@Component({
  selector: 'gf-add-collaborator-to-approval-dialog',
  templateUrl: './add-collaborator-to-approval-dialog.component.html',
  styleUrls: ['./add-collaborator-to-approval-dialog.component.scss']
})

export class AddCollaboratorToApprovalDialogComponent {

  @Output() emailsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input() emails: string[] = [];
  @ViewChild('emailInput', { static: true }) emailInput: ElementRef<HTMLInputElement>;
  canApprove$: Observable<boolean> = this.approvalDetailQuery.canApprove$;
  collaboratorId: ID;
  collaboratorLinkId: ID;
  CollaboratorRole = CollaboratorRole;
  collaborators$: Observable<Collaborator[]>;
  copyToClipboardSubject: Subject<string> = new Subject<string>();
  emailAlreadyExist = false;
  emailRegEx: RegExp = GlobalHelpers.EMAIL_REGEXP;
  existingEmailIds: string[] = [];
  isCollaborator = false;
  message: string;
  newEmail = '';
  pendingState = false;
  role: CollaboratorRole;
  sharedLink$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  emailFormControl = new FormControl('', [
    Validators.pattern(this.emailRegEx),
    Validators.email
  ]);
  imagePreviewType: typeof ImagePreviewType = ImagePreviewType;
  filteredOptions: Observable<string[]>;
  collaboratorEmails: string[] = [];
  showError: boolean;
  shareableLinkPermission: CollaboratorRole;
  @ViewChild('tooltip') tooltip: MatTooltip;
  approval: ArtApproval;

  constructor(@Inject(MAT_DIALOG_DATA) public data: AddCollaboratorToApprovalDialogData,
    private readonly apiService: ApiService,
    private readonly approvalQuery: ApprovalQuery,
    private readonly approvalService: ApprovalService,
    private readonly collaboratorQuery: CollaboratorQuery,
    private readonly collaboratorService: CollaboratorService,
    private readonly approvalDetailQuery: ApprovalDetailQuery,
    public readonly windowService: WindowService,
    public translations: Translations,
    private readonly artApprovalHttpService: ArtApprovalHttpService) {
    this.approval = _cloneDeep(this.approvalQuery.getApprovalById(this.data.approvalId));
    this.approvalDetailQuery.collaborator$.pipe(
      switchMap((collaborator: Collaborator) => {
        this.isCollaborator = !!collaborator;
        this.collaboratorId = collaborator?.collaboratorId;

        if (this.approval?.shareableCollaboratorLinkId) {
          return of(<CollaboratorLink>{
            collaboratorLinkId: this.approval.shareableCollaboratorLinkId
          });
        }

        if (collaborator) {
          return this.collaboratorService.createNewCollaboratorLink(data.approvalId, collaborator.collaboratorId);
        }
        return this.approvalService.createShareLink(data.approvalId);
      }),
      tap((link: CollaboratorLink) => {
        this.collaboratorLinkId = link.collaboratorLinkId;
        this.sharedLink$.next(window.location.origin + '/invite/collaborator/' + this.collaboratorLinkId + '/' + this.apiService.getOrgUri());

        if (!this.approval?.shareableCollaboratorLinkId) {
          // update collaborator link in approval
          this.approval['shareableCollaboratorLinkId'] = link.collaboratorLinkId;
          this.approvalService.updateShareableCollaboratorLinkId(this.data.approvalId, link.collaboratorLinkId);
        }

        if (!this.isCollaborator) {
          this.getCollaboratorEmails();
        }

        this.shareableLinkPermission = this.approval.shareableLinkPermission;
      }),
      switchMap(() => {
        if (!this.approval?.shareableLinkPermission) {
          return this.approvalService.getShareableLinkPermission(this.approval).pipe(
            tap((collaborator: Collaborator) => this.shareableLinkPermission = collaborator.role)
          );
        }
      })
    ).subscribe();

    this.collaborators$ = this.collaboratorQuery.getArtApprovalCollaborator(data.approvalId).pipe(
      tap(collaborators => {
        this.existingEmailIds = collaborators.map(collaborator => _toLower(collaborator.email));
        return collaborators;
      })
    );

    this.emailFormControl.valueChanges.subscribe((email: string) => {
      this.newEmail = email;
    });

    this.copyToClipboardSubject.pipe(
      switchMap(() => this.sharedLink$)
    ).subscribe((shareLink: string) => {
      GlobalHelpers.copyToClipboard(shareLink);
    });

    this.approvalDetailQuery.role$.pipe(
      untilDestroyed(this)
    ).subscribe((role: CollaboratorRole) => {
      this.role = role;
    });

    this.filteredOptions = this.emailFormControl.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        map(val => {
          if (!val?.length) {
            return [];
          }
          return this.collaboratorEmails.filter((email: string) => _startsWith(email, val?.toLowerCase()));
        })
      );
  }

  createEmail(): void {
    this.newEmail = this.newEmail.trim();
    if (!GlobalHelpers.validateEmail(this.newEmail) || !this.newEmail) {
      return;
    }
    if (!this.emails) {
      this.emails = [];
    }
    if (!this.emails.find(email => _toLower(email) === _toLower(this.newEmail))) {
      this.emails.push(this.newEmail);
      this.emailAlreadyExist = !!_intersection(this.existingEmailIds, this.emails.map(_toLower)).length;
      this.emailsChange.emit(this.emails);
    }
    this.resetEmailInput();
  }

  resetEmailInput() {
    this.newEmail = '';
    this.emailFormControl.setValue('');
    // To fix this issue https://github.com/angular/components/issues/10968
    this.emailInput.nativeElement.value = '';
  }

  sendInvites() {
    if (this.pendingState) {
      return;
    }
    this.pendingState = true;
    this.approvalService.addCollaboratorsToApproval(this.data.approvalId, this.emails, this.message, this.role, this.collaboratorId).subscribe(() => {
      this.emails = [];
      this.pendingState = false;
      this.message = null;
    });
  }

  updateCollaborator(collaborator: Collaborator, role: CollaboratorRole) {
    this.approvalService.updateCollaboratorRole(collaborator.collaboratorId, role).subscribe();
  }

  copyToClipboard(collaboratorLinkId: ID = this.collaboratorLinkId) {
    this.sharedLink$.next(window.location.origin + '/invite/collaborator/' + collaboratorLinkId + '/' + this.apiService.getOrgUri());
    this.copyToClipboardSubject.next(null);
  }

  removeEmail(emailToRemove: string): void {
    this.emails = this.emails.filter((email: string) => email !== emailToRemove);
    this.emailAlreadyExist = !!_intersection(this.existingEmailIds, this.emails.map(_toLower)).length;
    this.emailsChange.emit(this.emails);
  }

  removeCollaborator(collaboratorId: ID) {
    const currentCollaboratorId: ID = this.isCollaborator ? this.collaboratorId : null;
    this.approvalService.removeCollaborator(this.data.approvalId, collaboratorId, currentCollaboratorId).subscribe();
  }

  getCollaboratorEmails() {
    this.artApprovalHttpService.getExistingCollaborators().subscribe((emails: string[]) => {
      this.collaboratorEmails = emails;
    });
  }

  enableValidation(): void {
    this.showError = true;
  }

  updateShareableLinkRole(role: CollaboratorRole): void {
    this.approvalService.updateShareableLinkPermission(this.data.approvalId, role).pipe(
      tap(() => this.shareableLinkPermission = role)
    ).subscribe();
  }

  copyShareableLink(): void {
    this.copyToClipboard();
    this.tooltip.show();
    setTimeout(() => {
      this.tooltip.hide();
    }, 3000);
  }

  updateCollaboratorRole(role: CollaboratorRole): void {
    this.role = role;
  }
}
