import { Injectable } from '@angular/core';
import { emitOnce } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { ArtHttpService, OrganizationHttpService } from '@graphics-flow/api';
import { ID, Organization, SearchKeyword, SearchResults, Watermark } from '@graphics-flow/types';
import { setActiveId, setEntities, updateEntities, upsertEntities } from '@ngneat/elf-entities';
import { OrganizationQuery } from './organization.query';
import { OrganizationStore } from './organization.state';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  constructor(
    private readonly artHttpService: ArtHttpService,
    private readonly organizationHttpService: OrganizationHttpService,
    private readonly organizationQuery: OrganizationQuery,
    private readonly organizationStore: OrganizationStore
  ) {
  }

  getOrganization(setActive: boolean = true): Observable<Organization> {
    return this.organizationHttpService.getOrganization().pipe(
      tap((organization: Organization) => {
        this.upsertEntities(organization);
        if (setActive) {
          this.setActiveOrganization(organization.organizationId);
        }
      })
    );
  }

  selectOrganization(organizationId: ID) {
    this.setActiveOrganization(organizationId);
  }

  setOrganizations(organizations: Organization[], activeOrg?: Organization) {
    emitOnce(() => {
      if (organizations?.length) {
        this.upsertEntities(organizations);
        if (activeOrg) {
          this.selectOrganization(activeOrg.organizationId);
        } else if (!this.organizationQuery.getActiveId()) {
          this.selectOrganization(organizations[0]?.organizationId);
        }
      } else {
        this.organizationStore.update(setEntities([]));
        this.selectOrganization(null);
      }
    });
  }

  updateOrganization(organization: Organization): Observable<Organization> {
    return this.organizationHttpService.updateOrganization(organization).pipe(
      tap((updatedOrg: Organization) => {
        this.upsertEntities(updatedOrg);
      })
    );
  }

  searchOrganization(searchString: string,
    includeArt?: boolean,
    includeArtApprovals?: boolean,
    includeFolders?: boolean): Observable<SearchResults> {
    return this.organizationHttpService.searchOrganization(
      searchString,
      includeArt,
      includeArtApprovals,
      includeFolders
    );
  }

  changeOrganizationProfilePicture(file: File): Observable<Organization> {
    return this.artHttpService.uploadProfilePicture(file, true).pipe(
      switchMap(() => this.getOrganization()));
  }

  removeOrganizationProfilePicture(): Observable<Organization> {
    return this.artHttpService.deleteOrganizatonProfilePicture().pipe(
      tap((organization: Organization) => this.updateEntities(organization.organizationId, organization))
    );
  }

  saveWatermark(watermark: Watermark): Observable<Organization> {
    return this.organizationHttpService.saveWatermark(watermark).pipe(
      tap((organization: Organization) => this.upsertEntities(organization))
    )
  }

  getStockArtKeywords(searchKeyword: SearchKeyword): Observable<string[]> {
    const params = {
      limit: 20,
      searchText: searchKeyword.searchText.toLowerCase(),
      stockArtType: searchKeyword.type
    };
    return this.artHttpService.getStockArtKeywords(params);
  }

  upsertEntities(organization: Organization | Organization[]): void {
    this.organizationStore.update(upsertEntities(organization));
  }

  updateEntities(id: ID, organization: Partial<Organization>): void {
    this.organizationStore.update(updateEntities(id, organization));
  }

  setActiveOrganization(id: ID): void {
    this.organizationStore.update(setActiveId(id));
  }
}
