import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[multipleEmailInput]'
})
export class MultipleEmailInputDirective {

  @Input() allowSpace = false;

  @Output() spacePress: EventEmitter<void> = new EventEmitter<void>();
  @Output() enterPress: EventEmitter<void> = new EventEmitter<void>();
  @Output() commaPress: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent): void | null {
    const allowedCodes: number[] = [46, 8, 9, 27, 13];

    if (e.keyCode === 13) {
      this.enterPress.emit();
    }

    if (e.keyCode === 32) {
      this.spacePress.emit();
    }

    if (e.keyCode === 188) {
      this.commaPress.emit();
    }

    if (allowedCodes.indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && e.ctrlKey === true) ||
      // Allow: Ctrl+V  (allowing paste can allow text if text is on the clipboard, do we want to give up paste?  I
      // say no, but I really like paste especially for credit card numbers)
      (e.keyCode === 86 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }

    if (e.keyCode === 32 || e.keyCode === 188) {
      e.preventDefault();
    }
  }
}
