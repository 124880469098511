import { Pipe, PipeTransform } from '@angular/core';
import { GlobalHelpers } from '@graphics-flow/util';

@Pipe({
  name: 'fileName'
})
export class FileNamePipe implements PipeTransform {
  transform(fileName: string): string {
    if (!fileName) {
      return;
    }
    return GlobalHelpers.GetFileNameWithOutExt(fileName);
  }
}
