import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

import { Translations } from '@graphics-flow/shared/assets';
import { HelpAndResourcesDialogComponent } from '../help-and-resources-dialog/help-and-resources-dialog.component';

@UntilDestroy()
@Component({
  selector: 'gf-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
export class HelpCenterComponent {

  @Input() panelClass = 'mat-48';
  @Input() isDarkHeader: boolean;

  constructor(
    public translations: Translations,
    private dialog: MatDialog
  ) {
  }

  openHelpCenterDialog() {
    this.dialog.open(HelpAndResourcesDialogComponent, {
      panelClass: 'mobile-screen-modal-medium-y',
      autoFocus: false
    });
  }
}
