import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Collaborator, CollaboratorRole, ID } from '@graphics-flow/types';
import { Translations } from '@graphics-flow/shared/assets';
import { WindowService } from 'shared/util';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'gf-approval-collaborator-item',
  templateUrl: './approval-collaborator-item.component.html',
  styleUrls: ['./approval-collaborator-item.component.scss']
})


export class ApprovalCollaboratorItemComponent {

  CollaboratorRole = CollaboratorRole;

  @Input() collaborator: Collaborator;
  @Input() allowChange = false;
  @Input() activeCollaboratorId: ID;
  @Output() removeCollaborator: EventEmitter<ID> = new EventEmitter<ID>();
  @Output() updateCollaboratorRole: EventEmitter<CollaboratorRole> = new EventEmitter<CollaboratorRole>();
  @Output() copyToClipboard: EventEmitter<ID> = new EventEmitter<ID>();
  @ViewChild('tooltip') tooltip: MatTooltip;


  constructor(
    readonly translations: Translations,
    public readonly windowService: WindowService
  ) {
  }

  changeRole(role: CollaboratorRole) {
    this.updateCollaboratorRole.emit(role);
  }

  remove() {
    this.removeCollaborator.emit(this.collaborator.collaboratorId);
  }

  copyCollaboratorLink(): void {
    this.copyToClipboard.emit(this.collaborator.collaboratorLinkIds[0]);
    this.tooltip.show();
    setTimeout(() => {
      this.tooltip.hide();
    }, 3000);
  }

}
