import { DateTime } from 'luxon';
import { DesignRequestItem, DesignRequestType, ID } from '@graphics-flow/types';
import { DateHelpers } from './date.helper';

export class DesignRequestHelper {
  public static getDateTimeIncludingTimeZone(value: Date) {
    if (!value) {
      return;
    }
    const isoString = DateHelpers.getDateAsIsoString(value);
    const date = DateTime.fromISO(isoString);
    // returns Apr 10, 2021 (8.29am MST) Viewer Local Time.
    return date.toFormat('MMM d, yyyy h:mm') + date.toFormat('a ').toLowerCase() + DateHelpers.getTimeZoneAbbreviation();
  }

  public static getSelectedArtComparison(requestItem: DesignRequestItem, id: ID) {
    if (requestItem.type === DesignRequestType.MyArt && requestItem?.art) {
      return requestItem?.art?.parentId === id;
    }
    return requestItem?.artId === id;
  }
}
