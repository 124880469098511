import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ArtApprovalRequest } from '@graphics-flow/types';
import { ArtApprovalSearchState, ArtApprovalSearchStore } from './art-approval-search.store';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ArtApprovalSearchQuery {
  activeFilters$: Observable<ArtApprovalRequest> = this.selectStateProps<ArtApprovalRequest>((store: ArtApprovalSearchState) => store.activeFilters);
  searchString$: Observable<string> = this.selectStateProps<string>((store: ArtApprovalSearchState) => store.activeFilters.searchText);
  searchFocus$: Observable<boolean> = this.selectStateProps<boolean>((store: ArtApprovalSearchState) => store.isSearchInFocus);
  totalResults$: Observable<number> = this.selectStateProps<number>((store: ArtApprovalSearchState) => store?.filteredArtApprovalsPagination?.totalResults);
  loading$: Observable<boolean> = this.selectStateProps<boolean>((store: ArtApprovalSearchState) => store.loading); // indicate load for first time loading
  loadingSearchResult$: Observable<boolean> = this.selectStateProps<boolean>((store: ArtApprovalSearchState) => store.loadingResults); // indicate load for infinite loading

  onSearchPage$ = combineLatest([
    this.searchFocus$,
    this.searchString$
  ]).pipe(
    map(([focus, search]) => !!focus || !!search?.length)
  );

  showClearSearch$: Observable<boolean> = this.selectStateProps<boolean>((store: ArtApprovalSearchState) => store.isSearchInFocus || !!store.activeFilters.searchText);

  constructor(
    protected store: ArtApprovalSearchStore
  ) {
  }

  selectStateProps<T>(predicate): Observable<T> {
    return this.store.pipe(select(predicate));
  }

  getTotalResult() {
    return this.store.getValue()?.filteredArtApprovalsPagination?.totalResults;
  }
}
