import { Injectable } from '@angular/core';
import { createState, Store } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { StockArtCategory } from '@graphics-flow/types';

const { state, config } = createState(
  withEntities<StockArtCategory, 'folderId'>({ idKey: 'folderId', initialValue: [] })
)


@Injectable({ providedIn: 'root' })
export class StockArtCategoriesStore extends Store {

  constructor() {
    super({ name: 'stock-art-categories', state, config });
  }

}
