import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { FolderHttpService } from '@graphics-flow/api';
import { Art, ArtFolderPackage, Folder, ID } from '@graphics-flow/types';

import { BillingPlansService } from '../billing-plans/billing-plans.service';
import { ArtService } from '../art/art.service';
import { FolderService } from '../folder/folder.service';
import { MyArtSearchQuery } from './../my-art-search/my-art-search.query';
import { MyArtBulkSelectionQuery } from './my-art-bulk-selection.query';
import { MyArtBulkSelectionStore } from './my-art-bulk-selection.store';

@Injectable({
  providedIn: 'root'
})
export class MyArtBulkSelectionService {
  selectedAllArtsAndFolder$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  keyStrokeSubscription: Subscription;
  isAllSelected$: Observable<boolean> = combineLatest([this.myArtBulkSelectionQuery.childrenCount$, this.myArtBulkSelectionQuery.selectedCount$, this.selectedAllArtsAndFolder$]).pipe(
    map(([childrenCount, selectedCount, selectedAllArtsAndFolder]: [number, number, number]) => {
      return childrenCount === selectedCount || selectedAllArtsAndFolder === selectedCount;
    })
  );

  constructor(
    private readonly artService: ArtService,
    private billingPlanService: BillingPlansService,
    private readonly folderHttpService: FolderHttpService,
    private readonly folderService: FolderService,
    private readonly myArtBulkSelectionStore: MyArtBulkSelectionStore,
    private readonly myArtSearchQuery: MyArtSearchQuery,
    private readonly myArtBulkSelectionQuery: MyArtBulkSelectionQuery
  ) { }

  selectFolder(id: ID) {
    this.myArtBulkSelectionStore.addFolder(id);
  }

  selectArt(id: ID) {
    this.myArtBulkSelectionStore.addArt(id);
  }

  selectAll() {
    this.myArtSearchQuery.activeArtsAndFolders$.pipe(
      take(1),
      tap(([folders, arts]: [Folder[], Art[]]) => {
        this.selectAllArtsAndFolders(arts.map(a => a.artId), folders.map(f => f.folderId));
      })
    ).subscribe();
  }

  removeFolder(id: ID) {
    this.myArtBulkSelectionStore.removeFolder(id);
  }

  removeArt(id: ID) {
    this.myArtBulkSelectionStore.removeArt(id);
  }

  reset() {
    this.myArtBulkSelectionStore.reset();
  }

  deleteArtsAndFolders(artIds: ID[], folderIds: ID[]): Observable<ArtFolderPackage> {
    return this.folderHttpService.deleteArtAndFolders(artIds, folderIds).pipe(
      tap(() => {
        this.artService.deleteArtEntities(artIds);
        this.folderService.deleteFolderEntities(folderIds);
        this.billingPlanService.updateResourceUsageInBackground();
      })
    );
  }

  removeFoldersAndArts(folderIds: ID[], artIds: ID[]): void {
    this.myArtBulkSelectionStore.removeFoldersAndArts(folderIds, artIds);
  }

  selectAllArtsAndFolders(artIds: ID[], folderIds: ID[]): void {
    this.myArtBulkSelectionStore.selectAll(artIds, folderIds);
  }

  getAllArtIds(): Observable<ID[]> {
    return this.folderHttpService.getAllArtIds(this.myArtSearchQuery.getActiveQueryParams());
  }

}
