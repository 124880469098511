import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Collaborator, User, IRoles, ImagePreviewType, ID } from '@graphics-flow/types';
import { CollaboratorQuery, TeamQuery } from '@graphics-flow/util';
import { UserProfilePicturePipe } from './../../pipes/user-profile-picture.pipe';

@Component({
  selector: 'gf-teammate-avatar',
  templateUrl: './teammate-avatar.component.html',
  styleUrls: ['./teammate-avatar.component.scss']
})
export class TeammateAvatarComponent implements OnInit, OnChanges {

  @Input() teammateId: ID;
  @Input() type: IRoles = IRoles.User;
  @Input() size: ImagePreviewType = ImagePreviewType.THUMBNAIL;
  @Input() panelClass: string;
  @Input() showFullInfo = false;
  @Input() nameOnly = false;
  @Input() showInActive = true;
  @Input() profilePicSize: 'xsmall' | 'small' | 'medium' = 'xsmall'

  private data$: BehaviorSubject<User | Collaborator> = new BehaviorSubject<User | Collaborator>(null);
  imageUrl: string;
  teammate$:Observable<User | Collaborator> = this.data$.asObservable();

  constructor(private readonly collaboratorQuery: CollaboratorQuery,
              private readonly teamQuery: TeamQuery,
              public userProfilePicturePipe: UserProfilePicturePipe) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.teammateId) {
      this.data$.next((this.type === IRoles.User) ? this.teamQuery.getUserEntity(this.teammateId) : this.collaboratorQuery.getCollabratorEntity(this.teammateId));
    }
  }

  ngOnInit() {
    const isUser = this.type === IRoles.User;
    this.data$.next(isUser ? this.teamQuery.getUserEntity(this.teammateId) : this.collaboratorQuery.getCollabratorEntity(this.teammateId));
  }
}
