import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpBlobErrorInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'application/json') {
          // https://github.com/angular/angular/issues/19888
          // When request of type Blob, the error is also in Blob instead of object of the json data
          return new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event: Event) => {
              try {
                const errmsg = JSON.parse((<any>event.target).result);
                reject(new HttpErrorResponse({
                    error: errmsg,
                    headers: err.headers,
                    status: err.status,
                    statusText: err.statusText,
                    url: err.url
                }));
              } catch (event) {
                reject(err);
              }
            };
            reader.onerror = () => {
              reject(err);
            };
            reader.readAsText(err.error);
          });
        }

        if (err instanceof HttpErrorResponse && err.error instanceof ArrayBuffer) {
          const decodedString = String.fromCharCode.apply(null, new Uint8Array(err.error));
          return throwError(new HttpErrorResponse({
            error: JSON.parse(decodedString),
            headers: err.headers,
            status: err.status,
            statusText: err.statusText,
            url: err.url
          }));
        }
        return throwError(err);
      })
    );
  }
}
