import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { omit as _omit } from 'lodash-es';
import {
  ApiResponse,
  CustomizeStockArt,
  CustomizeStockArtDefaultColor,
  ReplaceColorCorelData,
  SaveDesignCorelData,
  Art,
  CSAType,
  CustomizeStockArtUndoRedoData,
  CustomizeStockArtBoundingBoxData,
  ID
} from '@graphics-flow/types';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CustomizeStockArtHttpService {

  constructor(private httpService: HttpService) {}

  createCorelSession(assetId: ID, stockArtId: ID, cdrFileName: string, csaType: CSAType = CSAType.STOCK_ART): Observable<CustomizeStockArt> {
    const params: FormData = new FormData();
    params.append('stockArtId', stockArtId?.toString());
    params.append('assetId', assetId.toString());
    params.append('cdrFileName', cdrFileName);
    params.append('artType', csaType.toString());
    return this.httpService.post(this.getUrl('CreateSession'), params).pipe(
      map((response: ApiResponse<CustomizeStockArt>) => response.data)
    );
  }

  keepCorelSessionAlive(cdrFileName: string, userId: ID) {
    const params: FormData = new FormData();
    params.append('userId', userId.toString());
    params.append('cdrFileName', cdrFileName);
    return this.httpService.post(this.getUrl('KeepAlive'), params);
  }

  getCorelDoc(cdrFileName: string): Observable<CustomizeStockArt> {
    return this.httpService.get(this.getUrl(`GetCorelDrawDoc?cdrFileName=${cdrFileName}`)).pipe(
      map((response: ApiResponse<CustomizeStockArt>) => response.data)
    );
  }

  updateCorelDrawDoc(art: CustomizeStockArt): Observable<CustomizeStockArt> {
    return this.httpService.post(this.getUrl('UpdateCorelDrawDoc'), _omit(art, 'pngPreview')).pipe(
      map((response: ApiResponse<CustomizeStockArt>) => response.data)
    );
  }

  getColors(): Observable<CustomizeStockArtDefaultColor[]> {
    return this.httpService.get(this.getUrl('GetColors')).pipe(
      map((response: ApiResponse<CustomizeStockArtDefaultColor[]>) => response.data)
    );
  }

  clearCorelSession(cdrFileName: string): Observable<CustomizeStockArt> {
    const params: FormData = new FormData();
    params.append('cdrFileName', cdrFileName);
    return this.httpService.post(this.getUrl('ClearSession'), params).pipe(
      map((response: ApiResponse<CustomizeStockArt>) => response.data)
    );
  }

  replaceColorCorelDrawDoc(data: ReplaceColorCorelData): Observable<CustomizeStockArt> {
    return this.httpService.post(this.getUrl('ReplaceColorCorelDrawDoc'), data).pipe(
      map((response: ApiResponse<CustomizeStockArt>) => response.data)
    );
  }

  private getUrl(api: string): string {
    return this.httpService.getUrl(`corel/${api}`);
  }

  saveDesignToMyArt(saveDesignData: SaveDesignCorelData): Observable<Art> {
    const params: FormData = new FormData();

    params.append('assetId', saveDesignData.assetId.toString());
    params.append('cdrFileName', saveDesignData.cdrFileName);
    params.append('designName', saveDesignData.designName);
    params.append('folderId', saveDesignData.folderId?.toString() || null);
    params.append('artType', saveDesignData.artType.toString());
    params.append('isUpdate', saveDesignData.isUpdate?.toString());
    if (saveDesignData.isUpdate) {
      params.append('artId', saveDesignData.artId?.toString());
    }

    return this.httpService.post(this.getUrl('SaveCorelDrawDoc'), params).pipe(
      map((response: ApiResponse<Art>) => response.data)
    );
  }

  replaceClipArtCorelDrawDoc(cdrFileName: string, shapeId: number, newClipArt: string): Observable<CustomizeStockArt> {
    return this.httpService.post(this.getUrl('ReplaceClipArtCorelDrawDoc'), { cdrFileName, shapeId, newClipArt }).pipe(
      map((response: ApiResponse<CustomizeStockArt>) => response.data)
    );
  }

  undoCorelDrawDoc(data: CustomizeStockArtUndoRedoData): Observable<CustomizeStockArt> {
    return this.httpService.post(this.getUrl('UndoCorelDrawDoc'), data).pipe(
      map((response: ApiResponse<CustomizeStockArt>) => response.data)
    );
  }

  redoCorelDrawDoc(data: CustomizeStockArtUndoRedoData): Observable<CustomizeStockArt> {
    return this.httpService.post(this.getUrl('RedoCorelDrawDoc'), data).pipe(
      map((response: ApiResponse<CustomizeStockArt>) => response.data)
    );
  }

  getObjectSelectionPreview(data: CustomizeStockArtBoundingBoxData): Observable<string> {
    return this.httpService.post(this.getUrl('GetObjectSelectionPreview'), data).pipe(
      map((response: ApiResponse<string>) => response.data)
    );
  }

  uploadArtToCSA(file: File, cdrFileName: string): Observable<CustomizeStockArt> {
    const params: FormData = new FormData();
    params.append('file', file, file.name);
    params.append('cdrFileName', cdrFileName);
    return this.httpService.post(this.getUrl('UploadArtToCSA'), params).pipe(
      map((response: ApiResponse<CustomizeStockArt>) => {
        return response.data;
      })
    );
  }

  addUploadedArtToCSA(artId: ID, customizeStockArt: CustomizeStockArt): Observable<CustomizeStockArt> {
    const updatedArt = _omit(customizeStockArt, ['pngPreview', 'successful']);
    const params = { ...updatedArt, artId };
    return this.httpService.post(this.getUrl('AddUploadedArtToCSA'), params).pipe(
      map((response: ApiResponse<CustomizeStockArt>) => {
        return response.data;
      })
    );
  }

}
