import { IAssociatedOrganization, IRoles, Order, User, UserSettings } from '@graphics-flow/types';

export class UserHelper {

  public static getDefaultUserSettings() {
    return <UserSettings>{
      sort: {
        sortBy: 'createdDate',
        sortByOrder: Order.DESC
      }
    };
  }

  public static getUserName(user: User): string {
    if (!user) {
      return null;
    }
    //Note: Invited user don't have first name and last name initially. It only have email as first name.
    return user?.lastName ? `${user.firstName} ${user.lastName}` : user.firstName;
  }

  public static getUserRole(user: User): IRoles {
    const org = user?.associatedOrganizations.find((assoicatedOrg: IAssociatedOrganization) => assoicatedOrg.organizationId === user.assignedOrganizationId);
    // For manually created user we won't get assignedOrganizationId, so returning first organization role
    return org?.role || user?.associatedOrganizations[0]?.role;
  }

  public static getExactUserRoleName(role: IRoles): IRoles | string {
    if (role === IRoles.User) {
      return 'Member';
    }

    return role;
  }
}
