<button *ngIf="showCustomizeStockArtOption"
  id="customizeMenuBtn"
  mat-menu-item
  (click)="openStockArtDetails.emit()">
  {{translations.common.customize | translate}}
</button>
<button mat-menu-item
  id="addArtToApprovalBtn"
  (click)="artActionsService.addStockArtToApproval(art?.artId, approvalMsg.artApprovalMsgRef);">
  {{translations.art.add_art_to_approval | translate}}
</button>

<ng-container *ngIf="(organizationQuery.isProPlan$ | async)">
  <button *ngIf="art.isHidden; else hideInArtPortal" type="button" mat-menu-item id="showInArtPortalBtn"
    (click)="updateStockArtVisibility(false)">
    {{translations.public_catalog_view.show_in_art_portal | translate}}
  </button>
  <ng-template #hideInArtPortal>
    <button *ngIf="!(userQuery.isSupport$ | async)" type="button" mat-menu-item id="hideInArtPortalBtn" (click)="updateStockArtVisibility(true)">
      {{translations.public_catalog_view.hide_in_art_portal | translate}}
    </button>
  </ng-template>
</ng-container>

<mat-divider class="m-y-1"></mat-divider>
<ng-container *ngFor="let extension of art?.artExtensions">
  <button mat-menu-item
    (click)="downloadStockArt(art, extension.name)">
    {{translations.art.download_x | translate : { x: extension.extension | uppercase } }}
  </button>
</ng-container>

<gf-add-to-approval-msg #approvalMsg></gf-add-to-approval-msg>
