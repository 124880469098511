import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _token = '';
  private _orgUri = '';

  getToken(): string {
    return this._token;
  }

  setToken(token: string): void {
    this._token = token;
  }

  getOrgUri(): string {
    return this._orgUri;
  }

  setOrgUri(orgUri: string): void {
    this._orgUri = orgUri;
  }

  reset(): void {
    this._token = '';
    this._orgUri = '';
  }
}
