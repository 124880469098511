import { WindowService } from 'shared/util';
import { Component, Input, OnInit } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';
import { MatSelectChange } from '@angular/material/select';
import { remove as _remove } from 'lodash-es';
import { take, tap } from 'rxjs/operators';
import { StockArtListQuery, StockArtListService } from '@graphics-flow/util';

@Component({
  selector: 'gf-stock-art-list-filter',
  templateUrl: './stock-art-list-filter.component.html',
  styleUrls: ['./stock-art-list-filter.component.scss']
})
export class StockArtListFilterComponent implements OnInit {
  @Input() filterProperty: string;
  @Input() label: string;
  selectedFilters: string[] = [];
  @Input() canShowClipArtMobileViewFilter = false;

  constructor(
    public readonly translations: Translations,
    public readonly stockArtListQuery: StockArtListQuery,
    public readonly stockArtListService: StockArtListService,
    public readonly windowService: WindowService
    ) { }

  ngOnInit(): void {
    // Used to update the existing filters that applied recently.
    this.stockArtListQuery.activeFilterHeaders$.pipe(
      take(1),
      tap(filterInfo => {
        if ((filterInfo[this.filterProperty])?.length) {
          this.selectedFilters.push(...(filterInfo[this.filterProperty]));
        }
      })
    ).subscribe();
  }

  onSelectionChange(e: MatSelectChange) {
    this.stockArtListService.setActiveFilters({
      index: 0,
      filterHeaders: {
        [this.filterProperty]: e.value
      }
    });
  }

  clearAllSelected() {
    this.stockArtListService.setActiveFilters({
      index: 0,
      filterHeaders: {
        [this.filterProperty]: []
      }
    });
  }

  setSelectedFilters(styleName: string, selected: boolean) {
    if (selected) {
      this.selectedFilters.push(styleName)
    } else {
      _remove(this.selectedFilters, style => style === styleName);
    }
  }

  onMobileSelectionChange() {
    this.stockArtListService.setActiveFilters({
      index: 0,
      filterHeaders: {
        [this.filterProperty]: this.selectedFilters
      }
    });
  }
}
