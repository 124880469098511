<div *ngIf="artId | art | async as art"
     class="art-detail-image-container width-100 height-100 trans-bg position-relative"
     [class.dark-preview-bg]="showDarkPreview">
  <img [src]="imageUrl || (art.assignedOrganizationId | imagePreview : art.assetId : imagePreviewType.LARGE : false : true : ( art | artExtension : 'png'))"
    class="width-100 height-100"
    [class.hide]="!imageUrl?.length && showUndoControls"
    fetchpriority="auto"
    loading="auto"
    [alt]="art?.name | fileName"/>

  <div *ngIf="showUndoControls" class="flex-container undo-controls">
    <button id="undoButton" mat-icon-button class="undo-btn" [disabled]="disableUndo" (click)="undo.emit()">
      <mat-icon fontIcon="undo"></mat-icon>
    </button>
    <button id="redoButton" mat-icon-button class="redo-btn" [disabled]="disableRedo" (click)="redo.emit()">
      <mat-icon fontIcon="redo"></mat-icon>
    </button>
  </div>

  <mat-icon *ngIf="showUndoControls && imageUrl?.length"
    id="lightDarkIcon"
    class="mat-48 preview-icon cursor-pointer secondary"
    [class.white-color]="showDarkPreview"
    [matTooltipPosition]="'above'"
    [matTooltip]="translations.custom_stock_art.light_dark_preview | translate"
    (click)="togglePreview()">
    contrast
  </mat-icon>
  <!-- hide for now GF-514 -->
  <!-- <div *ngIf="showTools" class="flex-container img-controls">
    <button mat-icon-button class="mat-48">
      <mat-icon>aspect_ratio</mat-icon>
    </button>
    <mat-divider class="m-a-1" [vertical]="true"></mat-divider>
    <button mat-icon-button class="mat-48">
      <mat-icon>center_focus_strong</mat-icon>
    </button>
    <button mat-icon-button class="mat-48">
      <mat-icon fontIcon="zoom_in"></mat-icon>
    </button>
    <button mat-icon-button class="mat-48">
      <mat-icon>zoom_out</mat-icon>
    </button>
    <mat-divider class="m-a-1" [vertical]="true"></mat-divider>
    <button mat-icon-button class="mat-48">
      <mat-icon>print</mat-icon>
    </button>
    <button mat-icon-button class="mat-48">
      <mat-icon fontIcon="login"></mat-icon>
    </button>
  </div> -->
  <div *ngIf="showCustomizeStockArt" class="customize-button-container">
    <button id="customizeStockArt"
            mat-raised-button
            class="notification-btn medium m-b-4"
            (click)="activeCustomizeStockArt.emit()">
      <mat-icon class="material-icons-outlined" fontIcon="color_lens"></mat-icon>
      {{translations.common.customize | translate}}
    </button>
  </div>
</div>
