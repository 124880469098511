import { Injectable } from '@angular/core';
import { Store, createState, withProps } from '@ngneat/elf';
import { isUndefined as _isUndefined } from 'lodash-es';
import { Art, ArtType, Folder, ID, MyArtActiveQueryParams, Pagination, Tag } from '@graphics-flow/types';
import { MY_ART_BASE_LIMIT } from '../../constants/infinity-scroller.constants';

export interface MyArtSearchState {
  isSearchInFocus?: boolean;
  activeQueryParams: MyArtActiveQueryParams;
  showOverAllLoader: boolean;
  showInfiniteScrollLoader: boolean;
  myArtPagination: Pagination; // pagination for my art
  folderIds: ID[];
  artIds: ID[];
  activeArtsCount: number;
  activeFoldersCount: number;
}

export const initialState: MyArtSearchState = <MyArtSearchState>{
  isSearchInFocus: false,
  activeQueryParams: <MyArtActiveQueryParams>{
    globalSearchText: '',
    filterSearchText: '',
    index: 0,
    limit: MY_ART_BASE_LIMIT,
    filterTags: [],
    folderId: null,
    sortBy: null,
    orderDesc: null,
    isGlobalFilter: false,
    artType: null,
    trashed: false
  },
  showOverAllLoader: false,
  showInfiniteScrollLoader: false,
  folderIds: [],
  artIds: [],
  activeArtsCount: 0,
  activeFoldersCount: 0
};

const { state, config } = createState(
  withProps<MyArtSearchState>(initialState)
);

@Injectable({
  providedIn: 'root'
})
export class MyArtSearchStore extends Store {
  constructor() {
    super({ name: 'MyArtSearch', state, config })
  }

  setActiveQueryParams(queryParams: MyArtActiveQueryParams, viewAll: boolean = false): void {
    this.update((state) => {
      const activeQueryParams: MyArtActiveQueryParams = <MyArtActiveQueryParams>{
        index: !_isUndefined(queryParams.index) ? queryParams.index : state.activeQueryParams.index,
        limit: !_isUndefined(queryParams.limit) ? queryParams.limit : state.activeQueryParams.limit,
        sortBy: !_isUndefined(queryParams.sortBy) ? queryParams.sortBy : state.activeQueryParams.sortBy,
        orderDesc: !_isUndefined(queryParams.orderDesc) ? queryParams.orderDesc : state.activeQueryParams.orderDesc,
        globalSearchText: !_isUndefined(queryParams.globalSearchText) ? queryParams.globalSearchText : state.activeQueryParams.globalSearchText,
        filterSearchText: !_isUndefined(queryParams.filterSearchText) ? queryParams.filterSearchText : state.activeQueryParams.filterSearchText,
        isGlobalFilter: !_isUndefined(queryParams.isGlobalFilter) ? queryParams.isGlobalFilter : state.activeQueryParams.isGlobalFilter,
        folderId: !_isUndefined(queryParams.folderId) ? queryParams.folderId : state.activeQueryParams.folderId,
        filterTags: !_isUndefined(queryParams.filterTags) ? queryParams.filterTags : state.activeQueryParams.filterTags,
        artType: !_isUndefined(queryParams.artType) ? queryParams.artType : state.activeQueryParams.artType,
        trashed: !_isUndefined(queryParams.trashed) ? queryParams.trashed : state.activeQueryParams.trashed
      };

      return {
        ...state,
        activeQueryParams,
        showOverAllLoader: activeQueryParams.index === 0 || viewAll,
        showInfiniteScrollLoader: activeQueryParams.index !== 0
      };
    });
  }

  searchMyArt(searchString: string): void {
    this.update((state) => ({
      ...state,
      activeQueryParams: {
        ...state.activeQueryParams,
        globalSearchText: searchString,
        filterTags: [],
        filterSearchText: '',
        index: 0,
        artType: null
      },
      showOverAllLoader: true
    }));
  }

  filterMyArtBySearch(filterSearchString: string): void {
    this.update((state) => ({
      ...state,
      activeQueryParams: {
        ...state.activeQueryParams,
        filterSearchText: filterSearchString,
        index: 0
      },
      showOverAllLoader: true
    }));
  }

  updatefilterTags(filterTags: Tag[]): void {
    this.update((state) => ({
      ...state,
      activeQueryParams: {
        ...state.activeQueryParams,
        filterTags: filterTags,
        index: 0
      },
      showOverAllLoader: true
    }));
  }

  updateSearchFocusStatus(focusStatus: boolean): void {
    this.update((state) => ({
      ...state,
      isSearchInFocus: focusStatus
    }));
  }

  updateFolderId(folderId: ID): void {
    this.update((state) => ({
      ...state,
      activeQueryParams: {
        ...state.activeQueryParams,
        folderId: folderId,
        globalSearchText: '',
        filterSearchText: '',
        filterTags: [],
        index: 0,
        limit: MY_ART_BASE_LIMIT
      },
      showOverAllLoader: true
    }));
  }

  setMyArts(myArts: Art[]): void {
    this.update((state) => ({
      ...state,
      showOverAllLoader: false,
      showInfiniteScrollLoader: false,
      artIds: myArts.map((art) => art.artId)
    }));
  }

  appendMyArts(myArts: Art[]): void {
    const artIds = myArts.map((art) => art.artId);
    this.update((state) => ({
      ...state,
      showInfiniteScrollLoader: false,
      showOverAllLoader: false,
      artIds: [...state.artIds, ...artIds]
    }));
  }

  setFolders(folders: Folder[]): void {
    this.update((state) => ({
      ...state,
      folderIds: folders.map((folder) => folder.folderId)
    }));
  }

  setPagination(pagination: Pagination): void {
    this.update((state) => ({
      ...state,
      myArtPagination: pagination
    }));
  }

  hideLoading(): void {
    this.update((state) => ({
      ...state,
      showInfiniteScrollLoader: false,
      showOverAllLoader: false
    }));
  }

  updateFilterArtType(filterArtType: ArtType) {
    this.update((state) => ({
      ...state,
      activeQueryParams: {
        ...state.activeQueryParams,
        artType: filterArtType,
        index: 0
      },
      showOverAllLoader: true
    }));
  }

  reduceTotalResultsCount(count: number) {
    this.update((state) => {
      state.myArtPagination.totalResults -= count;
      return {...state};
    });
  }
}
