<div class="folder-drop grid-y"
  [dropTarget]="folderDropTarget">
  <ng-content></ng-content>
  <div
    [class.hide]="!showDropIndicator"
    [class.show-indicator]="hovering$ | async"
    [class.root-folder]="isRootFolder"
    class="drop-indicator">
  </div>
</div>
