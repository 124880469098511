import { Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrganizationQuery } from './../data/organization/organization.query';

@Injectable({
  providedIn: 'root'
})
export class ProPlansOnlyRouteGuard {

  constructor(
    private origanizationQuery: OrganizationQuery,
    private readonly router: Router
  ) {
  }

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.isAllowed();
  }
  canActivateChild(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.isAllowed();
  }

  isAllowed(): Observable<boolean> {
    return this.origanizationQuery.isProPlan$.pipe(
      map((isProPlan: boolean) => {
        if (!isProPlan) {
          this.router.navigate(['/page-not-found']);
        }
        return isProPlan;
      })
    );
  }

}
