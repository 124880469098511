import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { fromEvent, merge, Observable } from 'rxjs';
import { delay, map, startWith } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'gf-shady-scroller',
  templateUrl: './shady-scroller.component.html',
  styleUrls: ['./shady-scroller.component.scss']
})
export class ShadyScrollerComponent implements AfterViewInit {
  @ViewChild('scrollingContainer', { static: false }) scrollingContainer: ElementRef;
  @Output() isScrolledFromTop: EventEmitter<boolean> = new EventEmitter();

  onResize$: Observable<any> = fromEvent(window, 'resize').pipe(untilDestroyed(this));
  onScroll$: Observable<any>;
  onScrollOrResize$: Observable<any>;
  isScrolledFromTop$: Observable<boolean>;

  ngAfterViewInit(): void {
    this.onScroll$ = fromEvent(this.scrollingContainer.nativeElement, 'scroll').pipe(untilDestroyed(this));

    this.onScrollOrResize$ = merge(this.onResize$, this.onScroll$);

    this.isScrolledFromTop$ = this.onScrollOrResize$.pipe(
      startWith(true), // Do once immediately
      delay(0), // Wait for change detection cycle
      map(() => {
        // Used minimum value as 10 here.. to avoid padding space height.
        const isScrollFromTop = this.scrollingContainer?.nativeElement.scrollTop > 10;
        this.isScrolledFromTop.emit(isScrollFromTop);
        return isScrollFromTop;
      })
    );
  }

}
