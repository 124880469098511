//NOTE: Written in the MM/DD/YYYY format because MAT_DATE_FORMATS doesn't support MM/dd/yyyy
export const gfDateFormats = {
  parse: {
    dateInput: 'MM/dd/yyyy'
  },
  display: {
    dateInput: 'MM/dd/yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'DDD',
    monthYearA11yLabel: 'MMMM yyyy'
  }
};
