import { Injectable } from '@angular/core';
import { User } from '@graphics-flow/types';
import { createState, Store } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';

const { state, config } = createState(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  withEntities<User, 'userId'>({ idKey: 'userId', initialValue: [] }),
  withActiveId()
)

@Injectable({ providedIn: 'root' })
export class UserStore extends Store {
  constructor() {
    super({ name: 'users', state, config });
  }
}
