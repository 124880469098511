import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NotificationData, NotificationType } from '@graphics-flow/types';

import { NotificationComponent } from '../notification-component/notification.component';

export const MAX_NOTIFICATION_DURATION = 5000;
export const MIN_NOTIFICATION_DURATION = 3000;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {
  }

  showNotification(type: NotificationType, title: string, message: string, templateRef: TemplateRef<any> = null, duration: number = MIN_NOTIFICATION_DURATION) {
    return this.snackBar.openFromComponent(NotificationComponent, <MatSnackBarConfig>{
      data: <NotificationData>{
        message,
        templateRef,
        title,
        type
      },
      duration: type === NotificationType.SUCCESS ? MAX_NOTIFICATION_DURATION : duration,
      horizontalPosition: 'start',
      verticalPosition: 'bottom'
    });
  }

  showFileNotification(type: NotificationType, title: string, message: string) {
    return this.showNotification(type, title, message, null, null);
  }
}
