import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

import { Folder, ID, MyArtActiveQueryParams } from '@graphics-flow/types';
import { FolderQuery, FolderService } from '@graphics-flow/util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WindowService } from 'shared/util';
import { FolderMoveDialogQuery } from '../folder-move-dialog.query';
import { FolderMoveDialogService } from '../folder-move-dialog.service';

@UntilDestroy()
@Component({
  selector: 'gf-folder-move-list-item',
  templateUrl: './folder-move-list-item.component.html',
  styleUrls: ['./folder-move-list-item.component.scss']
})
export class FolderMoveListItemComponent implements OnChanges {
  readonly Math = Math;
  @Input() sourceFolderIds: ID[];
  @Input() folder: Folder;
  // Depth in the category tree, just used for indentation
  @Input() depth = 0;
  expanded: boolean;
  selected: boolean;
  showFolderLoader = false;

  children$: Observable<Folder[]>;

  constructor(
    private readonly folderQuery: FolderQuery,
    public readonly folderMoveDialogQuery: FolderMoveDialogQuery,
    public readonly windowService: WindowService,
    public readonly folderService: FolderService,
    public readonly folderMoveDialogService: FolderMoveDialogService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.folder) {
      this.children$ = this.folderQuery.selectFolderChildren(this.folder?.folderId || null)
      .pipe(
        map(folders => folders.filter(folder =>  !this.sourceFolderIds.includes(folder.folderId)))
      );
    }
  }

  toggleExpanded(): void {
    const children: Folder[] = this.folderQuery.getFolderChildren(this.folder?.folderId || null);
    if (children?.length) {
      this.expanded = !this.expanded;
      return;
    }
    this.showFolderLoader = true;
    this.folderService.loadFolders(<MyArtActiveQueryParams>{folderId: this.folder.folderId}).pipe(
      first(),
      untilDestroyed(this),
      tap(() => {
        this.expanded = !this.expanded;
        this.showFolderLoader = false;
      })
    ).subscribe();
  }

}
