// No need to fix unused variables here, we may need it in future so leave it as of now.
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { ID } from '@graphics-flow/types';

// For Segment - details here: https://github.com/segmentio/analytics-angular
declare global {
  interface Window {
    analytics: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SegmentService {

  identify(id: ID, details: object) {
    //window.analytics.identify(id, details);
  }

  track(action: string, deets?: object) {
    // TODO: Pull more deets out of user/organization
    //window.analytics.page(action, deets);
  }
}
