import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Art, ID, StockArtDetailSections } from '@graphics-flow/types';
import { ArtQuery } from '../art/art.query';
import { CustomizeStockArtQuery } from '../custom-stock-art/customize-stock-art.query';
import { MyArtDetailState, MyArtDetailStore } from './my-art-detail.store';

type MyArtDetailPredicate<T> = (state: MyArtDetailState) => T;

@Injectable({ providedIn: 'root' })
export class MyArtDetailQuery {
  drawerIsOpen$: Observable<boolean> = this.selectStateProps<boolean>((state: MyArtDetailState) => state.isDrawerOpen);
  detailActiveSection$: Observable<string> = this.selectStateProps<string>((state: MyArtDetailState) => state.activeSection);
  activeArt$: Observable<Art> =  this.selectStateProps<ID>((state: MyArtDetailState) => state.artId).pipe(
      switchMap((artId: ID) => this.artQuery.selectArt(artId))
    );

  isCSAEditMode$: Observable<boolean> = combineLatest([this.detailActiveSection$, this.customizeStockArtQuery.isEditMode$]).pipe(
    map(([detailActiveSection, inEditMode]: [string, boolean]) => detailActiveSection === StockArtDetailSections.CUSTOMIZE && inEditMode)
  );

  constructor(
    private artQuery: ArtQuery,
    protected myArtDetailStore: MyArtDetailStore,
    private customizeStockArtQuery: CustomizeStockArtQuery
  ) {
  }

  selectStateProps<T>(predicate: MyArtDetailPredicate<T>): Observable<T> {
    return this.myArtDetailStore.pipe(select(predicate));
  }

  getValue(): MyArtDetailState {
    return this.myArtDetailStore?.getValue();
  }

}
