<div class="stock-art-list-container-header grid-x align-justify align-middle" [ngClass]="alignmentClass">
          <span class="results-count" *ngIf="!(stockArtListQuery.loading$ | async)">
            {{translations.common.x_results | translate : { x: stockArtListTotalResults$ | async } }}
          </span>
  <div class="sort-container" *ngIf="stockArtListQuery.activeFilters$ | async as activeFilters">
    <button id="sortOptionsBtn"
      *ngIf="canShowSortFilter"
      mat-button
      type="button"
      class="small rounded-button p-r-1 p-l-2"
      #sortMenuTrigger="matMenuTrigger"
      [mat-menu-trigger-for]="sortMenu"
      [class.active]="sortMenuTrigger.menuOpen">
      <ng-container>
        {{currentSortString$ | async | translate}}
        <mat-icon class="secondary" fontIcon="expand_more"></mat-icon>
      </ng-container>
    </button>
  </div>
  <div
    *ngIf="canShowFilterOption || (smallDown$ | async)"
    class="filter-toggle-container">
    <button mat-button
            id="filterToggleBtn"
            type="button"
            class="p-x-1"
            #filterMenuTrigger="matMenuTrigger"
            [mat-menu-trigger-for]="filterMenu">
      <mat-icon fontIcon="filter_list"></mat-icon>
      {{translations.stock.filter_x | translate: { x: (stockArtListQuery.chipCount$ | async) } }}
    </button>
  </div>
</div>

<mat-menu #sortMenu="matMenu"
          yPosition="below"
          xPosition="before">
  <button id="recentFirstBtn" type="button" mat-menu-item (click)="changeSortOrder(StockArtOrder.CreatedDate, true)">
    {{translations.filter.recent_first | translate}}
  </button>

  <button id="oldestFirstBtn" type="button" mat-menu-item (click)="changeSortOrder(StockArtOrder.CreatedDate, false)">
    {{translations.filter.oldest_first | translate}}
  </button>

  <button id="atozBtn" type="button" mat-menu-item (click)="changeSortOrder(StockArtOrder.Name, false)">
    {{translations.filter.name_a_to_z | translate}}
  </button>

  <button id="ztoaBtn" type="button" mat-menu-item (click)="changeSortOrder(StockArtOrder.Name, true)">
    {{translations.filter.name_z_to_a | translate}}
  </button>
</mat-menu>

<mat-menu #filterMenu="matMenu"
          yPosition="below"
          xPosition="before"
          class="filter-menu-wrap filter-menu-panel">
  <ng-container *ngIf="showDownloadFilter">
    <button id="allBtn" type="button" mat-menu-item
      [class.selected-option]="(stockArtListQuery.stockArtFilter$ | async) === stockArtFilterType.All"
      (click)="changeFilterType(stockArtFilterType.All)">
      {{stockArtListQuery.currentArtTypeString$ | async | translate}}
    </button>

    <button id="favouritesBtn" type="button" mat-menu-item
      [class.selected-option]="(stockArtListQuery.stockArtFilter$ | async) === stockArtFilterType.Favorites"
      (click)="changeFilterType(stockArtFilterType.Favorites)">
      {{ translations.common.favorites | translate }}
    </button>

    <ng-container *ngIf="(stockArtListQuery.activeStockArtType$ | async) !== StockArtType.Font">
      <button id="downloadedBtn" type="button" mat-menu-item
        [class.selected-option]="(stockArtListQuery.stockArtFilter$ | async) === stockArtFilterType.Downloaded"
        (click)="changeFilterType(stockArtFilterType.Downloaded)">
        {{translations.common.downloaded | translate}}
      </button>
      <button id="notDownloadedBtn" type="button" mat-menu-item
        [class.selected-option]="(stockArtListQuery.stockArtFilter$ | async) === stockArtFilterType.NotDownloaded"
        (click)="changeFilterType(stockArtFilterType.NotDownloaded)">
        {{translations.common.not_downloaded | translate}}
      </button>
      <button id="manuallyHiddenInArtPortalBtn" type="button" mat-menu-item
        [class.selected-option]="(stockArtListQuery.stockArtFilter$ | async) === stockArtFilterType.HiddenManuallyInArtPortal"
        (click)="changeFilterType(stockArtFilterType.HiddenManuallyInArtPortal)">
        {{translations.common.manually_hidden_in_art_portal | translate}}
      </button>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="(stockArtListQuery.activeStockArtType$ | async) !== StockArtType.Font">
    <button id="categoryBtn" type="button" mat-menu-item class="align-justify align-middle" (click)="openCategoryFilterDialog()">
      {{translations.stock.category | translate}} <mat-icon fontIcon="navigate_next"></mat-icon>
    </button>

    <button is="styleBtn" type="button" *ngIf="((stockArtListQuery.activeStockArtType$ | async) !== StockArtType?.ClipArt)"
      class="align-justify align-middle" mat-menu-item (click)="openStyleFilterDialog()">
      {{translations.stock.style | translate}} <mat-icon fontIcon="navigate_next"></mat-icon>
    </button>

    <button id="collectionBtn" type="button" *ngIf="(billingPlansQuery.isGraphicsBuilderPartOfAddOn$ | async) || showCollectionFilter" mat-menu-item
      class="align-justify align-middle" (click)="openCollectionFilterDialog()">
      {{translations.stock.collection | translate}} <mat-icon fontIcon="navigate_next"></mat-icon>
    </button>
  </ng-container>
</mat-menu>
