import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { AdditionalPrice, BasePrice, OrganizationPaymentOption, OrganizationResourceUsage, SelectedPlanInfo } from '@graphics-flow/types';
import { BillingPlansQuery } from '@graphics-flow/util';
import { FileSizePipe } from 'ngx-filesize';

export interface PlanUsageWarningInfo {
  someUsageWarningExist: boolean;
  userUsageWarning: UserUsageWarning
  storageUsageWarning: StorageUsageWarning
}

export interface UserUsageWarning {
  userCountByPlan: number;
  existingUserCount: number;
  userPrice: string;
}

export interface StorageUsageWarning {
  storageSizeByPlan: string;
  totalUsedStorageSize: string;
  additionalPlanToOpt: string
}

@Pipe({
  name: 'selectedPlanUsageWarningInfo'
})
export class SelectedPlanUsageWarningInfoPipe implements PipeTransform {
  organizationPaymentOption: OrganizationPaymentOption = this.billingPlansQuery.getOrganizationPaymentOptions();
  userPriceInfo: BasePrice = this.organizationPaymentOption?.additional?.find((price) => price.productName === AdditionalPrice.USER);
  additionalStoragePriceInfo: BasePrice = this.organizationPaymentOption?.additional?.find((price) => price.productName === AdditionalPrice.STORAGE);

  constructor(public readonly billingPlansQuery: BillingPlansQuery,
    public readonly fileSizePipe: FileSizePipe,
    public readonly currencyPipe: CurrencyPipe) {
  }

  transform(selectedPlanInfo: SelectedPlanInfo): PlanUsageWarningInfo {
    const organizationResourceUsage: OrganizationResourceUsage = this.billingPlansQuery.getOrganizationResourceUsageValue();

    const selectedBasePlan: BasePrice = selectedPlanInfo?.selectedPlan;
    const selectedStoragePlan: BasePrice = selectedPlanInfo?.storagePlan;

    let userUsageWarning: UserUsageWarning = null;
    let storageUsageWarning: StorageUsageWarning = null;

    if (organizationResourceUsage && selectedBasePlan) {
      if (organizationResourceUsage.userCount > selectedBasePlan.users) {
        userUsageWarning = {
          userCountByPlan: selectedBasePlan.users,
          existingUserCount: organizationResourceUsage.userCount,
          userPrice: this.currencyPipe.transform(this.userPriceInfo?.amount, 'USD', 'symbol', '1.0-2')
        };
      }

      if (organizationResourceUsage.storageBytes > (selectedBasePlan?.storageBytes + ((selectedStoragePlan?.storageBytes * selectedStoragePlan?.quantity) || 0))) {
        storageUsageWarning = {
          storageSizeByPlan: <string>this.fileSizePipe.transform(selectedBasePlan.storageBytes, { base: 2, standard: 'jedec' }),
          totalUsedStorageSize: <string>this.fileSizePipe.transform(organizationResourceUsage.storageBytes, { base: 2, standard: 'jedec' }),
          additionalPlanToOpt: <string>this.fileSizePipe.transform(
            this.findNextPossibleStorageSizeToOpt(selectedBasePlan.storageBytes, organizationResourceUsage.storageBytes),
            { base: 2, standard: 'jedec' }
          ),
        };
      }
    }

    return {
      someUsageWarningExist: !(userUsageWarning === null) || !(storageUsageWarning === null),
      userUsageWarning,
      storageUsageWarning
    };
  }

  // Recursive Method: To calculate and suggest the next possible storage size to opt by customer...
  // Handle with care...
  private findNextPossibleStorageSizeToOpt(selectedBasePlanStorageBytes: number, totalUsedStorageSize: number, additionalStoragePlanQty: number = 1): number {
    const nextPossibleStorageSizeToOpt = (this.additionalStoragePriceInfo?.storageBytes * additionalStoragePlanQty) || 0;
    if (!totalUsedStorageSize) {
      return 0;
    }

    if (totalUsedStorageSize < (selectedBasePlanStorageBytes + nextPossibleStorageSizeToOpt)) {
      return nextPossibleStorageSizeToOpt;
    }
    additionalStoragePlanQty++;
    return this.findNextPossibleStorageSizeToOpt(selectedBasePlanStorageBytes, totalUsedStorageSize,  additionalStoragePlanQty);
  }

}
