import {
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Translations } from '@graphics-flow/shared/assets';
import { Art, DialogData, Folder, ID } from '@graphics-flow/types';
import { FolderQuery, FolderService } from '@graphics-flow/util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map, take, first, distinctUntilChanged } from 'rxjs/operators';
import { WindowService } from 'shared/util';
import { FolderMoveDialogQuery } from './folder-move-dialog.query';
import { FolderMoveDialogService } from './folder-move-dialog.service';

export interface FolderMoveDialogData extends DialogData {
  art: Art[];
  folders: Folder[];
}

@UntilDestroy()
@Component({
  selector: 'gf-folder-move-dialog',
  templateUrl: './folder-move-dialog.component.html',
  styleUrls: ['./folder-move-dialog.component.scss']
})
export class FolderMoveDialogComponent implements OnInit, OnDestroy {
  sourceFolderIds: ID[] = [];
  selectedFolder: Folder;
  folders$: Observable<Folder[]> = this.folderQuery.rootFolders$
    .pipe(
      map(folders => {
      return folders.filter(folder => !this.sourceFolderIds.includes(folder?.folderId))
    })
  );
  showFolderLoader = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FolderMoveDialogData,
    private dialogRef: MatDialogRef<FolderMoveDialogComponent>,
    private readonly folderQuery: FolderQuery,
    public readonly folderMoveDialogQuery: FolderMoveDialogQuery,
    public readonly windowService: WindowService,
    public translations: Translations,
    public readonly folderService: FolderService,
    private readonly folderMoveDialogService: FolderMoveDialogService
  ) {
    // clear previous store
    this.folderMoveDialogService.reset();
    if (data?.folders?.length) {
      this.sourceFolderIds = data?.folders.map(folder => folder.folderId);
    }
    this.folderMoveDialogQuery.selectedFolder$.pipe(untilDestroyed(this)).subscribe(
      (folder: Folder) => {
        if (folder) {
          this.selectedFolder = folder;
        }
      }
    )
  }

  ngOnInit(): void {
    if (!this.folderService.isExistingRootFoldersLoaded) {
      this.showFolderLoader = true;
      this.folderService.loadFolders().pipe(
        first(),
        untilDestroyed(this),
      ).subscribe(() => {
        this.showFolderLoader = false;
        this.folderService.isExistingRootFoldersLoaded = true;
      });
    }
    this.windowService.smallDown$.pipe(
      distinctUntilChanged(),
      untilDestroyed(this),
    ).subscribe((smallDown) => {
      if (!this.selectedFolder && smallDown) {
        this.selectRootFolder();
      }
    })
  }

  ngOnDestroy() {
    this.folderMoveDialogService.setSelectedFolderId(null);
  }

  selectRootFolder() {
    this.selectedFolder = <Folder> {folderId: null};
    this.folderMoveDialogService.setSelectedFolderId(null);
  }

  goToParent() {
    this.folderMoveDialogQuery.navigationLevelFolder$.pipe(
      take(1)
    ).subscribe((folder: Folder) => {
      this.selectRootFolder();
      this.folderMoveDialogService.setNavigationLevel(folder?.parentId);
    });
  }

  close() {
    this.dialogRef.close();
  }

}
