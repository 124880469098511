<div id="genericWarningDialog" class="generic-warning-dialog">
  <div mat-dialog-title id="dialogHeader" class="flex-dir-column align-center-middle">
    <mat-icon [class]="data.iconClass" fontIcon="{{data.icon}}"></mat-icon>
    <div class="t-20-500-p m-b-1">{{ data.header | translate }}</div>
    <mat-divider class="w-100"></mat-divider>
  </div>

  <mat-dialog-content>
    <div id="dialogBody" class="container t-16-400-p" [innerHTML]="data.body | translate"></div>
  </mat-dialog-content>

  <mat-dialog-actions class="align-right">
    <button mat-raised-button
      id="cancelTextBtn"
      class="t-15-600-p large"
      color="secondary"
      [ngClass]="data.cancelClass"
      [mat-dialog-close]="false">
      {{ data.cancelText | translate }}
    </button>
    <button mat-raised-button
      id="continueTextBtn"
      class="t-15-600-p large"
      color="warn"
      [ngClass]="data.continueClass"
      [mat-dialog-close]="true">
      {{ data.continueText | translate }}
    </button>
  </mat-dialog-actions>
</div>
