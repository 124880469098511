import { Pipe, PipeTransform } from '@angular/core';
import { Art } from '@graphics-flow/types';
import { ArtHelper } from '@graphics-flow/util';

@Pipe({
  name: 'isNoPreviewImage'
})
export class IsNoPreviewImagePipe implements PipeTransform {

  transform(art: Art): boolean {
    return ArtHelper.isNoPreviewImage(art);
  }

}
