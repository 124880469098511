import { Pipe, PipeTransform } from '@angular/core';
import { Art, ArtExtension } from '@graphics-flow/types';

@Pipe({
  name: 'artExtension'
})
export class ArtExtensionPipe implements PipeTransform {
  transform(art: Art, extension: string = 'png'): string {

    if (!art) {
      return;
    }

    const ext: ArtExtension = art.artExtensions.find((ext: ArtExtension) => {
      return ext.extension === extension
    })
    return ext?.name;
  }
}
